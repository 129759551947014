import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import "./adminadasboardlisting.scss";
import { useHistory } from "react-router-dom";
import {
  uploadPersistencyFileToBlob,
  uploadPersistencyFilesToBlob,
  uploadCscCampaignActivitiesFilesToBlob,
  uploadAgencyCampaignActivitiesFilesToBlob,
  uploadCampaignActivitiesFilesToBlob,
  uploadCscPersistencyFilesToBlob,
  uploadAgencyPersistencyFilesToBlob,
  uploadCscCampaignFileToBlob,
  uploadAgencyCampaignFileToBlob,
  activateLoader,
  deActivateLoader,
} from "../redux/actions/common";

function DataGridModalPopup(
  props
) {
  const [files, setFiles] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  //const [show, setShow] = useState(false);
  const [filename, setFileName] = useState("");
  const [isshow, setIsShow] = useState(false);
  const history = useHistory();
  let isClicked = false;
  const handleMsgClose = (event, reason) => {
    if (severity === "success") {
      props.onPopupClose(false);
    }
    setOpen(false);
    setSeverity("");
    setSuccessMsg("");
  };
  const handleClose = () => {
    props.onPopupClose(false);
  };
  const handleFile = (e) => {
    let selectedFile = e.target.files;
    setFiles(selectedFile);
  };
  const uploadFile = async (doc) => {
    props.activateLoader();
    isClicked = true;
    console.log(isClicked);
    const file = doc ? doc[0] : "";
    let name;
    console.log(" name is ", name);
    const maxSize = 10e8 / 2;
    if (file && file.size < maxSize) {
      name = file.name;
      setFileName(name);
      const campaign = name.includes("campaignlead_");
      const persistency = name.includes("persistencylead_");
      const campaignactivity = name.includes("campaignactivities_");
      const persistencyleadagency = name.includes("persistencyleadagency_");
      const campaignleadagency = name.includes("campaignleadagency_");
      const persistencyleadcsc = name.includes("persistencyleadcsc_");
      const campaignleadcsc = name.includes("campaignleadcsc_");
      const campaignactivityagency = name.includes("campaignactivitiesagency_");
      const campaignactivitycsc = name.includes("campaignactivitiescsc_");
      console.log("file size is ", file.size);
      const fileName = file.name;
      let fileRes;
      if (campaign && props.id === "4") {
        props.onPopupClose(false);
        fileRes = await props.uploadPersistencyFileToBlob({
          file: file,
          fileName: fileName,
        });
      } else if (persistency && props.persistencyid === "1") {
        props.onPopupClose(false);
        fileRes = await props.uploadPersistencyFilesToBlob({
          file: file,
          fileName: fileName,
        });
      } else if (persistencyleadagency && props.persistencyid === "2") {
        props.onPopupClose(false);
        fileRes = await props.uploadAgencyPersistencyFilesToBlob({
          file: file,
          fileName: fileName,
        });
      } else if (persistencyleadcsc && props.persistencyid === "3") {
        props.onPopupClose(false);
        fileRes = await props.uploadCscPersistencyFilesToBlob({
          file: file,
          fileName: fileName,
        });
      } else if (campaignleadagency && props.id === "5") {
        props.onPopupClose(false);
        fileRes = await props.uploadAgencyCampaignFileToBlob({
          file: file,
          fileName: fileName,
        });
      } else if (campaignleadcsc && props.id === "6") {
        props.onPopupClose(false);
        fileRes = await props.uploadCscCampaignFileToBlob({
          file: file,
          fileName: fileName,
        });
      } else if (campaignactivitycsc && props.id === "9") {
        props.onPopupClose(false);
        fileRes = await props.uploadCscCampaignActivitiesFilesToBlob({
          file: file,
          fileName: fileName,
        });
      } else if (campaignactivityagency && props.id === "8") {
        props.onPopupClose(false);
        fileRes = await props.uploadAgencyCampaignActivitiesFilesToBlob({
          file: file,
          fileName: fileName,
        });
      } else if (campaignactivity && props.id === "7") {
        props.onPopupClose(false);
        fileRes = await props.uploadCampaignActivitiesFilesToBlob({
          file: file,
          fileName: fileName,
        });
      }
      if (!persistency && props.persistencyid === "1") {
        props.deActivateLoader();
        setOpen(true);
        setSeverity("error");
        setSuccessMsg(
          "you are uploading wrong file for banca persistency lead"
        );
      }
      if (!persistencyleadagency && props.persistencyid === "2") {
        props.deActivateLoader();
        setOpen(true);
        setSeverity("error");
        setSuccessMsg(
          "you are uploading wrong file for agency persistency lead"
        );
      }

      if (!persistencyleadcsc && props.persistencyid === "3") {
        props.deActivateLoader();
        setOpen(true);
        setSeverity("error");
        setSuccessMsg("you are uploading wrong file for csc persistency lead");
      }
      if (!campaign && props.id === "4") {
        props.deActivateLoader();
        setOpen(true);
        setSeverity("error");
        setSuccessMsg("you are uploading wrong file for  campaign lead banca");
      }
      if (!campaignleadagency && props.id === "5") {
        props.deActivateLoader();
        setOpen(true);
        setSeverity("error");
        setSuccessMsg("you are uploading wrong file for  campaign lead agency");
      }
      if (!campaignleadcsc && props.id === "6") {
        props.deActivateLoader();
        setOpen(true);
        setSeverity("error");
        setSuccessMsg("you are uploading wrong file for campaign lead csc");
      }
      if (!campaignactivity && props.id === "7") {
        props.deActivateLoader();
        setOpen(true);
        setSeverity("error");
        setSuccessMsg(
          "you are uploading wrong file for  campaign activties banca"
        );
      }

      if (!campaignactivityagency && props.id === "8") {
        props.deActivateLoader();
        setOpen(true);
        setSeverity("error");
        setSuccessMsg(
          "you are uploading wrong file for  campaign activities agency"
        );
      }

      if (!campaignactivitycsc && props.id === "9") {
        props.deActivateLoader();
        setOpen(true);
        setSeverity("error");
        setSuccessMsg(
          "you are uploading wrong file for campaign activities csc"
        );
      }

      if (fileRes.status === 200) {
        props.deActivateLoader();
        console.log("download link is", fileRes);
        console.log("success");
        setSeverity("success");
        setIsShow(true);
        console.log("status is", fileRes.status);
        console.log("file name", filename);
      }
    } else {
      setOpen(true);
      setSeverity("error");
      setSuccessMsg("Please select file first");
      props.deActivateLoader();
    }
  };

  useEffect(() => {
    if (severity) {
      setOpen(false);
      setSeverity("");
      setSuccessMsg("");
    }
  }, []);
  return (
    <div className="admindashCompain">
      <Fragment>
        <Modal
          show={props.showModalPopup}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton className="modalHeadPopup">
            <Modal.Title id="sign-in-title">Bulk uploads</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="admindashCompain">
              <div className="signUp">
                <p>Upload XLSX and csv file(limit upto 100MB/100,000 lines)</p>
              </div>
              <div className="signUp" style={{ marginBottom: "1px" }}>
                <input type="file" onChange={(e) => handleFile(e)} />
              </div>
              <div className="float-right">
                <button
                  className="btn btn-primary"
                  onClick={() => uploadFile(files)}
                >
                  upload
                </button>
                <Snackbar
                  open={open}
                  autoHideDuration={3000}
                  onClose={handleMsgClose}
                >
                  <Alert severity={severity}>{successMsg}</Alert>
                </Snackbar>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {isshow &&
          history.push({
            pathname: "/app/filedetails",
            File: filename,
            status: severity,
            filetype: props.filetype,
          })}
      </Fragment>
    </div>
  );
}
const mapStateToProps = (state) => ({
  leadFormData: state.leadFormReducer,
  masterDataState: state.commonReducer,
});
export default connect(mapStateToProps, {
  uploadPersistencyFileToBlob,
  uploadPersistencyFilesToBlob,
  uploadCscCampaignActivitiesFilesToBlob,
  uploadAgencyCampaignActivitiesFilesToBlob,
  uploadCampaignActivitiesFilesToBlob,
  uploadCscPersistencyFilesToBlob,
  uploadAgencyPersistencyFilesToBlob,
  uploadCscCampaignFileToBlob,
  uploadAgencyCampaignFileToBlob,
  activateLoader,
  deActivateLoader,
})(DataGridModalPopup);
