import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@mui/material/Typography";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../draftListing/draftActivityLead.scss";
import { useHistory } from "react-router-dom";
import { deleteDraftListByID } from "../../redux-reducer/reducer";
import { useDispatch } from "react-redux";
import AlertDialogBox from "../alertdialogbox/alertDialogBox";
import ModalPopup from 'f1-modal-react'
import { Button } from "@material-ui/core";
import { isUserSame } from "../../utils/utils";

export default function DraftListing({
  ListOfDrafts,
  TotalNoOfRows,
  loadMoreRecord,
  NoOfRecordperPage,
  getAllDraftsData,
  UserId,
  userViewFor
}) {
  console.log("listofdrafts", ListOfDrafts);
  const history = useHistory();
  const dispatch = useDispatch();

  const [isChecked, setIsChecked] = useState(ListOfDrafts?.slice().fill(false));

  const handleCheckboxChange = (val) => {
    setIsChecked(isChecked.map((v, i) => (i === val ? !v : v)));
  };

  const handleSelectAll = (e) => {
    const selectAll = ListOfDrafts?.slice().fill(e.target.checked);
    setIsChecked(selectAll);
  };

  const [deleteRecordId, setDeleteRecordID] = useState("");
  const deleteAllDraftRecord = () => {
    var isValueSelected = false;

    const draftsToDelete = [];
    isChecked.map((v, i) => {
      if (v === true) {
        draftsToDelete.push(ListOfDrafts[i].LeadId);
      }
      console.log("draftstodelete", draftsToDelete.join(","));
      setDeleteRecordID(draftsToDelete.join(","));

      for (const i of isChecked) {
        if (i === true) {
          isValueSelected = true;
        }
      }
      if (isValueSelected === true) {
        setIsModalOpen(!isModalOpen);
      } else {
        setShowModal(true)
      }
    });
  };

  const deleteDraftRecord = (id, e) => {
    e.stopPropagation()
    setDeleteRecordID(JSON.stringify(id));
    setIsModalOpen(!isModalOpen);
  };

  const handleConfirmDelete = () => {
    console.log("handledelete");
    getAllDraftsData();
    dispatch(deleteDraftListByID("lead", deleteRecordId));
    setIsModalOpen(!isModalOpen);
  };

  const getIntials = (name) => {
    if (name != null) {
      return name.slice(0, 1).toUpperCase();
    } else {
      return "";
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
    setDeleteRecordID("");
  };

  const goToLeadPage = (id, statusId, CreatedBy) => {
    if (isUserSame(CreatedBy, UserId)) {
      if (statusId === 11 && history.location.pathname.split('/')[2] !== "businesslead" && history.location.pathname.split('/')[2] !== "recruitmentlead") {
        history.push(`/app/lead/update/${id}`);
      } else if (history.location.pathname.split('/')[2] === "businesslead") {
        history.push(`/app/businesslead/agency/update/${id}`)
      } else if (history.location.pathname.split('/')[2] === "recruitmentlead") {
        history.push(`/app/recruitmentlead/agency/update/${id}`)
      }
      else {
        history.push(`/app/lead/detail/${id}`);
      }
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(3),
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
    activityLogo: {
      borderRadius: 10,
      textAlign: "center",
    },
    heading: {
      padding: "10px 20px 20px 10px",
      fontWeight: "bold",
    },
    subHeading: {
      fontSize: 14,
      fontWeight: "400",
      padding: "5px 10px 5px 0!important",
    },
    subHeadingBold: {
      fontWeight: "bold",
    },
  }));
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <div className="draftSec">
        <Grid container spacing={3} className="topDummy">
          <Grid item xs={8} md={6}>
            <div className="selectBox">
              <FormControlLabel
                label="Select All"
                control={
                  <Checkbox
                    onChange={(event) => {
                      handleSelectAll(event);
                    }}
                  />
                }
              />
            </div>
            <div className="smsBox">
              {/* <select>
                <option>Send SMS</option>
              </select> */}
              <button disabled={userViewFor === 'SELF' ? "" : "disabled"} onClick={deleteAllDraftRecord}>
                Delete <DeleteIcon />
              </button>
              <AlertDialogBox
                open={isModalOpen}
                handleClose={handleModalClose}
                deleteDraft={handleConfirmDelete}
              />
            </div>
          </Grid>
          <Grid item xs={4} md={6}>
            <div className="rightPanel" style={{ float: "right" }}>
              <span>
                {" "}
                {NoOfRecordperPage < TotalNoOfRows
                  ? NoOfRecordperPage
                  : TotalNoOfRows}{" "}
              </span>
              /
              <span>
                {TotalNoOfRows > 1
                  ? `${TotalNoOfRows}${" "}${"Results"}`
                  : `${TotalNoOfRows}${" "}${"Result"}`}
              </span>
            </div>
          </Grid>
        </Grid>
        {/* <Modal
          open={isModalOpen}
          onClose={handleModalClose}
        >
          Are you sure you want to delete
          <button>yes</button>
          <button>No</button>
        </Modal> */}
        <div className="listingCard">
          <Grid container spacing={3} textAlign="left">
            {ListOfDrafts &&
              ListOfDrafts.length > 0 &&
              ListOfDrafts.map((draft, index) => (
                <Grid item xs={12} md={6} key={draft?.id} onClick={() =>
                  goToLeadPage(draft.LeadId, draft.LeadStatusId, draft.CreatedBy)
                }>
                  <Paper className={classes.paper}>
                    <Grid
                      container
                      style={{ position: "relative" }}
                    >
                      <Grid
                        item
                        xs={1}
                        md={1}
                        className="checkBoxCol"
                        alignItems="center"
                      >
                        <Checkbox
                          checked={isChecked && isChecked[index]}
                          onClick={(e) => e.stopPropagation()}
                          onChange={() => {
                            handleCheckboxChange(index);
                          }}
                        />
                      </Grid>
                      <Grid item xs={2} md={2} className={`gridLogo`}>
                        <Typography
                          variant="h5"
                          component="h2"
                          className="boxName"
                        >
                          {getIntials(draft.FirstName)}{" "}
                          {getIntials(draft.LastName)}
                        </Typography>
                      </Grid>
                      <Grid item xs={9} md={9}>
                        <Grid container className="cardTop">
                          <Grid className="createDiv" item xs={12}>
                            <span
                              className="icnRight"
                            >
                              {/* <ArrowRightAltIcon /> */}
                              {isUserSame(draft.CreatedBy, UserId) && <><ArrowRightAltIcon /></>}
                            </span>
                            <Typography
                              variant="h5"
                              component="h2"
                              className={classes.heading}
                            >
                              {" "}
                              {draft.SalutationName !== null
                                ? draft.SalutationName
                                : ""}{" "}
                              {draft.FirstName !== null
                                ? draft.FirstName
                                : "Unnamed"}{" "}
                              {draft.LastName !== null ? draft.LastName : ""}
                            </Typography>
                            <div className={classes.subHeading}>
                              {isUserSame(draft.CreatedBy, UserId) && <>
                                <span
                                  className="icnDel"
                                  onClick={(e) => {
                                    deleteDraftRecord(draft.LeadId, e);
                                  }}
                                >
                                  <DeleteIcon />
                                </span>
                              </>}
                              <label>Created Date</label>
                              <br />
                              <span className={classes.subHeadingBold}>
                                {draft.CreatedDate
                                  ? moment(draft.CreatedDate).format(
                                    "DD MMM YYYY"
                                  )
                                  : "N/A"}{" "}
                              </span>{" "}
                              <span className="timeStamp">
                                {draft.CreatedDate
                                  ? moment(draft.CreatedDate).format("h:mma")
                                  : " "}
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))}
          </Grid>
          {NoOfRecordperPage <= TotalNoOfRows ? (
            <button className="btn loadMore" onClick={loadMoreRecord}>
              Load More
            </button>
          ) : (
            <div className='noMoreDiv'>No more records to load</div>
          )}
        </div>
      </div>
      <ModalPopup
        show={showModal}
        size={"sm"}                                       // "lg","sm" or "xl"
        centered={true}                                   // vertically center the Dialog in the window
        modelHeader={"Invalid Selection"}                     // to show model header
        modelContent={
          <div className="footerButtons">
            <p>Please select atleast one draft to delete</p>
            <Button onClick={() => setShowModal(false)}>OK</Button>
          </div>}
        backdrop={'static'}
      />
    </>
  );
}


