import React, { useState } from "react";
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';

import {
    setActivityData, setRescheduleForm, setCancelForm, setCompleteForm, setCallingVLEForm,
    setUpdateCallsForm,
    setFpmJointCallCompleteForm,
    setJointCallCancelForm,
    setJointCallRescheduleForm
} from "../../../../redux/actions";

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EditIcon from '@mui/icons-material/Edit';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import PrimaryDetailsCard from "./primaryDetailsCard";
import CheckInCard from "./checkInCard";
import ActivityDetailsCard from "./activityDetailsCard"
import HistoryCard from "./historyCard"
import { ALLOWED_CHECK_IN_ACTIVITY, STATUS_ACTIVITY_CANCELLED, STATUS_ACTIVITY_COMPLETED, FINISH_DAY_ACTIVITY, CIRCULAR_FOR_SALES_ENABLEMENT_BDM_CHECK, CIRCULAR_FOR_SALES_ENABLEMENT_BROKA_ABH_CHECK } from "../../../../utils/config";
import { getMobileOS, isUserSame } from "../../../../utils/utils";
import { getOptionById } from "../helper";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const DetailLayout = (props) => {
    const history = useHistory();
    const commonState = useSelector(state => state)
    const activityStatusTypes = commonState.commonReducer.ActivityStatus
    const { activityData, activityTitle, activityHistoryData } = props;
    const activityStatusOption = getOptionById(activityData.ActivityStatusId ? activityData.ActivityStatusId.toString() : '', activityStatusTypes)
    const [state, setState] = useState({})
    const isDisabled = activityStatusOption?.key === STATUS_ACTIVITY_CANCELLED || activityStatusOption?.key === STATUS_ACTIVITY_COMPLETED
    const dispatch = useDispatch();
    const userState = commonState.userReducer;
    const userData = JSON.parse(localStorage.getItem("persist:root"));
    const userId = JSON.parse(userData.userReducer).profileInfo.userId;
    const isCampiagn = activityData.CampaignActivityid !== undefined && activityData.CampaignActivityid !== null
    const roleId = JSON.parse(userData.userReducer).profileInfo.roleId;
    const isMobileOS = getMobileOS()

    const goToActivity = (type) => {
        dispatch(setActivityData(props.activityData));

        if (type === "ACTIVITY_CANCELLED") {
            if (activityData?.ActivityID === 25){
                dispatch(setJointCallCancelForm())
            } else {
                dispatch(setCancelForm());
            }
        } else if (type === "ACTIVITY_RESCHEDULED") {
            if (activityData?.ActivityID === 25){
                dispatch(setJointCallRescheduleForm())
            } else {
                dispatch(setRescheduleForm());
            }
        } else if (type === "ACTIVITY_CALLING_VLE") {
            dispatch(setCallingVLEForm());
        } else if (type === "ACTIVITY_UPDATE_CALLS") {
            dispatch(setUpdateCallsForm());
        }
        else {
            if (activityData?.ActivityID === 25){
                dispatch(
                    setFpmJointCallCompleteForm()
                )
            } else {
                dispatch(setCompleteForm());
            }
        }
        if (userState.profileInfo.channelid === 2) {
            history.push('/app/csc/activity/update')
        }
        else if (userState.profileInfo.channelid === 13) {
            history.push('/app/gcl/activity/update')
        }
        else if (userState.profileInfo.channelid === 79) {
            history.push('/app/msme/activity/update')
        }
        else if (userState.profileInfo.channelid === 3) {
            history.push('/app/agency/activity/update')
        }
        else if (userState.profileInfo.channelid === 21) {
            history.push('/app/broca/activity/update')
        }
        else {
            history.push('/app/activity/update')
        }
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor, activityStatusOption) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className="updatePopupDiv">
                <ListItem color="black" sx={{ fontWeight: 'bold', fontSize: 18 }} > Update Activity
                    <CloseIcon />
                </ListItem>
                <ListItem
                    disabled={isDisabled}
                    className={isDisabled ? 'disabled' : ''}
                    button
                    onClick={() => goToActivity("ACTIVITY_CANCELLED")}>
                    <ListItemIcon >
                        <Fab size="small" color="primary" aria-label="add" sx={{ mr: 2 }}>
                            <PendingActionsIcon />
                        </Fab> Activity Cancelled
                    </ListItemIcon>
                    <ListItemText />
                </ListItem>
                <ListItem
                    disabled={isDisabled}
                    className={isDisabled ? 'disabled' : ''}
                    button
                    onClick={() => goToActivity("ACTIVITY_RESCHEDULED")}>
                    <ListItemIcon>
                        <Fab size="small" color="primary" aria-label="add" sx={{ mr: 2 }}>
                            <PendingActionsIcon />
                        </Fab> Activity Rescheduled
                    </ListItemIcon>
                    <ListItemText />
                </ListItem>

                <ListItem
                    disabled={isDisabled}
                    className={isDisabled ? 'disabled' : ''}
                    button
                    onClick={() => goToActivity("ACTIVITY_COMPLETED")}
                >
                    <ListItemIcon >
                        <Fab size="small" color="primary" aria-label="add" sx={{ mr: 2 }}>
                            <PendingActionsIcon />
                        </Fab> Activity Completed
                    </ListItemIcon>
                    <ListItemText />
                </ListItem>

                {userState.profileInfo.channelid  === 2 && props.activityData.ActivityIdName==="Calling to VLE/RAP/DM" &&<ListItem
                    disabled={isDisabled}
                    className={isDisabled ? 'disabled' : ''}
                    button
                    onClick={() => goToActivity("ACTIVITY_UPDATE_CALLS")}>
                    <ListItemIcon >
                        <Fab size="small" color="primary" aria-label="add" sx={{ mr: 2 }}>
                            <PendingActionsIcon />
                        </Fab> Update Calls
                    </ListItemIcon>
                    <ListItemText />
                </ListItem>

                }
            </List>
            <Divider />
        </Box>
    );

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            textAlign: 'left',
            color: theme.palette.text.secondary,
            //height: '100%',
            padding: theme.spacing(2),

        },
        paper2: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary,
            // height: '300px',
        },
        activityLogo: {
            //  borderRadius: 10,
            textAlign: 'center',
            backgroundColor: 'rgb(0 66 126 / 27%)',
            height: '50px',
            padding: theme.spacing(2),
        },
        heading: {
            padding: "1px 2px 2px 1px",
        }
    }));
    const classes = useStyles();
    const getHistory = () => {
        props.getActivityHistory();
    }
    return <>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4} >
                    <PrimaryDetailsCard
                        activityData={activityData}
                        activityTitle={activityTitle}
                        classes={classes}
                    ></PrimaryDetailsCard>

                    {(!ALLOWED_CHECK_IN_ACTIVITY.includes(activityData.ActivityStatus) && !FINISH_DAY_ACTIVITY.includes(activityTitle) && roleId !='ADM' &&
                        !CIRCULAR_FOR_SALES_ENABLEMENT_BDM_CHECK.includes(activityData.ActivityID) && (isMobileOS !== "Other")) &&
                        <CheckInCard classes={classes} statusId={props.activityData.ActivityStatusId} CheckinCount={props.activityData.CheckinCount} onGetUpdatedHistory={getHistory} isCampiagn={isCampiagn}></CheckInCard>
                    }
                </Grid>

                <Grid item xs={12} md={4}>
                    <ActivityDetailsCard
                        activityData={activityData}
                        activityTitle={activityTitle}
                        classes={classes}
                        Item={Item}
                    >
                    </ActivityDetailsCard>
                </Grid>

                <Grid item xs={12} md={4}>
                    <HistoryCard
                        classes={classes}
                        activityHistoryData={activityHistoryData}
                        activityTitle={activityTitle}
                        Item={Item}
                    ></HistoryCard>
                </Grid>

            </Grid>
        </Box>
        <span>
            {['bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                    {
                        (!ALLOWED_CHECK_IN_ACTIVITY.includes(activityData.ActivityStatus) && 
                        !CIRCULAR_FOR_SALES_ENABLEMENT_BROKA_ABH_CHECK.includes(activityData.ActivityID) &&
                            isUserSame(activityData.AssignedTo, userId) && !FINISH_DAY_ACTIVITY.includes(activityTitle)) &&
                            // isUserSame(588229, userId) && !FINISH_DAY_ACTIVITY.includes(activityTitle)) &&
                        <Box className="editBtn" sx={{ '& > :not(style)': { m: 1 } }} onClick={toggleDrawer(anchor, true)} >
                            <Fab color="primary" aria-label="add" sx={{ float: 'right' }}>
                                <EditIcon />
                            </Fab>
                        </Box>
                    }
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor, activityStatusOption)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </span>
    </>
}

export default DetailLayout;
