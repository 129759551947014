import React, { useEffect, useState } from 'react';
import "font-awesome/css/font-awesome.min.css";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { getBDMReport } from '../../../redux/actions/dashboardAction';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


const TopPerformingBDMCard = ({ classes, userID, selectedView, selectedBDM, roleId }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [toggleCard, setToggleCard] = useState(true);
  const [getBDMList, setGetBDMList] = useState([])


  useEffect(() => {
    dispatch(getBDMReport({
      userId: userID,
      viewfor: "BDM",
      subordinateId: "all",
      RoleName: roleId

    }))
      .then((res) => {
        setGetBDMList(res.data)
      }).catch((err) => {
        console.log("err", err)
      })


  }, [])

  const onCardIconClick = (value) => {
    setToggleCard(!toggleCard)
  }

  const addSpaceBeforeCapital = (str) => {
    return str
      .split('')
      .map(char => (char === char.toUpperCase() && char !== ' ' ? ` ${char}` : char))
      .join('');
  }


  const redirectToBDMReport = () => {
      history.push('/app/bdmPerformance')
  }

  return (
    <Paper className={classes.paper2} sx={{ mb: 2 }}>
      <Grid className="activityOverviewSec" container>
        {/* <ZoomInIcon className="zoomIcon" /> */}
        <Grid item xs={12} md={12} >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                <span
                  onClick={() => onCardIconClick(toggleCard)}
                >Top Performing BDM
                  {toggleCard ? <RemoveIcon /> : <AddIcon />}
                </span>
              </Typography>
              <p className="viewAll"
              onClick={redirectToBDMReport}
              >View All</p>
            </Grid>
            {toggleCard ?
              <Grid item xs={12} >
                {getBDMList.slice(0,3).map((bdm) => (

                  <span className='bdmList'>{addSpaceBeforeCapital(bdm.BDMName)}</span>
                ))}
              </Grid>
              :
              ''
            }


          </Grid>

        </Grid>
      </Grid>
    </Paper>
  )
}

export default TopPerformingBDMCard



