import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import CallIcon from '@mui/icons-material/Call';
import moment from "moment";
import { getDateDuration } from "../../../../utils/utils";
import { ABH_SALES_ACTIVITY_TYPES_ID } from "../../../../utils/config";


const PlanCard = ({
  planStatus,
  planType,
  customerAddress,
  meetingStartDateTime,
  meetingEndDateTime,
  planName,
  planId,
  history,
  mobileNo,
  channelid,
  activityTypeId
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(3),
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
    activityLogo: {
      borderRadius: 10,
      textAlign: "center",
    },
    heading: {
      padding: "10px 20px 20px 10px",
    },
    subHeading: {
      fontSize: 16,
      fontWeight: "400",
      padding: "5px 10px !important",
    },
    subHeadingBold: {
      fontWeight: "bold",
    },
  }));
  const classes = useStyles();
  const onItemClick = (id, type, activityTypeId) => {
    switch (type?.toLowerCase()) {
      case 'activity':
        if (ABH_SALES_ACTIVITY_TYPES_ID.includes(activityTypeId)) {
          history.push(`/app/salesmanagement/detail/${id}`)
        } else {
            history.push(`/app/activity/detail/${id}`)
        }
        break;
      case 'persistencelead':
        history.push(`${'/app/lead/detail/'}${id}`);
        break;
      case 'campaignlead':
        history.push(`${'/app/lead/detail/'}${id}`);
        break;
      case 'lead':
        history.push(`${'/app/lead/detail/'}${id}`);
      break;
      default:
        break;
    }
  }
  const onCallClick = (number) => {
    if (number) {
      window.open(`tel:${number}`)
    }
  }
  return (
    <div>
      <div className="listingCard">
        <Grid container spacing={3} textAlign="left">
          <Grid item xs={12} className="dataListContainer">
            <Paper className={classes.paper}>
              <Grid container style={{ position: "relative" }}>
                <ArrowRightAltIcon className="arrowRtIcon" onClick={()=> onItemClick(planId, planType, activityTypeId)}/>
                <div>
                  <Typography className="activityText">
                    {planStatus}{" "}
                    <span className="spanActivity">{channelid === 3 ? 'Business Lead' : planType}</span>
                  </Typography>
                </div>
                <div>
                  <Typography className="title">{planName}</Typography>
                </div>
                <div>
                  <Typography className="cardTime">
                    <AccessTimeIcon className="timeIcon" />
                    {moment(meetingStartDateTime).format("LT") +
                      "-" +
                      moment(meetingEndDateTime).format("LT")}
                    <span className="cardTimeDuration">
                      {"(" +
                        getDateDuration(
                          meetingStartDateTime,
                          meetingEndDateTime,
                          "HOUR"
                        ) +
                        " " +
                        getDateDuration(
                          meetingStartDateTime,
                          meetingEndDateTime,
                          "MINUTE"
                        ) +
                        ")"}
                    </span>
                  </Typography>
                </div>
                <div>
                  <Typography>
                    <LocationOnIcon className="locationIcon" />{" "}
                    <span className="mapAddressText">{customerAddress}</span>{" "}
                    <SendIcon className="sendBG" />
                  </Typography>
                </div>
              </Grid>
            </Paper>
            <Grid container className="updateCallSec">
              {
                mobileNo && mobileNo !== '' && (
                  <>
                <Grid item xs={6} onClick={()=> onCallClick(mobileNo)}>
                  <span>
                    <CallIcon /> Call
                  </span>
                </Grid>
                <Grid item xs={6} onClick={()=> onItemClick(planId, planType, activityTypeId)}>
                <span>
                  <EditIcon /> Update
                </span>
                </Grid>
                </>
                )
              }
              {
                !mobileNo &&
                <Grid item xs={12} onClick={()=> onItemClick(planId, planType, activityTypeId)}>
                  <span>
                    <EditIcon /> Update
                  </span>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PlanCard;
