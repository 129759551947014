/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { PERMISSIONS } from "../auth";
import MenuDrawer from "../components/menuDrawer/menuDrawer";
import scheduleActivityTable from "../components/scheduleActivityTable/scheduleActivityTable";
import createActivity from "../pages/postlogin/activity/createActivity";
import createActivityGCL from "../pages/postlogin/activity/createActivityGCL";
import ResumeDraftedActivity from "../pages/postlogin/activity/resumeDraftedActivity";
import CampaignActivitiesDetails from "../pages/postlogin/activity/activityDetails/campaignActivitiesDetails";
import ResumeDraftedActivityGCL from "../pages/postlogin/activity/resumeDraftedActivityGCL";
import ResumeDraftedActivityCSC from "../pages/postlogin/activity/resumeDraftedActivityCSC";
import ResumeDraftedActivityAgency from "../pages/postlogin/activity/resumeDraftedActivityAgency";
import ResumeDraftedActivityBroca from "../pages/postlogin/activity/brocaSMA/resumeDraftActivityBroca"
import SalesManagementDetails from "../pages/postlogin/salesManagement/salesManagementDetails";
import ActivityDetails from "../pages/postlogin/activity/activityDetails/activityDetails";
import home from "../pages/postlogin/Home/home";
import AdminPersistancyLeadDetails from "../admin/lead/persistencyLead/adminPersistancyLeadDetails";
import LeadListing from "../pages/postlogin/leads/leadListing";
import LeadListingPersistancy from "../pages/postlogin/leads/persistancyLead/leadListingPersistancy";
import CampaignLeadListing from "../pages/postlogin/leads/campaignlead/campaignLeadListing";
import UpdateActivity from "../pages/postlogin/activity/updateActivity";
import CSCUpdateActivity from "../pages/postlogin/activity/csc/updateCSCActivity";
import GCLUpdateActivity from "../pages/postlogin/activity/gcl/updateGCLActivity";
import MSMEUpdateActivity from "../pages/postlogin/activity/msme/updateMSMEActivity";
import AgencyUpdateActivity from "../pages/postlogin/activity/agency/updateAgencyActivity";
import UpdateBrocaActivity from "../pages/postlogin/activity/broca/updateBrocaActivity";
import { AuthorizedRoute } from "../utils/routeValidator";
import LeadDetail from "../pages/postlogin/leads/leadDetail";
import PersistancyLeadDetail from "../pages/postlogin/leads/persistancyLead/persistancyLeadDetail";
import CampaignLeadDetail from "../pages/postlogin/leads/campaignlead/campaignLeadDetail";
import HomeIcon from "@mui/icons-material/Home";
import { menuList, menuListAdmin, dashboardSettingMenuList } from "../utils/menuList";
import MessageBar from "../components/messageBar/messageBar";
import Loader from "../components/loader/loader";
import createLead from "../pages/postlogin/leads/createLead";
import createLeadGCL from "../pages/postlogin/leads/gcl/createLeadGCL";
import createLeadAgency from "../pages/postlogin/leads/agency/createLeadAgency";
import updateLead from "../pages/postlogin/leads/updateLead";
import updateLeadGCL from "../pages/postlogin/leads/gcl/updateLeadGCL";
import updateLeadAgency from "../pages/postlogin/leads/agency/updateLeadAgency";
import CommonCalendar from "../pages/postlogin/commonCalendar/commonCalendar";
import { connect, useSelector } from "react-redux";
import ProfileSetting from "../pages/postlogin/Home/profileSetting";
import createActivityCSC from "../pages/postlogin/activity/createActivityCSC";
import createActivityAgency from "../pages/postlogin/activity/createActivityAgency";
import MyTeamList from "../pages/postlogin/Home/MyTeamList";
import ReAssignLead from "../pages/postlogin/leads/reAssignLead";
import AllNotifications from "../components/notification/allNotifications";
import SearchListing from "../components/listing/searchListing";
import SsoAuth from "../pages/postlogin/SSO/ssoAuth";
import Terms from "../pages/Terms/terms";
import PrivacyPolicy from "../pages/privacyPplicy/privacyPolicy";
import CreateSalesManagementActivity from "../pages/postlogin/salesManagement/createSalesManagementActivity";
import UpdatesalesManagementDetail from "../pages/postlogin/salesManagement/updatesalesManagementActivity";
import UpdatesalesManagementDetailBroca from "../pages/postlogin/activity/brocaSMA/updateSmaActivityBroca";
/*ADMIN Imports */
import homeAdmin from "../pages/postlogin/Home/admin/home";
import { PersistancyBancaListing, PersistancyCscListing, PersistancyAgencyListing } from "../admin/lead/persistencyLead/persistencyChannelWiseGridListing";
import PersistancyListingAll from "../admin/lead/persistencyLead/persistencyChannelWiseGridListing";
import {CampaignLeadAgencyListing ,CampaignLeadBancaListing } from "../admin/lead/campaignLead/campaignLeadChnnelWiseGridListing"
import CampaignLeadListingAll  from "../admin/lead/campaignLead/campaignLeadChnnelWiseGridListing";
import FileDetails from "../admin/fileDetails";
import { CampaignActivityAgencyListing, CampaignActivityBancaListing, CampaignActivityCSCListing ,CampaignActivityBrocaListing ,ActivityCscListing} from "../admin/activity/campaign/campaignActivityChannelWiseGridListing";
import ActivityListingAll from "../admin/activity/campaign/campaignActivityChannelWiseGridListing";
import { LeadAgencyListing, LeadBancaListing, RecruitmentLeadAgencyListing ,LeadBrocaListing} from "../admin/lead/commonLead/leadChannelWiseGrid";
import LeadListingAll from "../admin/lead/commonLead/leadChannelWiseGrid";

import PehlaKadamQuestion from "../pages/postlogin/pehlaKadam/pehlaKadamQuestion.js";

import LeadDetailAdmin from "../admin/lead/commonLead/leadDetailAdmin";
import createLeadBroca from "../pages/postlogin/leads/broca/createLeadBroca";
import CreateActivityBroca from "../pages/postlogin/activity/broca/createActivityBroca";
import resumeDraftedActivityBROCA from "../pages/postlogin/activity/resumeDraftedActivityBROCA";

import CreateSmaActivityBroca from "../pages/postlogin/activity/brocaSMA/createSmaActivityBroca"
import CampaignActivityTable from "../components/scheduleActivityTable/scheduleActivityCampiagnTable";
// import createLeadmsme from "../pages/postlogin/leads/msme/createLeadmsme";
import createActivityMSME from "../pages/postlogin/activity/msme/createActivityMSME";
import resumeDraftedActivityMSME from "../pages/postlogin/activity/resumeDraftedActivityMSME";
import updateLeadMSME from "../pages/postlogin/leads/msme/updateLeadMSME";
// import CreateLeadmsme from "../pages/postlogin/leads/msme/createLeadmsme";
// import createActivityMSME from "../pages/postlogin/activity/msme/createActivityMSME";
import CreateLeadMSME from "../pages/postlogin/leads/msme/createLeadMSME";
import Livetracking from "../pages/postlogin/livetracking/livetracking.js";
import BdmPerformance from "../pages/postlogin/Home/bdmPerformance.js";

function AgentLayout(props) {
  const { window } = props;
  const menuListObject = menuList;
  const channelId = props.userState.profileInfo.channelid;
  const roleId = props.userState.profileInfo.roleId;
  const commonState = useSelector(state => state);
  const { getLoginCount, getMenuDisabled } = commonState.leadListReducer;
  const [menu, setmenu] = useState([]);
  useEffect(() => {
    let menu1 = [];
    let flag = 0;
    if (getLoginCount.isFirstLogin) {
      flag = 1;
      menu1 = menuListObject.filter((val) => {
        if (val.id === 8) {
          return val;
        }
      })
    }
    if (roleId === "BDM" || roleId === "FPM"){
      menu1 = menuListObject.filter((val) => {
        return (val.id !== 18);
      })
    }
    if (channelId === 3) {
      flag = 1;
      if (roleId === "BDM") {
        menu1 = menuListObject.filter((val) => {
          return !(val.id === 3 || val.id === 9 ||val.id === 18);
        })
      } else {
        menu1 = menuListObject.filter((val) => {
          return !(val.id === 3);
        })
      }
    }
    if (channelId !== 3) {
      flag = 1;
      if (roleId === "BDM" || roleId === "FPM") {
        menu1 = menuListObject.filter((val) => {
          return !(val.id === 9 || val.id === 13 || val.id === 14 ||val.id === 18);
        })
      } else {
        menu1 = menuListObject.filter((val) => {
          return !(val.id === 13 || val.id === 14);
        })
      }
    }
    if (channelId === 1 && roleId === "RBH") {
      flag = 1;
      menu1 = menuListObject.filter((val) => {
        return !(val.id === 13 || val.id === 14);
      })
    }
    if (roleId === "ZBH" || roleId === "RBH" || roleId === "NH" && (channelId !== 1 && channelId !== 21)) {
      flag = 1;
      menu1 = menuListObject.filter((val) => {
        return !(val.id === 9 || val.id === 3 || val.id === 4 || val.id === 6 || val.id === 13 || val.id === 14 || val.id === 15 ||
          (channelId === 21 ? val.id === 2 : ''));
      })
    }
    if (roleId === "ZBH" || roleId === "RBH" || roleId === "NH" && (channelId === 1 || channelId === 21)) {
      flag = 1;
      menu1 = menuListObject.filter((val) => {
        return !( val.id === 3 || val.id === 4 || val.id === 6 || val.id === 13 || val.id === 14 || val.id === 15 ||
          (channelId === 21 ? val.id === 2 : ''));
      })
    }
    if (flag === 0) {
      menu1 = menuListObject
    }
    setmenu(menu1)
  }, [getLoginCount, getMenuDisabled]);

  useEffect(() => {
    let menu1 = [];
    let flag = 0;
    if (getLoginCount.isFirstLogin) {
      flag = 1;
      menu1 = menuListObject.filter((val) => {
        if (val.id === 8) {
          return val;
        }
      })
    }
    if (channelId === 3) {
      flag = 1;
      if (roleId === "BDM") {
        menu1 = menuListObject.filter((val) => {
          return !(val.id === 3 || val.id === 9 ||val.id === 18);
        })
      } else {
        menu1 = menuListObject.filter((val) => {
          return !(val.id === 3);
        })
      }
    }
    if (channelId !== 3) {
      flag = 1;
      if (roleId === "BDM" || roleId === "FPM") {
        menu1 = menuListObject.filter((val) => {
          return !(val.id === 9 || val.id === 13 || val.id === 14 ||val.id === 18);
        })
      } else {
        menu1 = menuListObject.filter((val) => {
          return !(val.id === 13 || val.id === 14);
        })
      }
    }
    if (channelId === 1 && roleId === "RBH") {
      flag = 1;
      menu1 = menuListObject.filter((val) => {
        return !(val.id === 13 || val.id === 14);
      })
    }
    if (roleId === "ZBH" || roleId === "RBH" || roleId === "NH" && (channelId !== 1 && channelId !== 21)) {
      flag = 1;
      menu1 = menuListObject.filter((val) => {
        return !(val.id === 9 || val.id === 3 || val.id === 4 || val.id === 6 || val.id === 13 || val.id === 14 || val.id === 15 ||
          (channelId === 21 ? val.id === 2 : ''));
      })
    }
    if (roleId === "ZBH" || roleId === "RBH" || roleId === "NH" && (channelId === 1 || channelId === 21)) {
      flag = 1;
      menu1 = menuListObject.filter((val) => {
        return !( val.id === 3 || val.id === 4 || val.id === 6 || val.id === 13 || val.id === 14 || val.id === 15 ||
          (channelId === 21 ? val.id === 2 : ''));
      })
    }
    if (flag === 0) {
      menu1 = menuListObject
    }
    setmenu(menu1)
  }, []);
  const getCreateActivityBychannelId = (chanelID) => {
    switch (channelId) {
      case 1:
        return createActivity;
      case 2:
        return createActivityCSC;
      case 3:
        return createActivityAgency;
      case 13:
        return createActivityGCL;
      case 21:
        return CreateActivityBroca;
      case 79:
        return createActivityMSME
      default:
        return createActivity;
    }
  };
  const getCreateSMAActivityBychannelId = (chanelId) => {
    switch (channelId) {
      case 1:
        return CreateSalesManagementActivity;
      case 21:
        return CreateSmaActivityBroca;
      default:
        return CreateSalesManagementActivity;
    }
  };
  const getCreateLeadBychannelId = (channelid) => {
    switch (channelid) {
      case 1:
        return createLead;
      case 13:
        return createLeadGCL;
      case 21:
        return createLeadBroca;
      case 79:
        return CreateLeadMSME
      default:
        return createLead;
    }
  };

  const getResumeDraftedActivity = (channelid) => {
    switch (channelid) {
      case 1:
        return ResumeDraftedActivity;
      case 2:
        return ResumeDraftedActivityCSC;
      case 3:
        return ResumeDraftedActivityAgency;
      case 13:
        return ResumeDraftedActivityGCL;
      case 79:
        return resumeDraftedActivityMSME;
      case 21:
        return resumeDraftedActivityBROCA;
      default:
        return ResumeDraftedActivity;
    }
  }
  const getResumeDraftedActivitySma = (channelid) => {
    switch (channelid) {
      case 1:
        return ResumeDraftedActivity;
      case 21:
        return ResumeDraftedActivityBroca
      default:
        return ResumeDraftedActivity;
    }
  }
  const getResumeDraftedLead = (channelid) => {
    switch (channelid) {
      case 1:
        return createLead
      case 13:
        return createLeadGCL
      case 21:
        return createLeadBroca
      case 79:
        return CreateLeadMSME
      default:
        return createLead
    }
  }

  return (
    <div className="agent-layout">
      <div className="row rowDiv">
        <div className="">
          <div className="sidebar">
            <MenuDrawer
              iconType={HomeIcon}
              menuWindow={window}
              roleId={roleId}
              menuList={roleId === "ADM" ? menuListAdmin : menu}
              dashboardSettingList={dashboardSettingMenuList}
            />
          </div>
        </div>
        <div className="content col-md-12">
          <div className="agent-container">
            <AuthorizedRoute
              path="/app/home"
              exact
              component={home}
              permission={PERMISSIONS.DASHBOARD}
            />
            <AuthorizedRoute
              path="/app/home/admin"
              exact
              component={homeAdmin}
              permission={PERMISSIONS.ADMDASHBOARD}
            />

            <AuthorizedRoute
              path="/app/home/reports"
              exact
              component={homeAdmin}
              permission={PERMISSIONS.DASHBOARD}
            />

            <AuthorizedRoute
              path="/app/admin/persistencylead/csc/all"
              exact
              component={PersistancyCscListing}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/report/persistencylead/all"
              exact
              component={PersistancyListingAll}
              permission={PERMISSIONS.DASHBOARD}
            />
            <AuthorizedRoute
              path="/app/admin/persistencylead/banca/all"
              exact
              component={PersistancyBancaListing}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/admin/persistencylead/agency/all"
              exact
              component={PersistancyAgencyListing}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/admin/campaignlead/banca/all"
              exact
              component={CampaignLeadBancaListing}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/report/campaignlead/all"
              exact
              component={CampaignLeadListingAll}
              permission={PERMISSIONS.DASHBOARD}
            />
            <AuthorizedRoute
              path="/app/admin/campaignlead/agency/all"
              exact
              component={CampaignLeadAgencyListing}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/admin/businesslead/banca/all"
              exact
              component={LeadBancaListing}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/report/businesslead/all"
              exact
              component={LeadListingAll}
              permission={PERMISSIONS.DASHBOARD}
            />
              <AuthorizedRoute
              path="/app/report/recruitmentlead/all"
              exact
              component={LeadListingAll}
              permission={PERMISSIONS.DASHBOARD}
            />
            <AuthorizedRoute
              path="/app/admin/businesslead/broca/all"
              exact
              component={LeadBrocaListing}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/admin/businesslead/agency/all"
              exact
              component={LeadAgencyListing}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/admin/recruitmentlead/agency/all"
              exact
              component={RecruitmentLeadAgencyListing}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/admin/campaignactivity/banca/all"
              exact
              component={CampaignActivityBancaListing}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/admin/activity/csc/all"
              exact
              component={ActivityCscListing}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/report/activity/all"
              exact
              component={ActivityListingAll}
              permission={PERMISSIONS.DASHBOARD}
            />
            <AuthorizedRoute
              path="/app/admin/campaignactivity/csc/all"
              exact
              component={CampaignActivityCSCListing}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path='/app/report/campaignactivity/all'
              exact
              component={CampaignActivityCSCListing}
              permission={PERMISSIONS.DASHBOARD}
            />

            <AuthorizedRoute
              path="/app/admin/campaignactivity/agency/all"
              exact
              component={CampaignActivityAgencyListing}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/admin/campaignactivity/broca/all"
              exact
              component={CampaignActivityBrocaListing}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/filedetails"
              exact
              component={FileDetails}
              permission={PERMISSIONS.ADMDASHBOARD}
            />

            <AuthorizedRoute
              path="/app/ssoAuth"
              exact
              component={SsoAuth}
              permission={PERMISSIONS.DASHBOARD}
            />
            <AuthorizedRoute
              path="/app/activity/create"
              exact
              component={getCreateActivityBychannelId(channelId)}
              permission={PERMISSIONS.CREATE_ACTIVITY}
            />
            <AuthorizedRoute
              path="/app/activity/drafted/:id"
              exact
              component={getResumeDraftedActivity(channelId)}
              permission={PERMISSIONS.CREATE_ACTIVITY}
            />
            <AuthorizedRoute
              path="/app/activity/smaActivities/drafted/:id"
              exact
              component={getResumeDraftedActivitySma(channelId)}
              permission={PERMISSIONS.CREATE_ACTIVITY}
            />
            <AuthorizedRoute
              path="/app/activity/all"
              exact
              component={scheduleActivityTable}
              permission={PERMISSIONS.CREATE_ACTIVITY}
            />
            <AuthorizedRoute
              path="/app/campaignactivity/all"
              exact
              component={CampaignActivityTable}
              permission={PERMISSIONS.CREATE_ACTIVITY}
            />
            <AuthorizedRoute
              path="/app/activity/smaActivities"
              exact
              component={scheduleActivityTable}
              permission={PERMISSIONS.CREATE_ACTIVITY}
            />
            <AuthorizedRoute
              path="/app/activity/update"
              exact
              component={UpdateActivity}
              permission={PERMISSIONS.CREATE_ACTIVITY}
            />
            <AuthorizedRoute
              path="/app/broca/activity/update"
              exact
              component={UpdateBrocaActivity}
              permission={PERMISSIONS.CREATE_ACTIVITY}
            />
            <AuthorizedRoute
              path="/app/activity/campaigndetails/:id"
              exact
              component={ActivityDetails}
              permission={PERMISSIONS.CREATE_ACTIVITY}
            />
            <AuthorizedRoute
              path="/app/activity/csc/campaigndetails/:id"
              exact
              component={ActivityDetails}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/csc/activity/update"
              exact
              component={CSCUpdateActivity}
              permission={PERMISSIONS.CREATE_ACTIVITY}
            />
            <AuthorizedRoute
              path="/app/gcl/activity/update"
              exact
              component={GCLUpdateActivity}
              permission={PERMISSIONS.CREATE_ACTIVITY}
            />
            <AuthorizedRoute
              path="/app/msme/activity/update"
              exact
              component={MSMEUpdateActivity}
              permission={PERMISSIONS.CREATE_ACTIVITY}
            />
            <AuthorizedRoute
              path="/app/agency/activity/update"
              exact
              component={AgencyUpdateActivity}
              permission={PERMISSIONS.CREATE_ACTIVITY}
            />

            <AuthorizedRoute
              path="/app/activity/detail/:id"
              exact
              component={ActivityDetails}
              permission={PERMISSIONS.CREATE_ACTIVITY}
            />
            <AuthorizedRoute
              path="/app/activity/details/:id"
              exact
              component={ActivityDetails}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/campaignactivity/details/:id"
              exact
              component={CampaignActivitiesDetails}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/lead/create"
              component={getCreateLeadBychannelId(channelId)}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/businesslead/create"
              component={createLeadAgency}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/recruitmentlead/create"
              component={createLeadAgency}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/lead/all"
              exact
              component={LeadListing}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/businesslead/all"
              exact
              component={LeadListing}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/recruitmentlead/all"
              exact
              component={LeadListing}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/lead/update/:id"
              exact
              component={getResumeDraftedLead(channelId)}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/businesslead/agency/update/:id"
              exact
              component={createLeadAgency}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/recruitmentlead/agency/update/:id"
              exact
              component={createLeadAgency}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/lead/gcl/update/:id"
              exact
              component={createLeadGCL}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/lead/agency/update/:id"
              exact
              component={createLeadAgency}
              permission={PERMISSIONS.CREATELEAD}
            />

            <AuthorizedRoute
              path="/app/lead/detail/:id"
              exact
              component={LeadDetail}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/lead/details/:id"
              exact
              component={LeadDetailAdmin}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/lead/update/:leadType/:updateAction/:id"
              exact
              component={updateLead}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/lead/gcl/update/:leadType/:updateAction/:id"
              exact
              component={updateLeadGCL}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/lead/msme/update/:leadType/:updateAction/:id"
              exact
              component={updateLeadMSME}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/lead/agency/update/:leadType/:updateAction/:id"
              exact
              component={updateLeadAgency}
              permission={PERMISSIONS.CREATELEAD}
            />

            <AuthorizedRoute
              path="/app/calendar"
              component={CommonCalendar}
              permission={PERMISSIONS.CREATELEAD}
            />

            <AuthorizedRoute
              path="/app/profilesetting"
              component={ProfileSetting}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/persistencylead/all"
              exact
              component={LeadListingPersistancy}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/campaignLead/all"
              exact
              component={CampaignLeadListing}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/persistencyLead/detail/:id"
              exact
              component={PersistancyLeadDetail}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/PersistencyLead/details/:id"
              exact
              component={AdminPersistancyLeadDetails}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/campaignLead/detail/:id"
              exact
              component={CampaignLeadDetail}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/campaignLead/details/:id"
              exact
              component={CampaignLeadDetail}
              permission={PERMISSIONS.ADMDASHBOARD}
            />
            <AuthorizedRoute
              path="/app/lead/reassignLead"
              exact
              component={ReAssignLead}
              permission={PERMISSIONS.CREATELEAD}
            />
            <AuthorizedRoute
              path="/app/home/myTeam/:type"
              exact
              component={MyTeamList}
              permission={PERMISSIONS.DASHBOARD}
            />
            <AuthorizedRoute
              path="/app/allNotifications"
              exact
              component={AllNotifications}
              permission={PERMISSIONS.DASHBOARD}
            />
            <AuthorizedRoute
              path="/app/searchListing"
              exact
              component={SearchListing}
              permission={PERMISSIONS.DASHBOARD}
            />
            <AuthorizedRoute
              path="/app/privacyPolicy"
              exact
              component={PrivacyPolicy}
              permission={PERMISSIONS.DASHBOARD}
            />
            <AuthorizedRoute
              path="/app/terms"
              exact
              component={Terms}
              permission={PERMISSIONS.DASHBOARD}
            />
            <AuthorizedRoute
              path="/app/salesmanagement/create"
              component={getCreateSMAActivityBychannelId(channelId)}
              permission={PERMISSIONS.SALESMANAGEMENTACTIVITY}
            />
            <AuthorizedRoute
              path="/app/salesmanagement/detail/:id"
              exact
              component={SalesManagementDetails}
              permission={PERMISSIONS.DASHBOARD}
            />
            <AuthorizedRoute
              path="/app/salesmanagement/update/:id"
              exact
              component={UpdatesalesManagementDetail}
              permission={PERMISSIONS.DASHBOARD}
            />
            <AuthorizedRoute
              path="/app/broca/salesmanagement/update/:id"
              exact
              component={UpdatesalesManagementDetailBroca}
              permission={PERMISSIONS.DASHBOARD}
            />
            <AuthorizedRoute
              path="/app/pehlakadam/question/:id"
              exact
              component={PehlaKadamQuestion}
              permission={PERMISSIONS.DASHBOARD}
            />
            <AuthorizedRoute
              path="/app/livetracking"
              exact
              component={Livetracking}
              permission={PERMISSIONS.DASHBOARD}
            />
            <AuthorizedRoute
              path="/app/bdmPerformance"
              exact
              component={BdmPerformance}
              permission={PERMISSIONS.DASHBOARD}
            />
            
            
            {/* <AuthorizedRoute path="/app/demo" exact component={CreateActivityMSME} permission={PERMISSIONS.DASHBOARD} /> */}
          </div>
        </div>
      </div>
      <MessageBar></MessageBar>
      <Loader></Loader>
    </div>
  );
}
const mapStateToProps = (state) => ({
  userState: state.userReducer,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AgentLayout);
