import {
	FINISH_DAY,
	PUBLIC_TRANSPORT,
	CLAIM_TRAVEL_EXPENCE_OPTIONS,
	CONFIGS, DROPDOWN_ACTIVITY_TYPE, STATE_HEAD, DISTRICT_MANAGER, TRAINING, TRAINING_FOR, STATE_MANAGER, RAP_VLE, MEETING_VISIT, DROPDOWN_EXPENCE_CLAIM,
	DROPDOWN_MODE_OF_TRANSPORT,
	MONTHLY_REVIEW_FOR, MONTHLY_REVIEW, CALLING_TO_VLE_RAP_DM, MEETING_VISIT_FOR, ROLE_BDM
} from "../../../../utils/config";
import {
	CscTrainingForm,
	CscTrainingStateHead,
	CscTrainingStateManager,
	CscTrainingDistrictManager,
	CscTrainingRAPVLE
} from './trainingForm';
import {
	CscMothlyReviewForm,
	CscMonthlyReviewStateHead,
	CscMonthlyReviewStateManager,
	CscMonthlyReviewDistrictManager
} from './monthlyReviewForm';
import {
	CscMeetingVisitForm,
	CscMeetingVisitStateHead,
	CscMeetingVisitStateManager,
	CscMeetingVisitDistrictManager,
	CscMeetingVisitRAPVLE
} from './meetingVisitForm';
import {
	CSCFinishDayForm, CSCExpenceClaimForm,
	CSCPublicTransportForm, CSCSelfOwnedTransportForm
} from './finishDayActivityForm';
import { CscCallingToVLE_RAP_DMForm } from "./callingToVLE_RAP_DMForm"

export const CscActivityForm = [
	{
		"key": "ActivityID", "type": "select", "disabled": false,
		"id": "ActivityID", "label": "Activity Type",
		"validators": [{ "name": "required", "value": true, "validationMessage": "Activity type is required." }], "options": []
	},
]


/*#################End of trainingactivity##########################3*/

export const CsaMonthlyReview = [
	{ "key": "reviewWith", "type": "autocomplete", "hide": false, "disabled": false, "id": "reviewWith", "label": "Review with" }
]

export const initFormData = (formFields, masterDataState) => {
	const formData = [...formFields]
	formData[0].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.ACTIVITY.TABLE_NAME]
	return formData
}

export const getAutoCompleteItems = (masterDataState, tableName) => {
	const options = []
	for (const option of masterDataState[tableName]) {
		options.push({ id: option.value.toString(), label: option.label })
	}
	return options
}

export const getDropDownOptions = (data, dropDownConfig, channelID = '') => {
	const options = [
		{ "key": "Select", "label": "Select", "value": "" }
	]
	for (const option of data) {
		if (channelID !== '' && channelID !== option[dropDownConfig.CHANNEL_ID]) {
			continue
		}
		options.push({ "key": option[dropDownConfig.CODE], "label": option[dropDownConfig.LABEL], "value": option[dropDownConfig.KEY] })
	}
	return options
}

export const getOptionById = (id, optionValues) => {
	return optionValues.find(item => item.value === id)
}

export const removeSubArrayFromArray = (subArray, keyForSearch, arrOfObjects) => {
	let newArray = [...arrOfObjects]
	for (const object of subArray) {
		newArray = newArray.filter((item) => item[keyForSearch] !== object[keyForSearch])
	}
	return newArray
}

export const removeExtraConcat = (data) => {
	return data.splice(0, 2);
}
export const handleActivitySelection = (event, key, formFields, masterDataState, cscType) => {
	let formData = [...formFields]
	switch (key) {
		case DROPDOWN_ACTIVITY_TYPE:
			formData = CscActivityForm
			const selectedActivity = getOptionById(event.target.value, masterDataState[CONFIGS.MASTER_DATA_TABLES.ACTIVITY.TABLE_NAME])
			switch (selectedActivity.key) {
				case TRAINING:
					formData = formData.concat(CscTrainingForm)
					formData[1].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.TRAINING_FOR.TABLE_NAME]
					return formData;
				case MONTHLY_REVIEW:
					formData = formData.concat(CscMothlyReviewForm)
					formData[1].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.MONTHLY_REVIEW_FOR.TABLE_NAME]
					return formData;

				case MEETING_VISIT:
					formData = formData.concat(CscMeetingVisitForm)
					formData[1].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.MEETING_VISIT_FOR.TABLE_NAME]
					return formData;

				case CALLING_TO_VLE_RAP_DM:
					formData = formData.concat(CscCallingToVLE_RAP_DMForm)
					return formData;
				case FINISH_DAY:
					formData = formData.concat(CSCFinishDayForm);
					formData[1].options = CLAIM_TRAVEL_EXPENCE_OPTIONS
					return formData;
			}
			break;
		case TRAINING_FOR:
			const selectedTrainingFor = getOptionById(event.target.value, masterDataState[CONFIGS.MASTER_DATA_TABLES.TRAINING_FOR.TABLE_NAME])
			switch (selectedTrainingFor.key) {
				case STATE_HEAD:
					formData = removeExtraConcat(formData);
					formData = formData.concat(CscTrainingStateHead)
					formData[2].options = masterDataState[
						CONFIGS.MASTER_DATA_TABLES.STATE_ID.TABLE_NAME
					];
					break;
				case STATE_MANAGER:
					formData = removeExtraConcat(formData);
					formData = formData.concat(CscTrainingStateManager);
					formData[2].options = masterDataState[
						CONFIGS.MASTER_DATA_TABLES.STATE_ID.TABLE_NAME
					];
					break;
				case DISTRICT_MANAGER:
					formData = removeExtraConcat(formData);
					formData = formData.concat(CscTrainingDistrictManager);
					formData[2].options = masterDataState[
						CONFIGS.MASTER_DATA_TABLES.STATE_ID.TABLE_NAME
					];
					break;
				case RAP_VLE:
					formData = removeExtraConcat(formData);
					formData = formData.concat(CscTrainingRAPVLE);
					break;
				default:
					break;
			}
			break;
		case MONTHLY_REVIEW_FOR:
			const selectedReviewWith = getOptionById(event.target.value, masterDataState[CONFIGS.MASTER_DATA_TABLES.MONTHLY_REVIEW_FOR.TABLE_NAME])
			switch (selectedReviewWith.key) {
				case STATE_HEAD:
					formData = removeExtraConcat(formData);
					formData = formData.concat(CscMonthlyReviewStateHead)
					formData[2].options = masterDataState[
						CONFIGS.MASTER_DATA_TABLES.STATE_ID.TABLE_NAME
					];
					break;
				case STATE_MANAGER:
					formData = removeExtraConcat(formData);
					formData = formData.concat(CscMonthlyReviewStateManager);
					formData[2].options = masterDataState[
						CONFIGS.MASTER_DATA_TABLES.STATE_ID.TABLE_NAME
					];
					break;
				case DISTRICT_MANAGER:
					formData = removeExtraConcat(formData);
					formData = formData.concat(CscMonthlyReviewDistrictManager);
					formData[2].options = masterDataState[
						CONFIGS.MASTER_DATA_TABLES.STATE_ID.TABLE_NAME
					];
					break;
				default:
					break;
			}
			break;
		case MEETING_VISIT_FOR:
			const selectedMeetingVisit = getOptionById(event.target.value, masterDataState[CONFIGS.MASTER_DATA_TABLES.MEETING_VISIT_FOR.TABLE_NAME])
			switch (selectedMeetingVisit.key) {
				case STATE_HEAD:
					formData = removeExtraConcat(formData);
					formData = formData.concat(CscMeetingVisitStateHead)
					formData[2].options = masterDataState[
						CONFIGS.MASTER_DATA_TABLES.STATE_ID.TABLE_NAME
					];
					formData[5].options = masterDataState[
						CONFIGS.MASTER_DATA_TABLES.MEETING_TYPE.TABLE_NAME
					];
					break;
				case STATE_MANAGER:
					formData = removeExtraConcat(formData);
					formData = formData.concat(CscMeetingVisitStateManager);
					formData[2].options = masterDataState[
						CONFIGS.MASTER_DATA_TABLES.STATE_ID.TABLE_NAME
					];
					formData[5].options = masterDataState[
						CONFIGS.MASTER_DATA_TABLES.MEETING_TYPE.TABLE_NAME
					];
					break;
				case DISTRICT_MANAGER:
					formData = removeExtraConcat(formData);
					formData = formData.concat(CscMeetingVisitDistrictManager);
					formData[2].options = masterDataState[
						CONFIGS.MASTER_DATA_TABLES.STATE_ID.TABLE_NAME
					];
					formData[5].options = masterDataState[
						CONFIGS.MASTER_DATA_TABLES.MEETING_TYPE.TABLE_NAME
					];
					break;
				case RAP_VLE:
					formData = removeExtraConcat(formData);
					formData = formData.concat(CscMeetingVisitRAPVLE);
					formData[3].options = masterDataState[
						CONFIGS.MASTER_DATA_TABLES.STATE_ID.TABLE_NAME
					];
					formData[6].options = masterDataState[
						CONFIGS.MASTER_DATA_TABLES.MEETING_TYPE.TABLE_NAME
					];
					break;
				default:
					break;
			}
			break;
		case DROPDOWN_EXPENCE_CLAIM:
			if (event) {
				formData = formData.concat(CSCExpenceClaimForm)
				formData[2].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME]
			} else {
				const arrayToRemove = CSCExpenceClaimForm.concat(CSCPublicTransportForm).concat(CSCSelfOwnedTransportForm)
				formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
			}
			break;
		case DROPDOWN_MODE_OF_TRANSPORT:
			const selectetTransport = getOptionById(event.target.value, masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME])
			if (selectetTransport.key === PUBLIC_TRANSPORT) {
				formData = removeSubArrayFromArray(CSCSelfOwnedTransportForm, "key", formData);
				formData = formData.concat(CSCPublicTransportForm)
			} else if (event.target.value === '2') {
				formData = removeSubArrayFromArray(CSCPublicTransportForm, "key", formData);
				let roleValidation = [ROLE_BDM];// "RBH", "ZBH", "NH"
				if (!roleValidation.includes(cscType)) {
					formData = formData.concat(CSCSelfOwnedTransportForm)
					formData[3].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME]
				} else {
					formData = removeSubArrayFromArray(CSCSelfOwnedTransportForm, "key", formData);
					formData = formData.concat(CSCPublicTransportForm)
				}
			} else {
				formData = removeSubArrayFromArray(CSCSelfOwnedTransportForm, "key", formData);
				formData = formData.concat(CSCPublicTransportForm)
			}
			break;
		default:
			break;
	}
	return formData;
}

export const validateExtraValidations = (formData) => {
	// validate expected and converted leads
	const extraValidations = {}
	if (formData.hasOwnProperty('ExpectedLead') &&
		formData.hasOwnProperty('ExpectedConversion') &&
		formData["ExpectedLead"] !== '' &&
		formData["ExpectedConversion"] !== '') {
		if (parseInt(formData['ExpectedConversion']) > parseInt(formData['ExpectedLead'])) {
			extraValidations['ExpectedConversion'] = 'Expected conversion should be less than or equal to expected leads.'
		}
	}
	return extraValidations;
}

