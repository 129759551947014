import React, { Component } from 'react'
import Breadcrumb from '../../../../components/breadcrumb/breadcrumb'
import MessageBar from '../../../../components/messageBar/messageBar'
import DynamicForm from "f1-dynamic-form-react"
import SaveAsDraftDrawer from '../../activity/saveAsDraftDrawer';
import IdleTimer from 'react-idle-timer';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Loader from "../../../../components/loader/loader";
import Footer from '../../../../layouts/footer/footer';
import { withTranslation } from 'react-i18next';
import { BROCA_LEAD, checkInConfig} from '../../../../utils/config';
import { userData } from '../../../../utils/utils';
import { customValidators, getForm } from './createLeadFormBroca';
import { connect } from "react-redux"
import moment from "moment"
import { t } from "i18next";
import { isLeadValid } from "../../../../offlineManager/leadOfflineManager";
import { v4 as uuidv4 } from 'uuid';
import {
    checkMobileNumberReducer, saveLeadForm,
    getLeadDetails, UpdateLeadForm, createLeadDeDupeValidation, getSelectedDraftLeadData
} from '../../../../redux-reducer/reducer';
import { setBreadcrumbs, activateLoader, deActivateLoader, activateSuccessMessageBar, activateErrorMessageBar } from '../../../../redux/actions/common';
import { LEADS_PATH } from '../../../../route/routePath';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../updateLead.scss';
import './createLeadBroca.scss';
import ModalPopup from 'f1-modal-react';
import { Button } from '@mui/material';

const createformat = "YYYY-MM-DD HH:mm:ss";

class CreateLeadBroca extends Component {
    constructor(props) {
        super(props);
        this.id = this.props.match.params.id;
        this.dyFormRef = React.createRef();
        this.LeadTitle = this.id ? 'Update Lead' : 'Create Lead'
        this.userData = JSON.parse(localStorage.getItem('persist:root'));
        this.userId = userData();
        this.employeeCode = JSON.parse(this.userData.userReducer).profileInfo.employeeCode;
        this.roleId = JSON.parse(this.userData.userReducer).profileInfo.roleId;
        this.channelID = JSON.parse(this.userData.userReducer).profileInfo.channelid;
        this.idleTimer = null
        this.formName = BROCA_LEAD
        this.state = {
            current: {
                id: 1,
            },
            formData: getForm(this.formName),
            AssignedToName: '',
            AssignedTo: '',
            extErrors: {},
            selectActivityID: "",
            defaultFormVal: {
                id: 1,
            },
            autoCompleteItems: {
                ActivityID: [],
            },
            collapseStates: {},
            leadDetail: {},
            isActiveMobileDraft: false,
            isFormSUbmitDisabled: false,
            timeOutMsg: false,
            labelForId: {
                ActivityIDLabel: ''
            },
            accordianFieldSet: { fieldset1: true},
            showModalSetup: false
        }
    }

    loadBreadCrumbs = () => {
        const breadcrumbs = [
            { name: 'Leads', url: '/app/lead/all' },
            { name: this.LeadTitle, url: '/createLead' }
        ]
        this.props.setBreadcrumbs(breadcrumbs);
    }

    getLeadDetail = async () => {
        this.props.activateLoader();
        const leadDetails = await this.props.getLeadDetails({ id: this.id })
        this.props.deActivateLoader();
        const { CampaigningDoneId, AssignedTo, AssignedToName, SalutationID, Salutation, FirstName, MiddleName,LastName, MobileNo, Email, ActivityID, ActivityName, PriorityID } = leadDetails.data;
        const ActiveLabel = this.state.autoCompleteItems.ActivityID.filter((Activate) => parseInt(Activate.id) === ActivityID)

        this.setState({
            leadDetail: {
                SalutationID: SalutationID?.toString(),
                Salutation: Salutation,
                FirstName: FirstName,
                MiddleName: MiddleName,
                LastName: LastName,
                MobileNo: MobileNo,
                Email: Email,
                ActivityName: ActivityName,
                ActivityID: ActivityID?.toString(),
                ActivityIDlabel: ActiveLabel[0]?.label,
                PriorityID: PriorityID,
                CampaigningDoneId: CampaigningDoneId?.toString()
            },
            AssignedTo: AssignedTo,
            AssignedToName: AssignedToName
        }, () => {
            const formData = this.initFormData(this.state.formData, this.formName)
            this.setState({ formData: formData })
        })
    }

    handleOnAction = (event) => {
        if (this.state.timeOutMsg) {
            setTimeout(() => {
                this.props.history.push("/app/home")
            }, 3000)
        }
    }
    handleOnActive = (event) => {
        if (this.idleTimer.getRemainingTime() === 0) {
            this.setState({
                ...this.state, timeOutMsg: this.idleTimer.getRemainingTime() === 0
            })
        }
    }
    handleOnIdle = (event) => {
    }
    getDropdownData = (optionsArr, id, label) => {
        let arr = [];
        if (optionsArr && optionsArr.length > 0) {
            arr = optionsArr.map((option) => {
                return { "id": `${option[id]}`, "label": `${option[label]}` }
            })
        }
        arr.shift({ "id": " ", "label": "select" })
        arr.unshift({ "id": " ", "label": "select" })
        return arr;
    }
    componentDidMount = async () => {
        this.initMasterData();
        this.loadBreadCrumbs();
        const autoCompleteItems = { ...this.state.autoCompleteItems };
        if (this.id) {
            autoCompleteItems.ActivityID = this.props.leadFormData.Activities.ActivityID;
        } else {
            autoCompleteItems.ActivityID = []
        }
        // autoCompleteItems.ActivityID = this.props.leadFormData.Activities.ActivityID;
        this.setState({
            autoCompleteItems: autoCompleteItems,
        }, () => {
            if (!this.id) {
                const formData = this.initFormData(this.state.formData, this.formName)
                this.setState({ formData: formData })
            }
            if (this.id) {
                this.getLeadDetail()
            }
        })
    }
    initMasterData = () => {
        if (!this.id) {
            this.props.deActivateLoader()
        }
    }
    onAutocompleteSelectHandler = (key, value, item) => {
        this.setState({ selectActivityID: item.id });
    }
    onSubmitHandler = async (model) => {
        console.log('onSubmitHandler model ', model);
        const isValidActivitiyLabel = this.props.leadFormData.Activities.ActivityID.some(ele => ele.label === model.ActivityIDlabel);
        if (model.CampaigningDoneId !== '' && model.CampaigningDoneId === 0) {
            delete model.errors.CampaigningDoneId;
        }
        if (model.PriorityID !== '') {
            delete model.errors.PriorityID;
        }
        if (!model.isValidForm || Object.keys(model.errors).length > 0 || Object.keys(this.state.extErrors) > 0) {

            const errorListClasess = Object.keys(model.errors);
            const selectedErrorClass = errorListClasess[0] === "extError" ? errorListClasess[1] : errorListClasess[0];
            const errorElement = document.getElementsByClassName(`${'control-'}${selectedErrorClass}`)[0];
            if (errorElement) {
                errorElement.scrollIntoView({
                    block: 'center'
                });
                this.props.activateErrorMessageBar("Please fill all required fields");
            }
            if (model.ActivityIDlabel !== '' && isValidActivitiyLabel) {
                delete model.errors.ActivityIDlabel;
            }
            else if (!isValidActivitiyLabel) {
                const extError = { ...this.state.extErrors, 'ActivityID': 'Invalid ActivityID' }
                this.setState({
                    ...this.state,
                    extErrors: extError
                })
                return false;
            }
            if (!model.isValidForm && Object.keys(model.errors).length > 0) {
                return false;
            }
        }
        if (model.ActivityIDlabel !== '' && isValidActivitiyLabel) {
            delete model.errors.ActivityIDlabel;
        }
        else if (!isValidActivitiyLabel) {
            const extError = { ...this.state.extErrors, 'ActivityID': 'Invalid ActivityID' }

            this.setState({
                ...this.state,
                extErrors: extError
            })
            return false;
        }

        if (!model.isValidForm && Object.keys(model.errors).length > 0) {
            return false;
        }
        if (
            !model.isValidForm ||
            (Object.keys(this.state.extErrors).length > 0 &&
                !this.state.extErrors.MobileNo) ||
            Object.keys(model.errors).length > 0 ||
            !isValidActivitiyLabel
        ) {
            const errList = Object.keys(this.state.extErrors)
            if (errList && errList.length > 0 && errList.includes('ActivityID')) {
                const errorElement = document.getElementsByClassName(`control-ActivityID`)[0];
                if (errorElement) {
                    errorElement.scrollIntoView({
                        block: 'center'
                    });
                }
                return false;
            }
        }
        const data = { ...model };
        delete data['advanceLayoutFields'];
        delete data['collapseStates'];
        delete data['errors'];
        delete data['isValidForm'];
        delete data['id'];
        delete data['ActivityIDlabel'];
        data['LeadStatusId'] = 1; //'NEW';
        data['UserId'] = this.userId;
        data['EmployeeCode'] = this.employeeCode;
        data["CreatedBy"] = this.userId;
        data["ModifiedBy"] = this.userId;
        data["AssignedTo"] = this.userId;
        data["ChannelID"] = parseInt(this.channelID);
        if (this.id) {
            data['IsBacklog'] = 0;
            data['LeadId'] = this.id;
        }
        const { Salutation } = this.props.masterDataState;
        if (navigator.onLine) {
            const res = await this.props.createLeadDeDupeValidation(data)
            if (res) {
                // lead id is present, so call Update lead
                if (this.id) {
                    data['AssignedTo'] = this.state.AssignedTo;
                    data['AssignedToName'] = this.state.AssignedToName;
                    this.props.UpdateLeadForm({ model: data, formBuilder: this.formBuilder, history: this.props.history })
                } else {
                    const leadScoreRequest = {
                        productName: "",
                        salutationName: `${this.getNameForId(Salutation, data.SalutationID)}${'.'}`,
                        priorityName: data.PriorityName,
                        lgCode: "",
                        branchCode: "",
                        branchCodeActivity: "",
                        activity: ""
                    };
                    const result = await this.props.saveLeadForm({ 'data': data, 'formName': this.formName, 'leadScoreRequest': leadScoreRequest })
                    this.handleSaveLeadResponse(result, 'Created');
                }
            }
        }
    }
    onSaveDraft = async (e) => {
        const formData = { ...this.dyFormRef.state };
        e.stopPropagation();
        e.preventDefault();
        delete formData['advanceLayoutFields'];
        delete formData['collapseStates'];
        delete formData['errors'];
        delete formData['isValidForm'];
        delete formData['id'];
        delete formData['ActivityIDlabel'];
        if (this.id) {
            formData['LeadId'] = this.id
        }
        formData['LeadStatusId'] = 11;
        formData['ChannelID'] = parseInt(this.channelID);
        formData['UserId'] = this.userId;
        formData['AssignedTo']=this.userId;
        formData['EmployeeCode'] = this.employeeCode;
        if(formData['SalutationID']===undefined){
            formData['SalutationID']=null;
        }
        const { Salutation } = this.props.masterDataState
        const mmData = {
            ...formData,
            "CreatedDate": moment(new Date()).format(createformat),
            "CreatedBy": this.userId,
            "ModifiedDate": moment(new Date()).format(createformat),
            "ModifiedBy": this.userId,
            "LmsleadGuid": uuidv4(),
            "LmsleadlastSyncTime": moment(new Date()).format(createformat),// Date.parse(new Date().toUTCString()),
            "IsLmsSync": navigator.onLine,
            "ActivityID": formData.ActivityID,
            "LeadStatus": 'Drafted',
            "SalutationName": this.getNameForId(Salutation, formData.SalutationID),
            "IsBacklog": false
        }
        if (navigator.onLine) {
            const leadScoreRequest = {
                productName: mmData.ProductName,
                salutationName: `${mmData.SalutationName}${'.'}`,
                priorityName: mmData.PriorityName,
                lgCode: "",
                branchCode: "",
                branchCodeActivity: "",
                activity: this.state.labelForId.ActivityIDLabel
            };
            if (this.id) {
                this.props.UpdateLeadForm({ model: mmData, formBuilder: this.formBuilder, history: this.props.history, Drafted: 'Draft' })
            } else {
                const result = await this.props.saveLeadForm({ 'data': mmData, 'formName': this.formName, 'leadScoreRequest': leadScoreRequest })
                this.handleSaveLeadResponse(result, 'Drafted');
            }
        }
    }
    onChangeHandler = async (event, key, model) => {
        const { formData } = this.state;
        const isValidActivitiyLabel = this.props.leadFormData.Activities.ActivityID.some(ele => ele.id === event.target?.value);
        if (this.id) {
            const details = { ...this.LeadDetailsData, [key]: event?.target?.value }
            this.setState({ leadDetail: details })
        }
        if (this.id) {
            if (key==="CampaigningDoneId"){
            this.state.leadDetail['CampaigningDoneId']= event?.target?.value
        }}
        switch (key) {
            case 'ActivityID':
                if (!isValidActivitiyLabel) {
                    const extError = { ...this.state.extErrors, 'ActivityID': 'Invalid ActivityID' }
                    this.setState({
                        ...this.state,
                        extErrors: { extError }
                    })
                } else {
                    const activityIdLabelList = this.props.leadFormData.Activities.ActivityID.filter(ele => ele.id === event.target?.value)
                    this.setState({ extErrors: {} })
                    this.setState({
                        labelForId: {
                            ...this.state.labelForId,
                            ActivityIDLabel: activityIdLabelList[0]?.label
                        }
                    })
                }
                break
            case 'MobileNo':
                if (event.target.value.length === 10) {
                    const mobileNo = event.target.value;
                    if (navigator.onLine) {
                        const mobileStatus = await this.props.checkMobileNumberReducer({ mobileNumber: event.target.value, userId: this.userId, formData: formData })
                        if (!mobileStatus) {
                            this.setState({
                                ...this.state, showModalSetup: true
                            })
                        }
                        else {
                            this.setState({ extErrors: {} })
                        }
                    } else {
                        isLeadValid(mobileNo).then((res) => {
                            if (!res) {
                                this.setState({
                                    ...this.state, showModalSetup: true
                                })
                            }
                            else {
                                this.setState({ extErrors: {} })
                            }
                        })
                    }
                }
                break;
            case 'CampaigningDoneId':
                const { value } = event.target;
                const activities = {...this.props.leadFormData.Activities}
                let matchedActivities = {
                    ActivityID: []
                }
                let defaultFormVal = {
                    ...this.state.defaultFormVal, CampaigningDoneId: value
                }
                this.setState({
                    ...this.state, autoCompleteItems: matchedActivities, defaultFormVal: defaultFormVal
                })
                if (activities) {
                    matchedActivities['ActivityID']  = activities.ActivityID.filter((res) => res.campaigningDoneId === parseInt(value))
                }
                if (matchedActivities.ActivityID.length === 0) {
                    matchedActivities.ActivityID.shift();
                    matchedActivities.ActivityID.unshift({ "key": "select", "label": "Data Not Found", "value": "" })
                }
                this.setState({
                    ...this.state, autoCompleteItems: matchedActivities
                })
                break;
            default:
            break;
        }
    }
    onCheckinHandler = (position, address) => {
        if (this.id) {
            this.setState({
                ...this.state.leadDetail, Location: address
            })
        }
    }
    initFormData = (formFields, actionType) => {
        this.setState({ ...this.state, accordianFieldSet: { fieldset1: true } });
        const formData = [...formFields]
        const { Salutation, PriorityStatus, BrocaCampaigningDone } = this.props.masterDataState;
        const copyPriorityStatus = [...PriorityStatus];
        const leadStatus = copyPriorityStatus.filter((el) => (el.value === '' || el.value === '1'|| el.value === '2' || el.value === '3'))
        formData[0].columns[0].rows[0][0].options = Salutation;
        formData[0].columns[0].rows[0][6].options = BrocaCampaigningDone;
        formData[0].columns[0].rows[0][8].options = leadStatus;
        return formData
    }
    toggleFieldsetAccordian = (collapseId) => {
        this.dyFormRef.toggleCollapse(collapseId);
    }
    renderFieldsetAccordianLabel = (collapseId) => {
        const buttonStyle = {
            float: 'right',

            cursor: 'pointer',
            color: '#000000',
        }

        const accordianClass = this.dyFormRef?.state?.collapseStates[collapseId]
        return (
            <div className="accordHead">
                <span style={buttonStyle} onClick={(e) => this.toggleFieldsetAccordian(collapseId)}>{!accordianClass ? "+" : "-"}</span>
                <strong>
                    {'Lead Personal Details'}
                </strong>
            </div>
        );
    }
    onCancelHandler = () => {
        const isMobile = Math.min(window.screen.width, window.screen.height) < 480 || navigator.userAgent.indexOf("Mobi") > -1;
        if (isMobile) {
            this.setState({ isActiveMobileDraft: true })
        }
        else {
            this.props.history.push(LEADS_PATH)
        }
    }
    toggleDrawer = (flag) => {
        this.setState({ isActiveMobileDraft: flag });
    }
    onDontSaveDraftHandler = () => {
        this.props.history.push(LEADS_PATH)
    }
    onSaveDraftHandler = () => {
        this.onSaveDraft(new Event('test'))
    }
    getNameForId = (optionArr, inputId) => {
        const matchEl = optionArr.filter((option) => (option.key === inputId || option.value === inputId) ? option.label : '')
        if (matchEl && matchEl.length > 0) {
            return matchEl[0].label
        }
        return null
    }
    handleSaveLeadResponse = (result, mode) => {
        if (result.status === 200 && result.data.Responsestatus === true) {
            this.props.activateSuccessMessageBar(`Lead has been ${mode} successfully`);
            this.props.deActivateLoader();
            if (mode === 'Created') {
                this.props.history.push(`/app/lead/detail/${result.data.Transactionid}`)
            } else if (mode === 'Drafted') {
                this.props.history.push('/app/lead/all')
            }
        } else {
            this.props.deActivateLoader();
            this.props.activateErrorMessageBar("Something went wrong! please try again later.");
        }
    }
    onclose = () => {
        this.setState({
            ...this.state, showModalSetup: false
        })
    }
    render() {
        return (
            <div>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * 10}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    onAction={this.handleOnAction}
                    debounce={250}
                />
                <Snackbar open={this.state.timeOutMsg} autoHideDuration={3000} >
                    <Alert severity="error" >Your session has been expired! You will be redirected to homepage in 3 seconds.</Alert>
                </Snackbar>
                <div className="mainContainer createLead">
                    <MessageBar />
                    <Breadcrumb />
                    <span className="formInfo">{t('Please fill in the lead information')}</span>
                    <div className="formBox">
                        <DynamicForm
                            key={1}
                            onRef={ref => this.dyFormRef = ref}
                            className="dynamicForm"
                            buttonSubmit={t('Submit')}
                            defaultValues={this.id ? this.state.leadDetail : this.state.defaultFormVal}
                            model={this.state.formData}
                            onSubmit={(model) => this.onSubmitHandler(model)}
                            disableSubmitOnInValid={this.state.isFormSUbmitDisabled}
                            design="advanceBootstrap"
                            materialOutline={false}
                            onChange={(event, key, model) => this.onChangeHandler(event, key, model)}
                            onAutocompleteSelect={this.onAutocompleteSelectHandler}
                            autoCompleteItems={this.state.autoCompleteItems}
                            customValidators={customValidators}
                            showAstersikOnRequiredFields={true}
                            extErrors={this.state.extErrors}
                            checkInConfig={checkInConfig}
                            onCheckinHandler={this.onCheckinHandler}
                            renderFieldsetAccordianLabel={this.renderFieldsetAccordianLabel}
                            defaultAccordianOpenStates={this.state.accordianFieldSet}
                            extaraActionFormButton={<>
                                <button className="savedraftLink" onClick={(e) => this.onSaveDraft(e)}>SAVE AS DRAFT</button>
                                <button color="indigo" className="btn cancelBtn" key="extra" id="extra" type="button" onClick={(e) => this.onCancelHandler(e)}>Cancel</button>

                            </>}
                        />
                    </div>
                    <Footer />
                    {this.props.masterDataState.isActiveLoader && <Loader />}
                    <SaveAsDraftDrawer
                        isActiveMobileDraft={this.state.isActiveMobileDraft}
                        toggleDrawer={this.toggleDrawer}
                        onDontSaveHandler={this.onDontSaveDraftHandler}
                        onSaveDraftHandler={this.onSaveDraftHandler}
                    ></SaveAsDraftDrawer>
                </div>
                <ModalPopup
                    show={this.state.showModalSetup}
                    onHide={() => this.onclose()}
                    size={"sm"}
                    centered={true}
                    modelHeader={'Alert'}
                    modelContent={
                        <>
                            <p>Lead with this mobile number already exists</p>
                            <Button onClick={()=> this.onclose()}>OK</Button>
                        </>
                    }
                    backdrop={"static"}
                />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    leadFormData: state.leadFormReducer,
    masterDataState: state.commonReducer
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
    saveLeadForm: (payload) => dispatch(saveLeadForm(payload)),
    activateLoader: (payload) => dispatch(activateLoader(payload)),
    deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
    activateSuccessMessageBar: (payload) => dispatch(activateSuccessMessageBar(payload)),
    activateErrorMessageBar: (payload) => dispatch(activateErrorMessageBar(payload)),
    checkMobileNumberReducer: (payload) => dispatch(checkMobileNumberReducer(payload)),
    getLeadDetails: (payload) => dispatch(getLeadDetails(payload)),
    UpdateLeadForm: (payload) => dispatch(UpdateLeadForm(payload)),
    createLeadDeDupeValidation: (payload) => dispatch(createLeadDeDupeValidation(payload)),
    getSelectedDraftLeadData: (payload) => dispatch(getSelectedDraftLeadData(payload))
});
export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(CreateLeadBroca));
