import React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";



import { makeStyles } from '@material-ui/core/styles';

const PehlaKadamQuestion = () => {

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            textAlign: 'left',
            color: theme.palette.text.secondary,
            //height: '100%',
            padding: theme.spacing(2),

        },
        paper2: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary,
            // height: '300px',
        },
        activityLogo: {
            //  borderRadius: 10,
            textAlign: 'center',
            backgroundColor: 'rgb(0 66 126 / 27%)',
            height: '50px',
            padding: theme.spacing(2),
        },
        heading: {
            padding: "1px 2px 2px 1px",
        }
    }));

    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        "& .MuiSwitch-track": {
            borderRadius: 22 / 2,
            "&:before, &:after": {
                content: '""',
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                width: 16,
                height: 19
            },
            "&:before": {
                content: '"on"',
                color: "white",
                left: 12
            },
            "&:after": {
                content: '"off"',
                color: `white`,
                right: 12
            }
        },
        "& .MuiSwitch-thumb": {
            boxShadow: "none",
            width: 20,
            height: 20,
            margin: 1
        }
    }));

    const classes = useStyles();

    return (
        <div className="mainContainer leadDetails">
            <Box>
                <Grid className="leadDetailsSec" container spacing={3}>
                    <Paper className={classes.paper} sx={{ mb: 2 }}>
                        <FormControl>
                            <div>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Accordion 1</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                {[1].map((value) => (
                                                    <>
                                                        <ListItem
                                                            key={value}
                                                            disableGutters
                                                        >
                                                            <CircleIcon sx={{ fontSize: 7 }} />
                                                            <ListItemText primary={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                            malesuada lacus ex, sit amet blandit leo lobortis eget.${value}`} />
                                                        </ListItem>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={<Android12Switch defaultChecked />}
                                                                label="" id="switchbuttion"
                                                            />
                                                        </FormGroup>
                                                        <textarea id="" name="w3review" rows="4" cols="40" />
                                                    </>


                                                ))}
                                            </List>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </FormControl>
                    </Paper>
                </Grid>
            </Box>
        </div>
    )
}
export default PehlaKadamQuestion;
