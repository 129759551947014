import React, { Component } from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { checkInConfig } from '../../../utils/config';
import { InputLabel, Button } from "@mui/material";
import GpsFixedOutlinedIcon from '@mui/icons-material/GpsFixedOutlined';
import SearchIcon from '@mui/icons-material/Search';
// import axios from 'axios';
import { checkInMap, activateErrorMessageBar, activateSuccessMessageBar, lmsHrmsCheckIn } from '../../../redux/actions';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux"
import { IN, DIRECTION_KEY, LMS_DEVICE_CODE, OUT } from '../../../api/baseURL'; // LOCATION_BASE_URL,
import Geocode from "react-geocode";
import moment from "moment";
import ModalPopup from 'f1-modal-react';
import { loggInfo } from '../../../redux/actions/userAction';

const ADDRESS_NOT_FOUND = 'Couldn\'t find the address for the selected location!'
export class MapRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coords: {
                lat: null,
                lng: null,
            },
            location: '',
            isClicked: false,
            showModalSetup: false,
            countryName: "",
        }
        // this.inputElement = React.createRef();
        this.options = {
            enableHighAccuracy: true
        };
        Geocode.setApiKey(checkInConfig.API_KEY);
        Geocode.setRegion("IN");
        this.Intervaldata = null;

        this.prevStateDat = {};
    }

    componentDidMount() {
        console.log('componentDidMount porps::: ', this.props.location);
        this.setState({ ...this.state, coords: { lat: null, lng: null } });
        this.Intervaldata = setInterval(() => {
            navigator.geolocation.getCurrentPosition(this.handleResponse, this.handleError);
        }, 5000);
    }

    componentDidUpdate(prevProps, prevState) {
        this.prevStateDat = this.state.coords;
        console.log('componentDidUpdate fetching location', this.state.coords);
        if (prevProps.google !== this.props.google) {
            console.log('componentDidUpdate google props changed');
            this.getCoords();
        }
        if (prevState.coords.lat !== this.state.coords.lat || prevState.coords.lng !== this.state.coords.lng) {
            console.log('componentDidUpdate coords changed');
            // window.location.reload(false);
            // this.getCoords();
        }
    }
    componentWillUnmount() {
        clearInterval(this.Intervaldata);
    }

    handleCoordsError(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }
    getCoords = () => {
        console.log('fetching getCurrentPosition');
        navigator.geolocation.getCurrentPosition(this.handleResponse, this.handleError);
    }

    handleResponse = (position) => {
        const { coords } = position
        const { latitude, longitude } = coords
        // this.setState({ coords: { lat: latitude, lng: longitude } })
        // console.log('fetching handleResponse', position);
        if (this.prevStateDat.lat !== latitude || this.prevStateDat.lng !== longitude) {
            // this.getCoords();
            Geocode.fromLatLng(latitude, longitude).then(
                (response) => {
                    console.log('Geocode.fromLatLng', response);
                    if (response.results.length > 0) {
                        const address = response.results[0].formatted_address;
                        const getAddress = address?.split(', ')
                        const country = getAddress[getAddress.length - 1]
                        this.setState({ location: address, coords: { lat: latitude, lng: longitude }, countryName: country });
                        this.prevStateDat.lat = latitude;
                        this.prevStateDat.lng = longitude;
                    } else {
                        this.props.activateErrorMessageBar(ADDRESS_NOT_FOUND);
                        this.setState({ location: "" });
                    }
                },
                (error) => {
                    this.props.activateErrorMessageBar(ADDRESS_NOT_FOUND);
                    this.setState({ location: "", showModalSetup: true, isClicked: true, });
                    this.props.setDrwerList(false)
                    console.error(error);
                }
            );
        }
    }

    handleError = (error) => {
        this.setState({
            isClicked: true,
            location: "",
            showModalSetup: true
        })
        this.props.activateErrorMessageBar(ADDRESS_NOT_FOUND)
        // this.props.setDrwerList(false)
        console.error(error);
        console.log('fetching handleError');
    }

    handleCheckIn = async () => {
        if (this.state.location === '') {
            this.props.setDrwerList(false)
            this.props.activateErrorMessageBar(ADDRESS_NOT_FOUND)
            return;
        }
        this.props.setDrwerList(false)
        const { statusId, status, id, checkinCount } = this.props;
        const isCheckInAttendanceRequired = checkinCount === 0 ? true : false;
        const { lat, lng } = this.state.coords;
        const userData = JSON.parse(localStorage.getItem('persist:root'));
        const employeeCode = JSON.parse(userData.userReducer).profileInfo.employeeCode;
        let getRemainingTimeInMins = 9 * 60;

        if (new Date().getHours() + 9 > 24) {
            getRemainingTimeInMins = 24 - new Date().getHours();
            getRemainingTimeInMins = getRemainingTimeInMins * 60;
            getRemainingTimeInMins = getRemainingTimeInMins - new Date().getMinutes()
        }
        const attendanaceRequestObj = {
            Attendance: [
                {
                    DateTime: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
                    EmployeeCode: employeeCode.toString(),
                    Direction: IN,
                    DeviceCode: LMS_DEVICE_CODE,
                    Status: "0"
                },
                {
                    DateTime: moment(new Date()).add(Math.floor(getRemainingTimeInMins / 60), "h").format('YYYY-MM-DDTHH:mm'),
                    EmployeeCode: employeeCode.toString(),
                    Direction: OUT,
                    DeviceCode: LMS_DEVICE_CODE,
                    Status: "0"
                }
            ],
            Key: DIRECTION_KEY
        }
        console.log('attendanaceRequestObj ', attendanaceRequestObj);
        if (this.state.countryName === "India") {
            const result = await this.props.checkInMap({
                status: status,
                statusId: statusId,
                latitude: lat.toString(),
                longitude: lng.toString(),
                id: id,
                address: this.state.location,
                setDrwerList: this.props.setDrwerList,
                isCheckInAttendanceRequired,
                attendanaceRequestObj,
                isCampiagn: this.props?.isCampiagn
            });
            console.log('checkInMap ', result);
            if (result.Responsestatus) {
                this.props.activateSuccessMessageBar("Check-In completed successfully");
                this.props.onCheckInComplete();
                if (isCheckInAttendanceRequired) {
                    this.props.lmsHrmsCheckIn(attendanaceRequestObj);
                }
            } else {
                this.props.activateErrorMessageBar(result.Responsemessage);
            }
            this.setState({
                location: '',
                isClicked: false,
                coords: {
                    lat: null,
                    lng: null,
                }
            }, () => this.props.setDrwerList(false))
        } else {
            this.props.setDrwerList(false)
            this.props.activateErrorMessageBar("Check-In cannot be done outside of India")
        }
        this.loggInfoForMapRoute('Check-In map and geocode')
    }
    handleChange = (e) => {
        console.log('handleChange ', e.target.value);
        this.setState({ location: e.target.value })
    }

    handleCancel = () => {
        this.setState({
            location: '',
            coords: {
                lat: null,
                lng: null,
            }
        }, () => this.props.setDrwerList(false))
    }
    loggInfoForMapRoute = (processname) => {
        const userData = JSON.parse(localStorage.getItem('persist:root'));
        const employeeCode = JSON.parse(userData.userReducer).profileInfo.employeeCode;
        const deviceId = JSON.parse(userData.userReducer).profileInfo.deviceId;
        const logInfoRequest = {
            processname: processname,
            employeeCode: employeeCode,
            ipAddress: "",
            requestFor: `${'Check-In used'}${this.props.status}`,
            leadActivityId: this.props.id,
            deviceId: deviceId,
            routUrl: this.props.location ? this.props.location.pathname : ""
        }
        loggInfo(logInfoRequest);
    }
    render() {

        return (
            <>
                {this.state.coords.lat && this.state.coords.lng &&
                    <div className="mapDiv">
                        <div className='mapGoogleHeight' id='map'>
                            <Map google={this.props.google} initialCenter={{
                                lat: this.state.coords.lat,
                                lng: this.state.coords.lng
                            }} zoom={14}>
                                <Marker position={{
                                    lat: this.state.coords.lat,
                                    lng: this.state.coords.lng
                                }}
                                />
                                <InfoWindow onClose={this.onInfoWindowClose}>
                                </InfoWindow>
                            </Map>
                        </div>
                        <div className="checkInDiv">
                            <p>Check In</p>
                            <InputLabel>Current Location</InputLabel>
                            <div className='srch'>
                                <SearchIcon className='srchIcon' />
                                <textarea
                                    cols="40"
                                    fullWidth
                                    id="standard-bare"
                                    variant="outlined"
                                    disabled
                                    value={this.state.location}
                                    onChange={this.handleChange}
                                    onClick={this.getCoords}
                                    InputProps={{
                                        endAdornment: (
                                            <span ><GpsFixedOutlinedIcon /></span>
                                        ),
                                    }}
                                />

                            </div>
                            <div className="btnBx">
                                <Button onClick={this.handleCancel} >Cancel</Button>
                                <Button onClick={this.handleCheckIn} >Submit</Button>
                            </div>
                        </div>
                    </div>
                }
                {
                    (!this.state.coords.lat || !this.state.coords.lng) &&
                    <div className="mapDiv">
                        <div className='mapGoogleHeight'>
                            <h4>fetching your location...</h4>
                        </div>
                    </div>
                }
                <ModalPopup
                    show={this.state.showModalSetup}
                    onHide={() => { this.setState({ ...this.state, showModalSetup: false }) }}
                    size={"sm"}
                    centered={true}
                    modelHeader={"Please enable your location"}
                    modelContent={
                        <Button
                            onClick={() => { this.setState({ ...this.state, showModalSetup: false }, this.props.setDrwerList(false)) }}
                        >
                            CLOSE
                        </Button>
                    }
                    backdrop={"static"}
                />
            </>
        );

    }

}

const mapDispatchToProps = dispatch => ({
    checkInMap: (payload) => dispatch(checkInMap(payload)),
    activateErrorMessageBar: (payload) => dispatch(activateErrorMessageBar(payload)),
    activateSuccessMessageBar: (payload) => dispatch(activateSuccessMessageBar(payload)),
    lmsHrmsCheckIn: (payload) => dispatch(lmsHrmsCheckIn(payload))
})


const mapStateToProps = state => ({

});

export default GoogleApiWrapper({
    apiKey: (checkInConfig.API_KEY)
})(withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(MapRoute)))