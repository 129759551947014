import { get} from '../../api/api'
import { VALIDATE_ACTIVITY ,GET_USER_CHECK_IN_COUNT, GET_ACTIVITIES_FOR_HNI,GET_SRM_LIST} from '../../api/baseURL'
import { getDropDownOptions } from "../../pages/postlogin/activity/gcl/createActivityForm";
import { CONFIGS } from "../../utils/config";
export const validateActivity = (payload) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get( `${VALIDATE_ACTIVITY}${payload.user_id}/${payload.formData.MeetingStartDateTime}/${payload.formData.MeetingEndDateTime}`, {})
            resolve(res)
        } catch (err) {
            reject(err)
        }
    })
}

export const getUserCheckInCountforFinishDay = (userId) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get( `${GET_USER_CHECK_IN_COUNT}/${userId}`, {})
            resolve(res)
        } catch (err) {
            reject(err)
        }
    })
}

export const getActivitiesForHNI = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get( GET_ACTIVITIES_FOR_HNI+"1?IsHNI="+true, {})
            resolve(res)
            const dropDownOptions = getDropDownOptions(
                res.data,
                CONFIGS.MASTER_DATA_TABLES.ACTIVITY_FOR_HNI,
               1
              );
            dispatch({
                type: 'ActivitiesForHNI',
                payload: dropDownOptions,
              });
        } catch (err) {
            reject(err)
        }
    })
}

export const getSRMList = (userId) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get( `${GET_SRM_LIST}/${userId}`, {})
            resolve(res)
            const dropDownOptions = getDropDownOptions(
                res.data.map(el=>Object.assign({ChannelID: 1},el)),
                CONFIGS.MASTER_DATA_TABLES.SRM_LIST,
               1
              );
            dispatch({
                type: 'SRMList',
                payload: dropDownOptions,
              });
        } catch (err) {
            reject(err)
        }
    })
}
