import {
  BANCA_LEAD,
  BUSSINESS_LEAD,
  CUSTOM_FIELDSET,
  FINANICAL_BG,
  RECRUITMENT_LEAD,
} from "../../../../utils/config";
import {
  isAlphabetOnly,
  isBranchValid,
  isEmailValid,
  isMeetindDate,
  numberStartWith6789Validator,
  isPositiveNumber,
  isValidPinCode,
} from "../../../../utils/validators";
import { format } from "date-fns";
import moment from "moment";
const endMaxDate = moment(new Date()).endOf("day").format("YYYY-MM-DD kk:mm");
export const customValidators = [
  {
    key: "FirstName",
    validator: isAlphabetOnly,
    message: "Please enter alphabets only.",
  },
  {
    key: "LastName",
    validator: isAlphabetOnly,
    message: "Please enter alphabets only.",
  },
  {
    key: "MiddleName",
    validator: isAlphabetOnly,
    message: "Please enter alphabets only.",
  },
  {
    key: "Email",
    validator: isEmailValid,
    message:
      "invalid email id with missing domain name, @, .com, gmail id etc.",
  },
  {
    key: "BranchID",
    validator: isBranchValid,
    message: "Branch code and activity id's branch code should be same.",
  },
  {
    key: "MobileNo",
    validator: numberStartWith6789Validator,
    message: "10 digit phone number starting with 6,7,8,9 only.",
  },
  {
    key: "AlternateNo",
    validator: numberStartWith6789Validator,
    message: "10 digit phone number starting with 6,7,8,9 only.",
  },
  {
    key: "MeetingDate",
    validator: isMeetindDate,
    message: "Meeting already exits.",
  },
  {
    key: "ExpectedPremium",
    validator: isPositiveNumber, // function to be called
    message: "Expected Premium should be greater than 0", // message to show
  },
  {
    key: "PinCode",
    validator: isValidPinCode,
    message: "Please Enter a Valid six digit pincode",
  },
];
const requiredField = "This field  is required.";
export const resetField = (formFields) => {
  for (const [index] of formFields.entries()) {
    if (index == 0) {
      continue;
    }
    formFields[index].hide = true;
  }
  return formFields;
};

export const AgencyLeadForm = [
  {
    fieldSetClassName: CUSTOM_FIELDSET,
    fieldSetLabel: "Lead Personal Details",
    fieldSetLabelType: "accordian",
    fielsetId: "fieldset1",
    columns: [
      {
        rows: [
          [
            {
              colmd: "12",
              key: "SalutationID",
              type: "select",
              disabled: false,
              id: "SalutationID",
              label: "Salutation",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This field is required.",
                },
              ],
              options: [],
            },
            {
              colmd: "12",
              key: "FirstName",
              type: "text",
              id: "FirstName",
              label: "First Name",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This field is required",
                },
                {
                  name: "minLength",
                  value: 3,
                  validationMessage: "Please enter at least 3 letters",
                },
                {
                  name: "maxLength",
                  value: 15,
                  validationMessage: "Please Enter no more than 15 Characters",
                },
              ],
            },
            {
              colmd: "12",
              key: "LastName",
              type: "text",
              id: "LastName",
              label: "Last Name",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This field is required",
                },
                {
                  name: "minLength",
                  value: 3,
                  validationMessage: "Please enter at least 3 letters",
                },
                {
                  name: "maxLength",
                  value: 15,
                  validationMessage: "Please Enter no more than 15 Characters",
                },
              ],
            },
            {
              colmd: "12",
              key: "MobileNo",
              type: "number",
              id: "MobileNo",
              label: "Mobile No.",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This field is required.",
                },
                {
                  name: "minLength",
                  value: 10,
                  validationMessage: "Min length for Mobile No is 10 digits",
                },
                {
                  name: "maxLength",
                  value: 10,
                  validationMessage: "Mobile number field require 10 digits.",
                },
              ],
            },
            {
              colmd: "12",
              key: "AlternateNo",
              id: "AlternateNo",
              type: "number",
              label: "Alternate Mobile No.",
              validators: [
                {
                  name: "minLength",
                  value: 10,
                  validationMessage:
                    "Min length for Alternate Mobile No is 10 digits",
                },
                {
                  name: "maxLength",
                  value: 10,
                  validationMessage:
                    "Alternate number field require 10 digits.",
                },
              ],
            },
            {
              colmd: "12",
              key: "Email",
              type: "email",
              id: "Email",
              label: "Email",
              hide: false,
              validators: [
                { name: "required", value: true },
                {
                  name: "isEmail",
                  value: true,
                  validationMessage: "Please enter a valid email ID",
                },
              ],
            },
          ],
        ],
      },
    ],
  },
  {
    fieldSetClassName: CUSTOM_FIELDSET,
    fieldSetLabelType: "accordian",
    fieldSetLabel: "Product Details",
    fielsetId: "fieldset2",
    columns: [
      {
        rows: [
          [
            {
              colmd: "12",
              key: "ProductID",
              type: "autocomplete",
              disabled: false,
              id: "ProductID",
              label: "Select Product",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "Please select product",
                },
              ],
              options: [],
            },
            {
              colmd: "12",
              key: "ExpectedPremium",
              id: "ExpectedPremium",
              type: "number",
              label: "Expected Premium",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "Please enter expected premium",
                },
                {
                  name: "maxLength",
                  value: 8,
                  validationMessage: "Only 8 digits are allowed.",
                },
              ],
            },
          ],
        ],
      },
    ],
  },
  {
    fieldSetClassName: CUSTOM_FIELDSET,
    fieldSetLabelType: "accordian",
    fieldSetLabel: "Business Details",
    fielsetId: "fieldset3",
    columns: [
      {
        rows: [
          [
            {
              key: "PriorityID",
              type: "select",
              disabled: false,
              id: "PriorityID",
              label: "Lead Status",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "Please select lead status.",
                },
              ],
              options: [],
            },
            {
              colmd: "12",
              key: "LeadSourceID",
              type: "select",
              disabled: false,
              id: "LeadSourceID",
              label: "Lead Source",
              validators: [{ name: "required", value: true }],
              options: [],
            },
            {
              colmd: "12",
              key: "ActivityID",
              hide: false,
              type: "autocomplete",
              disabled: false,
              id: "ActivityID",
              label: "Activity ID",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This field is required",
                },
              ],
              options: [],
            },

            {
              colmd: "12",
              key: "ISleadHasMeeting",
              name: "ISleadHasMeeting",
              type: "CustomSwitch",
              id: "ISleadHasMeeting",
              label: "Meeting with Lead?",
              handleDiameter: 45,
              offColor: "#00427E",
              onColor: "#FFFFFF",
              offHandleColor: "#FFFFFF",
              onHandleColor: "#00427E",
              className: "radioBoxDiv",
              height: 30,
              width: 75,
              borderRadius: 24,
              border: "1px solid #ddd",
              activeBoxShadow: "0px 0px 1px 2px #fffc35",
              uncheckedIcon: <div className="switchUnChecked">Yes</div>,
              checkedIcon: <div className="switchChecked">No</div>,
            },
            {
              key: "MeetingStartDateTime",
              noDate: null,
              min:
                format(new Date(), "yyyy-MM-dd") +
                "T" +
                format(new Date(), "kk:mm"),
              hide: true,
              type: "datetime-local",
              disabled: false,
              id: "MeetingStartDateTime",
              label: "Meeting Start Date & Time",
              validators: [],
            },

            {
              key: "MeetingEndDateTime",
              noDate: null,
              hide: true,
              max: endMaxDate,
              type: "datetime-local",
              disabled: false,
              id: "MeetingEndDateTime",
              label: "Meeting End Date & Time",
              validators: [],
            },

            {
              colmd: "12",
              key: "ActivityDoneWith",
              type: "select",
              id: "ActivityDoneWith",
              label: "Select ZMA or Premier ZMA Name",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: requiredField,
                },
              ],
              options: [],
            },

            {
              colmd: "12",
              key: "ZmaID",
              type: "autocompleteMultiselect",
              id: "ZmaID",
              label: "ZMA /Premier ZMA Name",
              hide: true,
              validators: [],
              options: [],
            },
            {
              colmd: "12",
              key: "AdvisorName",
              type: "autocompleteMultiselect",
              id: "AdvisorName",
              label: "Select Advisor Name",
              hide: true,
              validators: [],
              options: [],
            },
            {
              colmd: "12",
              key: "Remarks",
              hide: false,
              type: "textarea",
              id: "Remarks",
              label: "Remarks",
              validators: [
                { name: "required", value: false },
                { name: "minLength", value: 2 },
                {
                  name: "maxLength",
                  value: 300,
                  validationMessage: "Only 300 characters allowed.",
                },
              ],
            },
          ],
        ],
      },
    ],
  },
];
export const BusinessLeadForm = [
  {
    fieldSetClassName: CUSTOM_FIELDSET,
    fieldSetLabel: "Lead Personal Details",
    fieldSetLabelType: "accordian",
    fielsetId: "fieldset1",
    columns: [
      {
        colmd: "12",
        colxs: "12",
        rows: [
          [
            {
              colmd: "12",
              key: "SalutationID",
              type: "select",
              disabled: false,
              id: "SalutationID",
              label: "Salutation",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This field is required.",
                },
              ],
              options: [],
            },
            {
              colmd: "12",
              key: "FirstName",
              type: "text",
              id: "FirstName",
              label: "First Name",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This field is required",
                },
                {
                  name: "minLength",
                  value: 3,
                  validationMessage: "Please enter at least 3 letters",
                },
                {
                  name: "maxLength",
                  value: 15,
                  validationMessage: "Please Enter no more than 15 Characters",
                },
              ],
            },
            {
              colmd: "12",
              key: "MiddleName",
              type: "text",
              id: "MiddleName",
              label: "Middle Name",
              validators: [
                // {
                //   name: "required",
                //   value: true,
                //   validationMessage: "This field is required",
                // },
                {
                  name: "minLength",
                  value: 3,
                  validationMessage: "Please enter at least 3 letters",
                },
                {
                  name: "maxLength",
                  value: 15,
                  validationMessage: "Please Enter no more than 15 Characters",
                },
              ],
            },
            {
              colmd: "12",
              key: "LastName",
              type: "text",
              id: "LastName",
              label: "Last Name",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This field is required",
                },
                {
                  name: "minLength",
                  value: 3,
                  validationMessage: "Please enter at least 3 letters",
                },
                {
                  name: "maxLength",
                  value: 15,
                  validationMessage: "Please Enter no more than 15 Characters",
                },
              ],
            },
            {
              colmd: "12",
              key: "MobileNo",
              type: "number",
              id: "MobileNo",
              label: "Mobile Number",
              validators: [
                { name: "required", value: true },
                {
                  name: "minLength",
                  value: 10,
                  validationMessage: "Min length for Mobile No is 10 digits",
                },
                {
                  name: "maxLength",
                  value: 10,
                  validationMessage: "Maximum 10 digits allowed.",
                },
              ],
            },
            {
              colmd: "12",
              key: "AlternateNo",
              id: "AlternateNo",
              type: "number",
              label: "Alternate Mobile No.",
              validators: [
                {
                  name: "minLength",
                  value: 10,
                  validationMessage:
                    "Min length for Alternate Mobile No is 10 digits",
                },
                {
                  name: "maxLength",
                  value: 10,
                  validationMessage:
                    "Alternate number field require 10 digits.",
                },
              ],
            },
            {
              colmd: "12",
              key: "Email",
              type: "email",
              id: "Email",
              label: "Email ID",
              hide: false,
              validators: [
                { name: "required", value: true },
                { name: "minLength", value: 5 },
                {
                  name: "isEmail",
                  value: true,
                  validationMessage: "Please enter a valid email ID",
                },
              ],
            },
          ],
        ],
      },
    ],
  },
  {
    fieldSetClassName: CUSTOM_FIELDSET,
    fieldSetLabelType: "accordian",
    fieldSetLabel: "Business Details",
    fielsetId: "fieldset2",
    columns: [
      {
        rows: [
          [
            {
              key: "PriorityID",
              type: "select",
              disabled: false,
              id: "PriorityID",
              label: "Lead Status",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "Please select lead status.",
                },
              ],
              options: [],
            },
          ],
        ],
      },
    ],
  },
  {
    fieldSetClassName: CUSTOM_FIELDSET,
    fieldSetLabelType: "accordian",
    fieldSetLabel: "Others",
    fielsetId: "fieldset3",
    columns: [
      {
        rows: [
          [
            {
              colmd: "12",
              key: "LeadSourceID",
              type: "select",
              disabled: false,
              id: "LeadSourceID",
              label: "Lead Source",
              validators: [{ name: "required", value: true }],
              options: [],
            },
            {
              colmd: "12",
              key: "ActivityID",
              hide: false,
              type: "autocomplete",
              disabled: false,
              id: "ActivityID",
              label: "Activity ID",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This field is required",
                },
              ],
              options: [],
            },
            {
              colmd: "12",
              key: "ActivityDoneWith",
              type: "select",
              id: "ActivityDoneWith",
              label: "Select ZMA or Premier ZMA Name",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: requiredField,
                },
              ],
              options: [],
            },

            {
              colmd: "12",
              key: "ZmaID",
              type: "autocompleteMultiselect",
              id: "ZmaID",
              label: "ZMA /Premier ZMA Name",
              hide: true,
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: requiredField,
                },
              ],
              options: [],
            },
            {
              colmd: "12",
              key: "AdvisorName",
              type: "autocompleteMultiselect",
              id: "AdvisorName",
              label: "Select Advisor Name",
              hide: true,
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: requiredField,
                },
              ],
              options: [],
            },
            {
              colmd: "12",
              key: "ExpectedPremium",
              id: "ExpectedPremium",
              type: "number",
              label: "Expected Premium",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "Please enter expected premium",
                },
                {
                  name: "maxLength",
                  value: 8,
                  validationMessage: "Only 8 digits are allowed.",
                },
              ],
            },

            {
              colmd: "12",
              key: "ISleadHasMeeting",
              name: "ISleadHasMeeting",
              type: "CustomSwitch",
              id: "ISleadHasMeeting",
              label: "Meeting with Lead?",
              handleDiameter: 45,
              offColor: "#00427E",
              onColor: "#FFFFFF",
              offHandleColor: "#FFFFFF",
              onHandleColor: "#00427E",
              className: "radioBoxDiv",
              height: 30,
              width: 75,
              borderRadius: 24,
              border: "1px solid #ddd",
              activeBoxShadow: "0px 0px 1px 2px #fffc35",
              uncheckedIcon: <div className="switchUnChecked">Yes</div>,
              checkedIcon: <div className="switchChecked">No</div>,
            },
            {
              key: "MeetingStartDateTime",
              noDate: null,
              min:
                format(new Date(), "yyyy-MM-dd") +
                "T" +
                format(new Date(), "kk:mm"),
              hide: true,
              type: "datetime-local",
              disabled: false,
              id: "MeetingStartDateTime",
              label: "Meeting Start Date & Time",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "Please Select Date",
                },
              ],
            },

            {
              key: "MeetingEndDateTime",
              noDate: null,
              min:
                format(new Date(), "yyyy-MM-dd") +
                "T" +
                format(new Date(), "kk:mm"),
              max: endMaxDate,
              hide: true,
              type: "datetime-local",
              disabled: false,
              id: "MeetingEndDateTime",
              label: "Meeting End Date & Time",
              validators: [],
            },

            {
              colmd: "12",
              key: "Remarks",
              hide: false,
              type: "textarea",
              id: "Remarks",
              label: "Remarks",
              validators: [
                { name: "required", value: false },
                { name: "minLength", value: 2 },
                {
                  name: "maxLength",
                  value: 300,
                  validationMessage: "Only 300 characters allowed.",
                },
              ],
            },
            {
              colmd: "12",
              key: "ProductID",
              type: "autocomplete",
              disabled: false,
              id: "ProductID",
              label: "Select Product",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "Please select product",
                },
              ],
              options: [],
            },
          ],
        ],
      },
    ],
  },
];
export const RecruitmentLeadForm = [
  {
    fieldSetClassName: CUSTOM_FIELDSET,
    fieldSetLabel: "Lead Personal Details",
    fieldSetLabelType: "accordian",
    fielsetId: "fieldset1",
    columns: [
      {
        colmd: "12",
        colxs: "12",
        rows: [
          [
            {
              colmd: "12",
              key: "SalutationID",
              type: "select",
              disabled: false,
              id: "SalutationID",
              label: "Salutation",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This field is required",
                },
              ],
              options: [],
            },
            {
              colmd: "12",
              key: "FirstName",
              type: "text",
              id: "FirstName",
              label: "First Name",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This field is required",
                },
                {
                  name: "minLength",
                  value: 3,
                  validationMessage: "Please enter at least 3 letters",
                },
                {
                  name: "maxLength",
                  value: 15,
                  validationMessage: "Please Enter no more than 15 Characters",
                },
              ],
            },
            {
              colmd: "12",
              key: "MiddleName",
              type: "text",
              id: "MiddleName",
              label: "Middle Name",
              validators: [
                // {
                //   name: "required",
                //   value: true,
                //   validationMessage: "This field is required",
                // },
                {
                  name: "minLength",
                  value: 3,
                  validationMessage: "Please enter at least 3 letters",
                },
                {
                  name: "maxLength",
                  value: 15,
                  validationMessage: "Please Enter no more than 15 Characters",
                },
              ],
            },
            {
              colmd: "12",
              key: "LastName",
              type: "text",
              id: "LastName",
              label: "Last Name",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This field is required",
                },
                {
                  name: "minLength",
                  value: 3,
                  validationMessage: "Please enter at least 3 letters",
                },
                {
                  name: "maxLength",
                  value: 15,
                  validationMessage: "Please Enter no more than 15 Characters",
                },
              ],
            },
            {
              colmd: "12",
              key: "Gender",
              type: "select",
              disabled: false,
              id: "Gender",
              label: "Select Gender",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This is required.",
                },
              ],
              options: [],
            },
            {
              colmd: "12",
              key: "DOB",
              label: "Date of Birth",
              type: "date",
              id: "DOB",
              max: moment(new Date()).format("YYYY-MM-DD"),
              hide: false,
              noDate: null,
              validators: [
                {
                  name: "required",
                  value: true,
                },
              ],
            },

            {
              colmd: "12",
              key: "MobileNo",
              type: "number",
              id: "MobileNo",
              label: "Mobile Number",
              validators: [
                { name: "required", value: true },
                {
                  name: "minLength",
                  value: 10,
                  validationMessage: "Min length for Mobile No is 10 digits",
                },
                {
                  name: "maxLength",
                  value: 10,
                  validationMessage: "Maximum 10 digits allowed.",
                },
              ],
            },
            {
              colmd: "12",
              key: "AlternateNo",
              id: "AlternateNo",
              type: "number",
              label: "Alternate Mobile No.",
              validators: [
                {
                  name: "minLength",
                  value: 10,
                  validationMessage:
                    "Min length for Alternate Mobile No is 10 digits",
                },
                {
                  name: "maxLength",
                  value: 10,
                  validationMessage:
                    "Alternate number field require 10 digits.",
                },
              ],
            },
            {
              colmd: "12",
              key: "Email",
              type: "email",
              id: "Email",
              label: "Email ID",
              hide: false,
              validators: [
                { name: "required", value: true },
                { name: "minLength", value: 5 },
                {
                  name: "isEmail",
                  value: true,
                  validationMessage:
                    "Please enter a valid email ID eg:example@domain.com",
                },
              ],
            },
          ],
        ],
      },
    ],
  },

  {
    fieldSetClassName: CUSTOM_FIELDSET,
    fieldSetLabelType: "accordian",
    fieldSetLabel: "Address",
    fielsetId: "fieldset2",
    columns: [
      {
        colmd: "12",
        colxs: "12",
        rows: [
          [
            {
              colmd: "12",
              key: "PinCode",
              id: "PinCode",
              type: "number",
              label: "Pincode",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This field is required",
                },
                {
                  name: "minLength",
                  value: 6,
                  validationMessage: "Min 6 digits allowed.",
                },
                {
                  name: "maxLength",
                  value: 6,
                  validationMessage: "Maximum 6 digits allowed.",
                },
              ],
            },
          ],
        ],
      },
    ],
  },
  {
    fieldSetClassName: CUSTOM_FIELDSET,
    fieldSetLabelType: "accordian",
    fieldSetLabel: "Others",
    fielsetId: "fieldset3",
    columns: [
      {
        colmd: "12",
        colxs: "12",
        rows: [
          [
            {
              colmd: "12",
              key: "LeadTypeID",
              type: "select",
              disabled: false,
              id: "LeadTypeID",
              label: "Select Type Of Lead ?",
              options: [],
            },

            {
              colmd: "12",
              key: "ActivityID",
              hide: false,
              type: "autocomplete",
              disabled: false,
              id: "ActivityID",
              label: "Activity ID",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This field is required",
                },
              ],
              options: [],
            },

            {
              colmd: "12",
              key: "ProfessionID",
              type: "select",
              disabled: false,
              id: "ProfessionID",
              label: "Select Profession",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This is required.",
                },
              ],
              options: [],
            },
            {
              colmd: "12",
              key: "EngagementID",
              type: "select",
              disabled: false,
              id: "EngagementID",
              label: "Select Engagment",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This is required.",
                },
              ],
              options: [],
            },
            {
              colmd: "12",
              key: "IncomeGroupID",
              type: "select",
              disabled: false,
              id: "IncomeGroupID",
              label: "Select Income Group",
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This is required.",
                },
              ],
              options: [],
            },
            {
              colmd: "12",
              key: "FinanicalBackroundID",
              name: "FinanicalBackroundID",
              type: "select",
              id: "FinanicalBackroundID",
              label: "Select Financial BackGround",
              handleDiameter: 45,
              // offColor: "#00427E",
              // onColor: "#FFFFFF",
              // offHandleColor: "#FFFFFF",
              // onHandleColor: "#00427E",
              // className: "radioBoxDiv",
              // height: 30,
              // width: 75,
              // borderRadius: 24,
              // border: "1px solid #ddd",
              // activeBoxShadow: "0px 0px 1px 2px #fffc35",
              // uncheckedIcon: <div className="switchUnChecked">Yes</div>,
              // checkedIcon: <div className="switchChecked">No</div>,
              options: FINANICAL_BG,
              validators: [
                {
                  name: "required",
                  value: true,
                  validationMessage: "This is required.",
                },
              ],
            },
          ],
        ],
      },
    ],
  },
];

export const getFormTitle = (actionType) => {
  let formTitle = "";
  switch (actionType) {
    case BANCA_LEAD:
      formTitle = "Banca Lead";
      break;
    case BUSSINESS_LEAD:
      formTitle = "Bussiness Lead";
      break;
    case RECRUITMENT_LEAD:
      formTitle = "Recruitment Lead";
      break;

    default:
      formTitle = "Banca Lead";
      break;
  }
  return formTitle;
};

export const getForm = (actionType) => {
  let form = [];
  switch (actionType) {
    case BANCA_LEAD:
      form = AgencyLeadForm;
      break;

    case BUSSINESS_LEAD:
      form = BusinessLeadForm;
      break;
    case RECRUITMENT_LEAD:
      form = RecruitmentLeadForm;
      break;

    default:
      form = AgencyLeadForm;
      break;
  }
  return form;
};
