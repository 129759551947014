import { BANCA_LEAD, BUSSINESS_LEAD, CUSTOM_FIELDSET, RECRUITMENT_LEAD, FPM_LEAD } from "../../../utils/config";
import { isAlphabetOnly, isBranchValid, isEmailValid, isMeetindDate, numberStartWith6789Validator, isPositiveNumber } from "../../../utils/validators";
import { format } from "date-fns";
import moment from "moment";
const endMaxDate = moment(new Date()).endOf('day').format("YYYY-MM-DD kk:mm");

export const customValidators = [
    {
        key: 'FirstName',
        validator: isAlphabetOnly,
        message: 'Please enter alphabets only.'
    },
    {
        key: 'LastName',
        validator: isAlphabetOnly,
        message: 'Please enter alphabets only.'
    },
    {
        key: 'MiddleName',
        validator: isAlphabetOnly,
        message: 'Please enter alphabets only.'
    },
    {
        key: 'Email',
        validator: isEmailValid,
        message: 'invalid email id with missing domain name, @, .com, gmail id etc.'
    },
    {
        key: 'BranchID',
        validator: isBranchValid,
        message: "Branch code and activity id's branch code should be same."
    },
    {
        key: 'MobileNo',
        validator: numberStartWith6789Validator,
        message: '10 digit phone number starting with 6,7,8,9 only.'
    },
    {
        key: 'AlternateNo',
        validator: numberStartWith6789Validator,
        message: '10 digit phone number starting with 6,7,8,9 only.'
    },
    {
        key: 'MeetingDate',
        validator: isMeetindDate,
        message: "Meeting already exits."
    },
    {
        key: "ExpectedPremium",
        validator: isPositiveNumber, // function to be called
        message: "Expected Premium should be greater than 0", // message to show
    }
]
export const resetField = (formFields) => {
    for (const [index] of formFields.entries()) {
        if (index == 0) {
            continue;
        }
        formFields[index].hide = true
    }
    return formFields;
}



export const BancaLeadForm = [
    {
        "fieldSetClassName": CUSTOM_FIELDSET,
        "fieldSetLabel": "Lead Personal Details",
        "fieldSetLabelType": "accordian",
        "fielsetId": "fieldset1",
        "columns": [
            {

                "rows": [

                    [
                        {
                            "colmd": "12", "key": "SalutationID", "type": "select", "disabled": false, "id": "SalutationID", "label": "Salutation",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required." }],
                            "options": []
                        },
                        {
                            "colmd": "12", "key": "FirstName", "type": "text", "id": "FirstName", "label": "First Name",
                            "validators": [
                                { "name": "required", "value": true, "validationMessage": "This field is required" },
                                { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" },
                                { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
                            ]
                        },
                        {
                            "colmd": "12", "key": "MiddleName", "type": "text", "id": "MiddleName", "label": "Middle Name",
                            "validators": [
                                // { "name": "required", "value": true, "validationMessage": "This field is required" },
                                { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" },
                                { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
                            ]
                        },
                        {
                            "colmd": "12", "key": "LastName", "type": "text", "id": "LastName", "label": "Last Name",
                            "validators": [
                                { "name": "required", "value": true, "validationMessage": "This field is required" },
                                { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" },
                                { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
                            ]
                        },
                        {
                            "colmd": "12", "key": "MobileNo", "type": "number", "id": "MobileNo", "label": "Mobile No.",
                            "validators": [
                                { "name": "required", "value": true, "validationMessage": "This field is required." },
                                { "name": "minLength", "value": 10, "validationMessage": "Min length for Mobile No is 10 digits" },
                                { "name": "maxLength", "value": 10, "validationMessage": "Mobile number field require 10 digits." }]
                        },
                        {
                            "colmd": "12", "key": "AlternateNo", "id": "AlternateNo", "type": "number", "label": "Alternate Mobile No.",
                            "validators": [
                                { "name": "minLength", "value": 10, "validationMessage": "Min length for Alternate Mobile No is 10 digits" },
                                { "name": "maxLength", "value": 10, "validationMessage": "Alternate number field require 10 digits." }
                            ]
                        },
                        {
                            "colmd": "12", "key": "Email", "type": "email", "id": "Email", "label": "Email", "hide": false,
                            "validators": [
                                { "name": "required", "value": true }, { "name": "isEmail", "value": true, "validationMessage": "Please enter a valid email ID" }
                            ]
                        },
                        {
                            "colmd": "12", "key": "Location", "locationIcon": "fa fa-map-marker", "label": "Address", "hide": false, "type": "checkinField",
                            "name": "address", "locationLabel": "Current Address: ", "okayButtonText": "Submit",
                            "cancelButtonText": "Cancel", "id": "Location",
                            "validators": [{ "name": "required", "value": false }]
                        },


                    ],]
            },
        ]

    },
    {
        "fieldSetClassName": CUSTOM_FIELDSET,
        "fieldSetLabelType": "accordian",
        "fieldSetLabel": "Product Details",
        "fielsetId": "fieldset2",
        "columns": [
            {

                "rows": [

                    [
                        {
                            "colmd": "12", "key": "ProductID", "type": "autocomplete", "disabled": false, "id": "ProductID", "label": "Select Product", "validators": [{ "name": "required", "value": true, "validationMessage": "Please select product" }],
                            "options": []
                        },
                        { "colmd": "12", "key": "ExpectedPremium", "id": "ExpectedPremium", "props": { "min": "1" }, "type": "number", "label": "Expected Premium", "validators": [{ "name": "required", "value": true, "validationMessage": "Please enter expected premium" }, { "name": "maxLength", "value": 8, "validationMessage": "Only 8 digits are allowed." }] },
                    ],
                ]

            },
        ]
    },
    {
        "fieldSetClassName": CUSTOM_FIELDSET,
        "fieldSetLabelType": "accordian",
        "fieldSetLabel": "Business Details",
        "fielsetId": "fieldset3",
        "columns": [
            {

                "rows": [
                    [{
                        "key": "PriorityID", "type": "select", "disabled": false, "id": "PriorityID", "label": "Lead Status", "validators": [{ "name": "required", "value": true, "validationMessage": "Please select lead status." }],
                        "options": []
                    },
                    {
                        "colmd": "12", "key": "LeadSourceID", "type": "select", "disabled": false, "id": "LeadSourceID", "label": "Lead Source", "validators": [{ "name": "required", "value": true,"validationMessage": "Please select Lead Source" }],
                        "options": []
                    },
                    {
                        "colmd": "12", "key": "ActivityID", "hide": false, "type": "autocomplete", "disabled": false, "id": "ActivityID", "label": "Activity ID", "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required" }],
                        "options": []
                    },
                    {
                        "colmd": "12", "key": "SPTypeID", "type": "select", "disabled": false, "id": "SPTypeID", "label": "Type Of SP", "validators": [{ "name": "required", "value": true, "validationMessage": "Please select type of SP value" }],
                        "options": []
                    },
                    {
                        "colmd": "12", "hide": true, "key": "BranchID", "type": "select", "id": "BranchID", "label": "Branch Name &     Code", "validators": [{ "name": "required", "value": true }],
                        "options": []
                    },
                    {
                        "colmd": "12", "hide": true, "key": "LGID", "type": "select", "id": "LGID", "label": "LG Code", "validators": [{ "name": "required", "value": true }],
                        "options": []
                    },
                    {
                        "colmd": "12", "hide": true, "key": "SPID", "type": "select", "id": "SPID", "label": "SP Code", "validators": [{ "name": "required", "value": true, }],
                        "options": []
                    },
                    {
                        "colmd": "12", "key": "ISleadHasMeeting", "name": "ISleadHasMeeting", "type": "CustomSwitch", "id": "ISleadHasMeeting", "label": "Meeting with Lead?", handleDiameter: 45,
                        offColor: "#00427E",
                        onColor: "#FFFFFF",
                        offHandleColor: "#FFFFFF",
                        onHandleColor: "#00427E",
                        className: "radioBoxDiv",
                        height: 30,
                        width: 75,
                        borderRadius: 24,
                        border: "1px solid #ddd",
                        activeBoxShadow: "0px 0px 1px 2px #fffc35",
                        uncheckedIcon: <div className="switchUnChecked">Yes</div>,
                        checkedIcon: <div className="switchChecked">No</div>
                    },
                    { "key": "MeetingStartDateTime", "noDate": null, "min": format(new Date(), 'yyyy-MM-dd') + "T" + format(new Date(), 'kk:mm'), "hide": true, "type": "datetime-local", "disabled": false, "id": "MeetingStartDateTime", "label": "Meeting Start Date & Time", "validators": [] },

                    { "key": "MeetingEndDateTime", "noDate": null, "min": format(new Date(), 'yyyy-MM-dd') + "T" + format(new Date(), 'kk:mm'), "max": endMaxDate, "hide": true, "type": "datetime-local", "disabled": false, "id": "MeetingEndDateTime", "label": "Meeting End Date & Time", "validators": [] },
                    { "colmd": "12", "key": "Remarks", "hide": false, "type": "textarea", "id": "Remarks", "label": "Remarks", "validators": [{ "name": "required", "value": false }, { "name": "minLength", "value": 2 }, { "name": "maxLength", "value": 300, "validationMessage": "Only 300 characters allowed." }] },
                    ],
                ]

            },
        ]
    },

];

export const FPMLeadForm = [
    {
        "fieldSetClassName": CUSTOM_FIELDSET,
        "fieldSetLabel": "Lead Personal Details",
        "fieldSetLabelType": "accordian",
        "fielsetId": "fieldset1",
        "columns": [
            {

                "rows": [

                    [
                        {
                            "colmd": "12", "key": "SalutationID", "type": "select", "disabled": false, "id": "SalutationID", "label": "Salutation",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required." }],
                            "options": []
                        },
                        {
                            "colmd": "12", "key": "FirstName", "type": "text", "id": "FirstName", "label": "First Name",
                            "validators": [
                                { "name": "required", "value": true, "validationMessage": "This field is required" },
                                { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" },
                                { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
                            ]
                        },
                        {
                            "colmd": "12", "key": "MiddleName", "type": "text", "id": "MiddleName", "label": "Middle Name",
                            "validators": [
                                // { "name": "required", "value": true, "validationMessage": "This field is required" },
                                { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" },
                                { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
                            ]
                        },
                        {
                            "colmd": "12", "key": "LastName", "type": "text", "id": "LastName", "label": "Last Name",
                            "validators": [
                                { "name": "required", "value": true, "validationMessage": "This field is required" },
                                { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" },
                                { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
                            ]
                        },
                        {
                            "colmd": "12", "key": "MobileNo", "type": "number", "id": "MobileNo", "label": "Mobile No.",
                            "validators": [
                                { "name": "required", "value": true, "validationMessage": "This field is required." },
                                { "name": "minLength", "value": 10, "validationMessage": "Min length for Mobile No is 10 digits" },
                                { "name": "maxLength", "value": 10, "validationMessage": "Mobile number field require 10 digits." }]
                        },
                        {
                            "colmd": "12", "key": "AlternateNo", "id": "AlternateNo", "type": "number", "label": "Alternate Mobile No.",
                            "validators": [
                                { "name": "minLength", "value": 10, "validationMessage": "Min length for Alternate Mobile No is 10 digits" },
                                { "name": "maxLength", "value": 10, "validationMessage": "Alternate number field require 10 digits." }
                            ]
                        },
                        {
                            "colmd": "12", "key": "Email", "type": "email", "id": "Email", "label": "Email", "hide": false,
                            "validators": [
                                { "name": "required", "value": true }, { "name": "isEmail", "value": true, "validationMessage": "Please enter a valid email ID" }
                            ]
                        },
                        {
                            "colmd": "12", "key": "Location", "locationIcon": "fa fa-map-marker", "label": "Address", "hide": false, "type": "checkinField",
                            "name": "address", "locationLabel": "Current Address: ", "okayButtonText": "Submit",
                            "cancelButtonText": "Cancel", "id": "Location",
                            "validators": [{ "name": "required", "value": false }]
                        },


                    ],]
            },
        ]

    },
    {
        "fieldSetClassName": CUSTOM_FIELDSET,
        "fieldSetLabelType": "accordian",
        "fieldSetLabel": "Product Details",
        "fielsetId": "fieldset2",
        "columns": [
            {

                "rows": [

                    [
                        {
                            "colmd": "12", "key": "ProductID", "type": "select", "disabled": false, "id": "ProductID", "label": "Select Product", "validators": [{ "name": "required", "value": true, "validationMessage": "Please select product" }],
                            "options": []
                        },
                        { "colmd": "12", "key": "ExpectedPremium", "id": "ExpectedPremium", "props": { "min": "1" }, "type": "number", "label": "Expected Premium", "validators": [{ "name": "required", "value": true, "validationMessage": "Please enter expected premium" }, { "name": "maxLength", "value": 8, "validationMessage": "Only 8 digits are allowed." }] },
                    ],
                ]

            },
        ]
    },
    {
        "fieldSetClassName": CUSTOM_FIELDSET,
        "fieldSetLabelType": "accordian",
        "fieldSetLabel": "Business Details",
        "fielsetId": "fieldset3",
        "columns": [
            {

                "rows": [
                    [{
                        "key": "PriorityID", "type": "select", "disabled": false, "id": "PriorityID", "label": "Lead Status", "validators": [{ "name": "required", "value": true, "validationMessage": "Please select lead status." }],
                        "options": []
                    },
                    {
                        "colmd": "12", "key": "LeadSourceID", "type": "select", "disabled": false, "id": "LeadSourceID", "label": "Lead Source", "validators": [{ "name": "required", "value": true ,"validationMessage": "Please select Lead Source"}],
                        "options": []
                    },
                    {
                        "colmd": "12", "key": "ActivityID", "hide": false, "type": "autocomplete", "disabled": false, "id": "ActivityID", "label": "Activity ID", "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required" }],
                        "options": []
                    },
                    {
                        "colmd": "12", "key": "SPTypeID", "type": "select", "disabled": false, "id": "SPTypeID", "label": "Type Of SP", "validators": [{ "name": "required", "value": true, "validationMessage": "Please select type of SP value" }],
                        "options": []
                    },
                    {
                        "colmd": "12", "hide": true, "key": "BranchID", "type": "select", "id": "BranchID", "label": "Branch Name &     Code", "validators": [{ "name": "required", "value": true }],
                        "options": []
                    },
                    {
                        "colmd": "12", "hide": true, "key": "SRMID", "type": "select", "id": "SRMID", "label": "SRM", "validators": [{ "name": "required", "value": true, }],
                        "options": []
                    },
                    {
                        "colmd": "12", "key": "ISleadHasMeeting", "name": "ISleadHasMeeting", "type": "CustomSwitch", "id": "ISleadHasMeeting", "label": "Meeting with Lead?", handleDiameter: 45,
                        offColor: "#00427E",
                        onColor: "#FFFFFF",
                        offHandleColor: "#FFFFFF",
                        onHandleColor: "#00427E",
                        className: "radioBoxDiv",
                        height: 30,
                        width: 75,
                        borderRadius: 24,
                        border: "1px solid #ddd",
                        activeBoxShadow: "0px 0px 1px 2px #fffc35",
                        uncheckedIcon: <div className="switchUnChecked">Yes</div>,
                        checkedIcon: <div className="switchChecked">No</div>
                    },
                    { "key": "MeetingStartDateTime", "noDate": null, "min": format(new Date(), 'yyyy-MM-dd') + "T" + format(new Date(), 'kk:mm'), "hide": true, "type": "datetime-local", "disabled": false, "id": "MeetingStartDateTime", "label": "Meeting Start Date & Time", "validators": [] },

                    { "key": "MeetingEndDateTime", "noDate": null, "min": format(new Date(), 'yyyy-MM-dd') + "T" + format(new Date(), 'kk:mm'),"max": endMaxDate, "hide": true, "type": "datetime-local", "disabled": false, "id": "MeetingEndDateTime", "label": "Meeting End Date & Time", "validators": [] },
                    { "colmd": "12", "key": "Remarks", "hide": false, "type": "textarea", "id": "Remarks", "label": "Remarks", "validators": [{ "name": "required", "value": false }, { "name": "minLength", "value": 2 }, { "name": "maxLength", "value": 300, "validationMessage": "Only 300 characters allowed." }] },
                    ],
                ]

            },
        ]
    },

];
export const BusinessLeadForm = [
    {
        "fieldSetClassName": CUSTOM_FIELDSET,
        "fieldSetLabelType": "component",
        "columns": [
            {
                "colmd": "12",
                "colxs": "12",
                "rows": [

                    [

                        {
                            "colmd": "12", "key": "salutation", "type": "select", "disabled": false, "id": "salutation", "label": "Salutation", "validators": [{ "name": "required", "value": true, "validationMessage": "Product selection is required." }],
                            "options": []

                        },
                        { "colmd": "12", "key": "firstName", "type": "text", "id": "firstName", "label": "First Name", "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required" }, { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" }, { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }] },
                        { "colmd": "12", "key": "MiddleName", "type": "text", "id": "MiddleName", "label": "Middle Name", "validators": [{ "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" }, { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }] },
                        { "colmd": "12", "key": "lastName", "type": "text", "id": "lastName", "label": "Last Name", "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required" }, { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" }, { "name": "maxLength", "value": 30, "validationMessage": "Maximum 30 letters allowed." }] },
                        { "colmd": "12", "key": "mobileNumber", "type": "number", "id": "mobileNumber", "label": "Mobile Number", "validators": [{ "name": "required", "value": true }, { "name": "minLength", "value": 10, "validationMessage": "Min length for Mobile No is 10 digits" }, { "name": "maxLength", "value": 10, "validationMessage": "Maximum 10 digits allowed." }] },
                        { "colmd": "12", "key": "email", "type": "email", "id": "email", "label": "Email ID", "hide": false, "validators": [{ "name": "required", "value": true }, { "name": "minLength", "value": 5 }, { "name": "isEmail", "value": true, "validationMessage": "Please enter a valid email ID" }] },


                    ],]


            },
        ]

    },
    {
        "fieldSetClassName": CUSTOM_FIELDSET,
        "fieldSetLabelType": "component",
        "columns": [
            {
                "colmd": "12",
                "colxs": "12",
                "rows": [

                    [
                        {
                            "colmd": "12", "key": "product", "type": "select", "disabled": false, "id": "product", "label": "Select Product", "validators": [{ "name": "required", "value": true, "validationMessage": "Product selection is required." }],
                            "options": []
                        }
                    ],
                ]

            },
        ]

    },
    {
        "fieldSetClassName": CUSTOM_FIELDSET,
        "fieldSetLabelType": "component",
        "columns": [
            {
                "colmd": "12",
                "colxs": "12",
                "rows": [
                    [

                        {
                            "colmd": "12", "key": "status", "type": "select", "disabled": false, "id": "status", "label": "Select Status", "validators": [{ "name": "required", "value": true, "validationMessage": "This is required." }],
                            "options": []
                        },
                        {
                            "key": "leadSource", "type": "select", "disabled": false, "id": "leadSource", "label": "Lead Source", "validators": [{ "name": "required", "value": true, "validationMessage": "This is required." }],
                            "options": []
                        },
                    ],
                ]

            },
        ]

    },
    {
        "fieldSetClassName": CUSTOM_FIELDSET,
        "fieldSetLabelType": "component",
        "columns": [
            {
                "colmd": "12",
                "colxs": "12",
                "rows": [
                    [


                        {
                            "colmd": "12", "key": "activityId", "id": "activityId", "label": "Activity ID", "validators": [{ "name": "required", "value": true, "validationMessage": "" }],
                            "options": []
                        },
                        { "key": "zmaName", "hide": true, "type": "select", "disabled": false, "id": "zmaName", "label": "ZMA Name", "validators": [{ "name": "required", "value": true, "validationMessage": "This is required." }], "options": [] },
                        { "key": "advisor", "hide": true, "type": "select", "disabled": false, "id": "advisor", "label": "Select Advisor", "validators": [{ "name": "required", "value": true, "validationMessage": "This is required." }], "options": [] },


                        { "colmd": "12", "key": "AlternateNo", "id": "AlternateNo", "type": "number", "label": "Alternate No", "validators": [{ "name": "minLength", "value": 10 }, { "name": "maxLength", "value": 10, "validationMessage": "Mobile number field require 10 digits." }] },
                        { "colmd": "12", "key": "ExpectedPremium", "id": "ExpectedPremium", "type": "number", "props": { "min": "1" }, "label": "Expected Premium", "validators": [{ "name": "required", "value": true, "validationMessage": "Please add expected premium field" }, { "name": "maxLength", "value": 8, "validationMessage": "Only 8 characters allowed." }] },
                        {
                            "colmd": "12", "key": "leadHasMeeting", "type": "select", "disabled": false, "id": "leadHasMeeting", "label": "Lead Has Meeting", "validators": [{ "name": "required", "value": false }],
                            "options": []
                        },
                        {
                            "colmd": "12",
                            "key": "meetingDate",
                            "label": "Meeting Date",
                            "type": "date",
                            "id": "meetingDate",
                            "min": "2021-01-27",
                            "max": "9999-12-31",
                            "hide": true,
                            "noDate": null,
                            "validators": [
                                {
                                    "name": "required",
                                    "value": true
                                }
                            ]
                        },
                        { "colmd": "12", "key": "remark", "hide": true, "id": "remark", "label": "Remarks", "validators": [{ "name": "required", "value": true }, { "name": "minLength", "value": 2 }, { "name": "maxLength", "value": 300, "validationMessage": "Maximum 300 letters allowed." }] },


                    ],
                ]

            },
        ]

    }



];
export const RecruitmentLeadForm = [
    {
        "fieldSetClassName": CUSTOM_FIELDSET,
        "fieldSetLabelType": "component",
        "columns": [
            {
                "colmd": "12",
                "colxs": "12",
                "rows": [

                    [

                        {
                            "colmd": "12", "key": "salutation", "type": "select", "disabled": false, "id": "salutation", "label": "Salutation", "validators": [{ "name": "required", "value": true, "validationMessage": "Product selection is required." }],
                            "options": []

                        },
                        { "colmd": "12", "key": "firstName", "type": "text", "id": "firstName", "label": "First Name", "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required" }, { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" }, { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }] },
                        { "colmd": "12", "key": "MiddleName", "type": "text", "id": "MiddleName", "label": "First Name", "validators": [{ "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" }, { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }] },

                        { "colmd": "12", "key": "lastName", "type": "text", "id": "lastName", "label": "Last Name", "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required" }, { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" }, { "name": "maxLength", "value": 30, "validationMessage": "Maximum 30 letters allowed." }] },
                        {
                            "colmd": "12", "key": "gender", "type": "select", "disabled": false, "id": "gender", "label": "Select Gender", "validators": [{ "name": "required", "value": true, "validationMessage": "This is required." }],
                            "options": []
                        },
                        { "colmd": "12", "key": "email", "type": "email", "id": "email", "label": "Email ID", "hide": false, "validators": [{ "name": "required", "value": true }, { "name": "minLength", "value": 5 }, { "name": "isEmail", "value": true, "validationMessage": "Please enter a valid email ID eg:example@domain.com" }] },

                        { "colmd": "12", "key": "mobileNumber", "type": "number", "id": "mobileNumber", "label": "Mobile Number", "validators": [{ "name": "required", "value": true }, { "name": "minLength", "value": 10, "validationMessage": "Min length for Mobile No is 10 digits" }, { "name": "maxLength", "value": 10, "validationMessage": "Maximum 10 digits allowed." }] },

                        { "colmd": "12", "key": "pincode", "id": "pincode", "label": "Pincode", "validators": [{ "name": "required", "value": true }, { "name": "minLength", "value": 6, "validationMessage": "Min 6 digits allowed." }, { "name": "maxLength", "value": 6, "validationMessage": "Maximum 6 digits allowed." }] },

                    ],]


            },
        ]

    },

    {
        "fieldSetClassName": CUSTOM_FIELDSET,
        "fieldSetLabelType": "component",
        "columns": [
            {
                "colmd": "12",
                "colxs": "12",
                "rows": [
                    [


                        {
                            "key": "leadType", "type": "select", "disabled": false, "id": "leadType", "label": "Lead Type", "validators": [{ "name": "required", "value": true, "validationMessage": "Product selection is required." }],
                            "options": []
                        },
                    ],
                ]

            },
        ]

    },
    {
        "fieldSetClassName": CUSTOM_FIELDSET,
        "fieldSetLabelType": "component",
        "columns": [
            {
                "colmd": "12",
                "colxs": "12",
                "rows": [
                    [

                        {
                            "colmd": "12", "key": "activityId", "id": "activityId", "label": "Activity ID", "validators": [{ "name": "required", "value": true, "validationMessage": "" }],
                            "options": []
                        },
                        {
                            "colmd": "12",
                            "key": "dateOfBirth",
                            "label": "Date of Birth",
                            "type": "date",
                            "id": "dateofBirth",
                            "min": "2021-01-27",
                            "max": "9999-12-31",
                            "hide": true,
                            "noDate": null,
                            "validators": [
                                {
                                    "name": "required",
                                    "value": true
                                }
                            ]
                        },
                        {
                            "colmd": "12", "key": "selectProfession", "type": "select", "disabled": false, "id": "selectProfession", "label": "Select Profession", "validators": [{ "name": "required", "value": false, "validationMessage": "Please select" }],
                            "options": []
                        },
                        {
                            "colmd": "12", "key": "selectEngagment", "type": "select", "disabled": false, "id": "selectEngagment", "label": "Select Engagment", "validators": [{ "name": "required", "value": false, "validationMessage": "Please select" }],
                            "options": []
                        },
                        {
                            "colmd": "12", "key": "selectIncomeGroup", "type": "select", "disabled": false, "id": "selectIncomeGroup", "label": "Select Income Group", "validators": [{ "name": "required", "value": false, "validationMessage": "Please select" }],
                            "options": []
                        },
                        {
                            "colmd": "12", "key": "selectFinancialBG", "type": "select", "disabled": false, "id": "selectFinancialBG", "label": "Select Financial Background", "validators": [{ "name": "required", "value": false, "validationMessage": "Please select" }],
                            "options": []
                        },

                    ],
                ]

            },
        ]

    }



];
export const ReserviourLeadForm = [
    {
        "fieldSetClassName": CUSTOM_FIELDSET,
        "fieldSetLabelType": "component",
        "columns": [
            {
                "colmd": "12",
                "colxs": "12",
                "rows": [

                    [
                        { "colmd": "12", "key": "firstName", "type": "text", "id": "firstName", "label": "First Name", "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required" }, { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" }, { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }] },
                        { "colmd": "12", "key": "lastName", "type": "text", "id": "lastName", "label": "Last Name", "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required" }, { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" }, { "name": "maxLength", "value": 30, "validationMessage": "Maximum 30 letters allowed." }] },
                        { "colmd": "12", "key": "mobileNumber", "type": "number", "id": "mobileNumber", "label": "Mobile Number", "validators": [{ "name": "required", "value": true }, { "name": "minLength", "value": 10, "validationMessage": "Min length for Mobile No is 10 digits" }, { "name": "maxLength", "value": 10, "validationMessage": "Maximum 10 digits allowed." }] },

                    ],]


            },
        ]

    },
    {
        "fieldSetClassName": CUSTOM_FIELDSET,
        "fieldSetLabelType": "component",
        "columns": [
            {
                "colmd": "12",
                "colxs": "12",
                "rows": [

                    [
                        {
                            "colmd": "12", "key": "typeOfSP", "type": "select", "disabled": false, "id": "typeOfSP", "label": "Type Of SP", "validators": [{ "name": "required", "value": false }],
                            "options": []
                        },
                        {
                            "colmd": "12", "key": "selectBranch", "type": "select", "disabled": true, "id": "selectBranch", "label": "Select Branch", "validators": [{ "name": "required", "value": false }],
                            "options": []
                        },
                        {
                            "colmd": "12", "key": "selectLG", "type": "select", "disabled": true, "id": "selectLG", "label": "Select LG", "validators": [{ "name": "required", "value": false }],
                            "options": []
                        },
                        {
                            "colmd": "12", "key": "selectSP", "type": "select", "disabled": true, "id": "selectSP", "label": "Select SP", "validators": [{ "name": "required", "value": false, }],
                            "options": []
                        },
                    ],
                ]

            },
        ]

    },

    {
        "fieldSetClassName": CUSTOM_FIELDSET,
        "fieldSetLabelType": "component",
        "columns": [
            {
                "colmd": "12",
                "colxs": "12",
                "rows": [
                    [

                        {
                            "colmd": "12", "key": "activityId", "id": "activityId", "label": "Activity ID", "validators": [{ "name": "required", "value": true, "validationMessage": "" }],
                            "options": []
                        },
                        {
                            "colmd": "12", "key": "leadSource", "type": "select", "disabled": false, "id": "leadSource", "label": "Lead Source", "validators": [{ "name": "required", "value": false }],
                            "options": []
                        },
                        {
                            "key": "leadType", "type": "select", "disabled": false, "id": "leadType", "label": "Lead Type", "validators": [{ "name": "required", "value": true, "validationMessage": "Product selection is required." }],
                            "options": []
                        },
                    ],
                ]

            },
        ]

    }



];



export const getFormTitle = (actionType) => {
    let formTitle = ''
    switch (actionType) {
        case BANCA_LEAD:
            formTitle = 'Banca Lead';
            break;
        case BUSSINESS_LEAD:
            formTitle = 'Bussiness Lead';
            break;
        case RECRUITMENT_LEAD:
            formTitle = 'Recruitment Lead';
            break;
        case FPM_LEAD:
            formTitle = 'FPM Lead';
            break;

        default:
            formTitle = 'Banca Lead';
            break;
    }
    return formTitle;
}

export const getForm = (actionType) => {
    let form = []
    switch (actionType) {
        case BANCA_LEAD:
            form = BancaLeadForm;
            break;
        case BUSSINESS_LEAD:
            form = BusinessLeadForm;
            break;
        case RECRUITMENT_LEAD:
            form = RecruitmentLeadForm;
            break;
        case FPM_LEAD:
            form = FPMLeadForm;
            break;

        default:
            form = BancaLeadForm;
            break;
    }
    return form;
}


