import React, { useEffect, useState } from 'react';
import {getallnotificationsbyuser,getMasterDataForNotification,readallnotificationsbyuser,readsinglenotificationsbyuser} from '../../redux/actions/notificationAction'
import BoltIcon from '@mui/icons-material/Bolt';
import EditIcon from '@mui/icons-material/Edit';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Grid from '@mui/material/Grid';
import { connect,useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import "./notification.scss";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {readAllNotification,activateLoader, deActivateLoader} from '../../redux/actions/common';
import CallIcon from '@mui/icons-material/Call';
import { useDispatch } from 'react-redux';

const AllNotification = () => {
  const dispatch=useDispatch()
    const commonState = useSelector(state => state);
    const userState = commonState.userReducer
    const [UserId] = useState(userState?.profileInfo?.userId)
    const history=useHistory()
    const [notificationData, setNotificationData] = useState([])
    const [notificationTypes, setNotificationTypes] = useState([])
    const getNotificationTypes = async()=>{
        const paylod = {
            tableName: "Notificationcategory"
        }
    
        const resposne = await dispatch(getMasterDataForNotification(paylod))
        setNotificationTypes(resposne.data)       
        console.log("getNotificationTypes",resposne)
    }

    const getAllNotificationsByUser = async()=>{
      dispatch(activateLoader())
        const res = await dispatch(getallnotificationsbyuser(UserId)  )     
        if (res.status === 200) {
          dispatch( deActivateLoader())
          console.log("getAllNotificationsByUser",res.data.PushNotifications)
          let da=res.data?.PushNotifications;
          let details = []
          da.forEach((data) => {
            details.push({
              ...data, isShowMore: data.NotificationMessage.length > 67 ? false : true
            })
          })
          setNotificationData(details)
        }
        else {
          dispatch(deActivateLoader())
        }
       
    }

    const handleAudioCall = (e,MobileNo) => {
      e.stopPropagation()
      window.open(`tel:${MobileNo}`)
  }
const getType=(el)=>{
  switch (el.LeadtypeID){
    case 1:
      history.push(`/app/lead/detail/${el.LeadId}`);
      break;
      case 2:
        history.push(`/app/persistencyLead/detail/${el.LeadId}`);
        break;
        case 3:
          history.push(`/app/campaignLead/detail/${el.LeadId}`);
          break;
      default:
       break;
  }
}
    const redirectToDetails=async (el)=>{
      switch (el.CategoryID) {
        case 1:
          history.push(`/app/activity/detail/${el.TrnActivityID}`);
          break;
        case 2:
          history.push(`/app/activity/detail/${el.TrnActivityID}`);
          break;
        case 3:
          history.push("/app/calendar");
          break;
        case 4:          
          getType(el)
          break;  
        case 5:
          history.push(`/app/activity/detail/${el.TrnActivityID}`);
          break;
        case 6:
          getType(el)
          break;  
        case 7:          
          getType(el)
          break;
        case 8:
          history.push(`/app/activity/detail/${el.TrnActivityID}`);
          break;
        case 9:
          history.push(`/app/activity/detail/${el.TrnActivityID}`);
          break;
        case 10:
          if(el.TrnActivityID){
            history.push(`/app/activity/detail/${el.TrnActivityID}`);
            }else{
              switch (el.LeadtypeID){
                case 1:
                  history.push(`/app/lead/detail/${el.LeadId}`);
                  break;
                  case 2:
                    history.push(`/app/persistencyLead/detail/${el.LeadId}`);
                    break;
                    case 3:
                      history.push(`/app/campaignLead/detail/${el.LeadId}`);
                      break;
                  default:
                   break;
              }
            }
          break;
        case 11:
        case 12:
        if (el.TrnActivityID) {
          history.push(`/app/salesmanagement/detail/${el.TrnActivityID}`);
        }
        break;
        case 30:
          history.push(`/app/activity/campaigndetails/${el.TrnActivityID}`);
          break;
        case 31:
          history.push(`/app/activity/campaigndetails/${el.TrnActivityID}`);
          break;
        default:
        break;
      }
          await dispatch(readsinglenotificationsbyuser(el.NotificationID))
          dispatch(readAllNotification(true))
        //   window.location.reload()
          //toggleNotificationDrawer()
    }

   const readAll=async()=>{
    await dispatch(readallnotificationsbyuser(UserId))
        dispatch(readAllNotification(true))
    }

    const timeConvert = (inputDateTime)=>{
        var now = moment();
        var displayValue = '';
        var dateString = inputDateTime;
        var targetDate = moment(dateString);
        var difference = now.diff(targetDate, 'days');

        if (difference > 6000 || difference < -3) {
            displayValue = targetDate.format('MM-DD-YYYY') + ' at ' + targetDate.format('hh:mm');
        } else {
            displayValue = targetDate.fromNow();
        }
        return displayValue;
            }

    useEffect(()=>{
        getAllNotificationsByUser()
        getNotificationTypes()
    },[])

  const handleSeeMore = (e, id) => {
    e.stopPropagation()
    let getData = notificationData[id]
    getData.isShowMore = !getData.isShowMore
    const data = [...notificationData]
    setNotificationData(data)
  }

    return (
        <div className="notificationPopupDiv notifiPage">
                <h3 className='title'>Notifications <span>Showing {notificationData?.length} Results <button className='readBtn' onClick={readAll}>Read All</button></span></h3>
                <div className="notifInner">
                    {notificationData.map((el,index)=>{
                      return <div key={index} >
                           <div className={el.Isnotificationreadbyuser?"readTxt listItem":"unreadTxt listItem"}>
                          <div onClick={()=>{redirectToDetails(el)}} style={{cursor:'pointer'}}>
                          <ArrowRightAltIcon className='arrowIcon' />
                        {notificationTypes.map(notif=>{
                           if(notif.CategoryID===el.CategoryID){
                               return <h6 key={"de"+notif.CategoryID} style=
                               {{color:el.Isnotificationreadbyuser?"rgb(74 67 67)":"#00427E"}} Isnotificationreadbyuser={el.Isnotificationreadbyuser}>
                                   <BoltIcon />
                                   {notif.CategoryName}
                                   </h6>
                           } else{
                               return ""
                           }
                        })}
                        
                          <div className="statusText">{el.isShowMore ? el.NotificationMessage : el.NotificationMessage.slice(0, 67)}
                            {!el.isShowMore && <span onClick={(e) => handleSeeMore(e, index)}>...see more</span>}
                        </div>
                        <div className="lastDate">
                            <span>{timeConvert(el.Notificationshownafter)}</span>
                            {/* { el.MeetingStartDateTime && el.MeetingEndDateTime &&<><b>Today</b>, {
                                        getDateDuration(el.MeetingStartDateTime, el.MeetingEndDateTime,'HOUR')+' '+
                                        getDateDuration(el.MeetingStartDateTime, el.MeetingEndDateTime,'MINUTE')}
                                        </>
                    } */}
                        </div>
                        </div>
                        <Grid container justifyContent="center" alignItems="center" className="updateEdit" spacing={3}>
                            

                            {el.Mobileno !== null?(
                              <Grid item xs={6}>
                                <span onClick={(e)=>handleAudioCall(e,el.Mobileno)} style={{cursor:'pointer'}}>
                                <CallIcon/>
                                Call
                                </span>
                                </Grid>
                                ):""}
                                
                                <Grid item xs={6}>
                                <span onClick={()=>{redirectToDetails(el)}}>
                                    <EditIcon /> Details
                                </span>
                            </Grid>
                           </Grid>
                    </div>

                          </div>
                    })}
               
                </div>
           
        </div>
    )
}

const mapStateToProps = (state) => ({
    masterDataState: state.commonReducer
  });

export default withTranslation('translation', { withRef: true })(connect(mapStateToProps)(AllNotification));
