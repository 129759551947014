import React, { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import DynamicForm from "f1-dynamic-form-react";
import { MSMELeadForm, customValidators } from "./createLeadFormMSME.js";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb.js";
import MessageBar from "../../../../components/messageBar/messageBar.js";
import { t } from "i18next";
import {
  setBreadcrumbs,
  activateLoader,
  deActivateLoader,
  getAcitvityDuration,
  activateSuccessMessageBar,
  activateErrorMessageBar,
  getSubordinateList,
} from "../../../../redux/actions/common.js";
import { checkMobileNumberReducer, getLeadDetails } from "../../../../redux-reducer/reducer";
import { connect, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { checkInConfig, MSMELEAD } from "../../../../utils/config.js";
import {
  saveLeadOffline,
  isLeadValid,
} from "../../../../offlineManager/leadOfflineManager";
import { LEADS_PATH } from "../../../../route/routePath.js";
import SaveAsDraftDrawer from "../../activity/saveAsDraftDrawer.js";
import Footer from "../../../../layouts/footer/footer";
import Loader from "../../../../components/loader/loader";
import {
  saveLeadForm,
  UpdateLeadForm,
} from "../../../../redux-reducer/reducer/index.js";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import IdleTimer from "react-idle-timer";
import { Snackbar } from "@material-ui/core";
import { Alert, Button } from "@mui/material";
import { minusMinuteFromDate } from "../../../../utils/utils.js";
import { checkDateIsValid } from "../../../../utils/validators.js";
import { format } from "date-fns";
import ModalPopup from "f1-modal-react";

let LeadTitle = "Create Lead";
const createformat = "YYYY-MM-DD HH:mm:ss";
const __formatDate = "YYYY-MM-DDTHH:mm";
const _formatDate = "yyyy-MM-dd";
const formatDate = "YYYY-MM-DD";

const CreateLeadMSME = (
  props
) => {
  let dyFormRef = useRef();
  const history = useHistory();
  const params = useParams();
  const id = params.id;
  const select = useSelector((state) => state);
  const userData = JSON.parse(localStorage.getItem("persist:root"));
  const employeeCode = JSON.parse(userData.userReducer).profileInfo
    .employeeCode;
  const channelID = JSON.parse(userData.userReducer).profileInfo.channelid;
  const roleId = JSON.parse(userData.userReducer).profileInfo.roleId;
  const userId = select.userReducer.profileInfo.userId;
  const masterDataState = useSelector((state) => state.commonReducer);

  const formName = MSMELEAD;
  let idleTimer = useRef();
  const [current, setCurrent] = useState({
    id: 1,
    LeadCreationDate: format(new Date(), _formatDate),
    ExpectedLeadClosureDate: format(new Date(), _formatDate),
    LoanSanctionDate: format(new Date(), _formatDate),
    ExpectedDisbustmentDate: format(new Date(), _formatDate),
    LoanDisbursementDate: format(new Date(), _formatDate),
    LoanTerm:'',
    CustomerType:'',
    Email:'',
    MSMESource:'Self'
  });
  const [formDataState, setFormDataState] = useState(MSMELeadForm);
  const [labelForId] = useState({
    ActivityIDLabel: "",
    LGIDLabel: "",
  });
  const [extErrors, setExtErrors] = useState({});
  const [branchIDLabel] = useState("");
  const [isActiveMobileDraft, setIsActiveMobileDraft] = useState(false);
  const [isFormSUbmitDisabled] = useState(false);
  const [timeOutMsg, setTimeOutMsg] = useState(false);
  const [leadDetails, setLeadDetails] = useState({});
  const [AssignedTo, setAssignedTo] = useState("");
  const [AssignedToName, setAssignedToName] = useState("");
  const [showModalSetup, SetShowModalSetup] = useState(false);

  const loadBreadCrumbs = () => {
    const breadcrumbs = [
      { name: "Leads", url: "/app/lead/all" },
      { name: LeadTitle, url: "/app/lead/create" },
    ];
    props.setBreadcrumbs(breadcrumbs);
  };

  const getDropdownData = (optionsArr, key, label, value) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr?.map((option) => {
        return { key: option[key], label: option[label], value: option[value] };
      });
    }
    arr.unshift({ key: "select", label: "select", value: "" });
    return arr;
  };

  const initFormData = (formFields) => {
    const formData = [...formFields];
    const { Salutation, userBranchData, MSMELoanType } = masterDataState;
    const copyActivities = props.leadFormData.Activities.ActivityID;
    const activities = copyActivities.filter((item)=>item.id !="" && item.activitytypeid != 61)
    formData[0].options = Salutation;
    formData[7].options = getDropdownData(
      activities,
      "id",
      "label",
      "id",
    );
    formData[9].options = MSMELoanType;
    formData[8].options = getDropdownData(
      userBranchData,
      "BranchID",
      "BranchCode",
      "BranchID",
    );
    return formData;
  };

  const onChangeHandler = async(event, key, model) => {
    if (id) {
      const details = { ...leadDetails, [key]: event?.target?.value };
      setLeadDetails(details);
    }
    if (
      key === "ExpectedDisbustmentDate" ||
      key === "LeadCreationDate" ||
      key === "ExpectedLeadClosureDate" ||
      key === "LoanSanctionDate" ||
      key === "ExpectedDisbustmentDate" ||
      key === "LoanDisbursementDate" ||
      key === "LoanTerm" ||
      key === "CustomerType" ||
      key === "MobileNo" ||
      key === "Email" ||
      key === "MSMESource"
    ) {
      const details = { ...current, [key]: event?.target?.value };
      setCurrent(details);
    }
    const formData = [...formDataState];
    if (key === "CustomerType") {
      if ("new" === event.target.value) {
        formData[17].hide = false;
        formData[18].hide = false;
        formData[19].hide = false;
        formData[20].hide = false;
        formData[21].hide = false;
      } else if ("existing" === event.target.value) {
        formData[17].hide = true;
        formData[18].hide = true;
        formData[19].hide = false;
        formData[20].hide = true;
        formData[21].hide = true;
      } else {
        formData[17].hide = true;
        formData[18].hide = true;
        formData[19].hide = true;
        formData[20].hide = true;
        formData[21].hide = true;
      }
    }
    if (key === "MeetingStartDateTime") {
      let StartDate = moment(event.target.value).format(__formatDate);
      let newDate = moment(event.target.value)
        .add(30, "m")
        .format(__formatDate);

      formDataState.map((option) => {
        if (option.key === "MeetingEndDateTime") {
          option.max = moment(StartDate)
            .endOf("day")
            .format("YYYY-MM-DD kk:mm");
          option.min = StartDate;
        }
      });

      if (
        !checkDateIsValid(
          event.target.value,
          moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        )
      ) {
        setExtErrors({ ...extErrors, [key]: "please select future value" });
      } else {
        delete extErrors?.MeetingStartDateTime;
        setExtErrors({ ...extErrors });
      }
      if (id) {
        setLeadDetails({
          ...leadDetails,
          MeetingStartDateTime: StartDate,
          MeetingEndDateTime: newDate,
        });
      } else {
        setCurrent({
          ...current,
          MeetingStartDateTime: StartDate,
          MeetingEndDateTime: newDate,
        });
      }
    }
    if (key === "MeetingEndDateTime") {
      const newDate = moment(event.target.value).format(__formatDate);
      if (!checkDateIsValid(event.target.value, current.MeetingStartDateTime)) {
        setExtErrors({
          ...extErrors,
          MeetingEndDateTime:
            "meeting end date should not less than meeting starting date",
        });
      } else {
        delete extErrors.MeetingEndDateTime;
        setExtErrors({ ...extErrors });
      }
      if (id) {
        setLeadDetails({
          ...leadDetails,
          MeetingEndDateTime: newDate,
        });
      } else {
        setCurrent({
          ...current,
          MeetingEndDateTime: newDate,
        });
      }
    }
    if (key === 'MobileNo'){
      if (event.target.value.length === 10) {
          const mobileNo = event.target.value;
          if (navigator.onLine) {
              const mobileStatus = await props.checkMobileNumberReducer({ mobileNumber: event.target.value, userId: userId, formData: formData })
              if (!mobileStatus) {
                  SetShowModalSetup(true)
                  delete model.errors['MobileNo']
              }
              else {
                setExtErrors({})
              }
          } else {
              isLeadValid(mobileNo).then((res) => {
                  if (!res) {
                    SetShowModalSetup(true)
                    delete model.errors['MobileNo']
                  }
                  else {
                    setExtErrors({})
                  }
              })
          }
      }
    }
    setFormDataState(formData)
  };

  const onSubmitHandler = async (model) => {
    if (model.MobileNo === ''){
    setExtErrors({MobileNo: "Mobile number is required"})
    } else {
      delete extErrors['MobileNo']
    }
    if (model.CustomerType === "existing" || model.CustomerType === "" ) {
      delete model.errors["ExpectedDisbustmentDate"];
      delete model.errors["IsPremiumFunding"];
      delete model.errors["LoanTerm"];
      delete model.errors["ActualPremiumAmount"];
      delete model.errors["LoanSanctionDate"];
      delete model["ExpectedDisbustmentDate"];
      delete model["MSMELoanTypeID"];
    }
    if (model.LoanTerm === ''){
      delete model.errors["LoanTerm"];
    }
    if (model.Email === ''){
      delete model.errors["Email"];
    }

    if (
      !model.isValidForm ||
      Object.keys(extErrors).length > 0 ||
      Object.keys(model.errors).length > 0
    ) {
      const errorListClasess = Object.keys(model.errors);
      const errArr = [];
      const formD = formDataState;
      formD.map((el, idx) => {
        if (el.key == errorListClasess[0]) {
          errArr.push(idx + 1);
        }
      });
      const errorElement = document.getElementsByClassName(
        `${"control"}${errArr[0]}`,
      )[0];
      if (errorElement) {
        errorElement.scrollIntoView({
          block: "center",
        });
      }
      if (Object.keys(model.errors).length == 1) {
        props.activateErrorMessageBar("Please fill all required fields");
        return false;
      }
      if (
        (!model.isValidForm && Object.keys(model.errors).length > 0) ||
        Object.keys(model.errors).length > 0 ||
        Object.keys(extErrors).length > 0
      ) {
        props.activateErrorMessageBar("Please fill all required fields");
        return false;
      }
    }
    const data = { ...model };
    delete data["advanceLayoutFields"];
    delete data["collapseStates"];
    delete data["errors"];
    delete data["isValidForm"];
    delete data["id"];
    delete data["ActivityIDlabel"];
    delete data["BranchName"];
    if (data.hasOwnProperty("MeetingStartDateTime")) {
      data["MeetingStartDateTime"] = data["MeetingStartDateTime"].replace(
        "T",
        " ",
      );
    }
    if (data.hasOwnProperty("MeetingEndDateTime")) {
      data["MeetingEndDateTime"] = minusMinuteFromDate(
        data["MeetingEndDateTime"],
      ).replace("T", " ");
    }

    data["LeadStatusId"] = 1; //'NEW';
    data["UserId"] = userId;
    data["EmployeeCode"] = employeeCode;
    data["IsPremiumCollected"] =data?.IsPremiumCollected ?  data?.IsPremiumCollected == "Yes" ? 1 : 0 : null
    data["IsPremiumFunding"] = data.IsPremiumFunding ? data.IsPremiumFunding == "Yes" ? 1 : 0 : null
    data["ModifiedBy"] = userId;
    data["AssignedTo"] = userId;
    data["CreatedBy"] = userId;
    data["ChannelID"] = parseInt(channelID);
    data["LoanTerm"] = model.LoanTerm !=="" ? model.LoanTerm : null
    if (id) {
      data["IsBacklog"] = 0;
      data["LeadId"] = id;
    }
    if (navigator.onLine) {
      const { Salutation } = masterDataState;
      if (id) {
        data["AssignedTo"] = AssignedTo;
        data["AssignedToName"] = AssignedToName;
        props.UpdateLeadForm({
          model: data,
          formBuilder: formName,
          history: history,
        });
      } else {
        const leadScoreRequest = {
          productName: "",
          salutationName: `${getNameForId(
            Salutation,
            data.SalutationID,
          )}${"."}`,
          priorityName: data.PriorityName ? data.PriorityName : "",
          lgCode: labelForId.LGIDLabel.split(" ")[0],
          branchCode: branchIDLabel,
          branchCodeActivity: branchIDLabel.split(" ")[0],
          activity: labelForId.ActivityIDLabel,
        };
        props.activateLoader();
        const result = await props.saveLeadForm({
          data: data,
          formName: formName,
          leadScoreRequest: leadScoreRequest,
        });
        handleSaveLeadResponse(result, "Created");
      }
    }
    if (!navigator.onLine) {
      // const mmData = {
      //   ...data,
      //   CreatedDate: moment(new Date()).format(createformat),
      //   // CreatedBy: userId,
      //   ModifiedDate: moment(new Date()).format(createformat),
      //   ModifiedBy: userId,
      //   LmsleadGuid: uuidv4(),
      //   LmsleadlastSyncTime: moment(new Date()).format(createformat), //Date.parse(new Date().toUTCString()),
      //   IsLmsSync: navigator.onLine,
      //   LeadStatus: "New Customer",
      //   Remarks: model.Remarks,
      //   PriorityID: model?.PriorityID,
      //   PriorityName: model.PriorityName ? model.PriorityName : "",
      //   IsBacklog: false,
      // };
      saveLeadOffline(data).then((res) => {
        if (res) {
          const data = res.data;
          const { LeadId, LmsleadGuid } = data?.data;
          if (LeadId) {
            history.push(`/app/lead/detail/${LeadId}`);
          } else {
            history.push(`/app/lead/detail/${LmsleadGuid}`);
          }
        }
      });
    }
  };

  const onSaveDraft = async (e) => {
    const formData = { ...dyFormRef.current.state };
    e.stopPropagation();
    e.preventDefault();
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    delete formData["ActivityIDlabel"];
    if (formData.hasOwnProperty("MeetingStartDateTime")) {
      formData["MeetingStartDateTime"] = formData[
        "MeetingStartDateTime"
      ].replace("T", " ");
    }
    if (formData.hasOwnProperty("MeetingEndDateTime")) {
      formData["MeetingEndDateTime"] = minusMinuteFromDate(
        formData["MeetingEndDateTime"],
      ).replace("T", " ");
    }
    if (id) {
      formData["LeadId"] = id;
    }
    formData["LeadStatusId"] = 11;
    formData["ChannelID"] = parseInt(channelID);
    formData["UserId"] = userId;
    formData["AssignedTo"] = userId;
    formData["EmployeeCode"] = employeeCode;
    if (formData["SalutationID"] === undefined) {
      formData["SalutationID"] = null;
    }
    formData["IsPremiumCollected"] =
      formData?.IsPremiumCollected == "Yes" ? 1 : 0;
    formData["IsPremiumFunding"] =  formData.IsPremiumFunding == "Yes" ? 1 : 0

    const { Salutation } = masterDataState;
    const mmData = {
      ...formData,
      CreatedDate: moment(new Date()).format(createformat),
      CreatedBy: userId,
      ModifiedDate: moment(new Date()).format(createformat),
      ModifiedBy: userId,
      LmsleadGuid: uuidv4(),
      LmsleadlastSyncTime: moment(new Date()).format(createformat), // Date.parse(new Date().toUTCString()),
      IsLmsSync: navigator.onLine,
      ActivityID: formData.ActivityID,
      LeadStatus: "Drafted",
      SalutationName: getNameForId(Salutation, formData.SalutationID),
      IsBacklog: false,
    };
    if (navigator.onLine) {
      const leadScoreRequest = {
        productName: mmData.ProductName,
        salutationName: `${mmData.SalutationName}${"."}`,
        priorityName: mmData.PriorityName,
        lgCode: labelForId.LGIDLabel.split(" ")[0],
        branchCode: labelForId.BranchIDLabel,
        branchCodeActivity: labelForId.BranchIDLabel,
        activity: labelForId.ActivityIDLabel,
      };
      if (id) {
        props.UpdateLeadForm({
          model: mmData,
          // formBuilder: formName,
          history: history,
          Drafted: "Draft",
        });
      } else {
        const result = await props.saveLeadForm({
          data: mmData,
          formName: formName,
          leadScoreRequest: leadScoreRequest,
        });
        handleSaveLeadResponse(result, "Drafted");
      }
    } else {
      saveLeadOffline(mmData).then((res) => {
        if (res) {
          history.push(`/app/lead/all`);
        }
      });
    }
  };

  const onCancelHandler = (e) => {
    const isMobile =
      Math.min(window.screen.width, window.screen.height) < 480 ||
      navigator.userAgent.indexOf("Mobi") > -1;
    if (isMobile) {
      setIsActiveMobileDraft(true);
    } else {
      history.push(LEADS_PATH);
    }
  };
  const toggleDrawer = (flag) => {
    setIsActiveMobileDraft(flag);
  };

  const onDontSaveDraftHandler = () => {
    history.push(LEADS_PATH);
  };

  const onSaveDraftHandler = () => {
    onSaveDraft(new Event("test"));
  };

  const getNameForId = (optionArr, inputId) => {
    const matchEl = optionArr.filter((option) =>
      option.key === inputId || option.value === inputId ? option.label : "",
    );
    if (matchEl && matchEl.length > 0) {
      return matchEl[0].label;
    }
    return null;
  };

  const handleSaveLeadResponse = (result, mode) => {
    if (result.status === 200 && result.data.Responsestatus === true) {
      activateSuccessMessageBar(`Lead has been ${mode} successfully`);
      props.deActivateLoader();
      if (mode === "Created") {
        history.push(`/app/lead/detail/${result.data.Transactionid}`);
      } else if (mode === "Drafted") {
        history.push("/app/lead/all");
      }
    } else {
      props.deActivateLoader();
      activateSuccessMessageBar(
        "Something went wrong! please try again later.",
      );
    }
  };

  const handleOnAction = (event) => {
    if (timeOutMsg) {
      setTimeout(() => {
        history.push("/app/home");
      }, 3000);
    }
  };
  const handleOnActive = (event) => {
    if (idleTimer.current.getRemainingTime() === 0) {
      setTimeOutMsg(idleTimer.current.getRemainingTime() === 0);
    }
  };
  const handleOnIdle = (event) => {};

  const getLeadDetail = async () => {
    const leadDetail = await props.getLeadDetails({ id: id });
    props.activateLoader();
    const {
      SalutationID,
      Salutation,
      FirstName,
      LastName,
      MiddleName,
      MobileNo,
      Email,
      BranchID,
      BranchName,
      GCLLeadSource,
      CreatedDate,
      CreatedBy,
      BranchCode,
      AssignedTo,
      AssignedToName,
      LoanACReferenceNo,
      LoanTypeID,
      ActualPremiumAmount,
      ExpectedLeadClosureDate,
      ExpectedDisbustmentDate,
      MSMELoanTypeID,
      IsPremiumFunding,
      IsPremiumCollected,
      MSMESource,
      CustomerType,
      LeadCreationDate,
      LoanACNo,
      LoanTerm,
      LoanAmount,
      LoanDisbursementDate,
      ActivityID,
      LoanSanctionDate,
      CreatedByMsme
    } = leadDetail.data;
    let IsPremiumFundingCopy =IsPremiumFunding ? IsPremiumFunding == true ? "Yes" : "No": null
    let IsPremiumCollectedCopy = IsPremiumCollected ? IsPremiumCollected == true ? "Yes" : "No" : null
    setLeadDetails({
      SalutationID: SalutationID?.toString(),
      Salutation: Salutation,
      FirstName: FirstName,
      MiddleName: MiddleName,
      LastName: LastName,
      MobileNo: MobileNo,
      Email: Email,
      GCLLeadSource: GCLLeadSource,
      LoanAmount: LoanAmount?.toString(),
      LoanTypeID: LoanTypeID?.toString(),
      BranchID: BranchID?.toString(),
      BranchName: BranchName,
      BranchCode: BranchCode,
      LoanACReferenceNo: LoanACReferenceNo,
      CreatedDate: CreatedDate,
      CreatedBy: CreatedBy,
      ExpectedLeadClosureDate: moment(ExpectedLeadClosureDate).format(
        formatDate,
      ),
      ExpectedDisbustmentDate: moment(ExpectedDisbustmentDate).format(
        formatDate,
      ),
      LeadCreationDate: moment(LeadCreationDate).format(formatDate),
      LoanDisbursementDate: moment(LoanDisbursementDate).format(formatDate),
      LoanSanctionDate: moment(LoanSanctionDate).format(formatDate),
      MSMELoanTypeID: MSMELoanTypeID?.toString(),
      MSMESource: MSMESource,
      CustomerType: CustomerType,
      IsPremiumCollected: IsPremiumCollectedCopy,
      IsPremiumFunding: IsPremiumFundingCopy,
      LoanACNo: LoanACNo,
      ActualPremiumAmount: ActualPremiumAmount?.toString(),
      LoanTerm: LoanTerm,
      ActivityID:ActivityID?.toString(),
      CreatedByMsme:CreatedByMsme?.toString()
    });
    const formData = initFormData(formDataState);

    if ("new" === CustomerType) {
      formData[17].hide = false;
      formData[18].hide = false;
      formData[19].hide = false;
      formData[20].hide = false;
      formData[21].hide = false;
    } else if ("existing" === CustomerType) {
      formData[17].hide = true;
      formData[18].hide = true;
      formData[19].hide = false;
      formData[20].hide = true;
      formData[21].hide = true;
    } else {
      formData[17].hide = true;
      formData[18].hide = true;
      formData[19].hide = true;
      formData[20].hide = true;
      formData[21].hide = true;
    }
    setFormDataState(formData);
    setAssignedTo(AssignedTo);
    setAssignedToName(AssignedToName);
    props.deActivateLoader();
  };

  const getBDMDdetails = async () => {
    const formData = [...formDataState];
    const bdm = JSON.parse(userData.userReducer).profileInfo;
    if (roleId == "ABH") {
      const getBDMNames = await props.getSubordinateList({ userId: userId });
      formData[23].options = getDropdownData(
        getBDMNames.data,
        "userID",
        "userName",
        "userID",
      );
    } else {
      formData[23].options = [
        { key: "select", label: "select", value: "" },
        {
          key: bdm.userId,
          label: `${bdm.firstName} ${bdm.lastName}`,
          value: bdm.userId,
        },
      ];
    }
    setFormDataState(formData);
  };

  const onclose = () => {
   SetShowModalSetup(false)
   setCurrent({...current, MobileNo:''})
  }
  const onMobileSubmite = () => {
   SetShowModalSetup(false)
  }

  useEffect(() => {
    if (!id) {
      const formData = initFormData(formDataState);
      setFormDataState(formData);
    }
    loadBreadCrumbs();
    if (id) {
      getLeadDetail();
    }
    getBDMDdetails();
  }, []);

  useEffect(() => {
    if (timeOutMsg) {
      setTimeout(() => {
        history.push("/app/home");
      }, 3000);
    }
  }, [timeOutMsg]);

  return (
    <div>
      <IdleTimer
        ref={(ref) => {
          idleTimer.current = ref;
        }}
        timeout={1000 * 60 * 1}
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        onAction={handleOnAction}
        debounce={250}
      />
      <Snackbar open={timeOutMsg} autoHideDuration={3000}>
        <Alert severity="error">
          Your session has been expired! You will be redirected to homepage in 3
          seconds.
        </Alert>
      </Snackbar>
      <div className="mainContainer createLead">
        <MessageBar />
        <Breadcrumb />
        <span className="formInfo">
          {t("Please fill in the lead information for MSME")}
        </span>
        <div className="formBox">
          <DynamicForm
            key={current.id}
            defaultValues={id ? leadDetails : current}
            onRef={(ref) => {
              dyFormRef.current = ref;
            }}
            title={LeadTitle}
            model={formDataState}
            className="dynamicForm"
            buttonSubmit={t("Submit")}
            onSubmit={(model) => onSubmitHandler(model)}
            disableSubmitOnInValid={isFormSUbmitDisabled}
            materialOutline={false}
            onChange={(event, key, model) => onChangeHandler(event, key, model)}
            customValidators={customValidators}
            showAstersikOnRequiredFields={true}
            extErrors={extErrors}
            checkInConfig={checkInConfig}
            extaraActionFormButton={
              <>
                <a className="savedraftLink" onClick={(e) => onSaveDraft(e)}>
                  SAVE AS DRAFT
                </a>
                <button
                  color="indigo"
                  className="btn cancelBtn"
                  key="extra"
                  id="extra"
                  type="button"
                  onClick={(e) => onCancelHandler(e)}>
                  Cancel
                </button>
              </>
            }
          />
        </div>
        <Footer />
        {masterDataState.isActiveLoader && <Loader />}
        <SaveAsDraftDrawer
          isActiveMobileDraft={isActiveMobileDraft}
          toggleDrawer={toggleDrawer}
          onDontSaveHandler={onDontSaveDraftHandler}
          onSaveDraftHandler={onSaveDraftHandler}></SaveAsDraftDrawer>
      </div>
      <ModalPopup
                    show={showModalSetup}
                    onHide={() => onclose()}
                    size={"sm"}
                    centered={true}
                    modelHeader={'Alert'}
                    modelContent={
                        <>
                            <p>Lead with this mobile number already exists, do you wish to continue?</p>
                            <div style={{display:"flex",justifyContent:"space-around",alignItems:"center"}}>
                            <Button onClick={()=> onMobileSubmite()} >OK</Button>
                            <Button onClick={()=> onclose()} >Cancel</Button>
                            </div>
                        </>
                    }
                    backdrop={"static"}
                />
    </div>
  );
};

const mapStateToProps = (state) => ({
  leadFormData: state.leadFormReducer,
  masterDataState: state.commonReducer,
});
const mapDispatchToProps = (dispatch) => ({
  setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  getAcitvityDuration: (payload) => dispatch(getAcitvityDuration(payload)),
  saveLeadForm: (payload) => dispatch(saveLeadForm(payload)),
  activateSuccessMessageBar: (payload) =>
    dispatch(activateSuccessMessageBar(payload)),
  activateErrorMessageBar: (payload) =>
    dispatch(activateErrorMessageBar(payload)),
  getLeadDetails: (payload) => dispatch(getLeadDetails(payload)),
  UpdateLeadForm: (payload) => dispatch(UpdateLeadForm(payload)),
  getSubordinateList: (payload) => dispatch(getSubordinateList(payload)),
  checkMobileNumberReducer: (payload) => dispatch(checkMobileNumberReducer(payload)),
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps)(CreateLeadMSME),
)
