

/*global google*/
import { Card } from "@material-ui/core";
import { CardContent, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect,useState } from "react";
import {
    withGoogleMap,
    withScriptjs,
    GoogleMap,
    DirectionsRenderer,
    Marker
} from "react-google-maps";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

const Map =(props)=>{
    let bdmTrackData = props.masterDataState.bdmTrackData
    const [directions,setDirections]=useState(null)
    const [copyBdmTrackData,setCopyBdmTrackData]=useState({})
    const [distance,setDistance]=useState('')
    const [duration,setDuration]=useState('')
    const [originLocation,setOriginLocation]=useState('')
   const  getBdmLocation =async()=>{
        const directionsService = new google.maps.DirectionsService();
         directionsService.route(
            {
                origin: { lat:Number(bdmTrackData.FromLatitude), lng: Number( bdmTrackData.FromLongitude)},
                destination: bdmTrackData.ToLocationName,
                travelMode: google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    console.log("result",result)
                   setDirections(result)
                   setOriginLocation(result.routes[0].legs[0].start_address)
                   setDistance(result.routes[0].legs[0].distance.text)
                   setDuration(result.routes[0].legs[0].duration.text)
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            }
        );
    }

    useEffect(()=>{
        setCopyBdmTrackData(bdmTrackData)
    },[])

    useEffect(()=>{
        getBdmLocation()
    },[bdmTrackData])


    const GoogleMapExample = withGoogleMap(props => (
        <GoogleMap
            defaultCenter={{ lat: 6.5244, lng:  3.3792 }}
            defaultZoom={13}
            onLoad={map => this.setMap(map)}
        >   
         <Marker
         onLoad={map => this.setMap(map)}
         position={bdmTrackData.CurrentLatitude !==null && bdmTrackData.CurrentLongitude !==null ?{ lat: Number(bdmTrackData.CurrentLatitude), lng: Number( bdmTrackData.CurrentLongitude)} :{}}
    />
            <DirectionsRenderer
                directions={directions}
            />
        </GoogleMap>
    ));

    return (
        <div>
        <div className='row'>
            <div className='col-md-10'>
            <GoogleMapExample
                containerElement={<div className="map-container"/>}
                mapElement={<div style={{ height: `100%` }} />}
            />
        </div>
                        <div className='col-md-2'>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Origin Location</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled value={originLocation}  />
                                            <label for="exampleInputEmail1">Destination Location</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled value={bdmTrackData?.ToLocationName}/>
                                            <div style={{marginTop:"10px"}}>
                                            <label ><b>Distance : </b></label>
                                            <label> <b>{distance} </b></label>
                                            </div>
                                            <div>
                                            <label ><b>Durration : </b></label>
                                            <label> <b>{duration}</b></label>
                                            </div>
                                        </div>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </div>
                   </div>
                   </div>


       );
    }

const mapStateToProps = state => ({
    leadFormData: state.leadFormReducer,
    masterDataState: state.commonReducer
});
const mapDispatchToProps = dispatch => ({
})

export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(Map));