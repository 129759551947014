
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import moment from 'moment';
import TravelHistory from '../common/travelHistory';
import { useState } from 'react';
import { getUploadedImage } from '../../../redux-reducer/reducer';

const IntrestedStatusHistory = (props) => {
    const { history, styletimeline } = props
    const [fileData, setfileData] = useState(null)
    const [loader, setLoader] = useState(true)
    const remarks = history.Remarks ? history.Remarks : 'N/A';
    const getBase64Data = async() => {
        if (history.UploadBillFileName) {
          setLoader(false)
          const res = await getUploadedImage(history.UploadBillFileName);
          if (res.status === 200 && res.data) {
            setfileData(`${"data:image/png;base64,"}${res.data}`);
            var image = new Image();
            image.src = "data:image/jpg;base64," + res.data;
            var w = window.open("");
            w.document.write(image.outerHTML);
          } 
          setLoader(true)
        }
      };
    return <>
        {history.LeadStatus === "Interested" ?
            <> <TimelineItem sx={styletimeline}>
                <TimelineSeparator>
                    <span className="separator">-</span>
                </TimelineSeparator>
                <TimelineContent>
                    <label>Remarks  </label>
                    <label>{remarks ? remarks : "N/A"}</label>
                </TimelineContent>
            </TimelineItem>
                {history.IsClaimRequired &&
                    <TravelHistory history={history} styletimeline={styletimeline} />
                }
                {history.UploadBill &&
                    <TimelineItem sx={styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                        {loader == true ? <label className="link-label" onClick={() => getBase64Data()}>View uploaded Bill</label> : <>Loading</>}
                                {
                                fileData &&
                                <img className='bill-copy' alt="uploaded bill" src={fileData}/>
                                }
                        </TimelineContent>
                    </TimelineItem>
                }
            </>
            : ''}
    </>
}

export default IntrestedStatusHistory
