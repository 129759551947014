import React, {useState, useEffect} from 'react';
import { withScriptjs } from "react-google-maps";
import Map from './ReactGoogleMap';
function GoogleMap() {

    const MapLoader = withScriptjs(Map);

    return(
        <div>
                    <MapLoader
                       googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCQSLRamh_KA73-4hD98GoUTf3sPhU5rpA"
                       loadingElement={<div style={{ height: `100%` }} />}
                   />
                 </div>
    )
}

export default GoogleMap;