import React, { useEffect, useState } from 'react';
import BoltIcon from '@mui/icons-material/Bolt';
import EditIcon from '@mui/icons-material/Edit';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import "./notification.scss";
import { readsinglenotificationsbyuser } from '../../redux/actions/notificationAction'
import moment from 'moment';
import CallIcon from '@mui/icons-material/Call';
//import { getDateDuration } from '../../utils/utils';

const Notification = ({ history, notificationTypes, notificationData, toggleNotifi, redirectToDetails, onClose }) => {
    // const dispatch = useDispatch()
    const [notificationDetails, setNotificationDetails] = useState([])


    const timeConvert = (inputDateTime) => {
        var now = moment();
        var displayValue = '';
        var dateString = inputDateTime;
        var targetDate = moment(dateString);
        var difference = now.diff(targetDate, 'days');
        if (difference > 6000 || difference < -3) {
            displayValue = targetDate.format('MM-DD-YYYY') + ' at ' + targetDate.format('hh:mm');
        } else {
            // otherwise...
            displayValue = targetDate.fromNow();
        }
        return displayValue;
    }

    useEffect(() => {
        let details = []
        notificationData.forEach((data) => {
            details.push({
                ...data, isShowMore: data.NotificationMessage.length > 67 ? false : true
            })
        })
        setNotificationDetails(details)
    }, [])
    useEffect(() => {
        let details = []
        notificationData.forEach((data) => {
            details.push({
                ...data, isShowMore: data.NotificationMessage.length > 67 ? false : true
            })
        })
        setNotificationDetails(details)
    }, [notificationData])
    const handleSeeMore = (e, id) => {
        e.stopPropagation()
        let getData = notificationDetails[id]
        getData.isShowMore = !getData.isShowMore
        const data = [...notificationDetails]
        setNotificationDetails(data)
    }

    const handleAudioCall = (e, MobileNo) => {
        e.stopPropagation()
        window.open(`tel:${MobileNo}`)
    }

    return (
        <div>
            <div className="notificationPopupDiv">
                <div onClick={() => onClose('right', true)}><CloseIcon className='closeIcon' /></div>
                <h3 className='title'>Notifications <span>Showing {notificationDetails.length} Results</span></h3>
                <div className="notifInner">
                    {notificationDetails.map((el, index) => {
                        return <div key={index} onClick={() => { redirectToDetails(el) }} style={{ cursor: 'pointer' }}>
                            <div className={el.Isnotificationreadbyuser ? "readTxt listItem" : "unreadTxt listItem"}>
                                <ArrowRightAltIcon className='arrowIcon' />
                                {notificationTypes.map(notif => {
                                    if (notif.CategoryID === el.CategoryID) {
                                        return <h6 key={"de" + notif.CategoryID} style=
                                            {{ color: el.Isnotificationreadbyuser ? "rgb(74 67 67)" : "#00427E" }}>
                                            <BoltIcon />
                                            {notif.CategoryName}
                                        </h6>
                                    } else {
                                        return ""
                                    }
                                })}

                                <div className="statusText">{el.isShowMore ? el.NotificationMessage : el.NotificationMessage.slice(0, 67)}
                                    {!el.isShowMore && <span onClick={(e) => handleSeeMore(e, index)}>...see more</span>}
                                </div>
                                <div className="lastDate">
                                    <span>{timeConvert(el.Notificationshownafter)}</span>
                                    {/* {el.MeetingStartDateTime && el.MeetingEndDateTime && <><b>Today</b>, {
                                        getDateDuration(el.MeetingStartDateTime, el.MeetingEndDateTime, 'HOUR') + ' ' +
                                        getDateDuration(el.MeetingStartDateTime, el.MeetingEndDateTime, 'MINUTE')}
                                    </>
                                    } */}

                                </div>
                                <Grid container justifyContent="center" alignItems="center" className="updateEdit" spacing={3}>

                                    {el.Mobileno !== null ? (
                                        <Grid item xs={6}>

                                            <span onClick={(e) => handleAudioCall(e, el.Mobileno)} style={{ cursor: 'pointer' }}>

                                                <CallIcon />

                                                Call

                                            </span>
                                        </Grid>

                                    ) : ""}

                                    <Grid item xs={6}>
                                        <span onClick={() => { redirectToDetails(el) }} style={{ cursor: 'pointer' }}>

                                            <EditIcon /> Details

                                        </span>
                                    </Grid>

                                </Grid>
                            </div>

                        </div>
                    })}
                    <button className='seeMoreBtn' onClick={() => {
                        onClose('right', true)
                        history.push('/app/allNotifications')
                    }}>see more Notifications</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    masterDataState: state.commonReducer
});

const mapDispatchToProps = dispatch => ({
    readsinglenotificationsbyuser: (payload) => dispatch(readsinglenotificationsbyuser(payload)),

});

export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(Notification));
