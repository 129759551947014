import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import moment from 'moment';
import { useState } from 'react';
import { getUploadedImage } from '../../../redux-reducer/reducer';
import TravelHistory from '../common/travelHistory';


const CallBackStatusHistory = (props) => {
    const { history, styletimeline } = props
    const [fileData, setfileData] = useState(null)
    const [loader, setLoader] = useState(true)
    const historyDate = history.MeetingStartDateTime ? moment(history.MeetingStartDateTime).format('DD MMM YYYY') : ''
    const historyTime = history.MeetingStartDateTime ? moment(history.MeetingStartDateTime).format('h:mma') : "";
    const getBase64Data = async() => {
        if (history.UploadBillFileName) {
          setLoader(false)
          const res = await getUploadedImage(history.UploadBillFileName);
          if (res.status === 200 && res.data) {
            setfileData(`${"data:image/png;base64,"}${res.data}`);
            var image = new Image();
            image.src = "data:image/jpg;base64," + res.data;
            var w = window.open("");
            w.document.write(image.outerHTML);
        }
        setLoader(true)
        }
      };
    return (
      <>
        {history.LeadStatus === "Call Back" ? (
          <>
            <TimelineItem sx={props.styletimeline}>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <label>Call Back Time</label>
                <label>
                  {historyDate} {historyTime}
                </label>
              </TimelineContent>
            </TimelineItem>
            {(history.ChannelID === 13 || history.ChannelID === 79) ? (
              <TimelineItem sx={props.styletimeline}>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>GCL Reasons for followup </label>
                  <label>{history.GCLReasonsforfollowup}</label>
                </TimelineContent>
              </TimelineItem>
            ) : (
              <TimelineItem sx={props.styletimeline}>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Reason </label>
                  <label>{history.ReasonName}</label>
                </TimelineContent>
              </TimelineItem>
            )}
            <TimelineItem sx={props.styletimeline}>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <label>Remark</label>
                <label>
                  <p>{history.Remarks ? history.Remarks : "N/A"}</p>
                </label>
              </TimelineContent>
            </TimelineItem>
            {(history.ChannelID === 13 || history.ChannelID === 79) && history.IsClaimRequired &&
                    <TravelHistory history={history} styletimeline={styletimeline} />
                }
                {(history.ChannelID === 13 || history.ChannelID === 79) && history.UploadBill &&
                    <TimelineItem sx={styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                        {loader == true ? <label className="link-label" onClick={() => getBase64Data()}>View uploaded Bill</label> : <>Loading</>}
                                {
                                fileData &&
                                <img className='bill-copy' alt="uploaded bill" src={fileData}/>
                                }
                        </TimelineContent>
                    </TimelineItem>
                }
          </>
        ) : (
          ""
        )}
      </>
    );
}

export default CallBackStatusHistory
