import React from "react";
import { useState, useEffect, useRef } from "react";
import DynamicForm from "f1-dynamic-form-react";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import {
  SalesActivityFormBuilder,
  salesManagementRadio,
  OtherReason,
  ActivityCompletedFormPart2,
  ActivityRescheduledFormPart3,
} from "./updatesalesManagementActivityForm";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import "../activity/createActivity.scss";
import {
  FOUR_WHEELER,
  PUBLIC_TRANSPORT,
  SELF_OWNED_TRANSPORT,
  TWO_WHEELER,
  ABH_SALES_ACTIVITY_TYPES_ID,
  ACTIVITY_CANCELLED,
  ACTIVITY_COMPLETED,
  ACTIVITY_RESCHEDULED,
  CONFIGS,
  ACTIVITYSTATUSID,
  ROLE_BDM,
} from "../../../utils/config";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import {
  setBreadcrumbs,
  activateErrorMessageBar,
  activateLoader,
  deActivateLoader,
} from "../../../redux/actions/common";
import { PolicyApplicationNumber } from "./updateSalesManagementActivityData/ActivityCompleted";
import { minusMinuteFromDate } from "../../../utils/utils";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { validateActivity } from "../../../redux/actions/createActivityActions";

import {
  updateDynamicData,
  getActivityById,
  uploadFileToBlob,
  activateSuccessMessageBar,
  getReasons,
  getAcitvityDuration,
} from "../../../redux/actions";

import {
  validateIsCheckin,
} from "../../../redux-reducer/reducer";
import { isPositiveNumber } from "../../../utils/validators";

const UpdatesalesManagementDetail = (props) => {
  const [currentData, setCurrentData] = useState([]);
  const [transpotData, setTranspotData] = useState({});
  const select = useSelector((state) => state);
  const updateActivity = select.updateSalesManagementActivityReducer
  const dispatch = useDispatch();
  const activityType = select.commonReducer.Activities;
  const UserId = select.userReducer.profileInfo.userId;
  const roleId = select.userReducer.profileInfo.roleId;

  const activityTypeList = activityType.filter((item) =>
    ABH_SALES_ACTIVITY_TYPES_ID.includes(Number(item.value))
  );
  const customValidators = [
    {
      key: "TravelExpence",
      validator: isPositiveNumber, // function to be called
      message: "Expense amount should be greater than 0", // message to show
    },
  ];

  const _formatDate = "YYYY-MM-DDTHH:mm";
  const _format = "YYYY-MM-DD HH:mm:ss";
  const DateFormatString = "YYYY-MM-DDTHH:mm";
  const checkInmsg = "Please Check In";

  const history = useHistory();

  const TrnActivityID = props.match.params.id;
  let SmaReason = []
  let activityName = select.updateSalesManagementActivityReducer.activityName;
  const activityData =
    select.updateSalesManagementActivityReducer.activityData.ActivityID;

  const [formBuilder, setFormBuilder] = useState([])

  const selectOption = [{ key: "Select", label: "Select", value: "" }];
  const [current, setCurrent] = useState({
    MeetingStartDateTime: moment(new Date()).format(_formatDate),
    MeetingEndDateTime: moment(new Date()).add(30, "m").format(_formatDate),
    CBIsClaimRequired: false,
    CROIsClaimRequired: false,
    CJIsClaimRequired: false,
  });
  const [extErrors] = useState({});
  const [uploadedFile, setuploadedFile] = useState({
    uploadedFile: "",
    UploadPdfFile: "",
  });
  const [OldData, setOldData] = useState();
  const [responseMessage, setresponseMessage] = useState("");
  const [activitynm, setactivitynm] = useState("");
  let dyFormRef = useRef();

  useEffect(() => {
    if (navigator.onLine) {
      fetchData();
    }
  }, []);

  const getSubTitles = () => {
    let str = "";
    if (updateActivity.activityData.ActivityName != null) {
      str += updateActivity.activityData.ActivityName;
    }

    if (updateActivity.activityData?.BranchId != null) {
      str += `-${updateActivity.activityData?.BranchId}`;
    }

    if (updateActivity.activityData?.BranchName != null) {
      str += `-${updateActivity.activityData?.BranchName}`;
    }

    if (
      updateActivity.activityData?.MeetingEndDateTime != null
    ) {
      const MeetingDate = moment(
        updateActivity.activityData
      ).format("DD MMM YYYY");
      str += `(${MeetingDate})`;
    }

    if (updateActivity.activityData?.BranchId != null) {
      str += `-${updateActivity.activityData?.BranchId}`;
    }
    return str;
  };


  const fetchData = async () => {
    props.activateLoader();
    const getCurrentActivity = await dispatch(getActivityById(TrnActivityID));
    if (getCurrentActivity.status === 200) {
      setOldData(getCurrentActivity.data);
    }
    props.deActivateLoader();
  };

  useEffect(() => {
    const fetch = async ()=>{
      const data = await props.getReasons();
      if (data.status === 200) {
        SmaReason = data.data
      }
      const formBuilderdata = formBuilderData();
      setFormBuilder(formBuilderdata)
      setCurrentData(formBuilderdata.getForm());
      setTranspotData({ data: transpotkey, status: false });
      LoadBreadCrumbs();
    }
    fetch();

  }, []);

  const getOptionActivityType = (optionList) => {
    const options = optionList.map((item) => {
      return {
        key: item["key"],
        label: item["label"],
        value: item["key"],
      };
    });
    return [...selectOption, ...options];
  };

  const getOptionActivityDuration = (optionList) => {
    const options = optionList.map((item) => {
      return {
        key: item["key"],
        label: `${item["label"]} mins`,
        value: item["value"],
      };
    });
    return [...selectOption, ...options];
  };

  const getOptionSmaReason = (optionList) => {
    const options = optionList.map((item) => {
      if (item.IsActive) {
        return {
          key: item["ReasonCode"],
          label: item["ReasonName"],
          value: item["ReasonID"],
        };
      }
    });
    return [...selectOption, ...options];
  };

  const getOptionReason = (optionList) => {
    const options = optionList.map((item) => {
      if (item.IsActive) {
        return {
          key: item["ReasonCode"],
          label: item["ReasonName"],
          value: item["ReasonName"],
        };
      }
    });
    return [...selectOption, ...options];
  };

  const formBuilderData = () =>{ 
        return new SalesActivityFormBuilder(
    select.updateSalesManagementActivityReducer.activityComplete
      ? ACTIVITY_COMPLETED
      : select.updateSalesManagementActivityReducer.activityReshedule
      ? ACTIVITY_RESCHEDULED
      : ACTIVITY_CANCELLED,
    select.updateSalesManagementActivityReducer.activityName,
    getOptionActivityType(activityTypeList),
    activityData,
    getOptionActivityDuration(select.commonReducer.SmaActivityDuration.slice(1,7)),
    getOptionSmaReason(SmaReason),
    getOptionReason(SmaReason)
  );}

  const transpotkey = {
    BRANCH_VISIT_PLAN_BDM: [],
    REGIONAL_OFFICE_MEETING_ABH: [],
    JOINT_CALLS_BDM: [],
    e: [], //
  };

  const Claim = (event, ele) => {
    if (event) {
      salesManagementRadio[0].options = select.commonReducer["ModeofTransport"];
      const data = currentData.concat(salesManagementRadio);
      setTranspotData({
        ...transpotData,
        data: { ...transpotData.data, [ele]: salesManagementRadio },
      });
      setCurrentData(data);
    } else {
      const arr = ["TransportModeID", "TravelExpence", "UploadBill", "VehicleTypeID"];
      const data = currentData.filter((val) => {
        return !arr.includes(val.key);
      });
      setTranspotData({
        ...transpotData,
        data: { ...transpotData.data, [ele]: [] },
      });
      setCurrentData(data);
    }
  };

  const renderRadioIcons = (radioOption) => {
    if (radioOption.key === PUBLIC_TRANSPORT) {
      return <DirectionsBusIcon></DirectionsBusIcon>;
    } else if (radioOption.key === SELF_OWNED_TRANSPORT) {
      return <TwoWheelerIcon></TwoWheelerIcon>;
    } else if (radioOption.key === TWO_WHEELER) {
      return <TwoWheelerIcon></TwoWheelerIcon>;
    } else if (radioOption.key === FOUR_WHEELER) {
      return <DirectionsCarIcon></DirectionsCarIcon>;
    } else {
      return "";
    }
  };

  const LoadBreadCrumbs = () => {
    let breadcrumbs = [];
    switch (activityName) {
      case "ACTIVITY_COMPLETED":
        breadcrumbs = [
          {
            name: "Sales Management",
            url: `/app/activity/smaActivities`,
          },
          {
            name: "Complete activity",
            url: `/app/salesmanagement/update/${TrnActivityID}`,
          },
        ];
        setactivitynm("Complete activity");
        dispatch(setBreadcrumbs(breadcrumbs));
        break;
      case "ACTIVITY_CANCELLED":
        breadcrumbs = [
          {
            name: "Sales Management",
            url: `/app/activity/smaActivities`,
          },
          {
            name: "Cancel activity",
            url: `/app/salesmanagement/update/${TrnActivityID}`,
          },
        ];
        setactivitynm("Cancel activity");
        dispatch(setBreadcrumbs(breadcrumbs));
        break;
      case "ACTIVITY_RESCHEDULED":
        breadcrumbs = [
          {
            name: "Sales Management",
            url: `/app/activity/smaActivities`,
          },
          {
            name: "Rescheduled activity",
            url: `/app/salesmanagement/update/${TrnActivityID}`,
          },
        ];
        setactivitynm("Rescheduled activity");
        dispatch(setBreadcrumbs(breadcrumbs));
        break;
    }
  };

  const onChangeHandler = async (event, key,model) => {
    if (key === "ISClosed") {
      if (event.target.value === "1") {
        const newCloseData = currentData;
        const last = newCloseData.slice(-1);
        newCloseData[newCloseData.length - 1] = PolicyApplicationNumber;
        newCloseData.concat(last);
        setCurrentData(newCloseData.concat(last));
      } else {
        const newData = currentData.filter((val) => {
          return val.key != "PolicyApplicationNumber";
        });
        setCurrentData(newData);
      }
    } else if (key === "Reason") {
      if (event.target.value === "Other Reason") {
        setCurrentData([
          ...currentData.slice(0, 1),
          ...OtherReason,
          ...currentData.slice(1),
        ]);
      } else {
        const newData = currentData.filter((val) => {
          return val.key !== "Other_Reason";
        });

        setCurrentData(newData);
      }
    } else if (key === "CROIsClaimRequired") {
      delete model.errors['TransportModeID']
      delete model.errors['TravelExpence']
      delete model.errors['VehicleTypeID']
      if (event) {
        const checkIn = await props.validateIsCheckin({
          checkinFor: "activity",
          Id: TrnActivityID,
        });
        const {data} = checkIn;
        if (data) {
          Claim(event, "REGIONAL_OFFICE_MEETING_ABH");
          delete model.errors['TransportModeID']
          delete model.errors['TravelExpence']
          delete model.errors['VehicleTypeID']
          setCurrent({ ...current, CROIsClaimRequired: true });
        } else {
          props.activateErrorMessageBar(checkInmsg);
        }
      } else {
        Claim(event, "REGIONAL_OFFICE_MEETING_ABH");
        setCurrent({ ...current, CROIsClaimRequired: false, TransportModeID: false, VehicleTypeID: false });
      }
    } else if (key === "CBIsClaimRequired") {
      delete model.errors['TransportModeID']
      delete model.errors['TravelExpence']
      delete model.errors['VehicleTypeID']
      if (event) {
        const checkIn = await props.validateIsCheckin({
          checkinFor: "activity",
          Id: TrnActivityID,
        });
        const {data} = checkIn;
        if (data) {
        Claim(event, "BRANCH_VISIT_PLAN_BDM");
        delete model.errors['TransportModeID']
        delete model.errors['TravelExpence']
        delete model.errors['VehicleTypeID']
          setCurrent({ ...current, CBIsClaimRequired: true });
        } else {
          props.activateErrorMessageBar(checkInmsg);
        }
      } else {
        Claim(event, "BRANCH_VISIT_PLAN_BDM");
        setCurrent({ ...current, CBIsClaimRequired: false , TransportModeID: false, VehicleTypeID: false});
      }
    } else if (key === "CJIsClaimRequired") {
      delete model.errors['TransportModeID']
      delete model.errors['TravelExpence']
      delete model.errors['VehicleTypeID']
      if (event) {
        const checkIn = await props.validateIsCheckin({
          checkinFor: "activity",
          Id: TrnActivityID,
        });
        const { data } = checkIn;
        if (data) {
          Claim(event, "JOINT_CALLS_BDM");
          delete model.errors['TransportModeID']
          delete model.errors['TravelExpence']
          delete model.errors['VehicleTypeID']
          setCurrent({ ...current, CJIsClaimRequired: true });
        } else {
          props.activateErrorMessageBar(checkInmsg);
        }
      } else {
        Claim(event, "JOINT_CALLS_BDM");
        setCurrent({ ...current, CJIsClaimRequired: false, TransportModeID: false, VehicleTypeID: false});
      }
    } else if (key === "TransportModeID") {
      setCurrent({ ...current,TransportModeID: event.target.value})
      delete model.errors['TravelExpence']
      if (event.target.value === "1") {
        const privateData = currentData.concat(ActivityCompletedFormPart2);
        const privatefields = privateData.filter((val) => {
          return val.key !== "VehicleTypeID";
        });
        setCurrentData(privatefields);
      } else if (event.target.value === "2") {
        delete model.errors['TravelExpence']
        const data = currentData.filter((val) => {
          return val.key !== "TravelExpence";
        });
        const data1 = data.filter((val) => {
          return val.key !== "UploadBill";
        });
        setCurrentData(data1);
        if (roleId !== ROLE_BDM) {
          const selfdata = data1.concat(ActivityRescheduledFormPart3);
          selfdata.map((val) => {
            if (val.key === "VehicleTypeID") {
              val.options = select.commonReducer.VehicleType;
            }
          });
          setCurrentData(selfdata);
        }
      }
    } else if (key === "VehicleTypeID"){
      setCurrent({ ...current, VehicleTypeID: event.target.value})
    }
    if (
      key === "UploadBill" ||
      key === "UploadPdfFile" ||
      key === "UploadTrainingAttendancePhoto1"
    ) {
      uploadFile(event, key);
    }

    if (key === "MeetingStartDateTime") {
      const newDate = moment(event.target.value)
        .add(30, "m")
        .format(DateFormatString);
      var StartDate = moment(event.target.value).format(_formatDate);
      currentData.map((option) => {
        if (option.key === "MeetingEndDateTime") {
          option.max = moment(StartDate)
            .endOf("day")
            .format("YYYY-MM-DD kk:mm");
          option.min = StartDate;
        }
      });
      setCurrent({
        ...current,
        MeetingStartDateTime: event.target.value,
        MeetingEndDateTime: newDate,
      });
      if (key === "MeetingEndDateTime") {
        var newEndDate = moment(event.target.value).format(DateFormatString);
        setCurrent({ ...current, MeetingEndDateTime: newEndDate });
      }
    }
    if (key === "MeetingEndDateTime") {
      var newEndDate2 = moment(event.target.value).format(DateFormatString);
      setCurrent({ ...current, MeetingEndDateTime: newEndDate2 });
    }
  };

  const uploadFile = async (files, key) => {
    if (navigator.onLine) {
      const fileRes = await props.uploadFileToBlob({ file: files[0] });
      const { downloadUrl } = fileRes.data
      if (fileRes.status === 200) {
        if (key === "UploadBill") {
          setuploadedFile({ ...uploadedFile, uploadedFile: downloadUrl, originalFileName: files[0].name });
        } else {
          setuploadedFile({ ...uploadedFile, UploadPdfFile: files[0].name  });
        }
      }
    } else {
      props.activateErrorMessageBar("Cannot upload files in offline mode!");
    }
  };

  const isValidBusinessActivity = async (formData) => {
    if (
      formData.hasOwnProperty("MeetingStartDateTime") &&
      formData.hasOwnProperty("MeetingEndDateTime")
    ) {
      const checkActivity = await props.validateActivity({
        user_id: UserId,
        formData: formData,
      });
      if (checkActivity.data.Responsestatus === false) {
        setresponseMessage(checkActivity.data.Responsemessage);
      }
      return (
        checkActivity.status === 200 &&
        checkActivity.data.Responsestatus === true
      );
    }
    return true;
  };

  const onSubmit = async (model) => {
    if(OldData.ActivityStatusId === 2){
      props.activateErrorMessageBar("Activity already completed.");
      return false;
    } else if(OldData.ActivityStatusId === 4){
      props.activateErrorMessageBar("Activity already cancelled.");
      return false;
    }
    if (
      !model.isValidForm ||
      Object.keys(model.errors).length > 0 ||
      Object.keys(extErrors).length > 0
    ) {
      props.activateErrorMessageBar("Activity is not updated.");
      return false;
    }

    props.activateLoader();

    if (model.MeetingStartDateTime && model.MeetingEndDateTime) {
      model.MeetingStartDateTime = model.MeetingStartDateTime.replace(
        "T",
        " "
      ).replace("Z", "");
      model.MeetingEndDateTime = minusMinuteFromDate(model.MeetingEndDateTime)
        .replace("T", " ")
        .replace("Z", "");
    }
    let formData = OldData;
    formData["Remarks"] = null;
    for (let key in model) {
      formData[key] = model[key];
    }
    if (
      model.CBIsClaimRequired ||
      model.CJIsClaimRequired ||
      model.CROIsClaimRequired
    ) {
      formData["IsClaimRequired"] = true;
      formData["TransportMode"] =
        model.TransportModeID === "1"
          ? "Public Transport"
          : "Self Owned Transport";
    } else {
      formData["IsClaimRequired"] = false;
      formData["TransportMode"] = null;
      formData["TransportModeID"] = null;
      formData["TravelExpence"] = null;
      formData["VehicleTypeID"] = null;
    }

    formData["ModifiedDate"] = moment().format(_format);
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    delete formData["CBIsClaimRequired"];
    delete formData["CJIsClaimRequired"];
    delete formData["CROIsClaimRequired"];
    const activityTypeListData = activityType.filter((item) => {
      if (parseInt(item.value) === parseInt(activityData)) {
        return item;
      }
    });
    formData["ActivityID"] = activityTypeListData[0].value;
    formData["ActivityName"] = activityTypeListData[0].label;
    formData["ActivityDurationID"] = formData["ActivityDurationID"] ? parseInt(formData["ActivityDurationID"]) : null;
    formData["IsCheckin"] = false;
    formData["ModifiedBy"] = UserId;
    formData["CreatedDate"] = moment(formData["CreatedDate"]).format(_format);

    formData.IsBacklog = 0;
    if (formData.hasOwnProperty("UploadBill")) {
      formData["UploadBill"] = uploadedFile.uploadedFile;
      formData["BillFileName"] = uploadedFile.originalFileName;
    }
    if (formData.hasOwnProperty("UploadPdfFile")) {
      formData["UploadPdfFile"] = uploadedFile.UploadPdfFile;
    }
    formData["ActivityStatusId"] = ACTIVITYSTATUSID[activityName];
    formData["ActivityStatus"] = activitynm;
    formData["OtherReason"] = formData["Other_Reason"];
    const validateMeetingStatusForm = [
      "ACTIVITY_COMPLETED",
      "ACTIVITY_CANCELLED",
    ];
    if (navigator.onLine) {
      if (validateMeetingStatusForm.includes(activityName)) {
        const result = await props.updateDynamicData({
          data: formData,
          formName: CONFIGS.UPDATE_ACTIVITY_FORM,
          formBuilder: formBuilder?.actionType,
          userID: UserId,
        });
        if (result.status === 200) {
          props.activateSuccessMessageBar("Activity is updated successfully.");
          props.deActivateLoader();
          setTimeout(() => {
            const str = `/app/salesmanagement/detail/${TrnActivityID}`;
            history.push(str);
          }, 1000);
        } else {
          props.activateErrorMessageBar("Activity is not updated.");
          props.deActivateLoader();
        }
      } else {
        const isValidActivity = await isValidBusinessActivity(formData);
        if (isValidActivity) {
          const result = await props.updateDynamicData({
            data: formData,
            formName: CONFIGS.UPDATE_ACTIVITY_FORM,
            formBuilder: formBuilder.actionType,
            userID: UserId,
          });
          if (result.status === 200) {
            props.activateSuccessMessageBar(
              "Activity is updated successfully."
            );
            props.deActivateLoader();
            setTimeout(() => {
              const str = `/app/salesmanagement/detail/${TrnActivityID}`;
              history.push(str);
            }, 1000);
          } else {
            props.activateErrorMessageBar("Activity is not updated.");
            props.deActivateLoader();
          }
        } else {
          props.activateErrorMessageBar(responseMessage);
          props.deActivateLoader();
        }
      }
    }
  };
  const removeFile = (e, file, index, key) => {
    dyFormRef.removeFile(e, file, index, key);
    setuploadedFile("");
  };

  const renderUploadedFilesItem = (file, index, key) => {
    return (
      <li style={{ borderColor: "#ccc" }} key={index}>
        <span className="file-name">{file.name}</span>
        <span
          className="action"
          onClick={(e) => removeFile(e, file, index, key)}
        >
          <i className="fa fa-trash"></i>
        </span>
      </li>
    );
  };

  const onCancelHandler = () => {
    history.push(`/app/salesmanagement/detail/${TrnActivityID}`);
  };
  return (
    <>
      <div className="mainContainer createActivity updateActivity">
        <Breadcrumb></Breadcrumb>
        {/* <span className="formInfo">
          {"Please fill in the activity information"}
        </span> */}
        <div className="formBox">
          <DynamicForm
            key={1}
            onRef={(ref) => {
              dyFormRef.current = ref;
            }}
            title={getSubTitles()}
            defaultValues={current}
            model={currentData}
            onChange={(event, key,model) => onChangeHandler(event, key,model)}
            className="activityTypeDiv"
            renderRadioIcons={(option) => renderRadioIcons(option)}
            showAstersikOnRequiredFields={true}
            onSubmit={(model) => {
              onSubmit(model);
            }}
            customValidators={customValidators}
            extErrors={extErrors}
            extaraActionFormButton={
              <>
                <button
                  className="btn cancelBtn"
                  key="cancel"
                  id="cancel"
                  type="button"
                  onClick={onCancelHandler}
                >
                  {"Cancel"}
                </button>
              </>
            }
            fileUploaderFields={["UploadBill"]}
            renderUploadedFilesItem={renderUploadedFilesItem}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
});
const mapDispatchToProps = (dispatch) => ({
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  updateDynamicData: (payload) => dispatch(updateDynamicData(payload)),
  activateSuccessMessageBar: (payload) =>
    dispatch(activateSuccessMessageBar(payload)),
  activateErrorMessageBar: (payload) =>
    dispatch(activateErrorMessageBar(payload)),
  validateActivity: (payload) => dispatch(validateActivity(payload)),
  uploadFileToBlob: (payload) => dispatch(uploadFileToBlob(payload)),
  validateIsCheckin: (payload) => dispatch(validateIsCheckin(payload)),
  getReasons: (payload) => dispatch(getReasons(payload)),
  getAcitvityDuration: (payload) => dispatch(getAcitvityDuration(payload)),
});

export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps)(UpdatesalesManagementDetail)
);
