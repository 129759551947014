import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import './calendar.scss';
import clockIcon from '../../../assets/img/time_clock_icon.svg';
import mapPlaceIcon from '../../../assets/img/map_location_place_map_icon.svg';
import Grid from '@material-ui/core/Grid';
import moment from "moment";
import { useHistory } from "react-router-dom";
import {getDateDuration} from '../../../utils/utils';
import useOnlineStatus from '../../../hooks/useOnlineStatus';
import {addMinuteToDate} from '../../../utils/utils'
import { ABH_SALES_ACTIVITY_TYPES_ID, BROCA_SMA_ACTIVITY_TYPES_ID_CALENDER } from "../../../utils/config";
import { useSelector } from "react-redux";


const CommonCalendarCard = ({cardItem, onShowPYDmap}) => {

        const {name,MeetingStartDateTime,MeetingEndDateTime,
status,type,id, activityTypeId} = cardItem;
        const history = useHistory();
        const isOnline = useOnlineStatus();
        const commonState = useSelector(state => state);
        const channelid = commonState.userReducer.profileInfo.channelid
const onCalendarCardClick = ()=>{
    if(type==="Activity"){
        if (ABH_SALES_ACTIVITY_TYPES_ID.includes(activityTypeId) || BROCA_SMA_ACTIVITY_TYPES_ID_CALENDER.includes(activityTypeId)) {
            history.push(`/app/salesmanagement/detail/${id}`)
        } else {
            history.push(`/app/activity/detail/${id}`)
        }
     }else if (type === "Campaign Activity"){
        history.push(`/app/activity/campaigndetails/${id}`)
     } else if(type==="Persistency Lead"){
        history.push(`/app/persistencyLead/detail/${id}`)
    }else if(type === "Campaign Lead"){
        history.push(`/app/campaignLead/detail/${id}`)
    }else if(type === "Recruitment Lead"){
        history.push(`/app/lead/detail/${id}`)
    }else if(type === "Business Lead"){
        history.push(`/app/lead/detail/${id}`)
    }else{
        history.push(`/app/lead/detail/${id}`)
    }
   }

    return (
        <div>
            <div className="cardListHeight">
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={10} sm={10} onClick={onCalendarCardClick} style={{cursor:"pointer"}}>
                                <Typography sx={{ fontSize: 12 }} color="#00427E" style={{ fontWeight: 600 }} gutterBottom>
                                    {status} <span className="typeLabel"> {type} </span>
                                </Typography>

                                <Typography style={{ fontSize: 14, fontWeight: "bold", minHeight: "36px", }} color="#000000">
                                    {name}
                                </Typography>
                                <Typography variant="body2" style={{marginTop:5}}>
                                    <img src={clockIcon} alt="clock"/><span className="cardTime">
                                        {moment(MeetingStartDateTime).format('LT')+"-"+moment(addMinuteToDate(MeetingEndDateTime)).format('LT')}
                                        <span className="cardTimeDuration">
                                    ({getDateDuration(MeetingStartDateTime, addMinuteToDate(MeetingEndDateTime), 'HOUR')}
                                    {getDateDuration(MeetingStartDateTime, addMinuteToDate(MeetingEndDateTime), 'MINUTE')})
                                    </span>
                                    </span>
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sm={2} style={{textAlign:'right'}}>
                                <div>
                                    <span onClick={onCalendarCardClick} className="icn rightIcn" ><ArrowRightAltIcon /></span>
                                </div>
                                {
                                    type !=="Activity" && type !=="Campaign Activity"&& isOnline && channelid !=13 && channelid !=79 &&
                                    <div onClick={onShowPYDmap('right', true)} style={{position:'relative',bottom:'-29px', cursor: 'pointer'}}><img src={mapPlaceIcon} alt="map"></img></div>
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}
export default CommonCalendarCard;
