import { isAlphabetOnly, isNumberOnly, isValidMobileNumber } from "../../../utils/validators";
// import { format } from "date-fns";
import { DATE_MONTH_YEAR_FORMAT } from "../../../utils/config";
import {datedata} from "../../../utils/utils";

export const LEAD_DATE = [
  { key: "Select", label: "Select", value: "" },
  { "key": "today", "label": "Today", "value": datedata('Today') },
  { "key": "yesterday", "label": "Yesterday", "value": datedata('Yesterday') },
  { "key": "thisweek", "label": "This Week", "value": datedata('thisweek') },
  { key: "7days", label: "Last 7 Days", value: datedata("7days") },
  { key: "thismonth", label: "This Month", value: datedata("thismonth") },
  { key: "30", label: "30 Days", value: datedata("30days") },
  { key: "duration", label: "Custom", value: "duration" }
];
export const leadBacklogFilterForm = [
  {
    colmd: "12",
    key: "userType",
    label: "User Type",
    hide: true,
    type: "select",
    id: "userType",
    name: "userType",
    options: [
    ],
    validators: [{ "name": "required", "value": false }]
  },
  {
    colmd: "12",
    key: "teamMember",
    label: "Team Member",
    hide: true,
    type: "select",
    id: "teamMember",
    name: "teamMember",
    options: [
    ],
    validators: [{ "name": "required", "value": false }]
  },
  {
    colmd: "12",
    key: "UserName",
    type: "text",
    id: "UserName",
    label: "User Name / Employee Code",
    validators: [],
  },
  {
    colmd: "12",
    key: "LeadName",
    type: "text",
    id: "LeadName",
    label: "Lead Name",
    validators: [
      {
        name: "minLength",
        value: 3,
        validationMessage: "Please enter at least 3 letters",
      },
      {
        name: "maxLength",
        value: 30,
        validationMessage: "Please enter no more than 30 characters.",
      },
    ],
  },
  {
    key: "LeadTypeID",
    type: "select",
    disabled: false,
    id: "LeadTypeID",
    label: "Lead Status",
    validators: [],
    options: [],
  },
  {
    key: "CreatedDateTime",
    hide: false,
    type: "select",
    disabled: false,
    id: "CreatedDateTime",
    label: "Created Date",
    validators: [],
    options: [...LEAD_DATE]
  },
  {
    key: "CreatedDateStartTIme",
    label: "From Date",
    type: "date",
    id: "CreatedDateStartTIme",
    hide: true,
    defaultValue: "",
    format: DATE_MONTH_YEAR_FORMAT,
    validators: []
  },
  {
    key: "CreatedDateEndTIme",
    label: "To Date",
    type: "date",
    id: "CreatedDateEndTIme",
    hide: true,
    defaultValue: "",
    format: DATE_MONTH_YEAR_FORMAT,
    validators: []
  },
  {
    key: "LeadCurrentStatus",
    type: "autocompleteMultiselect",
    disabled: false,
    id: "currentStatus",
    label: "Current Status",
    validators: [],
    options: [],
  },
  {
    key: "MeetingDate",
    hide: false,
    type: "select",
    disabled: false,
    id: "MeetingDate",
    label: "Meeting Date",
    validators: [],
    options: [...LEAD_DATE]
  },
  {
    key: "fromdate",
    label: "From Date",
    type: "date",
    id: "fromdate",
    hide: true,
    defaultValue: "",
    format: DATE_MONTH_YEAR_FORMAT,
    validators: []
  },
  {
    key: "todate",
    label: "To Date",
    type: "date",
    id: "todate",
    hide: true,
    defaultValue: "",
    format: DATE_MONTH_YEAR_FORMAT,
    validators: []
  },

  {
    key: "activityID",
    type: "autocomplete",
    disabled: false,
    id: "activityID",
    label: "Activity ID",
    validators: [],
    options: [],
  },
  {
    key: "branchNameCode",
    type: "autocomplete",
    disabled: false,
    id: "branchNameCode",
    label: "Branch Name & Code",
    validators: [],
    options: [],
    hide: false
  },
  // {
  //   key: "leadSource",
  //   type: "select",
  //   disabled: false,
  //   id: "leadSource",
  //   label: "Lead Source",
  //   validators: [],
  //   options: [],
  // },
  {
    key: "product",
    type: "autocomplete",
    disabled: false,
    id: "product",
    label: "Product",
    validators: [],
    options: [],
  },
  {
    key: "typeOfSP",
    type: "select",
    disabled: false,
    id: "typeOfSP",
    label: "Type of SP",
    validators: [],
    options: [],
  },
  {
    key: "campaign",
    type: "select",
    disabled: false,
    id: "campaign",
    label: "Campaign",
    validators: [],
    options: [],
    hide: false
  },
  {
    colmd: "12",
    key: "MobileNo",
    type: "text",
    id: "MobileNo",
    label: "Mobile Number",
    validators: [],
  }
];
export const customValidators = [
  {
    key: "LeadName", // field name to which the validation should be applied
    validator: isAlphabetOnly, // function to be called
    message: "Please enter alphabets only.", // message to show
  },
  {
    key: 'MobileNo',
    validator: isNumberOnly,
    message: 'Please enter numbers only'
  },
  {
    key: 'MobileNo',
    validator: isValidMobileNumber,
    message: 'Please enter a valid 10 digit mobile number'
  }
];
