import React, { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import DynamicForm from "f1-dynamic-form-react";
import { GCLLeadForm, customValidators } from "./createLeadFormGCL.js";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb.js";
import MessageBar from "../../../../components/messageBar/messageBar.js";
import { t } from "i18next";
import {
  setBreadcrumbs,
  activateLoader,
  deActivateLoader,
  getAcitvityDuration,
  activateSuccessMessageBar,
  activateErrorMessageBar,
} from "../../../../redux/actions/common.js";
import {  getLeadDetails } from "../../../../redux-reducer/reducer";
import { connect, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { checkInConfig, GCLLEAD, GENDER } from "../../../../utils/config.js";
import {
  saveLeadOffline,
  getLgSpList,
} from "../../../../offlineManager/leadOfflineManager";
import { LEADS_PATH } from "../../../../route/routePath.js";
import SaveAsDraftDrawer from "../../activity/saveAsDraftDrawer.js";
import Footer from "../../../../layouts/footer/footer";
import Loader from "../../../../components/loader/loader";
import {
  saveLeadForm,
  UpdateLeadForm,
} from "../../../../redux-reducer/reducer/index.js";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import IdleTimer from "react-idle-timer";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import { minusMinuteFromDate } from "../../../../utils/utils.js";
import { checkDateIsValid } from "../../../../utils/validators.js";

let LeadTitle = "Create Lead";
const _formatDate = "YYYY-MM-DD";
const createformat = "YYYY-MM-DD HH:mm:ss";
const __formatDate = "YYYY-MM-DDTHH:mm";



const CreateLeadGCL = (
  props
) => {

  let dyFormRef = useRef();
  const history = useHistory();
  const params = useParams();

  const id = params.id;
  const data = useSelector((state) => state);
  const userData = JSON.parse(localStorage.getItem("persist:root"));
  const employeeCode = JSON.parse(userData.userReducer).profileInfo.employeeCode;
  const channelID = JSON.parse(userData.userReducer).profileInfo.channelid;
  const userId = data.userReducer.profileInfo.userId;
  const masterDataState = useSelector((state) => state.commonReducer);

  const formName=GCLLEAD
    let   idleTimer = useRef()
  const [current, setCurrent] = useState({
    id: 1,
    GCLLeadSource: "LLPS",
    InsuranceRequired: "Yes",
    MemberFormFilled: "No",
    Email:null,
  });
  const [formDataState, setFormDataState] = useState(GCLLeadForm);
  const [labelForId, setLabelForId] = useState({
    ActivityIDLabel: "",
    LGIDLabel: "",
  });
  const [extErrors, setExtErrors] = useState({});
  const [branchIDLabel, setBranchIDLabel] = useState("");
  const [isActiveMobileDraft, setIsActiveMobileDraft] = useState(false);
  const [isFormSUbmitDisabled] = useState(false)
  const [timeOutMsg,setTimeOutMsg] = useState(false)
  const [leadDetails,setLeadDetails] = useState({})
  const [AssignedTo,setAssignedTo] = useState('')
  const [AssignedToName,setAssignedToName] = useState('')


  const loadBreadCrumbs = () => {
    const breadcrumbs = [
      { name: "Leads", url: "/app/lead/all" },
      { name: LeadTitle, url: "/app/lead/create" },
    ];
    props.setBreadcrumbs(breadcrumbs);
  };

  const getDropdownData = (optionsArr, key, label, value) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr?.map((option) => {
        return { key: option[key], label: option[label], value: option[value] };
      });
    }
    arr.unshift({ key: "select", label: "select", value: "" });
    return arr;
  };

  const initFormData = (formFields) => {
    const formData = [...formFields];
    const { Salutation, PriorityStatus,LoanType,GCLCallDuration,userBranchData} = masterDataState;
    const arrPriorityStatus = PriorityStatus.slice(0, 4);
    const gender = [...GENDER];
    gender.push({ key: "transgender", label: "transgender", value: "transgender"});
    formData[0].options = Salutation;
    formData[15].options = arrPriorityStatus;
    formData[19].options = gender;
    formData[11].options = LoanType
    formData[18].options = GCLCallDuration
    formData[4].options = getDropdownData( userBranchData, "BranchID", "BranchCode", "BranchID" );
    return formData
  };

  const bindLgSpForSelectedBranch = (branchCode) => {
    getLgSpList(branchCode).then((res) => {
      const { lgList } = res;
      const formData = [...formDataState];
      let LEAD_LG;
      if (lgList) {
        LEAD_LG = getDropdownData(lgList, "LGCode", "FullName", "LGID");
      }
      if (LEAD_LG.length <= 1) {
        formData[8].validators[0].value=false
        formData[9].validators[0].value=true
        formData[8].hide = true;
        formData[9].hide = false;
      }else{
        formData[8].validators[0].value=true
        formData[9].validators[0].value=false
        formData[8].hide = false;
        formData[9].hide = true;
      }
      formData[8].options = LEAD_LG;
      setFormDataState(formData);
    });
  };

const branchChange =async (value) =>{

  const formData = [...formDataState];
      const { userBranchData, Branches } = masterDataState;
      formData[5].hide = false;
      formData[6].hide = false;
      formData[7].hide = false;
      formData[8].hide = false;
        const getBranchName = userBranchData.filter(
          (item) => item.BranchID == value
        );
        if (id ){
        setLeadDetails({ ...leadDetails, BranchName: getBranchName[0].BranchName,  })
        }else{
        setCurrent({
          ...current,
          BranchName: getBranchName[0].BranchName,
        });
       }
        setBranchIDLabel( getBranchName[0].BranchName);
        formData[7].options = getDropdownData( getBranchName, "SMSID", "SMSCode", "SMSID" );
        formData[6].options = getDropdownData( getBranchName, "Bank_Name", "Bank_Name", "Bank_Name" );
      const selectBrannchCode = Branches.find(
        (b) => b.value === value
      )?.key;
      bindLgSpForSelectedBranch(selectBrannchCode);
}

  const onChangeHandler = (event, key, model) => {
    if (id) {
      const details = { ...leadDetails, [key]: event?.target?.value }
      setLeadDetails( details )
  }
    if (key === "BranchID") {
      delete model.errors["BranchName"]
      if (event.target.value != "") {
        branchChange(event.target.value)
      }
    }
    if (key === "LGID") {
      const lgindex = event.nativeEvent.target.selectedIndex;
        setLabelForId({
          ...labelForId,
          LGIDLabel: event.nativeEvent.target[lgindex].text,
        });
    }
    if (model.Email =="" || key=="Email"  && event.target.value == ''){
      model.Email = null
      delete model.errors["Email"]
      }
      if (key === 'MeetingStartDateTime') {
          let StartDate = moment(event.target.value).format(__formatDate);
          let newDate = moment(event.target.value)
            .add(30, "m")
            .format(__formatDate);

          formDataState.map((option) => {
            if (option.key === "MeetingEndDateTime") {
              option.max = moment(StartDate)
                .endOf("day")
                .format("YYYY-MM-DD kk:mm");
              option.min = StartDate;
            }
          });

          if (
            !checkDateIsValid(
              event.target.value,
              moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
            )
          ) {
            setExtErrors({ ...extErrors, [key]: "please select future value" });
          } else {
            delete extErrors?.MeetingStartDateTime;
            setExtErrors({ ...extErrors });
          }
          if(id){
            setLeadDetails({
              ...leadDetails,
              MeetingStartDateTime: StartDate,
              MeetingEndDateTime: newDate,
            });
          } else {
            setCurrent({
              ...current,
              MeetingStartDateTime: StartDate,
              MeetingEndDateTime: newDate,
            });
          }
      }
      if(key === 'MeetingEndDateTime') {
         const newDate = moment(event.target.value).format(__formatDate);
        if (
          !checkDateIsValid(event.target.value, current.MeetingStartDateTime)
        ) {
          setExtErrors({
            ...extErrors,
            MeetingEndDateTime:
              "meeting end date should not less than meeting starting date",
          });
        } else {
          delete extErrors.MeetingEndDateTime;
          setExtErrors({ ...extErrors });
        }
        if(id){
          setLeadDetails({
            ...leadDetails,
            MeetingEndDateTime: newDate,
          });
        } else {
          setCurrent({
            ...current,
            MeetingEndDateTime: newDate,
          });
        }
      }
  };

  const onSubmitHandler = async (model) => {

    if (model.Email == null || model.Email ==""){
     delete model.errors["Email"]
     }

    if ( !model.isValidForm || Object.keys(extErrors).length > 0 || Object.keys(model.errors).length > 0 ) {
      const errorListClasess = Object.keys(model.errors);
      const errArr = []
      const formD = formDataState
      formD.map((el, idx) => {
        if (el.key == errorListClasess[0]) {
          errArr.push(idx + 1)
        }
      })
      const errorElement = document.getElementsByClassName(`${'control'}${errArr[0]}`)[0];
      if (errorElement) {
        errorElement.scrollIntoView({
          block: "center",
        });
      }
      if (Object.keys(model.errors).length == 1) {
        props.activateErrorMessageBar("Please fill all required fields");
        return false;
      }
      if ((!model.isValidForm && Object.keys(model.errors).length > 0) || Object.keys(model.errors).length > 0 || Object.keys(extErrors).length > 0) {
        props.activateErrorMessageBar("Please fill all required fields");
        return false;
      }
    }
    const data = { ...model };
    delete data["advanceLayoutFields"];
    delete data["collapseStates"];
    delete data["errors"];
    delete data["isValidForm"];
    delete data["id"];
    delete data["ActivityIDlabel"];
    delete data["BranchName"];
 if (data.hasOwnProperty("MeetingStartDateTime")) {
        data["MeetingStartDateTime"] = data[
        "MeetingStartDateTime"
      ].replace("T", " ");
    }
    if (data.hasOwnProperty("MeetingEndDateTime")) {
        data["MeetingEndDateTime"] = minusMinuteFromDate(
            data["MeetingEndDateTime"]
      ).replace("T", " ");
    }


    data["LeadStatusId"] = 1; //'NEW';
    data["UserId"] = userId;
    data["EmployeeCode"] = employeeCode;
    data["CreatedBy"] = userId;
    data["ModifiedBy"] = userId;
    data["AssignedTo"] = userId;
    data["ChannelID"] = parseInt(channelID);
    data["InsuranceRequired"] = data?.InsuranceRequired == "yes" ? 0 : 1
    data["MemberFormFilled"] = data?.MemberFormFilled == "Yes" ? 1 : 0
    if (id) {
      data["IsBacklog"] = 0;
      data["LeadId"] = id;
    }
    if (navigator.onLine) {
      const { Salutation } = masterDataState;
      if (id) {
        data['AssignedTo'] = AssignedTo;
        data['AssignedToName'] = AssignedToName;
        props.UpdateLeadForm({ model: data, formBuilder: formName, history: history })
      }else{
      const leadScoreRequest = {
        productName: "",
        salutationName: `${getNameForId(Salutation, data.SalutationID)}${"."}`,
        priorityName: data.PriorityName ? data.PriorityName : "",
        lgCode: labelForId.LGIDLabel.split(" ")[0],
        branchCode: branchIDLabel,
        branchCodeActivity: branchIDLabel.split(" ")[0],
        activity: labelForId.ActivityIDLabel,
      };
        props.activateLoader();
        const result = await props.saveLeadForm({
        data: data,
        formName: formName,
        leadScoreRequest: leadScoreRequest,
        });
      handleSaveLeadResponse(result, "Created");
      }
    }
      if (!navigator.onLine) {
      //   const mmData = {
      //     ...data,
      //     "CreatedDate": moment(new Date()).format(createformat),
      //     "CreatedBy": userId,
      //     "ModifiedDate": moment(new Date()).format(createformat),
      //     "ModifiedBy": userId,
      //     "LmsleadGuid": uuidv4(),
      //     "LmsleadlastSyncTime": moment(new Date()).format(createformat),//Date.parse(new Date().toUTCString()),
      //     "IsLmsSync": navigator.onLine,
      //     "LeadStatus": 'New Customer',
      //     "Remarks": model.Remarks,
      //     "PriorityID": model?.PriorityID,
      //     "PriorityName": model.PriorityName ? model.PriorityName : '',
      //     "IsBacklog": false
      // }
        saveLeadOffline(data).then((res) => {
          if (res) {
            const  data  = res.data;
            const { LeadId, LmsleadGuid } = data?.data;
            if (LeadId) {
              history.push(`/app/lead/detail/${LeadId}`);
            } else {
              history.push(`/app/lead/detail/${LmsleadGuid}`);
            }
          }
        });
      }
    }


  const onSaveDraft = async (e) => {

    const formData =  {...dyFormRef.current.state};
    e.stopPropagation();
    e.preventDefault();
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    delete formData["ActivityIDlabel"];
     if (formData.hasOwnProperty("MeetingStartDateTime")) {
            formData["MeetingStartDateTime"] = formData[
              "MeetingStartDateTime"
            ].replace("T", " ");
          }
          if (formData.hasOwnProperty("MeetingEndDateTime")) {
            formData["MeetingEndDateTime"] = minusMinuteFromDate(
              formData["MeetingEndDateTime"]
            ).replace("T", " ");
          }
    if (id) {
      formData["LeadId"] = id;
    }
    formData["LeadStatusId"] = 11;
    formData["ChannelID"] = parseInt(channelID);
    formData["UserId"] = userId;
    formData["AssignedTo"] = userId;
    formData["EmployeeCode"] = employeeCode;
    if (formData["SalutationID"] === undefined) {
      formData["SalutationID"] = null;
    }
    formData["InsuranceRequired"] = formData?.InsuranceRequired == "No" ? 0 : 1
    formData["MemberFormFilled"] = formData?.MemberFormFilled == "Yes" ? 1 : 0
    const {  Salutation } = masterDataState;
    const mmData = {
      ...formData,
      CreatedDate: moment(new Date()).format(createformat),
      CreatedBy: userId,
      ModifiedDate: moment(new Date()).format(createformat),
      ModifiedBy: userId,
      LmsleadGuid: uuidv4(),
      LmsleadlastSyncTime: moment(new Date()).format(createformat), // Date.parse(new Date().toUTCString()),
      IsLmsSync: navigator.onLine,
      ActivityID: formData.ActivityID,
      LeadStatus: "Drafted",
      SalutationName: getNameForId(Salutation, formData.SalutationID),
      IsBacklog: false,
    };
    if (navigator.onLine) {
      const leadScoreRequest = {
        productName: mmData.ProductName,
        salutationName: `${mmData.SalutationName}${"."}`,
        priorityName: mmData.PriorityName,
        lgCode: labelForId.LGIDLabel.split(" ")[0],
        branchCode: labelForId.BranchIDLabel,
        branchCodeActivity: labelForId.BranchIDLabel,
        activity: labelForId.ActivityIDLabel,
      };
      if (id) {
        props.UpdateLeadForm({
          model: mmData,
          // formBuilder: formName,
          history: history,
          Drafted: "Draft",
        });
      } else {
        const result = await props.saveLeadForm({
          data: mmData,
          formName: formName,
          leadScoreRequest: leadScoreRequest,
        });
        handleSaveLeadResponse(result, "Drafted");
      }
    } else {
      saveLeadOffline(mmData).then((res) => {
        if (res) {
          history.push(`/app/lead/all`);
        }
      });
    }
  };

  const onCancelHandler = (e) => {
    const isMobile = Math.min(window.screen.width, window.screen.height) < 480 || navigator.userAgent.indexOf("Mobi") > -1;
    if (isMobile) {
      setIsActiveMobileDraft(true);
    } else {
      history.push(LEADS_PATH);
    }
  };
  const toggleDrawer = (flag) => {
    setIsActiveMobileDraft(flag);
  };

  const onDontSaveDraftHandler = () => {
    history.push(LEADS_PATH);
  };

  const onSaveDraftHandler = () => {
    onSaveDraft(new Event("test"));
  };

  const getNameForId = (optionArr, inputId) => {
    const matchEl = optionArr.filter((option) =>
      option.key === inputId || option.value === inputId ? option.label : ""
    );
    if (matchEl && matchEl.length > 0) {
      return matchEl[0].label;
    }
    return null;
  };

  const handleSaveLeadResponse = (result, mode) => {
    if (result.status === 200 && result.data.Responsestatus === true) {
      activateSuccessMessageBar(`Lead has been ${mode} successfully`);
      props.deActivateLoader();
      if (mode === "Created") {
        history.push(`/app/lead/detail/${result.data.Transactionid}`);
      } else if (mode === "Drafted") {
        history.push("/app/lead/all");
      }
    } else {
      props.deActivateLoader();
      activateSuccessMessageBar(
        "Something went wrong! please try again later."
      );
    }
  };

  const handleOnAction = (event) => {
    if (timeOutMsg) {
      setTimeout(() => {
        history.push("/app/home")
      }, 3000)
    }
  }
  const handleOnActive = (event) => {
    if (idleTimer.current.getRemainingTime() === 0) {
      setTimeOutMsg(idleTimer.current.getRemainingTime() === 0)
    }
  }
  const handleOnIdle = (event) => {
  }

 const getLeadDetail = async () => {
    const leadDetail = await props.getLeadDetails({ id: id })
    props.activateLoader()
    const { SalutationID,Salutation, FirstName,LastName, MobileNo, Email, BranchID, BranchName,GCLLeadSource,
    LGID, CreatedDate, CreatedBy, BankName,BranchCode,DOB,GCLCallDurationId,Gender, InsuranceRequired,AssignedTo, AssignedToName,
    LoanACReferenceNo,LGCode, MemberFormFilled, PriorityID, SMSId,LoanTypeID,LoanAmount,RateOfInterest,ExpectedPremiumAmount,CoBorrower} = leadDetail.data
    let InsuranceRequiredCopy = InsuranceRequired== true ? "Yes" : "No" 
    let MemberFormFilledCopy=MemberFormFilled == true ? "Yes" : "No"
    setLeadDetails({
            SalutationID: SalutationID?.toString(), 
            Salutation: Salutation, 
            FirstName: FirstName, 
            LastName: LastName,
            MobileNo: MobileNo,
            Email: Email,
            GCLLeadSource: GCLLeadSource,
            ExpectedPremiumAmount:ExpectedPremiumAmount?.toString(),
            CoBorrower:CoBorrower,
            BranchID:BranchID?.toString(),
            RateOfInterest:RateOfInterest?.toString(),
            LoanAmount:LoanAmount?.toString(),
            LoanTypeID: LoanTypeID?.toString(),
            BranchID: BranchID?.toString(), 
            BranchName: BranchName,
            LGID: LGID?.toString(),
            BankName:BankName,
            BranchCode:BranchCode,
            DOB:moment(DOB).format(_formatDate),
            GCLCallDurationId:GCLCallDurationId?.toString(),
            Gender:Gender,
            InsuranceRequired:InsuranceRequiredCopy,
            LoanACReferenceNo : LoanACReferenceNo,
            MemberFormFilled:MemberFormFilledCopy, 
            PriorityID:PriorityID,
            SMSId:SMSId?.toString(),
            LGCODE:LGCode?LGCode:null,
            CreatedDate: CreatedDate,
            CreatedBy: CreatedBy
    },() => {
        const formData = initFormData(formDataState) & branchChange(BranchID)
        setFormDataState(formData )
  })
  if (BranchID !=null){
    branchChange(BranchID.toString())
   }
   setAssignedTo(AssignedTo)
   setAssignedToName(AssignedToName)
  props.deActivateLoader()
}


  useEffect(()=>{
    if (!id) {
      const formData = initFormData(formDataState)
      setFormDataState(formData)
  }
    loadBreadCrumbs();
    if (id) {
      getLeadDetail()
    }
  },[])

  useEffect(()=>{
    if(timeOutMsg){
      setTimeout(() => {
        history.push("/app/home")
      }, 3000)
    }
  }, [timeOutMsg])

  return (
    <div>
    <IdleTimer
          ref={(ref) => {
            idleTimer.current = ref;
          }}
          timeout={1000 * 60 * 1}
          onActive={handleOnActive}
          onIdle={handleOnIdle}
          onAction={handleOnAction}
          debounce={250}
        />
        <Snackbar open={timeOutMsg} autoHideDuration={3000}>
          <Alert severity="error">
            Your session has been expired! You will be redirected to homepage in
            3 seconds.
          </Alert>
        </Snackbar>
      <div className="mainContainer createLead">
        <MessageBar />
        <Breadcrumb />
        <span className="formInfo">
          {t("Please fill in the lead information for GCL")}
        </span>
        <div className="formBox">
          <DynamicForm
            key={current.id}
            defaultValues={id ? leadDetails : current}
            onRef={(ref) => {
              dyFormRef.current = ref;
            }}
            title={LeadTitle}
            model={formDataState}
            className="dynamicForm"
            buttonSubmit={t("Submit")}
            onSubmit={(model) => onSubmitHandler(model)}
            disableSubmitOnInValid={isFormSUbmitDisabled}
            materialOutline={false}
            onChange={(event, key, model) => onChangeHandler(event, key, model)}
            customValidators={customValidators}
            showAstersikOnRequiredFields={true}
            extErrors={extErrors}
            checkInConfig={checkInConfig}
            extaraActionFormButton={
              <>
                <a className="savedraftLink" onClick={(e) => onSaveDraft(e)}>
                  SAVE AS DRAFT
                </a>
                <button
                  color="indigo"
                  className="btn cancelBtn"
                  key="extra"
                  id="extra"
                  type="button"
                  onClick={(e) => onCancelHandler(e)}
                >
                  Cancel
                </button>
              </>
            }
          />
        </div>
        <Footer />
        {masterDataState.isActiveLoader && <Loader />}
        <SaveAsDraftDrawer
          isActiveMobileDraft={isActiveMobileDraft}
          toggleDrawer={toggleDrawer}
          onDontSaveHandler={onDontSaveDraftHandler}
          onSaveDraftHandler={onSaveDraftHandler}
        ></SaveAsDraftDrawer>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  leadFormData: state.leadFormReducer,
  masterDataState: state.commonReducer,
});
const mapDispatchToProps = (dispatch) => ({
  setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  getAcitvityDuration: (payload) => dispatch(getAcitvityDuration(payload)),
  saveLeadForm: (payload) => dispatch(saveLeadForm(payload)),
  activateSuccessMessageBar: (payload) =>dispatch(activateSuccessMessageBar(payload)),
  activateErrorMessageBar: (payload) =>dispatch(activateErrorMessageBar(payload)),
  getLeadDetails: (payload) =>dispatch(getLeadDetails(payload)),
  UpdateLeadForm: (payload) => dispatch(UpdateLeadForm(payload)),
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps)(CreateLeadGCL)
);
