import React from "react";
import "./activityDetails.scss";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getActivityDetails, getActivityDetailsHistory,getCampiagnActivityDetailsHistory } from '../../../../redux/actions/activityDetailsAction'
import { activateLoader, deActivateLoader, activateErrorMessageBar, activateSuccessMessageBar, setBreadcrumbs, getCampaignActivityById } from '../../../../redux/actions/common'
import { getSubTitles, getOptionById } from "../helper";
import DetailLayout from "./detailLayout";
import Breadcrumb from '../../../../components/breadcrumb/breadcrumb';
import {
  getActivityNameList, getActivityOffline,
  getActivityOfflineHistory,
  getActivityStatusNameList, getDistrictNameList, getStateNameList, getTrainingNameList
} from '../../../../offlineManager/activityOfflineManager';
import { getBranchList } from "../../../../offlineManager/leadOfflineManager";
import { getUpdatedStatusCallCountforCSC, getStatusCallCount, getUpdatedStatusCallCountforCampaignCSC } from '../../../../redux/actions/updateActivityAction';
import { getAdvisorZMAPremierZMAListing, getAcitvityDuration, getReasons } from '../../../../redux/actions/common'
class ActivityDetails extends React.Component {
  constructor(props) {
    super();
    this.isCampaignRoute = props.location.pathname.includes('/activity/campaigndetails') || props.location.pathname.includes('/activity/csc/campaigndetails')
    this.state = {
      activityData: {},
      activityHistoryData: [],
      activityTitle: '',
      branchCode: '',
      activityId: 0,
      breadcrumbs: [],
      optionList: {
        branchType: [],
        activityNameType: [],
        activityStatusType: [],
        stateList: [],
        districtList: [],
        trainingForList: []
      }
    }
  }
  setActivityTitle = (activityData) => {
    let title = ''
    if (activityData.BranchId) {
      const branchOption = getOptionById(activityData.BranchId.toString(), this.props.masterDataState.Branches)
      title = getSubTitles(activityData.ActivityName, branchOption?.key, branchOption?.label, activityData.CreatedDate)
      this.setState({ activityData: { ...this.state.activityData, branchCode: branchOption ? branchOption.key : "" } });
    }
    else {
      title = getSubTitles(activityData.ActivityName, '', '', activityData.CreatedDate)
    }
    this.setState({ activityTitle: title });
  }
  getBranchName = (inputId) => {
    const collection = this.state.optionList['branchType'];
    if (inputId && collection) {
      const name = collection.filter((el) => el.BranchID === inputId?.toString());
      if (name && name.length > 0) {
        return name[0].BranchName;
      }
      return null;
    }
    return null
  }
  getAssigneTohName = (inputId) => {
    const collection = this.state.optionList['branchType'];
    if (inputId && collection) {
      const name = collection.filter((el) => el.UserID === inputId);
      if (name && name.length > 0) {
        return `${name[0].FirstName}${' '} ${name[0].LastName}`;
      }
      return null;
    }
    return null;
  }
  getActivityName = (inputId) => {
    const collection = this.state.optionList['activityNameType'];
    if (inputId && collection) {
      const name = collection.filter((el) => el.value === inputId.toString());
      if (name.length > 0) {
        return name[0].label;
      }
      return null;
    }
    return null;
  }
  getActivityStatusName = (inputId) => {
    const collection = this.state.optionList['activityStatusType'];
    if (inputId && collection) {
      const name = collection.filter((el) => el.value === inputId.toString());
      if (name.length > 0) {
        return name[0].label;
      }
      return null;
    }
    return null;
  }
  getStateName = (inputId) => {
    const collection = this.state.optionList['stateList'];
    if (inputId && collection) {
      const name = collection.filter((el) => el.value === inputId.toString());
      if (name.length > 0) {
        return name[0].label;
      }
      return null;
    }
    return null;
  }
  getDistrictName = (inputId) => {
    const collection = this.state.optionList['districtList'];
    if (inputId && collection) {
      const name = collection.filter((el) => el.value === inputId.toString());
      if (name.length > 0) {
        return name[0].label;
      }
      return null;
    }
    return null;
  }
  getTrainingForName = (inputId) => {
    const collection = this.state.optionList['trainingForList'];
    if (inputId && collection) {
      const name = collection.filter((el) => el.value === inputId.toString());
      if (name.length > 0) {
        return name[0].label;
      }
      return null;
    }
    return null;
  }

  getActivityHistoryOffline = () => {
    getActivityOfflineHistory(this.props.match.params.id).then((result) => {
      if (result && result.HistoryOutput) {
        this.setState({ activityHistoryData: result.HistoryOutput })
      }
    })
  }

  getOptionData = () => {
    Promise.all([getBranchList(), getActivityStatusNameList(), getActivityNameList(), getStateNameList(), getDistrictNameList(), getTrainingNameList()]).then((res) => {
      if (res && res.length > 0) {
        const optionState = {
          branchType: res[0],
          activityStatusType: res[1],
          activityNameType: res[2],
          stateList: res[3],
          districtList: res[4],
          trainingForList: res[5]
        }
        this.setState({ optionList: optionState }, () => {
          const activityId = this.props.match.params.id
          this.getActivityDetailsOffline(activityId);
        });
      }
    })
  }
  getActivityDetailsOffline = (activityId) => {
    getActivityOffline(activityId).then((result) => {
      const activityModel = {
        ActivityDoneWith: null,
        ActivityID: result?.ActivityID,
        ActivityName: this.getActivityName(result?.ActivityID),
        ActivityStatus: this.getActivityStatusName(result?.ActivityStatusId),
        ActivityStatusId: result?.ActivityStatusId,
        ActivitySubTypeId: null,
        ActivitySubTypeName: null,
        ActualNoOfTrainees: null,
        Address: result?.Address,
        AdvisorID: null,
        AssignedTo: result?.AssignedToName ? result?.AssignedToName : this.getAssigneTohName(result?.AssignedTo),
        AverageTicketSize: result?.AverageTicketSize,
        BillAmount: null,
        BillDate: null,
        BillFileName: null,
        BillNumber: null,
        BranchId: result?.BranchId,
        BranchName: result.BranchId ? this.getBranchName(result.BranchId) : result?.UserName,
        CallingToVLERAPDM: null,
        ChannelID: null,
        ChannelName: null,
        CheckinCount: result?.CheckinCount,
        ChekinEnabled: true,
        CreatedBy: null,
        CreatedDate: result?.CreatedDate,
        CurrentState: null,
        DistrictID: null,
        DistrictName: this.getDistrictName(result.DistrictID),
        ExpectedConversion: result?.ExpectedConversion,
        ExpectedLead: result?.ExpectedLead,
        ExpectedPremium: null,
        ExpenseAmount: null,
        ExpenseClaim: null,
        ExpenseTypeId: null,
        ExpenseTypeName: null,
        IsActive: true,
        IsBacklog: result?.IsBacklog,
        IsCheckin: result?.IsCheckin,
        IsClaimRequired: null,
        IsDeleted: false,
        Location: result?.Location,
        MeetingEndDateTime: result?.MeetingEndDateTime,
        MeetingStartDateTime: result?.MeetingStartDateTime,
        MeetingType: null,
        MeetingWith: null,
        ModifiedBy: null,
        ModifiedDate: result?.ModifiedDate,
        NewFormType: null,
        NoOfCallsPlanned: null,
        NoOfExpectedLeadsConverted: null,
        NoOfExpectedLeadsGenerated: null,
        NoOfLeads: null,
        NoOfPoliciesPlanned: result?.NoOfPoliciesPlanned,
        NoOfRAPActivationPlanned: result?.NoOfRAPActivationPlanned,
        NumberOfCallsDone: null,
        NumberOfProposals: null,
        OutcomeOfCall: null,
        PlannedForNoOfPeople: result?.PlannedForNoOfPeople,
        PremiumAmount: null,
        RAPActivated: null,
        RAP_VLE: null,
        Reason: null,
        RecruitmentFor: null,
        Remarks: null,
        ReviewWith: result?.ReviewWith,
        StateID: null,
        StateName: this.getStateName(result.StateID),
        SubjectAgendaOfMeeting: null,
        TicketSize: result?.TicketSize,
        TrainingFor: result.TrainingFor,
        TrainingForName: this.getTrainingForName(result.TrainingFor),
        TrainingTopic: result?.TrainingTopic,
        TransportMode: null,
        TransportModeID: null,
        TravelExpence: result?.TravelExpence,
        TrnActivityID: result?.TrnActivityID,
        UploadBill: null,
        UploadPhoto: null,
        UploadTrainingAttendanceDocument1: null,
        UploadTrainingAttendanceDocument2: null,
        UploadTrainingAttendanceDocument3: null,
        UploadTrainingAttendancePhoto1: null,
        UploadTrainingAttendancePhoto2: null,
        UploadTrainingAttendancePhoto3: null,
        UserId: result?.UserId,
        UserName: result?.UserName ? result?.UserName : result?.AssignedToName,
        VLERAPDMMobile: null,
        VehicleTypeID: null,
        ZMAID: null,
        LmsActivityGuid: result?.LmsActivityGuid,
        LmsActivitylastSyncTime: result?.LmsActivitylastSyncTime,
        IsLmsSync: result?.IsLmsSync,
      }
      if (result) {
        this.setState({ activityData: activityModel }, () => {
          this.setActivityTitle(result);
        })
        this.getListData(result?.UserId)

      }
    })

    this.getActivityHistoryOffline()
  }

  getUpdatedStatusCallCount = async () => {
    const activityId = this.props.match.params.id
    const trn = activityId
    if (this.isCampaignRoute){
    const res = await this.props.getUpdatedStatusCallCountforCampaignCSC(trn)
    this.setState({ callCount: res?.data })
    this.props.getStatusCallCount(res?.data)
    this.callsCount = res?.data
    }else{
      const res = await this.props.getUpdatedStatusCallCountforCSC(trn)
      this.setState({ callCount: res?.data })
      this.props.getStatusCallCount(res?.data)
      this.callsCount = res?.data
    }
  }

  getListData = async (userId) => {
    const channelId = this.props.userState.profileInfo.channelid;
    if (channelId == 3) {
      await this.props.getAdvisorZMAPremierZMAListing({
        userId: userId,
        status: "ZMA"
      })
      await this.props.getAdvisorZMAPremierZMAListing({
        userId: userId,
        status: "Advisor"
      })
      await this.props.getAdvisorZMAPremierZMAListing({
        userId: userId,
        status: "Premier ZMA"
      })
      await this.props.getAdvisorZMAPremierZMAListing({
        userId: userId,
        status: "EAP"
      })
    }
  }

  loadData = async () => {
    this.props.activateLoader()
    const activityId = this.props.match.params.id
    if (!activityId) {
      this.props.history.push("/app/activity/all")
    }
    if(this.isCampaignRoute){
      const breadcrumbs = [
        { name: 'Campaign Activity', url: '/app/campaignactivity/all' },
        { name: 'Activity Details', url: `/app/activity/campaigndetails/${activityId}` }
      ]
      this.props.setBreadcrumbs(breadcrumbs)
    } else {
    const breadcrumbs = [
      { name: 'Activity', url: '/app/activity/all' },
      { name: 'Activity Details', url: `/app/activity/detail/${activityId}` }
    ]
    this.props.setBreadcrumbs(breadcrumbs)
     }
    if (navigator.onLine) {
      if(!this.isCampaignRoute){
        const result = await this.props.getActivityDetails({ 'activityId': activityId })
        if (result.status === 200) {
          const data = result.data;
          this.setState({ activityData: data }, () => {
            this.setActivityTitle(data);
          })
          this.getListData(data?.UserId)
          this.props.deActivateLoader();
          // console.log("data",data)
        }
      }else{
          const result = await this.props.getCampaignActivityById({ 'activityId': activityId })
        if (result.status === 200) {
          const data = result.data;
          this.setState({ activityData: data }, () => {
            this.setActivityTitle(data);
          })
          this.getListData(data?.UserId)
          this.props.deActivateLoader();
        }
      }
      if(!this.isCampaignRoute){
        this.props.activateLoader()
        const resultHistory = await this.props.getActivityDetailsHistory({ 'activityId': activityId })
        if (resultHistory.status === 200) {
          this.setState({ activityHistoryData: resultHistory.data })
          this.props.deActivateLoader();
        }}else{
          this.props.activateLoader()
          const resultHistory = await this.props.getCampiagnActivityDetailsHistory({ 'activityId': activityId })
          if (resultHistory.status === 200) {
            this.setState({ activityHistoryData: resultHistory.data })
            this.props.deActivateLoader();
          }}
        }
        this.getUpdatedStatusCallCount();


  }
  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadData()
    }
  }
  componentDidMount = async () => {
    this.loadData()
    // this.props.getReasons()
    // this.props.getAcitvityDuration()
  }

  takeWordFirstLetter = (name) => {
    const str = []
    if (name) {
      name.split(" ").map(el => str.push(el.charAt(0)))
    }
    return str.toString().replaceAll(',', '')
  }
  getUpdatedActivityHistory = async () => {
    if (navigator.onLine) {
      const activityId = this.props.match.params.id
      this.props.activateLoader()
      if(this.isCampaignRoute){
        const resultHistory =  await this.props.getCampiagnActivityDetailsHistory({ 'activityId': activityId })
        if (resultHistory.status === 200) {
          this.setState({ activityHistoryData: resultHistory.data })
          this.props.deActivateLoader();
        }
      }else{
      const resultHistory = await this.props.getActivityDetailsHistory({ 'activityId': activityId })
      if (resultHistory.status === 200) {
        this.setState({ activityHistoryData: resultHistory.data })
        this.props.deActivateLoader();
      }
    }
    }
  }
  render() {
    return (
      <div className="mainContainer activityDetail">
        <Breadcrumb></Breadcrumb>
        <DetailLayout
          activityData={this.state.activityData}
          activityTitle={this.state.activityTitle}
          activityHistoryData={this.state.activityHistoryData}
          getActivityHistory={this.getUpdatedActivityHistory}
        ></DetailLayout>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  masterDataState: state.commonReducer,
  userState: state.userReducer,
});
const mapDispatchToProps = dispatch => ({
  activateSuccessMessageBar: (payload) => dispatch(activateSuccessMessageBar(payload)),
  activateErrorMessageBar: (payload) => dispatch(activateErrorMessageBar(payload)),
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  getActivityDetails: (payload) => navigator.onLine && dispatch(getActivityDetails(payload)),
  getActivityDetailsHistory: (payload) => navigator.onLine && dispatch(getActivityDetailsHistory(payload)),
  getUpdatedStatusCallCountforCSC: (payload) => dispatch(getUpdatedStatusCallCountforCSC(payload)),
  getStatusCallCount: (payload) => dispatch(getStatusCallCount(payload)),
  getUpdatedStatusCallCountforCampaignCSC: (payload) => dispatch(getUpdatedStatusCallCountforCampaignCSC(payload)),
  setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
  getAcitvityDuration: (payload) => dispatch(getAcitvityDuration(payload)),
  getReasons: (payload) => dispatch(getReasons(payload)),
  getAdvisorZMAPremierZMAListing: (payload) => dispatch(getAdvisorZMAPremierZMAListing(payload)),
  getCampiagnActivityDetailsHistory: (payload) => dispatch(getCampiagnActivityDetailsHistory(payload)),
  getCampaignActivityById: (payload) => dispatch(getCampaignActivityById(payload)),
});
export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(ActivityDetails));

