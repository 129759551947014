
import { isAlphabetOnly, isNumberOnly, isValidMobileNumber } from "../../../../utils/validators";
import { datedata } from "../../../../utils/utils";

export const resetField = (formFields) => {
  for (const [index] of formFields.entries()) {
    if (index === 0) {
      continue;
    }
    formFields[index].hide = true;
  }
  return formFields;
};
// function datedata(option) {
//   const dateFormat = 'YYYY-MM-DD';
//   switch (option) {
//     case "Today":   
//       return `${moment(new Date()).format(dateFormat)},${moment(new Date()).format(dateFormat)}`
//     case "Yesterday":
//       return `${moment().subtract(1, 'days').format(dateFormat)},${moment().subtract(1, 'days').format(dateFormat)}`
    
//     case "thismonth":
//       const startOfMonth = moment().startOf("month").format(dateFormat);
//       const endOfMonth = moment().endOf("month").format(dateFormat);
//       return `${startOfMonth},${endOfMonth}`;
//     case 'thisweek':
//       const startDate = moment().startOf('week').format(dateFormat);
//       const endDate = moment().endOf('week').format(dateFormat);
//       return `${startDate},${endDate}`;
//     case "7days":
//       const daybefor = moment().subtract(7, "days").format(dateFormat);
//       const today = moment().subtract(1, 'days').format(dateFormat);
//       return `${daybefor},${today}`;
//     case "15days":
//       const last15day = moment().subtract(15, "days").format(dateFormat);
//       const last1day = moment().subtract(1, 'days').format(dateFormat);
//       return `${last15day},${last1day}`;
//     case "30days":
//       const befornoofday = moment().subtract(30, "days").format(dateFormat);
//       const todaydate = moment().subtract(1, 'days').format(dateFormat);
//       return `${befornoofday},${todaydate}`;
//     case "lastmonth":
//       const lastmonthfirstday = moment()
//         .subtract(1, "month")
//         .startOf("month")
//         .format(dateFormat);
//       const lastmonthlastday = moment()
//         .subtract(1, "month")
//         .endOf("month")
//         .format(dateFormat);
//       return `${lastmonthfirstday},${lastmonthlastday}`;
//     default:
//       return `${null},${null}`
//   }
// }
export const LEAD_DATE = [
  { key: "Select", label: "Select", value: "" },  
  { "key": "today", "label": "Today", "value": datedata('Today') },
  { "key": "yesterday", "label": "Yesterday", "value": datedata('Yesterday') },
  { "key": "thisweek", "label": "This Week", "value": datedata('thisweek') },
  { key: "7days", label: "Last 7 Days", value: datedata("7days") },
  { key: "30", label: "30 Days", value: datedata("30days") },
  { key: "thismonth", label: "This Month", value: datedata("thismonth") },
  { key: "duration", label: "Custom", value: "duration" }
];
export const campaignLeadFilterForm = [
  {
    colmd: "12",
    key: "userType",
    label: "User Type",
    hide: true,
    type: "select",
    id: "userType",
    name: "userType",
    options: [
    ],
    validators: [{ "name": "required", "value": false }]
  },
  {
    colmd: "12",
    key: "teamMember",
    label: "Team Member",
    hide: true,
    type: "select",
    id: "teamMember",
    name: "teamMember",
    options: [
    ],
    validators: [{ "name": "required", "value": false }]
  },
  {
    colmd: "12",
    key: "UserName",
    type: "text",
    id: "UserName",
    label: "User Name / Employee Code",
    validators: [],
  },
  {
    colmd: "12",
    key: "LeadName",
    type: "text",
    id: "LeadName",
    label: "Lead Name",
    validators: [{ "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" },
    { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }],
  },
  {
    key: "CreatedDateTime",
    hide: false,
    type: "select",
    disabled: false,
    id: "CreatedDateTime",
    label: "Created Date",
    validators: [],
    options: [...LEAD_DATE]
  },
  {
    key: "CreatedDateStartTIme",
    label: "From Date",
    type: "date",
    id: "CreatedDateStartTIme",
    hide: true,
    defaultValue: "",
    format: "dd/MM/yyyy",
    validators: []
  },
  {
    key: "CreatedDateEndTIme",
    label: "To Date",
    type: "date",
    id: "CreatedDateEndTIme",
    hide: true,
    defaultValue: "",
    format: "dd/MM/yyyy",
    validators: []
  },
  {
    key: "campaigncurrentStatus",
    type: "autocompleteMultiselect",
    disabled: false,
    id: "currentStatus",
    label: "Current Status",
    validators: [],
    options: [],
  },
  {
    key: "MeetingDate",
    label: "Meeting Date",
    type: "select",
    id: "MeetingDate",
    disabled: false,
    // name:"lastdate",
    options: [...LEAD_DATE],
    validators: [],
  },
  {
    key: "fromdate",
    label: "From Date",
    type: "date",
    id: "fromdate",
    hide: true,
    defaultValue: "",
    format: "dd/MM/yyyy",
    validators: []
  },
  {
    key: "todate",
    label: "To Date",
    type: "date",
    id: "todate",
    hide: true,
    defaultValue: "",
    format: "dd/MM/yyyy",
    validators: []
  },
  {
    key: "activityID",
    type: "autocomplete",
    disabled: false,
    id: "activityID",
    label: "Activity ID",
    validators: [],
    options: [],
  },
   {

    key: "branchNameCode",
    type: "autocomplete",
    disabled: false,
    id: "branchNameCode",
    label: "Select Branch",
    validators: [],
    options: [],
    hide:false
},
  {
    key: "product",
    type: "autocomplete",
    disabled: false, 
    id: "product",
    label: "Product",
    validators: [],
    options: [],
  },
  {
    colmd: "12",
    key: "MobileNo",
    type: "text",
    id: "MobileNo",
    label: "Mobile Number",
    validators: [],
  },
  {
    colmd: "12",
    key: "CampaignName",
    type: "select",
    id: "CampaignName",
    label: "Campaign",
    hide: true,
    validators: [],
    options:[]
  }
];


export const customValidators = [
  {
    key: 'LeadName', // field name to which the validation should be applied
    validator: isAlphabetOnly, // function to be called
    message: 'Please enter alphabets only.' // message to show
  },
  {
    key: 'MobileNo',
    validator: isNumberOnly,
    message: 'Please enter numbers only'
  },
  {
    key: 'MobileNo',
    validator: isValidMobileNumber,
    message: 'Please enter a valid 10 digit mobile number'
  }
];
