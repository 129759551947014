let MODE = 'MODE';

const initialState = {
    profileInfo: {
        userId: 0,
        email: '',
        roleId : '',
        rolename: '',
        userType: '',
        firstName: '',
        lastName: '',
        agentCode: '',
        employeeCode: '',
        lastLoginDatetime: '',
        channelid:1,
        lastSeen:'',
        reportingMgrfirstname:'',
        reportingMgrlastname:''

    },
    forgotPasswordStep: 1,
}

function userReducer(state=initialState, action){
    switch (action.type){
        case 'PROFILE_INFO':
            return{
                ...state,
                profileInfo: action.payload
            };
        case 'FORGOT_PASSWORD_STEP':
            return{
                ...state,
                forgotPasswordStep: action.payload
            };
        default:
            return state;
    }
}

export default userReducer;