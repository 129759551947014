import Dynamicform from 'f1-dynamic-form-react';
import React, { Component } from 'react'
import Loader from '../../../../components/loader/loader';
import MessageBar from '../../../../components/messageBar/messageBar';
import Footer from '../../../../layouts/footer/footer';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux"
import {
    getCallBackReasonList, getDroppedReasonList,
    getFollowupReasonList, getLeadListById, getRescheduleReasonList,
    getTeamList, getVehicleTypeList, ModeofTransportList, UpdateLeadForm, validateMeetingDateStatus, validateIsCheckin,
    UpdatePersistancyLeadForm, UpdateCampaignLeadForm,
    getPersistancyLeadListById, validateMeetingDateStatusPersistancy, getCampaginLeadListById
} from '../../../../redux-reducer/reducer';
import { activateLoader, deActivateLoader, uploadFileToBlob, activateErrorMessageBar } from '../../../../redux/actions';
import { customValidators, getForm, getFormTitle, handleDropdownSelection } from './updateLeadFormAgency';
import {
    CALLBACK, checkInConfig, CONFIGS, DROPPED,
    FOLLOWUP, FORMSTATUSID, FOUR_WHEELER, INTRESTED,
    MEETINGSCHEDULE, PUBLIC_TRANSPORT, REASSIGN, RESCHEDULE,
    ROLE_BDM, SELF_OWNED_TRANSPORT, TWO_WHEELER,
    PREMIUMCOLLECTED, LEAD, PERSISTANCY, CAMPAIGNLEAD, THIS_IS_REQUIRED, CONVERTED,
} from '../../../../utils/config';
import { LEADS_PATH, PERSISTANCY_LEAD_PATH, CAMPAIGN_LEAD_PATH } from '../../../../route/routePath';
import moment from 'moment';
import Breadcrumb from '../../../../components/breadcrumb/breadcrumb';
import { setBreadcrumbs } from "../../../../redux/actions/common"
import "./updateLead.scss"
import { getLeadOffline, updateLeadOffline } from '../../../../offlineManager/leadOfflineManager';
// import { format } from 'date-fns';
import { checkDateIsValid } from "../../../../utils/validators";
import { dateTimeFormat, dateTimeFormatWithT } from '../../../../utils/utils';
const _formatDate = "YYYY-MM-DDTHH:mm";
const createformat = "YYYY-MM-DD HH:mm:ss";
class updateLeadAgency extends Component {
    constructor(props) {
        super(props);
        this.id = this.props.match.params.id;
        this.leadType = this.props.match.params.leadType
        if (this.props.match.params.id) {
            if (this.leadType === LEAD) {
                this.props.getLeadListById(this.id)
            } else if (this.leadType === PERSISTANCY) {
                this.props.getPersistancyLeadListById(this.id);
            } else {
                this.props.getCampaginLeadListById(this.id);
            }
        }
        this.userData = JSON.parse(localStorage.getItem('persist:root'));
        this.userId = JSON.parse(this.userData?.userReducer)?.profileInfo?.userId;
        this.employeeCode = JSON.parse(this.userData.userReducer).profileInfo.employeeCode;

        this.formBuilder = this.props.match.params.updateAction
        this.channelId = JSON.parse(this.userData.userReducer).profileInfo.channelid;
        this.dyFormRef = React.createRef();
        this.state = {
            defaultFormVal: {
                id: 1,
                MeetingStartDateTime: moment(new Date()).format(_formatDate),
                MeetingEndDateTime: moment(new Date()).add(30, "m")
                    .format(_formatDate),
                IsClaimRequired: false
            },
            autoCompleteItems: {
                AssignedTo: []
            },
            current: {
                id: 1
            },
            formData: getForm(this.formBuilder),
            extErrors: {},
            uploadedFile: '',
            uploadedDGHFile: '',
            startMeetingDate: moment(new Date()).format(_formatDate),
            endMeetingDate: moment(new Date()).add(30, "m").format(_formatDate),
            leadData: null,
            TravelExpense: '',
            isSubmitbuttonDisable: false,
        }
    }

    loadBreadCrumbs = () => {
        let label;
        if (this.leadType === LEAD) {
            label = "Lead";
        } else if (this.leadType === PERSISTANCY) {
            label = "Persistency Lead";
        } else {
            label = "Campaign Lead";
        }
        const breadcrumbs = [
            { name: label, url: `/app/${this.leadType}/all` },
            { name: getFormTitle(this.formBuilder), url: '#' },
        ]
        this.props.setBreadcrumbs(breadcrumbs);
    }

    getDropdownData = (optionsArr, key, label, value) => {
        let arr = [];
        if (optionsArr && optionsArr.length > 0) {
            arr = optionsArr?.map((option) => {
                return { key: option[key], label: option[label], value: option[value] }
            })
        }
        arr.unshift({ "key": "select", "label": "select", "value": "" })
        return arr;
    }

    renderRadioIcons = (radioOption) => {
        let icon = ""
        if (radioOption.key === PUBLIC_TRANSPORT) {
            icon = <DirectionsBusIcon></DirectionsBusIcon>
        }
        else if (radioOption.key === SELF_OWNED_TRANSPORT) {
            icon = <TwoWheelerIcon></TwoWheelerIcon>
        }
        else if (radioOption.key === TWO_WHEELER) {
            icon = <TwoWheelerIcon></TwoWheelerIcon>
        }
        else if (radioOption.key === FOUR_WHEELER) {
            icon = <DirectionsCarIcon></DirectionsCarIcon>
        }
        return icon
    }


    getRadioData = (optionsArr, key, label, value) => {
        let arr = [];
        if (optionsArr && optionsArr.length > 0) {
            arr = optionsArr.map((option) => {
                return { key: option[key], label: option[label], value: option[value] }
            })
        }
        return arr;
    }
    componentWillMount() {
        this.setState({
            autoCompleteItems: this.props.updateLeadData?.AssignedTo
        })
    }


    initFormData = (formFields, actionType, role) => {
        const formData = [...formFields]
        const { getLeadData, getPersistancyLeadData, getCampaginLeadData } = this.props.getLeadData;
        const { CallBackReason, FollowupReason, droppedreason, RescheduleReason,
            PersistencyRescheduleReason, PersistencyCallBackReason, PaymentType, AgencyRecruitReason } = this.props.masterDataState //PersistencyDroppedReason, 
        const { meeting_value} = this.props.updateLeadData; //dropped_value
        switch (actionType) {
            case CALLBACK:
                this.props.getCallBackReasonList();
                if (this.leadType === PERSISTANCY) {
                    formData[2].options = PersistencyCallBackReason
                } else {
                    formData[2].options = CallBackReason
                }
                break;
            case RESCHEDULE:
                this.props.getRescheduleReasonList();
                if (this.leadType === PERSISTANCY) {
                    formData[3].options = PersistencyRescheduleReason
                } else {
                    formData[3].options = RescheduleReason
                }
                formData[6].options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME]
                // if (role !== ROLE_BDM) {
                    formData[7].options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME];
                // }
                const meeting = new Object();
                const dateformat = "YYYY-MM-DD HH:mm a";
                if (this.leadType === LEAD) {
                    meeting.MeetingStartDateTime = moment(getLeadData.MeetingStartDateTime).format(dateformat);
                    meeting.MeetingEndDateTime = moment(getLeadData.MeetingEndDateTime).format(dateformat);
                } else if (this.leadType === CAMPAIGNLEAD) {
                    meeting.MeetingStartDateTime = moment(getCampaginLeadData.MeetingStartDateTime).format(dateformat);
                    meeting.MeetingEndDateTime = moment(getCampaginLeadData.MeetingEndDateTime).format(dateformat);
                } else {
                    meeting.MeetingDateTime = moment(getPersistancyLeadData.MeetingDateTime).format(dateformat);
                }
                this.setState({
                    defaultFormVal: meeting
                })
                formData[6].hide = true;
                formData[7].hide = true;
                formData[8].hide = true;
                formData[9].hide = true;
                break;
            case DROPPED:
                this.props.getDroppedReasonList()
                const businessReason = ['', '1', '2', '3', '4', '7']
                const recruitmentReason = [8,9]
                const recruitDroppedList = AgencyRecruitReason?.filter((val)=>{
                    return recruitmentReason.includes(val.DroppedReasonID)
                })
                const bussinessDroppedList = droppedreason?.filter((val) => {
                        return businessReason.includes(val.value)
                })
                formData[0].options = getLeadData.IsRecruitmentLead === 1 ? this.getDropdownData(recruitDroppedList, 'DroppedReasonID', 'DroppedReasonName', 'DroppedReasonID') : bussinessDroppedList
                formData[3].options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME]
                // if (role !== ROLE_BDM) {
                    formData.find(item=>item.key==="VehicleTypeID").options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME];
                // }
                formData[3].hide = true;
                formData[4].hide = true;
                formData[5].hide = true;
                formData[6].hide = true;
                break;
            case CONVERTED:
                formData.find(item=>item.key==="TransportModeID").options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME]
                if (role !== ROLE_BDM) {
                    formData.find(item=>item.key==="VehicleTypeID").options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME];
                }
                formData[3].hide = true;
                formData[4].hide = true;
                formData[5].hide = true;
                formData[6].hide = true;
                break;
            case INTRESTED:
                formData[2].options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME]
                if (role !== ROLE_BDM) {
                    formData[3].options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME];
                }
                formData[2].hide = true;
                formData[3].hide = true;
                formData[4].hide = true;
                formData[5].hide = true;
                break;
            case MEETINGSCHEDULE:
                formData[0].options = this.getDropdownData(meeting_value, 'DroppedReasonID', 'DroppedReasonName', 'DroppedReasonID');
                break;
            case FOLLOWUP:
                formData[0].options = FollowupReason;
                formData[5].options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME]
                if (role !== ROLE_BDM) {
                    formData[6].options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME];
                }
                formData[5].hide = true;
                formData[6].hide = true;
                formData[7].hide = true;
                formData[8].hide = true;
                break;
            case PREMIUMCOLLECTED:
                formData[0].options = PaymentType;
                break;
            case REASSIGN:
                this.props.getTeamList(this.userId)
                break;
            default:
                break;

        }
        return formData
    }
    fetchLeadOffline = () => {
        const leadId = this.props.match.params.id;
        getLeadOffline(leadId).then((res) => {
            if (res) {
                this.setState({ leadData: res });
            }
        })
    };
    componentDidMount() {
        this.props.deActivateLoader();
        this.loadBreadCrumbs();
        const formData = this.initFormData(this.state.formData, this.formBuilder, this.props.userState.profileInfo.roleId)
        this.setState({ formData: formData });
        this.fetchLeadOffline();
    }


    reset = (model) => {
        for (const [key] of Object.entries(model)) {
            model[key] = ''
        }
        if (this.leadType === LEAD) {
            this.props.history.push(LEADS_PATH)
        }
        if (this.leadType === PERSISTANCY) {
            this.props.history.push(PERSISTANCY_LEAD_PATH)
        }
        if (this.leadType === CAMPAIGNLEAD) {
            this.props.history.push(CAMPAIGN_LEAD_PATH)
        }
        return true;
    }
    onSubmitHandler = (model) => {
        if (!model.IsClaimRequired || model.TransportModeID === "2") {
            delete model.errors['TravelExpense'];
        }
        if ((!model.isValidForm && Object.keys(model.errors).length > 0 ) || Object.keys(model.errors).length > 0 || Object.keys(this.state.extErrors).length > 0) {
                return false;
        }
        let formData;
        if (this.leadType === LEAD) {
            formData = navigator.onLine ? { ...this.props.getLeadData.getLeadData } : { ...this.state.leadData }
        }
        if (this.leadType === PERSISTANCY) {
            formData = navigator.onLine ? { ...this.props.getLeadData.getPersistancyLeadData } : { ...this.state.leadData }
        }
        if (this.leadType === CAMPAIGNLEAD) {
            formData = navigator.onLine ? { ...this.props.getLeadData.getCampaginLeadData } : { ...this.state.leadData }
        }

        formData['Remarks'] = null;

        formData["PaymentSubType"] = model.PaymentTypeID === "1" ? model.PaymentSubType : "";
        for (const key in model) {
            formData[key] = model[key];
        }
        delete formData['advanceLayoutFields'];
        delete formData['collapseStates'];
        delete formData['errors'];
        delete formData['isValidForm'];
        delete formData['Paymentname'];
        delete formData['id'];
        formData['IsCheckin'] = false;
        if(formData['IsCheckin'] && formData['IsClaimRequired']){
            this.props.activateErrorMessageBar("Lead is not updated");
            return false;
        }
        formData['IsLmsSync'] = navigator.onLine;
        formData['LmsleadlastSyncTime'] = moment(new Date()).format(createformat)
        formData['IsBacklog'] = false;
        if (formData.hasOwnProperty('UploadBill')) {
            formData["UploadBill"] = this.state.uploadedFile;
        }
        if (formData.hasOwnProperty('UploadDGHPhoto')) {
            formData["UploadDGHPhoto"] = this.state.uploadedDGHFile;
        }
        formData['LeadStatusId'] = FORMSTATUSID[this.formBuilder]
        formData['LeadStatus'] = this.formBuilder;

        formData['UserId'] = this.userId;
        formData['ExpectedPremium'] = formData['ExpectedPremium'] ? parseInt(formData['ExpectedPremium']) : null;
        formData['AssignedTo'] = this.userId;
        formData["ChannelID"] = parseInt(this.channelId);
        formData['EmployeeCode'] = this.employeeCode;
        const validateMeetingStatusForm = [RESCHEDULE, MEETINGSCHEDULE, CALLBACK, FOLLOWUP];
        if (model.IsClaimRequired) {
            formData['TransportID'] = model.TransportModeID;
            formData['TransportModeID'] = parseInt(model.TransportModeID);
            formData['TravelExpense'] = model.TravelExpense;
            formData['TransportMode'] = model.TransportModeID === "1" ? "Public Transport" : "Self Owned Transport";

        }
        if (validateMeetingStatusForm.includes(this.formBuilder)) {
            if (this.state.startMeetingDate) {
                formData['MeetingStartDateTime'] = this.state.startMeetingDate.replace("T", ' ');
            } else {
                formData["MeetingStartDateTime"] = null;
            }
            if (this.state.endMeetingDate) {
                formData['MeetingEndDateTime'] = this.state.endMeetingDate.replace("T", ' ');
            } else {
                formData["MeetingEndDateTime"] = null;
            }
        } else {
            formData["MeetingStartDateTime"] = null;
            formData["MeetingEndDateTime"] = null;
        }
        if(formData["LeadStatus"] === "CONVERTED"){
            formData['DateofJoining'] = formData['DateofJoining'].replace("T", ' ')
        }
        if (formData["LeadStatus"] === "DROPPED") {
            formData['MeetingStartDateTime'] = formData['MeetingStartDateTime'] ? moment(formData['MeetingStartDateTime']).format(dateTimeFormat) : null;
            formData['MeetingEndDateTime'] = formData['MeetingEndDateTime'] ? moment(formData['MeetingEndDateTime']).format(dateTimeFormat) : null;
        }
        if (this.leadType === PERSISTANCY && this.formBuilder === PREMIUMCOLLECTED) {
            formData['MeetingDateTime'] = formData['MeetingDateTime'] ? moment(formData['MeetingDateTime']).format(dateTimeFormat) : null;
            formData['DueDate'] = formData['DueDate'] ? moment(formData['DueDate']).format(dateTimeFormat) : null;
            formData['CreatedDate'] = formData['CreatedDate'] ? moment(formData['DueDate']).format(dateTimeFormat) : null;
            formData['FundAccountDate'] = formData['FundAccountDate'] ? moment(formData['FundAccountDate']).format(dateTimeFormat) : null;
            formData['ChequePaymentDate'] = formData['ChequePaymentDate'] ? moment(formData['ChequePaymentDate']).format(dateTimeFormat) : null;
        }
        this.props.activateLoader();
        if (this.leadType === LEAD) {
            if (validateMeetingStatusForm.includes(this.formBuilder)) {
                if (navigator.onLine) {
                    this.props.validateMeetingDateStatus({
                        startDate: this.state.startMeetingDate,
                        endDate: this.state.endMeetingDate,
                        id: this.userId,// (this.leadType === LEAD) ? this.userId : ,
                        formData: formData,
                        formBuilder: this.formBuilder,
                        history: this.props.history,
                        leadType: 1,
                    })
                    // this method will update the lead in indexDb for offline version
                    updateLeadOffline(formData);
                } else {
                    updateLeadOffline(formData).then((res) => {
                        this.props.history.push(LEADS_PATH);
                    })
                        .catch((err) => {
                            console.log(err);
                            this.props.history.push(LEADS_PATH);
                        })
                }
            } else {
                if (navigator.onLine) {
                    this.props.UpdateLeadForm({ model: formData, formBuilder: this.formBuilder, history: this.props.history })
                } else {
                    // update lead in offline
                    updateLeadOffline().then((res) => {
                        this.props.history.push(LEADS_PATH);
                    })
                        .catch((err) => {
                            console.log(err);
                            this.props.history.push(LEADS_PATH);
                        })
                }
            }
        } else {
            if (validateMeetingStatusForm.includes(this.formBuilder)) {
                if (navigator.onLine) {
                    if (validateMeetingStatusForm.includes(this.formBuilder)) {
                        this.props.validateMeetingDateStatusPersistancy({
                            startDate: this.state.startMeetingDate,
                            endDate: this.state.endMeetingDate,
                            id: this.userId,
                            formData: formData,
                            formBuilder: this.formBuilder,
                            history: this.props.history,
                            leadType: this.leadType,
                        })
                    }
                }
            } else {
                if (navigator.onLine) {
                    if (this.leadType === PERSISTANCY) {
                        this.props.UpdatePersistancyLeadForm({ model: formData, formBuilder: this.formBuilder, history: this.props.history })
                    } else {
                        this.props.UpdateCampaignLeadForm({ model: formData, formBuilder: this.formBuilder, history: this.props.history });
                    }
                }
            }
        }

        if (this.props.updateLeadData.updateLead.responsestatus === true) {
            this.reset(formData);
        }
    }

    onChangeHandler = async (event, key, model) => {
        // this will enable the submit button
        if (key === 'UploadBill') {
            this.uploadFile(event, key);
        }
        if (key === 'UploadDGHPhoto') {
            this.uploadFile(event, key);
        }

        const formData = handleDropdownSelection(event, key, this.state.formData, this.props.match.params.updateAction,
            this.props.masterDataState,
            this.props.updateLeadData,
            this.props.userState.profileInfo.roleId)
        const isExpense = formData && formData.length > 0 && formData.find((data) => data.key === "TransportModeID")
        const isVehicleType = formData && formData.length > 0 && formData.find((data) => data.key === "VehicleTypeID")
        const isTravelExpense = formData && formData.length > 0 && formData.find((data) => data.key === "TravelExpense")
        const isUploadBill = formData && formData.length > 0 && formData.find((data) => data.key === "UploadBill")
        if (key === "PaymentSubType" && event.target.value === "1") {
            formData[2].hide = false;
            formData[3].hide = true;
            formData[3].validators = [];
            formData[2].validators = [{ name: "required", value: true, validationMessage: THIS_IS_REQUIRED },];
        }
        if (key === "PaymentSubType" && event.target.value === "2") {
            formData[3].hide = false;
            formData[2].hide = true;
            formData[2].validators = [];
            formData[3].validators = [{ name: "required", value: true, validationMessage: THIS_IS_REQUIRED },];
        }
        this.setState({ formData: formData })

        switch (key) {
            case 'MeetingStartDateTime':
                this.setState({ extErrors: { MeetingEndDateTime: null, MeetingStartDateTime: null } })
                delete model.errors['MeetingEndDateTime']

                var startDate = moment(event.target.value).format(_formatDate);
                this.state.formData.map((option) => {
                    if (option.key === "MeetingEndDateTime") {
                        option.max = moment(startDate).endOf('day').format("YYYY-MM-DD kk:mm");
                        option.min = startDate;
                    }
                })

                // const startDate = event.target.value;
                this.setState({ startMeetingDate: startDate.replace("T", ' ') })
                const endMeetDate = moment(startDate).add(30, 'm').toString();
                if (!checkDateIsValid(event.target.value, moment(new Date()).format(dateTimeFormatWithT))) {
                    this.setState({
                        startMeetingDate: startDate.replace("T", ' '), extErrors: { ...this.state.extErrors, MeetingStartDateTime: "please select future value" }
                    })
                } else {
                    this.setState({
                        defaultFormVal: {
                            MeetingEndDateTime: moment(endMeetDate).format(dateTimeFormatWithT)
                        },
                        extErrors: {}
                    })
                    this.setState({ endMeetingDate: moment(endMeetDate).format(dateTimeFormatWithT).replace("T", ' ') });
                }

                break;
            case 'MeetingEndDateTime':
                const endDate = event.target.value;
                this.setState({ endMeetingDate: endDate.replace("T", ' ') })
                this.setState({ ...this.state, extErrors: {} })
                if (!checkDateIsValid(event.target.value, moment(new Date()).format(dateTimeFormatWithT))) {
                    this.setState({
                        defaultFormVal: {
                            MeetingEndDateTime: moment(endDate).format(dateTimeFormatWithT)
                        },
                        extErrors: { ...this.state.extErrors, MeetingEndDateTime: "please select future value" }
                    })
                } else {
                    this.setState({
                        defaultFormVal: {
                            MeetingEndDateTime: moment(endDate).format(dateTimeFormatWithT)
                        }, extErrors: {},
                        endMeetingDate: moment(endDate).format(dateTimeFormatWithT),
                    })
                }

                break;
            case 'IsClaimRequired':
                if (event === true) {
                    this.setState({
                        defaultFormVal: {
                            IsClaimRequired: true
                        }
                    })
                    isExpense.hide = false;
                    let checkIn;
                    if (this.leadType === PERSISTANCY) {
                        checkIn = await this.props.validateIsCheckin({ checkinFor: PERSISTANCY, Id: this.props.match.params.id })
                    }
                    if (this.leadType === LEAD) {
                        checkIn = await this.props.validateIsCheckin({ checkinFor: LEAD, Id: this.props.match.params.id })
                    }
                    if (this.leadType === CAMPAIGNLEAD) {
                        checkIn = await this.props.validateIsCheckin({ checkinFor: "campaignlead", Id: this.props.match.params.id })
                    }
                    const { data } = checkIn

                    if (data) {
                        this.setState({
                            defaultFormVal: {
                                IsClaimRequired: data
                            }
                        })
                        // isExpense.hide = false
                        // isVehicleType.hide = false
                        // isTravelExpense.hide = false
                        // isUploadBill.hide = false

                        const errorElement = document.querySelectorAll('input[type=radio]');
                        errorElement[0].checked = false;
                        errorElement[1].checked = false;

                        const errorElement1 = document.getElementsByClassName(`activeRadio`);
                        if (errorElement1.length > 0) {
                            errorElement1[0].classList.remove('activeRadio');
                        }

                        isExpense.validators = [
                            {
                                "name": "required",
                                "value": true,
                                "validationMessage": THIS_IS_REQUIRED
                            }

                        ]
                    } else {
                        this.setState({
                            defaultFormVal: {
                                IsClaimRequired: false
                            }
                        })
                        isExpense.hide = true
                        isVehicleType.hide = true
                        isTravelExpense.hide = true
                        isTravelExpense.validators = []
                        isUploadBill.hide = true
                        this.props.activateErrorMessageBar("Please Check-In first to claim the expenses")
                        delete model.errors['UploadBill'];
                        delete model.errors['TransportModeID'];
                        delete model.errors['TravelExpense'];
                    }
                } else {
                    this.setState({
                        defaultFormVal: {
                            IsClaimRequired: false
                        }
                    })
                    delete model.errors['UploadBill'];
                    delete model.errors['TransportModeID'];
                    delete model.errors['TravelExpense'];
                    isExpense.hide = true
                    isVehicleType.hide = true
                    isTravelExpense.hide = true
                    isUploadBill.hide = true
                    
                }

                break;
            case "TravelExpense":
                const expense = event.target.value;
                if (expense < 0) {
                    this.setState({
                        extErrors: { ...this.state.extErrors, TravelExpense: "Travel Expense should be greater than 0" }
                    })
                } else if (expense.length > 0 && expense.length > 4) {
                    this.setState({
                        extErrors: { ...this.state.extErrors, TravelExpense: 'Maximum 4 digits allowed' }
                    })
                } else {
                    this.setState({
                        extErrors: {}
                    })
                    delete model.errors['TravelExpense'];
                }
                break;
            default:
                break;
        }
    }

    uploadFile = async (files, key) => {
        if (navigator.onLine) {
            const fileRes = await this.props.uploadFileToBlob({ 'file': files[0] });
            const { downloadUrl } = fileRes.data
            if (fileRes.status === 200) {
                if (key === "UploadDGHPhoto") {
                    this.setState({ uploadedDGHFile: downloadUrl })
                } else {
                    this.setState({ uploadedFile: downloadUrl })
                }
            }
        } else {
            this.props.activateErrorMessageBar(
                "Cannot upload files in offline mode!"
            );
        }
    }


    removeFile = (e, file, index, key) => {
        this.dyFormRef.removeFile(e, file, index, key);
        this.setState({ uploadedFile: '', uploadedDGHFile: '' })
    }

    renderUploadedFilesItem = (file, index, key) => {
        return (
            <li style={{ borderColor: '#ccc' }} key={index}>
                <span className="file-name">{file.name}</span>
                <span className="action" onClick={(e) => this.removeFile(e, file, index, key)}>
                    <i className="fa fa-trash"></i>
                </span>
            </li>
        );
    }

    onCancelHandler = () => {
        this.props.history.push(`/app/${this.leadType}/detail/${this.id}`);
    }

    onAutocompleteSelectHandler = (key, value, item) => {
    }

    onCheckinHandler = (position, address) => { }

    render() {
        const { getLeadData, getPersistancyLeadData, getCampaginLeadData } = this.props.getLeadData;
        let label;
        if (this.leadType === LEAD) {
            label = `${getLeadData.Salutation} ${getLeadData.FirstName} ${getLeadData.LastName}`;
        }
        if (this.leadType === PERSISTANCY) {
            label = getPersistancyLeadData.CustomerName;
        }
        if (this.leadType === CAMPAIGNLEAD) {
            label = `${getCampaginLeadData.SalutationName} ${getCampaginLeadData.FirstName} ${getCampaginLeadData.LastName}`;
        }

        return (
            <div className="mainContainer updateLead">
                <MessageBar />
                <Breadcrumb />
                <span className="formInfo">{label}</span>
                <div className="formBox">
                    <Dynamicform
                        key={this.state?.current?.id}
                        onRef={ref => this.dyFormRef = ref}
                        className="updateTypeDiv"
                        buttonSubmit="Update"
                        defaultValues={this.state.defaultFormVal}
                        model={this.state.formData}
                        onSubmit={(model) => {
                            this.onSubmitHandler(model);
                        }}
                        disableSubmitOnInValid={this.state.isSubmitbuttonDisable}
                        design="bootrstap"
                        materialOutline={false}
                        onChange={(event, key, model) => this.onChangeHandler(event, key, model)}
                        buttonNormalClick={(id, key, obj) => console.log(id, key, obj)}
                        showAstersikOnRequiredFields={true}
                        fileUploaderFields={['UploadBill', 'UploadDGHPhoto']}
                        renderUploadedFilesItem={this.renderUploadedFilesItem}
                        onAutocompleteSelect={this.onAutocompleteSelectHandler}
                        autoCompleteItems={this.state.autoCompleteItems}
                        extErrors={this.state.extErrors}
                        checkInConfig={checkInConfig}
                        onCheckinHandler={this.onCheckinHandler}
                        extaraActionFormButton={<>
                            <button className="btn cancelBtn" key="cancel" id="cancel" type="button" onClick={this.onCancelHandler}>{('Cancel')}</button>
                        </>}
                        renderRadioIcons={(option) => this.renderRadioIcons(option)}
                        customValidators={customValidators}
                    />
                </div>
                <Footer></Footer>
                {this.props.masterDataState.isActiveLoader && <Loader />}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    updateLeadData: state.updateLeadReducer,
    getLeadData: state.leadFormReducer,
    masterDataState: state.commonReducer,
    userState: state.userReducer
})

const mapDispatchToProps = dispatch => ({
    getPersistancyLeadListById: (payload) => dispatch(getPersistancyLeadListById(payload)),
    getLeadListById: (payload) => dispatch(getLeadListById(payload)),
    activateLoader: (payload) => dispatch(activateLoader(payload)),
    getCallBackReasonList: (payload) => dispatch(getCallBackReasonList(payload)),
    getRescheduleReasonList: (payload) => dispatch(getRescheduleReasonList(payload)),
    ModeofTransportList: (payload) => dispatch(ModeofTransportList(payload)),
    getVehicleTypeList: (payload) => dispatch(getVehicleTypeList(payload)),
    getFollowupReasonList: (payload) => dispatch(getFollowupReasonList(payload)),
    UpdateLeadForm: (payload) => dispatch(UpdateLeadForm(payload)),
    validateMeetingDateStatus: (payload) => dispatch(validateMeetingDateStatus(payload)),
    validateMeetingDateStatusPersistancy: (payload) => dispatch(validateMeetingDateStatusPersistancy(payload)),
    getTeamList: (props) => dispatch(getTeamList(props)),
    deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
    setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
    getDroppedReasonList: (payload) => dispatch(getDroppedReasonList(payload)),
    validateIsCheckin: (payload) => dispatch(validateIsCheckin(payload)),
    activateErrorMessageBar: (payload) => dispatch(activateErrorMessageBar(payload)),
    UpdatePersistancyLeadForm: (payload) => dispatch(UpdatePersistancyLeadForm(payload)),
    uploadFileToBlob: (payload) => dispatch(uploadFileToBlob(payload)),
    getCampaginLeadListById: (payload) => dispatch(getCampaginLeadListById(payload)),
    UpdateCampaignLeadForm: (payload) => dispatch(UpdateCampaignLeadForm(payload))
})
export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(updateLeadAgency));

