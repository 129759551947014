import React, { useEffect, useState } from 'react';
import "font-awesome/css/font-awesome.min.css";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { getActivitiesOverviewCount, getCampaignActivitiesOverviewCountGraphData } from '../../../redux/actions/dashboardAction';
import { setFilterBy, setActivityListLanding } from '../../../redux/actions/updateActivityAction';
import { withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import BarChart from "../../../components/barchart/barChart";
import { activateLoader, deActivateLoader } from "../../../redux/actions/common";

import { levelbyUser } from "../../../utils/config";
const CampaignActivityOverviewCard = ({
  classes,
  getCampaignActivitiesOverviewCountGraphData: getActivityCountsGraphData,
  setFilterBy: SetFilter, selectedView, selectedBDM, roleId,
  setActivityListLanding: SetActivityLanding,
  selectedSecondLevelSubordinate, selectedThirdLevelSubordinate, selectedFourthLevelSubordinate }) => {
  const commonState = useSelector(state => state);
  const userState = commonState.userReducer;
  const secondLevelSubordinateList = commonState.leadFormReducer?.secondLevelSubordinateList?.UsersDetails;
  const thirdLevelSubordinateList = commonState.leadFormReducer?.thirdLevelSubordinateList?.UsersDetails;
  const fourthLevelSubordinateList = commonState.leadFormReducer?.fourthLevelSubordinateList?.UsersDetails;

  const bdmList = commonState.leadFormReducer.BDMList.UsersDetails;
  const ABHListForZBH = commonState.leadFormReducer.ABHListForZBH;

  const [UserId] = useState(userState.profileInfo.userId)
  const history = useHistory()
  const [channelid] = useState(userState.profileInfo.channelid);
  const { viewForStatus, GetBDMByABH } = useSelector(state => state.commonReducer)
  const [selectedDays, setSelectedDays] = useState("Today");
  const [counts, setCounts] = useState({})
  const [toggleChart, setToggleChart] = useState(false)
  const [toggleCard, setToggleCard] = useState(true);

  const [chartDataSet, setChartData] = useState({
    labels: [],
    datasets: []
  })
  const campignactivityLst = '/app/campaignactivity/all';
  const All = "all";
  const getActivityDatabyDays = (e) => {
    getDashboardActivityGraphData(e.target.value)
    setSelectedDays(e.target.value)
    const dayfilter = {
      page: (e.target.value === "Today") ? 0 : 2,
    }
    ActivityPageLanding(dayfilter);
  }

  useEffect(() => {
    if (toggleCard) {
      getDashboardActivityGraphData(selectedDays)
    }
    const dayfilter = {
      page: (selectedDays === "Today") ? 0 : 2,
    }
    ActivityPageLanding(dayfilter);
  }, [selectedBDM, selectedView, selectedSecondLevelSubordinate, selectedThirdLevelSubordinate, selectedFourthLevelSubordinate])

  const onCardIconClick = (value) => {
    if (!value) {
      handleRefresh();
    }
    setToggleCard(!toggleCard)
  }

  const handleRefresh = () => {
    getDashboardActivityGraphData(selectedDays)
  }
  const getDashboardActivityGraphData = async (days) => {
    let payload = {}
    if (roleId === "NH" || roleId === "RBH" || roleId === "ZBH") {
      if (selectedBDM === 0) {
        payload = {
          userId: UserId,
          roleName: roleId,
          viewFor: viewForStatus ? viewForStatus : 'SELF',
          subordinateUserIds: selectedBDM === 0 ? "all" : selectedBDM,
          viewByDate: days,
        };
      } else if (selectedFourthLevelSubordinate) {
        const selectedBDMDetails = fourthLevelSubordinateList?.filter((bdm) => bdm.UserID === selectedFourthLevelSubordinate);
        payload = {
          userId: UserId,
          roleName: roleId,
          viewFor: viewForStatus,
          subordinateUserIds: selectedBDMDetails[0]?.UserID?.toString(),
          viewByDate: days,
        };
      } else if (selectedThirdLevelSubordinate) {
        const selectedABHDetails = thirdLevelSubordinateList?.filter((abh) => abh.UserID === selectedThirdLevelSubordinate);
        payload = {
          userId: selectedABHDetails[0]?.UserID,
          roleName: selectedABHDetails[0]?.Level,
          viewFor: levelbyUser(channelid, roleId, "Four"),
          subordinateUserIds: All,
          viewByDate: days,
        };
      } else if (selectedSecondLevelSubordinate && selectedSecondLevelSubordinate !== "ALL") {
        if (channelid === 2 && roleId === "NH") {
          const selectedBDMDetails = ABHListForZBH?.filter((bdm) => bdm.userID === selectedSecondLevelSubordinate);

          payload = {
            userId: selectedBDMDetails[0]?.userID,
            roleName: selectedBDMDetails[0]?.level,
            viewFor: levelbyUser(channelid, roleId, "Three"),
            subordinateUserIds: All,
            viewByDate: days,
          };
        } else {
          const selectedBDMDetails = secondLevelSubordinateList?.filter((bdm) => bdm.UserID === selectedSecondLevelSubordinate);
          payload = {
            userId: selectedBDMDetails[0]?.UserID,
            roleName: selectedBDMDetails[0].Level,
            viewFor: levelbyUser(channelid, roleId, "Three"),
            subordinateUserIds: All,
            viewByDate: days,
          };
        }

      } else if (selectedSecondLevelSubordinate === "ALL") {
        if (channelid === 2 && roleId === "ZBH") {
          const selectedBDMDetails = ABHListForZBH?.filter((bdm) => bdm.userID === selectedBDM);
          payload = {
            userId: selectedBDM,
            roleName: selectedBDMDetails[0].level,
            viewFor: viewForStatus,
            subordinateUserIds: "ALL",
            viewByDate: days,
          };
        } else {
          const selectedBDMDetails = bdmList.filter((bdm) => bdm.UserID === selectedBDM);

          payload = {
            userId: selectedBDM,
            roleName: selectedBDMDetails[0].Level,
            viewFor: viewForStatus,
            subordinateUserIds: "ALL",
            viewByDate: days,
          };
        }
      } else {
        if (channelid === 2 && roleId === "ZBH") {
          const selectedBDMDetails = ABHListForZBH?.filter((bdm) => bdm.userID === selectedBDM);
          payload = {
            userId: selectedBDMDetails[0]?.userID,
            roleName: selectedBDMDetails[0].level,
            viewFor: levelbyUser(channelid, roleId, "Second"),
            subordinateUserIds: All,
            viewByDate: days,
          };
        } else {
          const selectedBDMDetails = bdmList.filter((bdm) => bdm.UserID === selectedBDM);
          payload = {
            userId: selectedBDM,
            roleName: selectedBDMDetails[0].Level,
            viewFor: levelbyUser(channelid, roleId, "Second"),
            subordinateUserIds: All,
            viewByDate: days
          };
        }


      }
    } else {
      if (selectedBDM === 0) {
        payload = {
          userId: UserId,
          roleName: roleId,
          viewFor: selectedView === 'ALL' ? 'BDM' : selectedView,
          subordinateUserIds: GetBDMByABH,
          viewByDate: days
        };
      } else {
        payload = {
          userId: UserId,
          roleName: roleId,
          viewFor: 'BDM',
          subordinateUserIds: selectedBDM?.toString(),
          viewByDate: days,
        };
      }
    }
    const response = await getActivityCountsGraphData(payload)
    setCounts(response.data[0])
    const chartData = {
      labels: [], datasets: [{
        "label": "Activity Planned",
        "backgroundColor": "#1B447D",
        "data": []
      },
      {
        "label": "Activity Completed",
        "backgroundColor": "#DD7528",
        "data": []
      }]
    }


    if (days === "Today") {
      if (response?.data[0] && response?.data[0].DateDetails.length > 0 && response?.data[0].DateDetails[0]?.HourDetails?.length >= 1) {

        chartData.labels = response?.data[0].DateDetails[0].HourDetails.map(el => el.Hour);

        const res = () => {
          return response?.data[0].DateDetails[0].HourDetails.map(el => {
            return (
              chartData.datasets[0].data.push(el.ActivityPlanned),
              chartData.datasets[1].data.push(el.ActivityCompleted)
            )
          })
        }
        res()
      } else {

        setChartData(
          {
            labels: [],
            datasets: []
          }
        )

      }
    } else {
      chartData.labels = response?.data[0].DateDetails.map(el => el.CreatedDate)
      const resp = () => {
        return response?.data[0].DateDetails.map(el => {
          return (
            chartData.datasets[0].data.push(el.ActivityPlanned),
            chartData.datasets[1].data.push(el.ActivityCompleted)
          )

        })

      }
      resp()
    }
    setChartData(chartData)
  }

  const RedirectFromDashboardToActivity = async (flag) => {
    if (roleId === "NH" || roleId === "ZBH" || roleId === "RBH") {
      await SetFilter(flag)
      if (viewForStatus === "SELF") {
        history.push(campignactivityLst)
      } else {
        return false
      }
    } else {
      await SetFilter(flag)
      history.push(campignactivityLst)
    }
  }
  const ActivityPageLanding = async (dayfilter) => {
    await SetActivityLanding(dayfilter)
  }

  const onIconClick = () => {
    setToggleChart(!toggleChart)
  }

  const redirectToActivity = () => {
    if (roleId === "NH" || roleId === "ZBH" || roleId === "RBH") {
      return false
    } else {
      history.push(campignactivityLst)
    }
  }
  return <Paper className={classes.paper2} sx={{ mb: 2 }}>
    <Grid className="activityOverviewSec" container>
      {/* <ZoomInIcon className="zoomIcon" /> */}
      <Grid item xs={12} md={12} >
        <Grid container>
          <Grid item xs={9}>
            <Typography variant="h5" gutterBottom>
              <span onClick={() => onCardIconClick(toggleCard)}>Campaign Activity Overview {toggleCard ? <RemoveIcon /> : <AddIcon />}</span>
            </Typography>
            <p className="viewAll" onClick={redirectToActivity}>View All</p>
          </Grid>
          {
            toggleCard ?
              <Grid item xs={3} textAlign="right">
                <FormControl className="selectBx">
                  <Select label="Day1" onChange={getActivityDatabyDays} value={selectedDays}>
                    <MenuItem value={"Today"}>Today</MenuItem>
                    <MenuItem value={"7 DAY"}>Last 7 Days</MenuItem>
                    <MenuItem value={"15 DAYS"}>Last 15 Days</MenuItem>
                    <MenuItem value={"MONTHLY"}>Last 30 Days</MenuItem>
                  </Select>
                </FormControl>
              </Grid> :
              ''
          }
        </Grid>
        {
          toggleCard ?
            <>
              <Grid container className="greyView">
                <Grid item xs={6} onClick={() => RedirectFromDashboardToActivity('planned')}>
                  <div className="greyViewInner">
                    <ArrowRightAltIcon className="arrowIcon" />
                    <DateRangeIcon className="navyBlueBG" />
                    <div className="clearfix"></div>
                    <div className="titleActivity">Planned Activities</div>
                    <div className="numDiv">
                      <span>{counts.ActivityPlanned}</span> / {counts.TotalActivity}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} onClick={() => RedirectFromDashboardToActivity('completed')}>
                  <div className="greyViewInner">
                    <ArrowRightAltIcon className="arrowIcon" />
                    <DateRangeIcon className="orangeBG" />
                    <div className="clearfix"></div>
                    <div className="titleActivity">Completed Activities</div>
                    <div className="numDiv">
                      <span>{counts.ActivityCompleted}</span> / {counts.TotalActivity}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h5" gutterBottom>
                    <span onClick={onIconClick}>Analytical Overview {toggleChart ? <RemoveIcon /> : <AddIcon />}</span>
                  </Typography>
                  {toggleChart && <BarChart chartData={chartDataSet} />}

                </Grid>
              </Grid>
            </>
            : ''
        }
      </Grid>
    </Grid>
  </Paper>
}

const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
})
export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, {
  getActivitiesOverviewCount, getCampaignActivitiesOverviewCountGraphData, setFilterBy, activateLoader, deActivateLoader, setActivityListLanding
})(CampaignActivityOverviewCard))

