import { useEffect } from "react";
import "font-awesome/css/font-awesome.min.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import PlannedHistory from "./plannedHistory";
import CancelledHistory from "./cancelledHistory";
import RescheduleHistory from "./rescheduleHistory";
import CompletedHistory from "./completedHistory";
import { TimelineDot } from "@material-ui/lab";
import CheckInHistory from "../../common/checkInHistory" 
import { useSelector } from 'react-redux';
import { getOptionById } from '../helper'
import UpdateCallsHistory from "./updateCallsHistory";
const HistoryCard = (props) => {
    
    const styletimeline = {
        ':before': { content: 'none' },
    }
    useEffect(() => {
    }, [props.activityHistoryData]);

    const {classes, activityHistoryData, Item, activityTitle} = props;
    // console.log("props===========",props)
    const commonState = useSelector(state => state)
    const activityTypes = commonState.commonReducer.Activities

    return <Paper className={classes.paper2} sx={{ mb: 2 }}>
        <Grid className="activityDetailsSec" container spacing={3}>
            <Grid item xs={12} md={12} >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} >
                        <Item className="activityDetailsHead"><b>History</b></Item>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className="historyDiv" item xs={12} md={12} >
                <Grid item xs={12} md={12} >
                    <div className="accBox">
                        {activityHistoryData.length > 0 && activityHistoryData.map((history, index) => {
                            const activityOption = getOptionById(history.ActivityID ? history.ActivityID.toString() : '', activityTypes)
                            return <Accordion key={`historyAcc ${index}`}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <TimelineDot className="customDot"></TimelineDot>
                                    <div className="accordianTitle">
                                        <span className="status">{history.IsCheckindata ? "Check In" : history?.ActivityStatus}</span><br /> 
                                        <span className="date">{history.ModifiedDate ? moment(history.ModifiedDate).format('DD MMM YYYY') : ""}</span>
                                        <span className="time">{history.ModifiedDate ? moment(history.ModifiedDate).format('h:mma') : ""}</span>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Timeline>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                        </TimelineItem>
                                        {
                                            history && history.IsCheckindata ?
                                                <CheckInHistory history={history} styletimeline={styletimeline} />
                                                :
                                                <>
                                                    <PlannedHistory history={history} activityTitle={activityTitle} 
                                                    activityOption={activityOption} ></PlannedHistory>
                                                    <RescheduleHistory history={history} styletimeline={styletimeline} activityOption={activityOption} ></RescheduleHistory>
                                                    <CompletedHistory history={history} styletimeline={styletimeline} 
                                                    activityOption={activityOption}></CompletedHistory>
                                                    <CancelledHistory history={history} styletimeline={styletimeline} activityOption={activityOption} ></CancelledHistory>
                                                    <UpdateCallsHistory history={history} styletimeline={styletimeline} activityOption={activityOption} ></UpdateCallsHistory>
                                                </>
                                        }
                                    </Timeline>
                                </AccordionDetails>
                            </Accordion>
                        })}

                        {props.activityHistoryData.length > 0 ? ''
                            : <div className="noData">No history is available.</div>
                        }
                    </div>
                </Grid>
            </Grid>
        </Grid>
    </Paper>
}

export default HistoryCard;
