import React, { useState, useEffect, useRef } from "react";
import Dynamicform from "f1-dynamic-form-react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  BrocaActivityForm,
  getDropdownDataBranch,
  handleActivitySelection,
  initFormData,
  provideDetailsValidator,
} from "./broca/createActivityForm";
import {
  checkDateIsValid,
  isEmailValid,
  isPositiveNumber,
  numberStartWith6789Validator,
} from "../../../utils/validators";
import {
  activateErrorMessageBar,
  activateLoader,
  activateSuccessMessageBar,
  addDynamicData,
  deActivateLoader,
  finishDayActivityCreate,
  validateActivity,
  setBreadcrumbs,
  getPartnerDetails,
  updateDynamicData,
} from "../../../redux/actions";
import { getOptionByCode } from "./helper";
import {
  minusMinuteFromDate,
  uuidv4,
} from "../../../utils/utils";
import {
  CONFIGS,
  MUST_DO,
  PARTNER_ENGAGEMENT,
  STATUS_ACTIVITY_DRAFTED,
  STATUS_ACTIVITY_PLANNED,
} from "../../../utils/config";
import { saveActivityOffline } from "../../../offlineManager/activityOfflineManager";
import { useHistory, useParams } from "react-router-dom";
import { format } from "date-fns";
import SaveAsDraftDrawer from "./saveAsDraftDrawer";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import { getActivityDetails } from "../../../redux/actions/activityDetailsAction";

const customValidators = [
  {
    key: "ContactDetails",
    validator: numberStartWith6789Validator,
    message: "10 digit phone number starting with 6,7,8,9 only.",
  },
  {
    key: "MobileNo",
    validator: numberStartWith6789Validator,
    message: "10 digit phone number starting with 6,7,8,9 only.",
  },
  {
    key: "EmailID",
    validator: isEmailValid,
    message:
      "invalid email id with missing domain name, @, .com, gmail id etc.",
  },
  {
    key: "NoOfAttendees",
    validator: isPositiveNumber, // function to be called
    message: "No of attendees  should be greater than 0",
  },
  {
    key: "NoOfLeads",
    validator: isPositiveNumber, // function to be called
    message: "No Of Leads  should be greater than 0",
  },
  {
    key: "SecretaryEmailID",
    validator: isEmailValid,
    message:
      "invalid email id with missing domain name, @, .com, gmail id etc.",
  },
];
const createActivityTitle = "Create Activity";
const _formatDate = "YYYY-MM-DDTHH:mm";
const dateFormate = "yyyy-MM-dd";
const _url = "/app/activity/all";

const ResumeDraftedActivityBROCA = (props) => {
  let dyFormRef = useRef();
  const history = useHistory();
  const params = useParams();

  const [current, setCurrent] = useState({
    id: 1,
    MeetingStartDateTime: moment(new Date()).format(_formatDate),
    MeetingEndDateTime: moment(new Date()).add(30, "m").format(_formatDate),
    SinceWhen: format(new Date(), dateFormate),
    RetirementDate: format(new Date(), dateFormate),
    MobileNo: "",
    EmailID: "",
    SecretaryEmailID: "",
  });

  const [formDataState, setFormDataState] = useState(BrocaActivityForm);
  const [extErrors, setextErrors] = useState({});
  const [responseMessage] = useState("");
  const [isActiveMobileDraft, setisActiveMobileDraft] = useState(false);
  const [partnerDetails, setPartnerDetails] = useState([]);

  const onSubmit = async (model) => {
    if (model.ActivityID === "52") {
      if (model.EmailID === "" && model.MobileNo === "") {
        props.activateErrorMessageBar("Please enter email or contact details");
        return false;
      }
    }
    if (model.EmailID === "" || model.EmailID == null) {
      delete model.errors["EmailID"];
    }
    if (model.SecretaryEmailID === "" || model.SecretaryEmailID == null) {
      delete model.errors["SecretaryEmailID"];
    }

    if (
      !model.isValidForm ||
      Object.keys(extErrors).length > 0 ||
      Object.keys(model.errors).length > 0
    ) {
      const errorListClasess = Object.keys(model.errors);
      const errArr = [];
      const formD = formDataState;
      formD.map((el, idx) => {
        if (el.key == errorListClasess[0]) {
          errArr.push(idx + 1);
        }
      });
      const errorElement = document.getElementsByClassName(
        `${"control"}${errArr[0]}`
      )[0];
      if (errorElement) {
        errorElement.scrollIntoView({
          block: "center",
        });
      }
      if (
        (!model.isValidForm && Object.keys(model.errors).length > 0) ||
        Object.keys(model.errors).length > 0 ||
        Object.keys(extErrors).length > 0
      ) {
        props.activateErrorMessageBar("Please fill all required fields");
        return false;
      }
    }

    const formData = { ...model };
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    if (formData.hasOwnProperty("MeetingStartDateTime")) {
      formData["MeetingStartDateTime"] = formData[
        "MeetingStartDateTime"
      ].replace("T", " ");
    }
    if (formData.hasOwnProperty("MeetingEndDateTime")) {
      formData["MeetingEndDateTime"] = minusMinuteFromDate(
        formData["MeetingEndDateTime"]
      ).replace("T", " ");
    }

    const _format = "YYYY-MM-DD HH:mm:ss";
    formData["UserId"] = props.userState.profileInfo.userId;
    formData["AssignedTo"] = props.userState.profileInfo.userId;
    formData["CreatedDate"] = moment().format(_format);
    formData["ModifiedDate"] = moment().format(_format);
    formData["LmsActivityGuid"] = uuidv4();
    formData["LmsActivitylastSyncTime"] = moment().format(_format);
    formData["IsLmsSync"] = navigator.onLine;
    formData["TrnActivityID"] = params.id;
    const statusOption = getOptionByCode(
      STATUS_ACTIVITY_PLANNED,
      props.masterDataState.ActivityStatus
    );
    formData["ActivityStatusId"] = statusOption.value;
    formData["ActivityStatus"] = statusOption.label;

    formData["ActivityName"] = getActivityName(formData.ActivityID);
    formData["AssignedToName"] = `${props.userState.profileInfo.firstName}${" "}${
      props.userState.profileInfo.lastName
    }`;
    formData["IsBacklog"] = false;
    formData["CreatedBy"] = parseInt(props.userState.profileInfo.userId);

    if (navigator.onLine) {
      props.activateLoader();
      let isValidActivity = await isValidBusinessActivity(formData);
      if (isValidActivity) {
        const result = await props.updateDynamicData({
          data: [formData],
          formName: CONFIGS.UPDATE_ACTIVITY_FORM,
          formBuilder: "",
          userID: props.userState.profileInfo.userId,
        });
        if (result.status === 200 && result.data.Responsestatus === true) {
          props.activateSuccessMessageBar("Activity has been created successfully.");

          props.deActivateLoader();
          const saveFormData = { ...formData };
          //  SAVE activity in indexDb online
          saveFormData["TrnActivityID"] = result.data.Transactionid;
          saveFormData["IsBacklog"] = false;
          saveActivityOffline(saveFormData).then((res) => {
            if (res) {
              reset(formData);
              history.push(`/app/activity/detail/${result.data.Transactionid}`);
            }
          });
        } else {
          props.deActivateLoader();
          props.activateErrorMessageBar(
            "Something went wrong! please try again later."
          );
        }
        // }
      } else {
        props.activateErrorMessageBar(responseMessage);
        props.deActivateLoader();
      }
    } else {
      props.deActivateLoader();
      //  SAVE activity in indexDb offline
      saveActivityOffline(formData).then((res) => {
        if (res) {
          history.push(`/app/activity/all`);
        }
      });
    }
  };

  const onChangeHandler = async (event, key, model) => {
    if (params.id) {
      const details = { ...current, [key]: event?.target?.value };
      setCurrent(details);
    }
    if (key === "ActivityID") {
      model.errors = {};
    }
    if (key === "PartnerName") {
      current["PartnerName"] = event.target.value;
    }
    const copyFormData = formDataState;
    switch (key) {
      case "ActivityPlannedId":
        if (event.target.value === "5") {
          copyFormData[6].hide = false;
          copyFormData[6].validators = provideDetailsValidator;
        } else {
          copyFormData[6].hide = true;
          copyFormData[6].validators = [];
        }
        break;
      case "TrainingTopic":
        if (event.target.value === "4") {
          copyFormData[5].hide = false;
          copyFormData[5].validators = provideDetailsValidator;
        } else {
          copyFormData[5].hide = true;
          copyFormData[5].validators = [];
        }
        break;
      case "JointCallActivityID":
        if (PARTNER_ENGAGEMENT.includes(event.target.value)) {
          copyFormData[6].options = props.masterDataState.BrocaJointCallActivityType.slice(1, 2);
          copyFormData[6].validators = [];
        }
        if (MUST_DO.includes(event.target.value)) {
          const options = props.masterDataState.BrocaJointCallActivityType.slice(2,3);
          copyFormData[6].options = options;
          copyFormData[6].validators = [];
        }
        if (event.target.value === "11") {
          copyFormData[6].options = props.masterDataState.BrocaJointCallActivityType;
          copyFormData[6].validators = [
            {
              name: "required",
              value: true,
              validationMessage: "Please select type",
            },
          ];
          setCurrent({ ...current, JointCAllACtivtytypeID: "" });
          copyFormData[5].hide = false;
          copyFormData[5].validators = provideDetailsValidator;
        } else {
          copyFormData[5].hide = true;
          copyFormData[5].validators = [];
        }
        break;
      case "JointCAllACtivtytypeID":
        setCurrent({ ...current, JointCAllACtivtytypeID: event.target.value });
        break;
      case "CampaigningDoneId":
        if (event.target.value === "3") {
          copyFormData[6].hide = false;
          copyFormData[6].validators = provideDetailsValidator;
        } else {
          copyFormData[6].hide = true;
          copyFormData[6].validators = [];
        }
        break;
      case "MeetingStartDateTime":
        let StartDate = moment(event.target.value).format(_formatDate);
        let newDate = moment(event.target.value)
          .add(30, "m")
          .format(_formatDate);
        formDataState.map((option) => {
          if (option.key === "MeetingEndDateTime") {
            option.max = moment(StartDate)
              .endOf("day")
              .format("YYYY-MM-DD kk:mm");
            option.min = StartDate;
          }
        });
        if (
          !checkDateIsValid(
            event.target.value,
            moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
          )
        ) {
          setextErrors({ ...extErrors, [key]: "please select future value" });
        } else {
          delete extErrors?.MeetingStartDateTime;
          setextErrors({ ...extErrors });
        }
        setCurrent({
          ...current,
          MeetingStartDateTime: StartDate,
          MeetingEndDateTime: newDate,
        });
        break;
      case "MeetingEndDateTime":
        if (
          !checkDateIsValid(event.target.value, current.MeetingStartDateTime)
        ) {
          setextErrors({
            ...extErrors,
            MeetingEndDateTime:
              "meeting end date should not less than meeting starting date",
          });
        } else {
          delete extErrors.MeetingEndDateTime;
          setextErrors({ ...extErrors });
        }
        setCurrent({
          ...current,
          MeetingEndDateTime: newDate,
        });
        break;
      case "BranchId":
        if (event.target.value && event.target.value !== "") {
          const { Branches } = props.masterDataState;
          const getBranchCode = Branches.filter(
            (item) => item.value == event.target.value
          );
          const res = await props.getPartnerDetails(getBranchCode[0].key);
          setPartnerDetails(res.data);
          copyFormData.find((elem, index) => {
            if (elem.key === "PartnerName") {
              copyFormData[index].options = getDropdownData(res.data, "BranchCode", "PartnerName", "PartnerName");
            }
          });
        }
        break;
      case "EmailID":
        setCurrent({ ...current, EmailID: event.target.value });
        break;
      case "MobileNo":
        setCurrent({ ...current, MobileNo: event.target.value });
        break;
      case "PartnerName":
        const getPartnerMobileNO = partnerDetails.filter(
          (item) => item.PartnerName == event.target.value
        );
        setCurrent({
          ...current,
          PartnerMobileNo: getPartnerMobileNO[0]?.Mobilenumber.toString(),
        });
        break;
      default:
        break;
    }
    setFormDataState(copyFormData);
    const formData = handleActivitySelection(
      event,
      key,
      formDataState,
      props.masterDataState,
      props.userState.profileInfo.roleId
    );
    setFormDataState(formData);
  };
  const getActivityName = (inputId) => {
    const copyFormData = formDataState;
    const name = copyFormData[0].options.filter(
      (el) => el.value === inputId.toString()
    );
    if (name && name.length > 0) {
      return name[0].label;
    }
    return null;
  };

  const isValidBusinessActivity = async (formData) => {
    if (
      formData.hasOwnProperty("MeetingStartDateTime") &&
      formData.hasOwnProperty("MeetingEndDateTime")
    ) {
      const checkActivity = await props.validateActivity({
        user_id: props.userState.profileInfo.userId,
        formData: formData,
      });
      if (
        checkActivity.status === 200 &&
        checkActivity.data.Responsestatus === true
      ) {
        return true;
      } else {
        this.setState({
          responseMessage: checkActivity.data.Responsemessage,
        });
      }
      return false;
    }
    return true;
  };

  const handleSaveAsDraft = async (e) => {
    e.preventDefault();
    const formData = { ...dyFormRef.current.state };
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    if (formData.hasOwnProperty("MeetingStartDateTime")) {
      formData["MeetingStartDateTime"] = formData[
        "MeetingStartDateTime"
      ].replace("T", " ");
    }
    if (formData.hasOwnProperty("MeetingEndDateTime")) {
      formData["MeetingEndDateTime"] = formData["MeetingEndDateTime"].replace(
        "T",
        " "
      );
    }

    const statusOption = getOptionByCode(
      STATUS_ACTIVITY_DRAFTED,
      props.masterDataState.ActivityStatus
    );
    formData["ActivityStatusId"] = statusOption.value;
    formData["UserId"] = props.userState.profileInfo.userId;
    formData["ModifiedBy"] = props.userState.profileInfo.userId;
    formData["CreatedBy"] = props.userState.profileInfo.userId;
    formData["AssignedTo"] = props.userState.profileInfo.userId;
    const _format = "YYYY-MM-DD HH:mm:ss";
    formData["CreatedDate"] = moment(new Date()).format(_format);
    formData["ModifiedDate"] = moment(new Date()).format(_format);

    formData["LmsActivityGuid"] = uuidv4();
    formData["LmsActivitylastSyncTime"] = moment().format(
      "YYYY-MM-DD HH:mm:ss"
    );
    formData["IsLmsSync"] = navigator.onLine;
    formData["TrnActivityID"] = params.id;
    formData["ActivityStatus"] = statusOption.label;
    formData["AssignedToName"] = `${props.userState.profileInfo.firstName}${" "}${
      props.userState.profileInfo.lastName
    }`;
    formData["IsBacklog"] = false;
    if (navigator.onLine) {
      const result = await props.updateDynamicData({
        data: [formData],
        formName: CONFIGS.UPDATE_ACTIVITY_FORM,
        formBuilder: "",
        userID: props.userState.profileInfo.userId,
      });
      if (result.status === 200 && result.data.Responsestatus === true) {
        props.activateSuccessMessageBar("Activity has been drafted successfully.");
        reset(formData);
        props.deActivateLoader();
        saveActivityOffline(formData);
        history.push("/app/activity/all");
      } else {
        props.deActivateLoader();
        props.activateErrorMessageBar(
          "Something went wrong! please try again later."
        );
      }
    } else {
      props.deActivateLoader();
      //  SAVE activity in indexDb offline
      saveActivityOffline(formData).then((res) => {
        if (res) {
          history.push(`/app/activity/all`);
        }
      });
    }
  };

  const getActivityDraftData = async () => {
    props.activateLoader();
    const activityId = params.id;
    const result = await props.getActivityDetails({ activityId: activityId });
    if (result.status === 200) {
      const data = result.data;
      let defaultState = data;
      let selectedFormData = formDataState;
      if (data?.ActivityID) {
        const formData = handleActivitySelection(
          { target: { value: data.ActivityID.toString() } },
          "ActivityID",
          selectedFormData,
          props.masterDataState,
          props.userState.profileInfo.roleId
        );
        selectedFormData = [...formData];
        defaultState.ActivityID = data.ActivityID.toString();
      }

      const {
        ActivityID,
        BranchCategory,
        MeetingEndDateTime,
        ActivityPlannedId,
        BranchId,
        CampaigningDoneId,
        CurrentPartner1,
        NoOfLeads,
        PartnerName,
        PartnerMobileNo,
        CurrentPartner2,
        CurrentPartner3,
        DesignationId,
        FoundationDate,
        JointCallActivityID,
        JointCAllACtivtytypeID,
        Location,
        MeetingStartDateTime,
        OtherActivityPlanned,
        TrainingTopic,
        OtherTrainingTopic,
        ProductId,
        NoOfAttendees,
        OtherDesignation,
        Name,
        LaunchProductName,
        OtherJointCallActivity,
        MeetingPlannedWithId,
        NameOfTheProspect,
        RenewalYear,
        LastAssignmentofId,
        LastAssignmentPlace,
        SinceWhen,
        RetirementDate,
        EmailID,
        MobileNo,
        SecretaryName,
        SecretaryEmailID,
        SecretaryMobileNo,
      } = data;

      if (ActivityPlannedId == "5") {
        selectedFormData[6].hide = false;
      }
      if (BranchId !== null) {
        const { Branches } = props.masterDataState;
        const getBranchCode = Branches.filter((item) => item.value == BranchId);
        const res = await props.getPartnerDetails(getBranchCode[0].key);
        selectedFormData[2].options = getDropdownData(
          res.data,
          "BranchCode",
          "PartnerName",
          "PartnerName"
        );
        setPartnerDetails(res.data);
      }
      if (PARTNER_ENGAGEMENT.includes(JointCallActivityID?.toString())) {
        selectedFormData[6].options = props.masterDataState.BrocaJointCallActivityType.slice(1, 2);
        selectedFormData[6].validators = [];
      }
      if (MUST_DO.includes(JointCallActivityID?.toString())) {
        const options = props.masterDataState.BrocaJointCallActivityType.slice(2, 3);
        selectedFormData[6].options = options;
        selectedFormData[6].validators = [];
      }
      if (JointCallActivityID === "11") {
        selectedFormData[6].options =
          props.masterDataState.BrocaJointCallActivityType;
        selectedFormData[6].validators = [
          {
            name: "required",
            value: true,
            validationMessage: "Please select type",
          },
        ];
        selectedFormData[5].hide = false;
        selectedFormData[5].validators = provideDetailsValidator;
      } else {
        selectedFormData[5].hide = true;
        selectedFormData[5].validators = [];
      }
      defaultState = {
        ActivityID: ActivityID ? ActivityID?.toString() : null,
        BranchCategory: BranchCategory ? BranchCategory?.toString() : null,
        MeetingEndDateTime: MeetingEndDateTime ? MeetingEndDateTime?.toString() : null,
        MeetingStartDateTime: MeetingStartDateTime ? MeetingStartDateTime?.toString() : null,
        ActivityPlannedId: ActivityPlannedId ? ActivityPlannedId?.toString() : null,
        BranchId: BranchId ? BranchId?.toString() : null,
        CampaigningDoneId: CampaigningDoneId ? CampaigningDoneId?.toString() : null,
        CurrentPartner1: CurrentPartner1 ? CurrentPartner1?.toString() : null,
        CurrentPartner2: CurrentPartner2 ? CurrentPartner2?.toString() : null,
        CurrentPartner3: CurrentPartner3 ? CurrentPartner3?.toString() : null,
        DesignationId: DesignationId ? DesignationId?.toString() : null,
        FoundationDate: FoundationDate ? moment(FoundationDate).format("YYYY-MM-DD") : null,
        JointCallActivityID: JointCallActivityID ? JointCallActivityID?.toString() : null,
        JointCAllACtivtytypeID: JointCAllACtivtytypeID ? JointCAllACtivtytypeID?.toString() : null,
        NoOfLeads: NoOfLeads ? NoOfLeads : null,
        Location: Location ? Location : null,
        OtherActivityPlanned: OtherActivityPlanned ? OtherActivityPlanned : null,
        TrainingTopic: TrainingTopic ? TrainingTopic : null,
        OtherTrainingTopic: OtherTrainingTopic ? OtherTrainingTopic : null,
        ProductId: ProductId ? ProductId?.toString() : null,
        NoOfAttendees: NoOfAttendees ? NoOfAttendees?.toString() : null,
        OtherDesignation: OtherDesignation ? OtherDesignation : null,
        Name: Name ? Name : null,
        LaunchProductName: LaunchProductName ? LaunchProductName : null,
        OtherJointCallActivity: OtherJointCallActivity ? OtherJointCallActivity : null,
        NameOfTheProspect: NameOfTheProspect ? NameOfTheProspect : null,
        RenewalYear: RenewalYear ? RenewalYear : null,
        LastAssignmentofId: LastAssignmentofId ? LastAssignmentofId : null,
        LastAssignmentPlace: LastAssignmentPlace ? LastAssignmentPlace : null,
        SinceWhen: SinceWhen ? moment(SinceWhen).format("YYYY-MM-DD") : null,
        RetirementDate: RetirementDate ? moment(RetirementDate).format("YYYY-MM-DD") : null,
        EmailID: EmailID ? EmailID : null,
        MobileNo: MobileNo ? MobileNo : null,
        SecretaryName: SecretaryName ? SecretaryName : null,
        SecretaryEmailID: SecretaryEmailID ? SecretaryEmailID : null,
        SecretaryMobileNo: SecretaryMobileNo ? SecretaryMobileNo : null,
        PartnerName: PartnerName ? PartnerName : null,
        PartnerMobileNo: PartnerMobileNo ? PartnerMobileNo : null,
        MeetingPlannedWithId: MeetingPlannedWithId ? MeetingPlannedWithId?.toString() : null,
      };
      setFormDataState(selectedFormData);
      setCurrent(defaultState);
      props.deActivateLoader();
    }
  };

  const reset = (model) => {
    for (let [key] of Object.entries(model)) {
      model[key] = "";
    }
    dyFormRef.current = model;
  };

  const onCancelHandler = () => {
    const isMobile =
      Math.min(window.screen.width, window.screen.height) < 468 ||
      navigator.userAgent.indexOf("Mobi") > -1;
    if (isMobile) {
      setisActiveMobileDraft(true);
    } else {
      history.push(_url);
    }
  };

  const getDropdownData = (optionsArr, key, label, value) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr?.map((option) => {
        return { key: option[key], label: option[label], value: option[value] };
      });
    }
    arr.unshift({ key: "select", label: "select", value: "" });
    return arr;
  };

  const toggleDrawer = (flag) => {
    setisActiveMobileDraft(flag);
  };

  const onDontSaveDraftHandler = () => {
    history.push(_url);
  };
  const onSaveDraftHandler = () => {
    handleSaveAsDraft(new Event("test"));
  };
  const LoadBreadCrumbs = () => {
    const breadcrumbs = [
      { name: "Activity", url: _url },
      { name: createActivityTitle, url: "/app/activity/create" },
    ];
    props.setBreadcrumbs(breadcrumbs);
  };

  useEffect(() => {
    const {  Branches } = props.masterDataState;
    const formData = initFormData(
      formDataState,
      props.masterDataState,
      props.userState.profileInfo.roleId,
      props.userState.profileInfo.channelid
    );
    LoadBreadCrumbs();
    setFormDataState(formData);
    const copyFormData = formDataState;
    copyFormData.find((val, index) => {
      if (val.key === "BranchId") {
        const copyFormData = formDataState;
        copyFormData[index].options = getDropdownDataBranch(
          Branches,
          "key",
          "label",
          "value"
        );
      }
    });
    setFormDataState(copyFormData);
    getActivityDraftData();
  }, []);

  return (
      <div className="mainContainer createActivity">
        <Breadcrumb></Breadcrumb>
        <span className="formInfo">
          {"Please fill in the activity information"}
        </span>
        <div className="formBox">
        <Dynamicform
          key={current.id}
          className="form"
          onRef={(ref) => {
            dyFormRef.current = ref;
          }}
          title={createActivityTitle}
          defaultValues={current}
          model={formDataState}
          onSubmit={(model) => {
            onSubmit(model);
          }}
          showAstersikOnRequiredFields={true}
          customValidators={customValidators}
          extErrors={extErrors}
          onChange={(event, key, model) => onChangeHandler(event, key, model)}
          extaraActionFormButton={
            <>
              <a
                className="savedraftLink"
                onClick={(e) => handleSaveAsDraft(e)}
                href="#"
              >
                SAVE AS DRAFT
              </a>

              <button
                className="btn cancelBtn"
                key="cancel"
                id="cancel"
                type="button"
                onClick={onCancelHandler}
              >
                {"Cancel"}
              </button>
            </>
          }
        />
      </div>
      <SaveAsDraftDrawer
        isActiveMobileDraft={isActiveMobileDraft}
        toggleDrawer={toggleDrawer}
        onDontSaveHandler={onDontSaveDraftHandler}
        onSaveDraftHandler={onSaveDraftHandler}
      ></SaveAsDraftDrawer>
    </div>
  );
};
const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
  userState: state.userReducer,
});
const mapDispatchToProps = (dispatch) => ({
  addDynamicData: (payload) => dispatch(addDynamicData(payload)),
  activateErrorMessageBar: (payload) => dispatch(activateErrorMessageBar(payload)),
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  activateSuccessMessageBar: (payload) => dispatch(activateSuccessMessageBar(payload)),
  finishDayActivityCreate: (payload) => dispatch(finishDayActivityCreate(payload)),
  validateActivity: (payload) => dispatch(validateActivity(payload)),
  setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
  getActivityDetails: (payload) => dispatch(getActivityDetails(payload)),
  getPartnerDetails: (payload) => dispatch(getPartnerDetails(payload)),
  updateDynamicData: (payload) => dispatch(updateDynamicData(payload)),
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps)(ResumeDraftedActivityBROCA)
);
