/* eslint-disable default-case */
import React from "react";
import Footer from '../../../../layouts/footer/footer';
import DynamicForm from "f1-dynamic-form-react";
import { withTranslation } from 'react-i18next';
import { ActivityFormBuilder, initFormData, customValidators, handleDropdownSelection } from "../broca/updateActivityForm";
import { ACTIVITY_RESCHEDULED, ACTIVITY_COMPLETED, ACTIVITY_CANCELLED, CONFIGS, FOUR_WHEELER, PUBLIC_TRANSPORT, SELF_OWNED_TRANSPORT, TWO_WHEELER } from '../../../../utils/config';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import moment from "moment";
import {
  setActivityData, updateDynamicData,
  getMasterData, getActivityById, uploadFileToBlob,
  activateSuccessMessageBar, activateErrorMessageBar,
  activateLoader, deActivateLoader,
  getActivityStatusMasterData, setBreadcrumbs
} from '../../../../redux/actions';
import { connect } from 'react-redux';

import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import '../agency/createActivity.scss'
import { getAutoCompleteActivities, validateIsCheckin } from "../../../../redux-reducer/reducer";
import { checkDateIsValid, meetingDateValidations } from "../../../../utils/validators";
import { updateActivityOffline } from "../../../../offlineManager/activityOfflineManager";
import { validateActivity } from "../../../../redux/actions/createActivityActions";
import { minusMinuteFromDate, addMinuteToDate } from '../../../../utils/utils'
const DateFormatString = 'YYYY-MM-DDTHH:mm'
const _formatDate = "YYYY-MM-DDTHH:mm";

class UpdateBrocaActivity extends React.Component {
  constructor(props) {
    super(props);
    this.dyFormRef = React.createRef();
    this.formBuilder = new ActivityFormBuilder(props.activityData.activityComplete ?
      ACTIVITY_COMPLETED : props.activityData.activityReshedule ?
        ACTIVITY_RESCHEDULED : ACTIVITY_CANCELLED, props.activityData.getSelectActivityData.ActivityID)
    this.state = {
      current: {
        id: 1,
        MeetingStartDateTime: props.activityData.getSelectActivityData.MeetingStartDateTime,
        MeetingEndDateTime: addMinuteToDate(props.activityData.getSelectActivityData.MeetingEndDateTime),
        IsClaimRequired: false,
        IsPartnerMet: "1",
        TravelExpence:null,
        VehicleTypeID:null
      },
      activityStatus: {},
      selectedActivityOldData: {},
      formData: this.formBuilder.getForm(),
      uploadedFile: '',
      extErrors: {},
      responseMessage: "",
      accordianFieldSet: { fieldset1: false },
      UploadPhoto:[],
      checkIn: true,
      uploadPhotoEVent:[]
    }

  }

  isValidBusinessActivity = async (formData) => {
    if (
      formData.hasOwnProperty("MeetingStartDateTime") && formData.hasOwnProperty("MeetingEndDateTime")
    ) {
      const checkActivity = await this.props.validateActivity({
        user_id: this.props.userState.profileInfo.userId,
        formData: formData,
      });
      if (
        checkActivity.status === 200 && checkActivity.data.Responsestatus === true
      ) {
        return true;
      } else {
        this.setState({
          responseMessage: checkActivity.data.Responsemessage
        })
      }
      return false;
    }
    return true;

  };

  onSubmitHandler = async (model) => {
    if (model.TransportModeID =="1" && model.IsClaimRequired ==true && model.TravelExpence=='' || model.TransportModeID =="1" && model.IsClaimRequired ==true && model.TravelExpence==null){
      const extError = { ...this.state.extErrors, 'TravelExpence': 'Travel expense is required.' }
      this.setState({
        ...this.state,
        extErrors: extError
      })
      return false
    }
    if (this.props.userState.profileInfo.roleId !== "BDM"  && model.TransportModeID =="2" && model.IsClaimRequired ==true && model.VehicleTypeID=='' || this.props.userState.profileInfo.roleId !== "BDM" && model.VehicleTypeID==null){
      const extError = { ...this.state.extErrors, 'VehicleTypeID': "mode of Trasport is required." }
      this.setState({
        ...this.state,
        extErrors: extError
      })
      return false
    }
    if (model.IsClaimRequired == false ){
      delete model.errors['TravelExpence'];
      delete model.errors['VehicleTypeID'];
      delete model.errors['TransportModeID'];
    }
    if (model.TransportModeID == "2" ){
      delete model.errors['TravelExpence'];
    }
    if (model.TransportModeID == "1" ){
      delete model.errors['VehicleTypeID'];
    }
    const oldData = this.state.selectedActivityOldData;
    if (this.state.uploadPhotoEVent.length>3){
      const extError = { ...this.state.extErrors, 'UploadPhoto': 'maximum 3 photo allowed' }
      this.setState({
        ...this.state,
        extErrors: extError
      })
    }else{
      delete this.state.extErrors["UploadPhoto"]
    }
    if (oldData.ActivityStatusId === 18) {
      this.props.activateErrorMessageBar("Activity already completed.");
      return false;
    } else if (oldData.ActivityStatusId === 20) {
      this.props.activateErrorMessageBar("Activity already cancelled.");
      return false;
    }
    if (this.props.activityData.getSelectActivityData.ActivityID === 46) {
      if (model.TrainingAttendee && model.TrainingAttendee.length > 0) {
        delete model.errors["CustomerCount"];
        delete model.errors.StaffCount;
        delete model.errors.BMCount;
        delete model.errors.FieldCount;
        delete model.errors.PartnerCount;
      }
    }
    let activityID = [45, 46, 49, 52]
    if (model.IsPartnerMet !="1" && activityID.includes(this.props.activityData.getSelectActivityData.ActivityID)) {
      delete model.errors.MobileNo;
      delete model.errors.Name;
      delete model.errors.OtherDesignation;
      delete model.errors.DesignationId;
    }
    if (!model.isValidForm && Object.keys(model.errors).length > 0 ||Object.keys(model.errors).length > 0|| Object.keys(this.state.extErrors).length > 0) {
      this.props.activateErrorMessageBar("Please fill all required fields")
      return false;
    }
    if (model.IsPartnerMet){
      if (!model.isValidForm && Object.keys(model.errors).length > 0 || Object.keys(this.state.extErrors).length > 0) {
        this.props.activateErrorMessageBar("Please fill all required fields")
        return false;
      }
    }
    this.props.activateLoader();
    if (model.MeetingStartDateTime && model.MeetingEndDateTime) {
      model.MeetingStartDateTime = model.MeetingStartDateTime.replace('T', ' ').replace('Z', '')
      model.MeetingEndDateTime = minusMinuteFromDate(model.MeetingEndDateTime).replace('T', ' ').replace('Z', '')
    }

    if (model.DateOfTraining) {
      model.DateOfTraining = model.DateOfTraining.replace('T', ' ').replace('Z', '')
    }


    if (model.MeetingEndDateTime === "Invalid date") {
      model.MeetingEndDateTime = null;
      model.MeetingStartDateTime = null;
    }

    oldData.ActivityStatusId = this.props.activityData.activityComplete ? 34 : this.props.activityData.activityReshedule ? 35 : 36
    oldData.ActivityStatus = this.state.activityStatus?.ActivityStatus
    oldData.ModifiedDate = (moment(new Date()).format(DateFormatString)).replace('T', ' ').replace('Z', '')
    const formData = { ...oldData, ...model };
    delete formData['advanceLayoutFields'];
    delete formData['collapseStates'];
    delete formData['errors'];
    delete formData['isValidForm'];
    delete formData['id'];


    formData["CreatedDate"] = formData.CreatedDate.replace('T', ' ').replace('Z', '')
    formData["SinceWhen"] = formData.CreatedDate ? formData.SinceWhen.replace('T', ' ').replace('Z', '') : null;
    formData["RetirementDate"] = formData.RetirementDate ? formData.RetirementDate.replace('T', ' ').replace('Z', '') : null;
    formData["FoundationDate"] = formData.FoundationDate ? formData.FoundationDate.replace('T', ' ').replace('Z', '') : null;
    
    formData['IsCheckin'] = false;
    formData['ModifiedBy'] = this.props.userState.profileInfo.userId;

    formData.IsBacklog = 0;
    if (formData.hasOwnProperty("UploadBill")) {
      formData["UploadBill"] = this.state.uploadedFile;
    }
    if (formData.ActivityID === 46 && formData.TrainingAttendee) {
      formData['TrainingAttendee'] = formData['TrainingAttendee'].length > 0 ? formData['TrainingAttendee'].join(',') : null
    }
    if (formData.ActivityID === 50 && formData['BFDActivityID'] !== null) {
      formData['BFDActivityID'] = formData['BFDActivityID'].length > 0 ? formData['BFDActivityID'].join(',') : null
    }
    if (formData.hasOwnProperty("UploadPhoto")) {
      formData["UploadTrainingAttendancePhoto1"] = this.state?.UploadPhoto[0]?.name;
      formData["UploadTrainingAttendancePhoto2"] = this.state?.UploadPhoto[1]?.name;
      formData["UploadTrainingAttendancePhoto3"] = this.state?.UploadPhoto[2]?.name;
    }
    const validateMeetingStatusForm = ["Activity Cancelled", "Activity Completed"];
    if (navigator.onLine) {
      this.props.activateLoader();
      if (validateMeetingStatusForm.includes(formData["ActivityStatus"])) {
        const result = await this.props.updateDynamicData({
          'data': [formData],
          'formName': CONFIGS.UPDATE_ACTIVITY_FORM,
          formBuilder: this.formBuilder?.actionType,
          userID: this.props.userState?.profileInfo?.userId
        });
        if (result.status === 200) {
          this.props.activateSuccessMessageBar("Activity is updated successfully.")
          // this method is called to fetch the latest completed activity to create the lead
          this.props.getAutoCompleteActivities({ userId: this.props.userState?.profileInfo?.userId });
          this.props.deActivateLoader();
          setTimeout(() => {
            const str = `/app/activity/detail/${this.props.activityData.getSelectActivityData.TrnActivityID}`
            this.props.history.push(str)
          }, 1000)
        } else {
          this.props.activateErrorMessageBar("Please fill all required fields")
          this.props.deActivateLoader();
        }
      } else {
        const isValidActivity = await this.isValidBusinessActivity(formData);
        if (isValidActivity) {
          const result = await this.props.updateDynamicData({
            'data': [formData],
            'formName': CONFIGS.UPDATE_ACTIVITY_FORM,
            formBuilder: this.formBuilder?.actionType,
            userID: this.props.userState?.profileInfo?.userId
          });
          if (result.status === 200) {
            this.props.activateSuccessMessageBar("Activity is updated successfully.")
            this.props.deActivateLoader();
            // this method is called to fetch the latest completed activity to create the lead
            this.props.getAutoCompleteActivities({ userId: this.props.userState?.profileInfo?.userId });
            setTimeout(() => {
              const str = `/app/activity/detail/${this.props.activityData.getSelectActivityData.TrnActivityID}`
              this.props.history.push(str)
            }, 1000)
          } else {
            this.props.activateErrorMessageBar("Please fill all required fields")
            this.props.deActivateLoader();
          }
        } else {
          this.props.activateErrorMessageBar(
            this.state.responseMessage
          );
          this.props.deActivateLoader();
        }
      }
    } else {
      this.props.deActivateLoader();
      // update the activity in offline
      const activityObject = { ...this.props.activityData.getSelectActivityData };
      activityObject.IsBacklog = false
      activityObject.IsCheckin = false
      activityObject.IsClaimRequired = formData.IsClaimRequired
      activityObject.MeetingEndDateTime = formData.MeetingEndDateTime
      activityObject.MeetingStartDateTime = formData.MeetingStartDateTime
      activityObject.ModifiedDate = formData.ModifiedDate
      activityObject.Reason = formData.Reason
      activityObject.TransportModeID = formData.TransportModeID
      activityObject.TravelExpence = formData.TravelExpence
      activityObject.isActiveCheckin = false
      const key = activityObject.TrnActivityID ? activityObject.TrnActivityID : activityObject.LmsActivityGuid
      updateActivityOffline(key.toString(), activityObject).then((res) => {
        if (res) {
          this.props.history.push(`/app/activity/detail/${key}`);
        }
      }).catch((err) => this.props.history.push(`/app/activity/all`));
    }
    return true;
  }
  onChangeHandler = async (event, key, model) => {
    const { formData } = this.state;
    if (key === "UploadBill") {
      this.uploadFile(event, key);
    }
    if (key === "UploadPhoto") {
      this.setState({
        ...this.state,
        uploadPhotoEVent: event
      })
      event.forEach((element) => {
        this.getuploadPhoto(element, event);
      });
    }
    if (key === "TravelExpence"){
      this.setState({
        current: {
          ...this.state.current,"TravelExpence":event.target.value
        }
      })
      this.setState({
        extErrors: {}
      })
    }
    if (key === "VehicleTypeID"){
      this.setState({
        current: {
          ...this.state.current,"VehicleTypeID":event.target.value
        }
      })
      this.setState({
        extErrors: {}
      })
    }
    switch (this.props.activityData.getSelectActivityData.ActivityID) {
      case 45:
      case 49:
      case 52:
        if (key === "IsPartnerMet") {
          this.setState({
            current: {
              ...this.state.current,
              IsPartnerMet: event.target.value
            }
          })
          formData[0].columns[0].rows[0][1].hide = event.target.value === "1" ? false : true;
          formData[0].columns[0].rows[0][2].hide = event.target.value === "1" ? false : true;
          formData[0].columns[0].rows[0][3].hide = event.target.value === "1" ? false : true;
        }
        break;
      case 46:
        if (key === "IsPartnerMet") {
          this.setState({
            current: {
              ...this.state.current,
              IsPartnerMet: event.target.value
            }
          })
        }
        if (key === "TrainingAttendee") {
          switch (event.target.value) {
            case "1":
              formData[1].columns[1].fieldsets[0].rows[0][0].hide = event.currentTarget.checked ? false : true;
              if (!event.currentTarget.checked) {
                delete model["PartnerCount"]
              }
              break;
            case "2":
              formData[1].columns[1].fieldsets[0].rows[1][0].hide = event.currentTarget.checked ? false : true;
              if (!event.currentTarget.checked) {
                delete model["CustomerCount"]
              }
              break;
            case "3":
              formData[1].columns[1].fieldsets[0].rows[2][0].hide = event.currentTarget.checked ? false : true;
              if (!event.currentTarget.checked) {
                delete model["StaffCount"]
              }
              break;
            case "4":
              formData[1].columns[1].fieldsets[0].rows[3][0].hide = event.currentTarget.checked ? false : true;
              if (!event.currentTarget.checked) {
                delete model["BMCount"]
              }
              break;
            case "5":
              formData[1].columns[1].fieldsets[0].rows[4][0].hide = event.currentTarget.checked ? false : true;
              if (!event.currentTarget.checked) {
                delete model["FieldCount"]
              }
              break;
          }
        }
        if (key === "PartnerCount") {
          const PartnerCount = event.target.value;
          if (parseInt(PartnerCount) <= 0) {
            this.setState({
              extErrors: { ...this.state.extErrors, PartnerCount: "Partner Count should be greater than 0" }
            })
          } else {
            this.setState({
              extErrors: {}
            })
          }
        }
        if (key === "CustomerCount") {
          const CustomerCount = event.target.value;
          if (parseInt(CustomerCount) <= 0) {
            this.setState({
              extErrors: { ...this.state.extErrors, CustomerCount: "Customer Count should be greater than 0" }
            })
          } else {
            this.setState({
              extErrors: {}
            })
          }
        }

        if (key === "StaffCount") {
          const StaffCount = event.target.value;
          if (parseInt(StaffCount) <= 0) {
            this.setState({
              extErrors: { ...this.state.extErrors, StaffCount: "Staff Count should be greater than 0" }
            })
          } else {
            this.setState({
              extErrors: {}
            })
          }
        }
        if (key === "BMCount") {
          const BMCount = event.target.value;
          if (parseInt(BMCount) <= 0) {
            this.setState({
              extErrors: { ...this.state.extErrors, BMCount: "BM Count should be greater than 0" }
            })
          } else {
            this.setState({
              extErrors: {}
            })
          }
        }

        if (key === "FieldCount") {
          const FieldCount = event.target.value;
          if (parseInt(FieldCount) <= 0) {
            this.setState({
              extErrors: { ...this.state.extErrors, FieldCount: "Field Count should be greater than 0" }
            })
          } else {
            this.setState({
              extErrors: {}
            })
          }
        }
        break;
    }
    const flag = this.props.activityData.activityComplete ? ACTIVITY_COMPLETED : this.props.activityData.activityReshedule ? ACTIVITY_RESCHEDULED : ACTIVITY_CANCELLED;

    let formWithData = handleDropdownSelection(event, key, this.state.formData, this.props.masterDataState, flag, this.props.userState.profileInfo.roleId, this.props.activityData.getSelectActivityData.ActivityID, this.state.checkIn)
    this.setState({ formData: formWithData })
    if (key === "IsClaimRequired") {
      delete model.errors['TravelExpense'];
      delete model.errors['TravelExpence'];
      delete model.errors['VehicleTypeID'];
      if (event === true) {
        const checkIn = await this.props.validateIsCheckin({ checkinFor: "activity", Id: this.props.activityData.getSelectActivityData.TrnActivityID })
        const { data } = checkIn;
        this.setState({
          current: {
            ...this.state.current,
            IsClaimRequired: true
          },
          checkIn: data
        })

        if (data) {
          this.setState({
            current: {
              ...this.state.current,
              IsClaimRequired: data
            }
          })
          this.setState({ formData: formWithData })
        } else {
          this.setState({
            current: {
              ...this.state.current,
              IsClaimRequired: false
            }
          })
          let formWithDataDetails = handleDropdownSelection(event, key, this.state.formData, this.props.masterDataState, flag, this.props.userState.profileInfo.roleId, this.props.activityData.getSelectActivityData.ActivityID, data)
          this.setState({ formData: formWithDataDetails })
          this.props.activateErrorMessageBar("Please check-in first to claim the travel expense");
        }
      } else {
        this.setState({
          current: {
            ...this.state.current,
            IsClaimRequired: false
          }
        })
        this.setState({
          extErrors: {}
        })
        delete model.errors['TravelExpence'];
        delete model.errors['VehicleTypeID'];
        const errorElement = document.querySelectorAll('input[type=radio]');
        errorElement[0].checked = false;
        errorElement[1].checked = false;

        const errorElement1 = document.getElementsByClassName(`activeRadio`);
        if (errorElement1.length > 0) {
          errorElement1[0].classList.remove('activeRadio');
        }

      }
    }
    if (key === "TransportModeID"){
      if (event.target.value =="2"){
        this.setState({
          extErrors: {}
        })
      } else {
        delete model.errors['VehicleTypeID'];
      }
    }
    if (key === "NoOfAttendees") {
      const NoOfAttendees = event.target.value;
      if (parseInt(NoOfAttendees) <= 0) {
        this.setState({
          extErrors: { ...this.state.extErrors, NoOfAttendees: "Attendees should be greater than 0" }
        })
      } else {
        this.setState({
          extErrors: {}
        })
      }
    }

    if (key === "NoOfCallsDone") {
      const NoOfCallsDone = event.target.value;
      if (parseInt(NoOfCallsDone) <= 0) {
        this.setState({
          extErrors: { ...this.state.extErrors, NoOfCallsDone: "No. of Calls should be greater than 0" }
        })
      } else {
        this.setState({
          extErrors: {}
        })
      }
    }

    if (key === "NoOfLeadContacted") {
      const NoOfLeadContacted = event.target.value;
      if (parseInt(NoOfLeadContacted) <= 0) {
        this.setState({
          extErrors: { ...this.state.extErrors, NoOfLeadContacted: "No. Of Lead Contacted should be greater than 0" }
        })
      } else {
        this.setState({
          extErrors: {}
        })
      }
    }
    if (key === "DesignationId" && this.props.activityData.getSelectActivityData.ActivityID === 52) {
      formData[0].columns[0].rows[0][3].hide = event.target.value === "5" ? false : true;
    }
    if (key === "BFDActivityID") {
      formData[0].columns[0].rows[0][1].hide = event.target.value === "4" && event.currentTarget.checked ? false : true;
    }


if (key === "MeetingStartDateTime") {
  var StartDate = moment(event.target.value).format(_formatDate);
  const newDate = moment(event.target.value).add(30, "m").format(_formatDate);

  this.state.formData[0].columns[0].rows[0].map((option) => {
    if (option.key === "MeetingEndDateTime") {
      option.max = moment(StartDate).endOf("day").format("YYYY-MM-DD kk:mm");
      option.min = StartDate;
    }
  });

  if (
    !checkDateIsValid(
      event.target.value,
      moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
    )
  ) {
    this.setState({
      ...this.state,
      extErrors: {
        ...this.state.extErrors,
        [key]: "please select future value",
      },
    });
  } else {
    delete this.state.extErrors?.MeetingStartDateTime;
    this.setState({
      ...this.state,
      extErrors: {},
    });
  }
  this.setState({
    current: {
      ...this.state.current,
      MeetingStartDateTime: StartDate,
      MeetingEndDateTime: newDate,
    },
  });
}
if (key === "MeetingEndDateTime") {
  const newDate = moment(event.target.value).format(_formatDate);
  if (
    !checkDateIsValid(
      event.target.value,
      this.state.current.MeetingStartDateTime,
    )
  ) {
    this.setState({
      ...this.state,
      extErrors: {
        ...this.state.extErrors,
        MeetingEndDateTime:
          "meeting end date should not less than meeting starting date",
      },
    });
  } else {
    this.setState({
      ...this.state,
      extErrors: {},
    });
  }
  this.setState(
    {
      current: { ...this.state.current, MeetingEndDateTime: newDate },
    },
    () => {
      const extErrors = meetingDateValidations(this.state);
      this.setState({ extErrors: extErrors });
        },
      );
    }
  }

  onCancelHandler = () => {
    this.props.history.push(`/app/activity/detail/${this.props.activityData.getSelectActivityData.TrnActivityID}`)
  }

  uploadFile = async (files, key) => {
    if (navigator.onLine) {
      const fileRes = await this.props.uploadFileToBlob({ 'file': files[0] });
      const { downloadUrl } = fileRes.data
      if (fileRes.status === 200) {
        this.setState({ uploadedFile: downloadUrl })
        if (key === "UploadAttendanceSheet") {
          this.setState({ UploadAttendanceSheet: files[0].name });
        } else if (key === "UploadPhoto") {
          this.setState({ UploadPhoto: files[0].name });
        } else if (key === "UploadBill") {
          this.setState({ UploadBill: files[0].name });
        } else {
          this.setState({ uploadedFile: downloadUrl, originalFileName: files[0].name });
        }
      }
    } else {
      this.props.activateErrorMessageBar(
        "Cannot upload files in offline mode!"
      );
    }
  }
  getuploadPhoto = async (files, key) => {
    if (navigator.onLine) {
      const fileRes = await this.props.uploadFileToBlob({ file: files });
      // const { name } = key;
      if (fileRes.status === 200) {
        this.setState({ UploadPhoto: key });
      }
    } else {
      this.props.activateErrorMessageBar(
        "Cannot upload files in offline mode!"
      );
    }
  };

  statusFunction = async () => {
    const result = await this.props.getActivityStatusMasterData()
    if (result.status === 200) {
      let statusId = []
      if (this.props.activityData.activityComplete) {
        statusId = result.data.filter(f => f.ActivityStatus === 'Activity Completed')
      } else if (this.props.activityData.activityReshedule) {
        statusId = result.data.filter(f => f.ActivityStatus === "Activity Rescheduled")
      } else {
        statusId = result.data.filter(f => f.ActivityStatus === "Activity Cancelled")
      }
      this.setState({ activityStatus: statusId[0] })
    }
  }
  loadBreadCrumbs = () => {
    const { t } = this.props;
    const createActivityTitle = t(this.formBuilder.getFormTitle())
    const breadcrumbs = [
      { name: 'Activity', url: '/app/activity/all' },
      { name: createActivityTitle, url: '/app/agency/activity/update' },
    ]
    this.props.setBreadcrumbs(breadcrumbs);
  }
  componentDidMount() {
    this.loadBreadCrumbs()
    this.statusFunction()

    const fetchData = async () => {
      this.props.activateLoader();
      const getCurrentActivity = await this.props.getActivityById(this.props.activityData.getSelectActivityData.TrnActivityID);
      if (getCurrentActivity.status === 200) {
        this.setState({ selectedActivityOldData: getCurrentActivity.data })
        this.props.deActivateLoader();
      } else {
        this.props.deActivateLoader();
      }
      const formData = this.props.masterDataState ? initFormData(this.state.formData,getCurrentActivity.data , this.props.masterDataState, this.props.activityData.activityComplete ? ACTIVITY_COMPLETED :
      this.props.activityData.activityReshedule ? ACTIVITY_RESCHEDULED : ACTIVITY_CANCELLED, this.props.activityData.getSelectActivityData.ActivityID) : this.state.formData;
      this.setState({ formData: formData })
    }
    if (navigator.onLine) {
      fetchData()
    }
  }
  removeFile = (e, file, index, key) => {
    this.dyFormRef.removeFile(e, file, index, key);
    this.setState({ uploadedFile: '' })
  }

  renderUploadedFilesItem = (file, index, key) => {
    return (
      <li style={{ borderColor: '#ccc' }} key={index}>
        <span className="file-name">{file.name}</span>
        <span className="action" onClick={(e) => this.removeFile(e, file, index, key)}>
          <i className="fa fa-trash"></i>
        </span>
      </li>
    );
  }

  renderRadioIcons = (radioOption) => {
    if (radioOption.key === PUBLIC_TRANSPORT) {
      return <DirectionsBusIcon></DirectionsBusIcon>
    }
    else if (radioOption.key === SELF_OWNED_TRANSPORT) {
      return <TwoWheelerIcon></TwoWheelerIcon>
    }
    else if (radioOption.key === TWO_WHEELER) {
      return <TwoWheelerIcon></TwoWheelerIcon>
    }
    else if (radioOption.key === FOUR_WHEELER) {
      return <DirectionsCarIcon></DirectionsCarIcon>
    } else {
      return ""
    }
  }

  toggleFieldsetAccordian = (collapseId) => {
    this.dyFormRef.toggleCollapse(collapseId);
  }

  renderFieldsetAccordianLabel = (collapseId) => {
    const buttonStyle = {
      float: 'right',

      cursor: 'pointer',
      color: '#000000',
    }

    const accordianClass = this.dyFormRef?.state?.collapseStates[collapseId]
    return (
      <div className="accordHead">
        <span style={buttonStyle} onClick={(e) => this.toggleFieldsetAccordian(collapseId)}>{!accordianClass ? "+" : "-"}</span>
        <strong>
          {collapseId === 'fieldset1' ? 'Lead Personal Details' : collapseId === 'fieldset2' ? 'Product Details' : collapseId === 'fieldset3' ? 'Business Details' : ''}
        </strong>
      </div>
    );
  }
  render() {
    const { t } = this.props;
    return <div className="mainContainer createActivity updateActivity">
      <Breadcrumb></Breadcrumb>
      <div className="formBox">
        <DynamicForm
          key={this.state.current.id}
          onRef={ref => this.dyFormRef = ref}
          title={this.state.selectedActivityOldData ? this.state.selectedActivityOldData.ActivityName : ''}

          buttonSubmit={t('Update')}
          defaultValues={this.state.current}
          model={this.state.formData}
          onSubmit={(model) => {
            this.onSubmitHandler(model);
          }}
          disableSubmitOnInValid={false}
          displayInformation={true}
          design="advanceBootstrap"
          materialOutline={false}
          onChange={(event, key, model) => this.onChangeHandler(event, key, model)}
          buttonNormalClick={(id, key, obj) => {
          }}
          showAstersikOnRequiredFields={true}
          className="activityTypeDiv"
          extaraActionFormButton={<>
            <button className="btn cancelBtn" key="cancel" id="cancel" type="button" onClick={this.onCancelHandler}>{t('Cancel')}</button>
          </>}
          customValidators={customValidators}
          fileUploaderFields={['UploadBill']}
          renderUploadedFilesItem={this.renderUploadedFilesItem}
          renderRadioIcons={(option) => this.renderRadioIcons(option)}
          extErrors={this.state.extErrors}
        />
      </div>
      <Footer></Footer>
    </div>
  }
}

const mapStateToProps = (state) => ({
  activityData: state.updateActivityReducer,
  masterDataState: state.commonReducer,
  userState: state.userReducer
});


const mapDispatchToProps = dispatch => ({
  getActivityStatusMasterData: (payload) => dispatch(getActivityStatusMasterData(payload)),
  setActivityData: (payload) => dispatch(setActivityData(payload)),
  getActivityById: (payload) => dispatch(getActivityById(payload)),
  updateDynamicData: (payload) => dispatch(updateDynamicData(payload)),
  uploadFileToBlob: (payload) => dispatch(uploadFileToBlob(payload)),
  activateSuccessMessageBar: (payload) => dispatch(activateSuccessMessageBar(payload)),
  activateErrorMessageBar: (payload) => dispatch(activateErrorMessageBar(payload)),
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  getMasterData: (payload) => dispatch(getMasterData(payload)),
  setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
  validateIsCheckin: (payload) => dispatch(validateIsCheckin(payload)),
  validateActivity: (payload) => dispatch(validateActivity(payload)),
  getAutoCompleteActivities: (payload) => dispatch(getAutoCompleteActivities(payload)),
});
export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(UpdateBrocaActivity));
