import { useState } from "react";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineConnector from "@mui/lab/TimelineConnector";
import ActivityTravelHistory from "./activityTravelHistory";
import moment from 'moment';
import {
  BDF,
  PARTNER_BRANCH_VISIT_PLAN,
  BRANCH_TRAINING,
  BRANCH_VISIT,
    HNI_MEET,
    ICE_E_SAMPARK,
    MONTHLY_REVIEW,
    TRAINING,
    MEETING_VISIT,
    CALLING_TO_VLE_RAP_DM,
    NEW_BUSINESS,
    JOINT_SALES_CALL_WITH_SRM,
    SCHEDULE_STRUCTURED_REVIEW_BDM,
    REVIEW_MEETING,
    NEW_RECRUITMENT_ACTIVITY,
    BRANCH_VISIT_PLAN_BDM,
    REGIONAL_OFFICE_MEETING_ABH,
    SALES_TRAINING_PLAN_BDM,
    JOINT_CALLS_BDM,
    CIRCULAR_FOR_SALES_ENABLEMENT_ABH,
    ICE_FOR_LOAN_ACTIVITY, HNI_LEAD_GENERATION,
  BUSINESS_DEVELOPMENT_ACTIVITY, PARTNER_VISIT, PRODUCT_TRAINING, BIRTHDAY_CELEBRATION, PRODUCT_LAUNCH,
  BRANCH_HEAD_MEET, BRANCH_FOUNDATION_DAY, JOINT_CALL_WITH_PARTNER, LIVE_LEAD_GENERATION, SME_LF, SME_CELL, BRANCH, COLLATE_THE_UNQUALIFIED_LEADS_DATA, REGIONAL_OFFICE_CONNECT, TEAM_REVIEW
} from "../../../../utils/config";
import { useSelector } from "react-redux";
import { getUploadedImage } from "../../../../redux-reducer/reducer";

const CompletedHistory = (props) => {
  const { history, activityOption } = props
  const commonState = useSelector(state => state);
  const userState = commonState.userReducer
  const [ChannelID] = useState(userState.profileInfo.channelid)
  const [fileData, setfileData] = useState(null)
  const [activityPhotoFileData, setActivityPhotoFileData] = useState(null)
  const [attendanceDocumentFileData, setAttendanceDocumentFileData] = useState(null)
  const [trainingAttendancePhotoOnetFileData, setTrainingAttendancePhotoOnetFileData] = useState(null)
  const [trainingAttendancePhotoTwotFileData, setTrainingAttendancePhotoTwotFileData] = useState(null)
  const [callingToVLEFileData, setCallingToVLEFileData] = useState(null)
  const [uploadPdfFileData, setUploadPdfFileData] = useState(null)
  const remarks = history.Remarks ? history.Remarks : 'N/A';
  const [loader, setLoader] = useState(true)
  const [activityLoader, setActivityLoader] = useState(true)
  const [attendanceDocumentLoader, setAttendanceDocumentLoader] = useState(true)
  const [trainingAttendancePhotoLoader, setTrainingAttendancePhotoLoader] = useState(true)
  const [trainingAttendancePhotoLoader2, setTrainingAttendancePhotoLoader2] = useState(true)
  const [callingToVLEUploadPhotoLoader, setCallingToVLEUploadPhotoLoader] = useState(true)
  const [uploadPdfFileLoader, setUploadPdfFileLoader] = useState(true)

  const getBase64Data = async (billData) => {
    if (history.UploadBillFileName || history.UploadTrainingAttendancePhoto3 || history.UploadTrainingAttendancePhoto1 || history?.UploadTrainingAttendanceDocument1 || history?.CallingToVLEUploadPhoto || history?.UploadPdfFile || history?.UploadTrainingAttendancePhoto2) {
      if (billData === "UploadBill") {
        setLoader(false)
      } else if (billData === "TrainingAttendanceDocument") {
        setAttendanceDocumentLoader(false)
      } else if (billData === "photoOfTheActivity") {
        setActivityLoader(false)
      } else if (billData === "trainingAttendancePhotoOne") {
        setTrainingAttendancePhotoLoader(false)
      } else if (billData === "CallingToVLEUploadPhoto") {
        setCallingToVLEUploadPhotoLoader(false)
      } else if (billData === "UploadPdfFile") {
        setUploadPdfFileLoader(false)
      } else if (billData === "trainingAttendancePhotoTwo") {
        setTrainingAttendancePhotoLoader2(false)
      }
    }
    const res = await getUploadedImage(billData === "UploadBill" ? history?.UploadBillFileName : billData === "TrainingAttendanceDocument" ?
      history?.UploadTrainingAttendanceDocument1 : billData === "photoOfTheActivity" ? history?.UploadTrainingAttendancePhoto3 : billData === "trainingAttendancePhotoOne" ?
        history?.UploadTrainingAttendancePhoto1 : billData === "CallingToVLEUploadPhoto" ? history?.CallingToVLEUploadPhoto : billData === "UploadPdfFile" ? history?.UploadPdfFile : billData === "trainingAttendancePhotoTwo" ? history?.UploadTrainingAttendancePhoto2 : null);
    if (res.status === 200 && res.data) {
      if (billData === "UploadBill") {
        setfileData(`${"data:image/png;base64,"}${res.data}`);
      } else if (billData === "photoOfTheActivity") {
        setActivityPhotoFileData(`${"data:image/png;base64,"}${res.data}`)
      } else if (billData === "TrainingAttendanceDocument") {
        setAttendanceDocumentFileData(`${"data:image/png;base64,"}${res.data}`);
      } else if (billData === "trainingAttendancePhotoOne") {
        setTrainingAttendancePhotoOnetFileData(`${"data:image/png;base64,"}${res.data}`);
      } else if (billData === "CallingToVLEUploadPhoto") {
        setCallingToVLEFileData(`${"data:image/png;base64,"}${res.data}`);
      } else if (billData === "UploadPdfFile") {
        setUploadPdfFileData(`${"data:image/png;base64,"}${res.data}`);
      } else if (billData === "trainingAttendancePhotoTwo") {
        setTrainingAttendancePhotoTwotFileData(`${"data:image/png;base64,"}${res.data}`);
      }
      if (billData === "UploadBill") {
        setLoader(true)
      } else if (billData === "TrainingAttendanceDocument") {
        setAttendanceDocumentLoader(true)
      } else if (billData === "photoOfTheActivity") {
        setActivityLoader(true)
      } else if (billData === "trainingAttendancePhotoOne") {
        setTrainingAttendancePhotoLoader(true)
      } else if (billData === "CallingToVLEUploadPhoto") {
        setCallingToVLEUploadPhotoLoader(true)
      } else if (billData === "UploadPdfFile") {
        setUploadPdfFileLoader(true)
      } else if (billData === "trainingAttendancePhotoTwo") {
        setTrainingAttendancePhotoLoader2(true)
      }
      var image = new Image();
      image.src = "data:image/jpg;base64," + res.data;
      var w = window.open("");
      w.document.write(image.outerHTML);
    }
  };
    return <>
        {history.ActivityStatus === "Activity Completed" ?
            <>
                {activityOption && (activityOption.key === BRANCH_VISIT 
                    || activityOption.key === BRANCH_TRAINING
                    || activityOption.key === HNI_MEET
                    || activityOption.key === ICE_E_SAMPARK
                    || activityOption.key === BDF ||
                    activityOption.key === ICE_FOR_LOAN_ACTIVITY || 
                    activityOption.key === HNI_LEAD_GENERATION
        ) &&
          <>
            {activityOption.key === BRANCH_TRAINING && ChannelID !== 13  ?


              <>
              {ChannelID == 79 && <TimelineItem>
                  <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <label>Topic of training</label>
                    <label> {history.TrainingTopic}</label>
                  </TimelineContent>
                </TimelineItem>}
              <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label> Date of training</label>
                  <label>
                    <span className="date">{history.DateOfTraining ? moment(history.ModifiedDate).format('DD MMM YYYY') : ""}</span>
                    <span className="time">{history.DateOfTraining ? moment(history.DateOfTraining).format('h:mma') : ""}
                    </span>
                  </label>
                </TimelineContent>
              </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <label>No of participants</label>
                    <label> {history.ActualNoOfTrainees}</label>
                  </TimelineContent>
                </TimelineItem>
                {ChannelID == 79 && <TimelineItem>
                  <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <label>Remarks</label>
                    <label> {remarks}</label>
                  </TimelineContent>
                </TimelineItem>}
              </> :
              <> <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Expected Premium</label>
                  <label> ₹{history.ExpectedPremium}</label>
                </TimelineContent>
              </TimelineItem>

                <TimelineItem>
                  <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <label># of Leads</label>
                    <label>{history.NoOfLeads}</label>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <span className="separator">-</span>
                  </TimelineSeparator>
                  <TimelineContent>
                    <label>Remarks</label>
                    <label>{remarks}</label>
                  </TimelineContent>
                </TimelineItem></>}
          </>
        }

                {activityOption && activityOption.key === TRAINING && ChannelID !=3 &&
                    <>
                        <TimelineItem>
                            <TimelineSeparator>
                                <span className="separator">-</span>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <label>Actual Number of Trainees</label>
                                <label> {history.ActualNoOfTrainees}</label>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineSeparator>
                                <span className="separator">-</span>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <label>Training Attendance Photo</label>
                                {/* <label> {history.UploadTrainingAttendancePhoto1 ? history.UploadTrainingAttendancePhoto1 : ''}</label>
                                <label> {history.UploadTrainingAttendancePhoto2 ? history.UploadTrainingAttendancePhoto2 : ''}</label>
                                <label> {history.UploadTrainingAttendancePhoto3 ? history.UploadTrainingAttendancePhoto3 : ''}</label> */}
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineSeparator>
                                <span className="separator">-</span>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <label>Training Attendance Document</label>
                                {/* <label> {history.UploadTrainingAttendanceDocument1 ? history.UploadTrainingAttendanceDocument1 : ''}</label>
                                <label> {history.UploadTrainingAttendanceDocument2 ? history.UploadTrainingAttendanceDocument2 : ''}</label>
                                <label> {history.UploadTrainingAttendanceDocument3 ? history.UploadTrainingAttendanceDocument3 : ''}</label> */}
                            </TimelineContent>
                        </TimelineItem>
                    </>
        }

        {activityOption && activityOption.key === PARTNER_BRANCH_VISIT_PLAN && ChannelID === 21 &&
          <>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <label>Partner Met</label>
                <label> {history.IsPartnerMet ? "Yes" : "No"}</label>
              </TimelineContent>
            </TimelineItem>

            {history.PartnerName &&
              <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Partner Name</label>
                  <label> {history.PartnerName}</label>
                </TimelineContent>
              </TimelineItem>
            }
            {history.MobileNo &&
              <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Partner Mobile Number</label>
                  <label>{history.MobileNo}</label>
                </TimelineContent>
              </TimelineItem>
            }
              <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Remarks</label>
                  <label>{history.Remarks? history.Remarks : "N/A"}</label>
                </TimelineContent>
              </TimelineItem>
          </>
        }

        {activityOption && (activityOption.key === PRODUCT_LAUNCH ||
          activityOption.key === BIRTHDAY_CELEBRATION) &&
          <>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <label>No of Attendees</label>
                <label> {history.NoOfAttendees}</label>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
              </TimelineSeparator>
              <TimelineContent>
                <label>Remarks</label>
                <label>{remarks}</label>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <label>Photo Upload</label>
                <label> {history.UploadPhoto}</label>
              </TimelineContent>
            </TimelineItem>
          </>
        }

        {activityOption && activityOption.key === BRANCH_FOUNDATION_DAY &&
          <>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <label>Activity Done</label>
                <label> {history.BFDActivityName}</label>
                <br></br>
                {history.OtherBFDActivity ? <><label>Provide details</label>
                  <label>{history.OtherBFDActivity}</label></> : ""}
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <label>No of Attendees</label>
                <label> {history.NoOfAttendees}</label>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
              </TimelineSeparator>
              <TimelineContent>
                <label>Remarks</label>
                <label>{remarks}</label>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <label>Photo Upload</label>
                <label> {history.UploadPhoto}</label>
              </TimelineContent>
            </TimelineItem>
          </>
        }

        {activityOption && activityOption.key === JOINT_CALL_WITH_PARTNER &&
          <>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <label>No of calls done</label>
                <label> {history.NoOfCallsDone}</label>
              </TimelineContent>
            </TimelineItem>
            {history.JointCallActivityName === "Other" && <><TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <label>No of Attendees</label>
                <label> {history.NoOfAttendees}</label>
              </TimelineContent>
            </TimelineItem></>}

            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
              </TimelineSeparator>
              <TimelineContent>
                <label>Remarks</label>
                <label>{remarks}</label>
              </TimelineContent>
            </TimelineItem>
          </>
        }

        {activityOption && (activityOption.key === BUSINESS_DEVELOPMENT_ACTIVITY ||
          activityOption.key === BRANCH_HEAD_MEET || activityOption.key === PARTNER_VISIT ||
          activityOption.key === PRODUCT_TRAINING) &&
          <>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <label>Partner Met</label>
                <label> {history.IsPartnerMet ? "Yes" : "No"}</label>
              </TimelineContent>
            </TimelineItem>
          </>
        }

        {activityOption && activityOption.key === PRODUCT_TRAINING &&
          <>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <label>No of Attendees</label>
                <label> {history.NoOfAttendees}</label>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <label>Topics Covered</label>
                <label> {history.TopicsCoveredName}</label>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <label>Training Attendee</label>
                <label> {history.TrainingAttendeeName}</label>
                <br></br>
                <table style={{ width: '100%' }}>
                  <tr>
                    <th className="th-header">Type of Attendee</th>
                    <th className="th-header">Count</th>
                  </tr>
                  <tr>
                    <td>Partner</td>
                    <td>{history.PartnerCount || 0}</td>
                  </tr>
                  <tr>
                    <td>Customer</td>
                    <td>{history.CustomerCount || 0}</td>
                  </tr>
                  <tr>
                    <td>Staff</td>
                    <td>{history.StaffCount || 0}</td>
                  </tr>
                  <tr>
                    <td>Field</td>
                    <td>{history.FieldCount || 0}</td>
                  </tr>
                  <tr>
                    <td>BM</td>
                    <td>{history.BMCount || 0}</td>
                  </tr>
                </table>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
              </TimelineSeparator>
              <TimelineContent>
                <label>Remarks</label>
                <label>{remarks}</label>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <label>Photo Upload</label>
                <label> {history.UploadPhoto}</label>
              </TimelineContent>
            </TimelineItem>
          </>
        }

        {activityOption && activityOption.key === BUSINESS_DEVELOPMENT_ACTIVITY && history.IsPartnerMet &&
          <>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
              </TimelineSeparator>
              <TimelineContent>
                <label>Name of the Person</label>
                <label>{history.Name}</label>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
              </TimelineSeparator>
              <TimelineContent>
                <label>Designation</label>
                <label>{history.DesignationName}</label>
                <br></br>
                {history.DesignationName === "Other" ? <><label>Provide details</label>
                  <label>{history.OtherDesignation}</label></> : ""}
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
              </TimelineSeparator>
              <TimelineContent>
                <label>Plan of Action/Remarks</label>
                <label>{history.PlanOfAction}</label>
              </TimelineContent>
            </TimelineItem>

          </>
        }


        {activityOption && (activityOption.key === BRANCH_HEAD_MEET || activityOption.key === PARTNER_VISIT) && history.IsPartnerMet &&
          <>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
              </TimelineSeparator>
              <TimelineContent>
                <label>Name</label>
                <label>{history.Name}</label>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
              </TimelineSeparator>
              <TimelineContent>
                <label>Designation</label>
                <label>{history.OtherDesignation}</label>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
              </TimelineSeparator>
              <TimelineContent>
                <label>Mobile no</label>
                <label>{history.MobileNo}</label>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
              </TimelineSeparator>
              <TimelineContent>
                <label>Remarks</label>
                <label>{remarks}</label>
              </TimelineContent>
            </TimelineItem>

          </>
        }

        {activityOption && activityOption.key === LIVE_LEAD_GENERATION &&
          <>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <label>No of Leads contacted</label>
                <label> {history.NoOfLeads}</label>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
              </TimelineSeparator>
              <TimelineContent>
                <label>Remarks</label>
                <label>{remarks}</label>
              </TimelineContent>
            </TimelineItem>
          </>
        }
                {activityOption && activityOption.key === NEW_BUSINESS &&
                   <>
                   <TimelineItem>
                            <TimelineSeparator>
                                <span className="separator">-</span>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <label>Expected Premium</label>
                                <label> ₹{history.ExpectedPremium}</label>
                            </TimelineContent>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineSeparator>
                                <span className="separator">-</span>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <label># of Leads</label>
                                <label>{history.NoOfLeads}</label>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineSeparator>
                                <span className="separator">-</span>
                            </TimelineSeparator>
                            <TimelineContent>
                                <label>Remarks</label>
                                <label>{remarks}</label>
                            </TimelineContent>
                        </TimelineItem>
                   </>
                }
                {activityOption && activityOption.key === JOINT_SALES_CALL_WITH_SRM &&
                   <>
                        <TimelineItem>
                            <TimelineSeparator>
                                <span className="separator">-</span>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <label>Joint call duration</label>
                                <label>{history.Fpmcallduration}</label>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineSeparator>
                                <span className="separator">-</span>
                            </TimelineSeparator>
                            <TimelineContent>
                                <label>Remarks</label>
                                <label>{remarks}</label>
                            </TimelineContent>
                        </TimelineItem>
                   </> 
                }


                {activityOption && activityOption.key === TRAINING && ChannelID ==3 &&
                    <>
                        <TimelineItem>
                            <TimelineSeparator>
                                <span className="separator">-</span>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <label>Training Topic</label>
                                <label> {history.TrainingTopic}</label>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineSeparator>
                                <span className="separator">-</span>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <label>Date of training </label>
                                <label> 
                                <span className="date">{history.DateOfTraining ? moment(history.DateOfTraining).format('DD MMM YYYY') : ""}</span>
                                        <span className="time">{history.DateOfTraining ? moment(history.DateOfTraining).format('h:mma') : ""}
                                        </span>
                                    </label>
                            </TimelineContent>
                        </TimelineItem>
                        {/* <TimelineItem>
                            <TimelineSeparator>
                                <span className="separator">-</span>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <label>Supporting document(Attendance sheet)</label>
                                <label> {history.UploadTrainingAttendancePhoto1 ? history.UploadTrainingAttendancePhoto1 : ''}</label>
                                <label> {history.UploadTrainingAttendancePhoto2 ? history.UploadTrainingAttendancePhoto2 : ''}</label>
                                <label> {history.UploadTrainingAttendancePhoto3 ? history.UploadTrainingAttendancePhoto3 : ''}</label>
                            </TimelineContent>
                        </TimelineItem> */}
                        {/* <TimelineItem>
                            <TimelineSeparator>
                                <span className="separator">-</span>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <label>Supporting document(Group photo of the training)</label>
                                <label> {history.UploadTrainingAttendanceDocument1 ? history.UploadTrainingAttendanceDocument1 : ''}</label>
                                <label> {history.UploadTrainingAttendanceDocument2 ? history.UploadTrainingAttendanceDocument2 : ''}</label>
                                <label> {history.UploadTrainingAttendanceDocument3 ? history.UploadTrainingAttendanceDocument3 : ''}</label>
                            </TimelineContent>
                        </TimelineItem> */}
                        <TimelineItem>
                            <TimelineSeparator>
                                <span className="separator">-</span>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <label>No. of participants </label>
                                <label> {history.ActualNoOfTrainees}</label>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineSeparator>
                                <span className="separator">-</span>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <label>Remarks</label>
                                <label> {history.Remarks}</label>
                            </TimelineContent>
                        </TimelineItem>
                    </>
                }
                


                {activityOption && activityOption.key === CALLING_TO_VLE_RAP_DM && <>
                    <TimelineItem>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Number of Calls done</label>
                            <label> {history.NumberOfCallsDone}</label>
                        </TimelineContent>
                    </TimelineItem>
                </>}
                {activityOption && activityOption.key === MONTHLY_REVIEW && <>
                    <TimelineItem>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Premium Amount</label>
                            <label> {history.PremiumAmount}</label>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Number of Proposals</label>
                            <label> {history.NoOfPoliciesPlanned}</label>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>RAP Activated</label>
                            <label> {history.NoOfRAPActivationPlanned}</label>
                        </TimelineContent>
                    </TimelineItem>
                </>}

                {activityOption && activityOption.key === MEETING_VISIT && <>
                    <TimelineItem>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Subject/Agenda of meeting </label>
                            <label> {history.SubjectAgendaOfMeeting}</label>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Remarks</label>
                            <label> {remarks}</label>
                        </TimelineContent>
                    </TimelineItem>
                </>}
                 {activityOption && activityOption.key === REVIEW_MEETING && <>
                    <TimelineItem>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>New Business Premium Expected</label>
                            <label> {history.ExpectedPremium}</label>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Persistency Expected Premium </label>
                            <label> {history.PersistencyExpectedPremium}</label>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Remarks</label>
                            <label> {history.Remarks}</label>
                        </TimelineContent>
                    </TimelineItem>
                   
                </>}

                {activityOption && activityOption.key === NEW_RECRUITMENT_ACTIVITY && <>
                    <TimelineItem>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Number of Leads</label>
                            <label> {history.NoOfLeads}</label>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Remarks</label>
                            <label> {history.Remarks}</label>
                        </TimelineContent>
                    </TimelineItem>
                   
                </>}
                {history.IsClaimRequired &&
                    <ActivityTravelHistory history={history} styletimeline={props.styletimeline} />
                }
                {activityOption && activityOption.key === MEETING_VISIT && <>
                    <TimelineItem>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Photo Upload</label>
                            <label> {history.UploadPhoto}</label>
                        </TimelineContent>
                    </TimelineItem>
                </>}
                          {/* {activityOption && activityOption.key === JOINT_CALLS_BDM||
          activityOption.key ===SCHEDULE_STRUCTURED_REVIEW_BDM||
          activityOption.key === BRANCH_VISIT_PLAN_BDM||
          activityOption.key === REGIONAL_OFFICE_MEETING_ABH||
          activityOption.key === SALES_TRAINING_PLAN_BDM||
          activityOption.key === CIRCULAR_FOR_SALES_ENABLEMENT_ABH
          ? (
            <>
            {activityOption.key !== CIRCULAR_FOR_SALES_ENABLEMENT_ABH && <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <label>Review Duration</label>
                <label>{history.ActivityDurationID}</label>
              </TimelineContent>
            </TimelineItem>}
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <label>Reschedule Date</label>
                <label>
                  {historyDate} {historyTime}
                </label>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
              </TimelineSeparator>
              <TimelineContent>
                <label>Remarks</label>
                <label>{remarks}</label>
              </TimelineContent>
            </TimelineItem>
          </>
          ) : (
            ""
          )} */}
          {activityOption && (activityOption.key === JOINT_CALLS_BDM||
          activityOption.key ===SCHEDULE_STRUCTURED_REVIEW_BDM||
          activityOption.key === BRANCH_VISIT_PLAN_BDM||
          activityOption.key === REGIONAL_OFFICE_MEETING_ABH) && 
          <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <label>Review Duration</label>
            <label>{`${history?.ActivityDuration} mins`}</label>
          </TimelineContent>
        </TimelineItem>
        }
        {activityOption && (activityOption.key === SME_LF||
          activityOption.key ===SME_CELL||
          activityOption.key === BRANCH||
          activityOption.key === COLLATE_THE_UNQUALIFIED_LEADS_DATA|| 
          activityOption.key === REGIONAL_OFFICE_CONNECT) && 
          <>
          <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <label>BDM met the Credit Head Officer Loan Officer </label>
            <label>{`${history?.BDMMetCreditHeadOfficer ? "Yes" : "No"}`}</label>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Credit Head Officer Loan Officer Name</label>
          <label>{`${history?.CreditHeadOfficerName}`}</label>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
      <TimelineSeparator>
        <span className="separator">-</span>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <label>Mobile No</label>
        <label>{`${history?.CreditHeadOfficerMobileNo}`}</label>
      </TimelineContent>
    </TimelineItem>
    <TimelineItem>
      <TimelineSeparator>
        <span className="separator">-</span>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <label>Remark</label>
        <label>{`${history?.Remarks}`}</label>
      </TimelineContent>
    </TimelineItem>
        </>
        }
        {activityOption && activityOption.key ===SCHEDULE_STRUCTURED_REVIEW_BDM && 
        <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <label>Action plan as agreed with BDM</label>
            <label>{history.ActionPlanasAgreedWithBDM}</label>
          </TimelineContent>
        </TimelineItem>
        }
        {activityOption && activityOption.key === BRANCH_VISIT_PLAN_BDM && 
          <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <label>Met Branch Manager</label>
            <label>{history.MetBranchManager ? "Yes" : "No"}</label>
          </TimelineContent>
        </TimelineItem>
        }
        {activityOption && (
          activityOption.key === SALES_TRAINING_PLAN_BDM) && 
          <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <label>Training Duration</label>
            <label>{`${history?.ActivityDuration} mins`}</label>
          </TimelineContent>
        </TimelineItem>
        }
                  {activityOption && (
          activityOption.key === BRANCH_VISIT_PLAN_BDM||
          activityOption.key === SALES_TRAINING_PLAN_BDM) && 
          <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <label>Comments</label>
            <label>{history.Comments}</label>
          </TimelineContent>
        </TimelineItem>
        }
        {activityOption && activityOption.key === REGIONAL_OFFICE_MEETING_ABH && 
        <>
          <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <label>Discuss Current Campaign</label>
            <label>{history.DiscussCurrentCampaign}</label>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <label>Discuss if the Region is Qualified for it</label>
            <label>{history.DiscussIfTheRegionisQualifiedforit}</label>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <label>Actionables Decided</label>
            <label>{history.ActionablesDecided}</label>
          </TimelineContent>
        </TimelineItem>
        </>
        }
        {activityOption && activityOption.key === SALES_TRAINING_PLAN_BDM && 
        <>
          <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <label>Completed</label>
            <label>{history.ISCompleted? "Yes" : "No" }</label>
          </TimelineContent>
        </TimelineItem>
        </>
        }
        {activityOption && activityOption.key === JOINT_CALLS_BDM && 
        <>
          <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <label>Customer Name</label>
            <label>{history.CustomerName}</label>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <label>Premium Amount</label>
            <label>{history.PremiumAmount}</label>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <label>Closed</label>
            <label>{history.ISClosed ? "Yes" : "No" }</label>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <label>Policy Application Number</label>
            <label>{history.PolicyApplicationNumber}</label>
          </TimelineContent>
        </TimelineItem>
        </>
        }
        {activityOption && activityOption.key === CIRCULAR_FOR_SALES_ENABLEMENT_ABH && 
        <>
          <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <label>Circular Title</label>
            <label>{history.CircularTitle}</label>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            {uploadPdfFileLoader == true ? <label className="link-label" onClick={() => getBase64Data('UploadPdfFile')}>Details of Circular</label> : <>Loading</>}
                {
                  uploadPdfFileData &&
                  <img className='bill-copy' alt="uploaded bill" src={uploadPdfFileData}/>
                }
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <label>Closed?</label>
            <label>{history.CircularClosed ? history.CircularClosed : "N/A"}</label>
          </TimelineContent>
        </TimelineItem>
        </>
        }
          
          {history.UploadBill &&
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                {loader == true ? <label className="link-label" onClick={() => getBase64Data('UploadBill')}>View uploaded Bill</label> : <>Loading</>}
                {
                  fileData &&
                  <img className='bill-copy' alt="uploaded bill" src={fileData}/>
                }
              </TimelineContent>
            </TimelineItem>
          }

        {history.UploadTrainingAttendancePhoto1 &&
          <TimelineItem>
            <TimelineSeparator>
              <span className="separator">-</span>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              {trainingAttendancePhotoLoader == true ? <label className="link-label" onClick={() => getBase64Data("trainingAttendancePhotoOne")}>View photo 1</label> : <>Loading</>}
              {
                trainingAttendancePhotoOnetFileData &&
                <img className='bill-copy' alt="training attendance" src={trainingAttendancePhotoOnetFileData} />
              }
            </TimelineContent>
          </TimelineItem>
        }

        {history.UploadTrainingAttendancePhoto2 &&
          <TimelineItem>
            <TimelineSeparator>
              <span className="separator">-</span>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              {trainingAttendancePhotoLoader2 == true ? <label className="link-label" onClick={() => getBase64Data("trainingAttendancePhotoTwo")}>View photo 2</label> : <>Loading</>}
              {
                trainingAttendancePhotoTwotFileData &&
                <img className='bill-copy' alt="activity bill" src={trainingAttendancePhotoTwotFileData} />
              }
            </TimelineContent>
          </TimelineItem>
        }

        {history.UploadTrainingAttendancePhoto3 &&
          <TimelineItem>
            <TimelineSeparator>
              <span className="separator">-</span>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              {activityLoader == true ? <label className="link-label" onClick={() => getBase64Data("photoOfTheActivity")}>View photo 3</label> : <>Loading</>}
              {
                activityPhotoFileData &&
                <img className='bill-copy' alt="activity bill" src={activityPhotoFileData} />
              }
            </TimelineContent>
          </TimelineItem>
        }

        {history.UploadTrainingAttendanceDocument1 &&
          <TimelineItem>
            <TimelineSeparator>
              <span className="separator">-</span>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              {attendanceDocumentLoader == true ? <label className="link-label" onClick={() => getBase64Data("TrainingAttendanceDocument")}>View uploaded training attendance document</label> : <>Loading</>}
              {
                attendanceDocumentFileData &&
                <img className='bill-copy' alt="training attendance document" src={attendanceDocumentFileData} />
              }
            </TimelineContent>
          </TimelineItem>
        }

        {history.CallingToVLEUploadPhoto &&
          <TimelineItem>
            <TimelineSeparator>
              <span className="separator">-</span>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              {callingToVLEUploadPhotoLoader == true ? <label className="link-label" onClick={() => getBase64Data("CallingToVLEUploadPhoto")}>View uploaded calling VLE photo</label> : <>Loading</>}
              {
                callingToVLEFileData &&
                <img className='bill-copy' alt="Calling To VLE" src={callingToVLEFileData} />
              }
            </TimelineContent>
          </TimelineItem>
        }
          {activityOption && activityOption.key === TEAM_REVIEW &&
        <>
          <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <label>Duration</label>
            <label>{`${history?.ActivityDuration} mins`}</label>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <span className="separator">-</span>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <label>Remarks</label>
            <label>{history.Remarks ? history.Remarks : 'N/A'}</label>
          </TimelineContent>
        </TimelineItem>
        </>
        }
      </> : ''}
  </>
};
export default CompletedHistory;
