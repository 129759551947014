import moment from "moment";
export const getOptionById = (id, optionValues) =>{
    return optionValues.find(item => item.value === id)
}

export const getOptionByCode = (key, optionValues) =>{
    return optionValues.find(item => item.key === key)
}
export const getBranchDataGCL = (optionList, inputId) => {
  console.log('getBranchDataGCL', "branchType" in optionList)
  const collection = "branchType" in optionList ? optionList['branchType'] : optionList;
  console.log(optionList);
  if (inputId && collection) {
    const name = collection.filter((el) => el.BranchID === inputId.toString() || el.value === inputId.toString());
    if (name && name.length > 0) {
      return name[0];
    }
    return null;
  }
  return null;
}
export const getActivityDataGCL = (optionList, inputId) => {
  console.log('getActivityDataGCL', "gclType" in optionList)
  const collection = "gclType" in optionList ? optionList['gclType'] : optionList;
  if (inputId && collection) {
    const name = collection.filter((el) => el.value === inputId.toString());
    if (name && name.length > 0) {
      return name[0].label;
    }
    return null;
  }
  return null;
}
export const getActivitySmaName = (activityType, activityTypeId, MeetingStartDateTime, branchId, branchList) => {
  if (!activityType && activityType.length <= 0) {
    return [];
  }
  let str='';
  const matched = activityType.filter((item) => item.value === activityTypeId)
  if (matched.length > 0) {
    str = matched[0].label;
  }
  let branchCodeDataMap = '';
  if (activityTypeId === '27') {
    const matchedBranch = branchList.filter((branch) => branch.value === branchId)
    const remove_after = matchedBranch[0].label.lastIndexOf('-');
    branchCodeDataMap =  matchedBranch[0].label.substring(remove_after);
    const result =  matchedBranch[0].label.substring(0, remove_after);
    str+=`(${result.trim()})`
  }
  if(MeetingStartDateTime !==null && MeetingStartDateTime !== undefined){
    const MeetingDate =  moment(MeetingStartDateTime).format('DD-MMM');
    str+=`-${MeetingDate}`
  }
  if (branchCodeDataMap !=='') {
    str+=`${branchCodeDataMap.trim()}`
  }
  return str;
}
export const getSubTitles = (ActivityName, branchCodeData, branchNameData, MeetingStartDateTime)=>{
    let str=""

    if(ActivityName !=null && ActivityName !=="" && typeof ActivityName != "undefined"){

      str+=ActivityName
    }

    // if(branchCodeData !==null && branchCodeData !=="" && branchCodeData !== undefined){
    //   str+=`-${branchCodeData}`
    // }
    let branchCodeDataMap = '';
    if(branchNameData !==null && branchNameData !==""&& branchNameData !== undefined){
      const remove_after = branchNameData.lastIndexOf('-');
      branchCodeDataMap =  branchNameData.substring(remove_after);
      const result =  branchNameData.substring(0, remove_after);
      str+=`(${result})`
    }

    if(MeetingStartDateTime !==null && MeetingStartDateTime !== undefined){
      const MeetingDate =  moment(MeetingStartDateTime).format('DD-MMM');
      str+=`-${MeetingDate}`
    }

    if(branchCodeDataMap !=="" && branchCodeDataMap !== undefined){
      str+=`${branchCodeDataMap}`
    }
    return str
}

export const getSubTitlesBroca = (ActivityName,branchCodeData,branchNameData,MeetingStartDateTime)=>{
  let str=""

  if(ActivityName !=null && ActivityName !=="" && typeof ActivityName != "undefined"){

    str+=ActivityName
  }

  // if(branchCodeData !==null && branchCodeData !=="" && branchCodeData !== undefined){
  //   str+=`-${branchCodeData}`
  // }

  if(branchNameData[0].label !==null && branchNameData[0].label !==""&& branchNameData[0].label !== undefined){
    str+=`(${branchNameData[0].label})`
  }

  if(MeetingStartDateTime !==null && MeetingStartDateTime !== undefined){
    const MeetingDate =  moment(MeetingStartDateTime).format('DD-MMM');
    str+=`-${MeetingDate}`
  }

  if(branchNameData[0].key !==null && branchNameData[0].key !=="" && branchNameData[0].key !== undefined){
    str+=`-${branchNameData[0].key}`
  }
  return str
}

export const getSubTitlesForCampaigns = (CampaignName,branchCodeData,branchNameData,MeetingStartDateTime)=>{
  let str="Campaigns-"

  if(CampaignName !=null && CampaignName !=="" && typeof CampaignName != "undefined"){

    str+=CampaignName
  }

  if(MeetingStartDateTime !==null && MeetingStartDateTime !== undefined){
    const MeetingDate =  moment(MeetingStartDateTime).format('MMM-DD');
    str+=`-${MeetingDate}`
  }
  return str
}
