import React from "react";
import "../activity/activityDetails/activityDetails.scss";
import {
  getActivityDetails,
  getActivityDetailsHistory,
} from "../../../redux/actions/activityDetailsAction";
import {
  activateLoader,
  deActivateLoader,
  setBreadcrumbs,
} from "../../../redux/actions/common";
import { getSubTitles, getOptionById } from "../activity/helper";
import DetailLayout from "./detailLayout";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import {
  getActivityNameList,
  getActivityOffline,
  getActivityStatusNameList,
  getDistrictNameList,
  getStateNameList,
  getTrainingNameList,
} from "../../../offlineManager/activityOfflineManager";
import { getBranchList } from "../../../offlineManager/leadOfflineManager";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";


const SalesManagementActivityDetails = (props) => {
  const select = useSelector((state) => state);
  const userState = select.userReducer;
  const dispatch = useDispatch();
  const [activityData, setActivityData] = useState({});
  const [activityHistoryData, setActivityHistoryData] = useState([]);
  const [activityTitle, setActivityTitle] = useState("");
  const [optionList, setOptionList] = useState({
    branchType: [],
    activityNameType: [],
    activityStatusType: [],
    stateList: [],
    districtList: [],
    trainingForList: [],
  });

  const SetActivityTitle = (activityData) => {
    let title = "";
    if(userState.profileInfo.channelid === 21)
    {
    if (activityData.BranchId) {
      const branchOption = getOptionById(
        activityData.BranchId.toString(),
        select.commonReducer.Branches
      );
      title = getSubTitles(
        activityData.ActivityName,
        branchOption?.key,
        branchOption?.label,
        activityData.MeetingStartDateTime,
        setActivityData({...activityData, branchCode: branchOption ? branchOption.key : ""})
      );
    } else {
      title = getSubTitles(
        activityData.ActivityName,
        "",
        "",
        activityData.MeetingStartDateTime
      );
    }
    setActivityTitle(title);
  }
  else{
    if (activityData.BranchId) {
      const branchOption = getOptionById(
        activityData.BranchId.toString(),
        select.commonReducer.Branches
      );
      title = getSubTitles(
        activityData.ActivityName,
        branchOption?.key,
        branchOption?.label,
        activityData.MeetingStartDateTime
      );
    } else {
      title = getSubTitles(
        activityData.ActivityName,
        "",
        "",
        activityData.MeetingStartDateTime
      );
    }
    setActivityTitle(title);
  }
  };

  const getBranchName = (inputId) => {
    const collection = optionList["branchType"];
    if (inputId && collection) {
      const name = collection.filter(
        (el) => el.BranchID === inputId?.toString()
      );
      if (name && name.length > 0) {
        return name[0].BranchName;
      }
      return null;
    }
    return null;
  };

  const getAssigneTohName = (inputId) => {
    const collection = optionList["branchType"];
    if (inputId && collection) {
      const name = collection.filter((el) => el.UserID === inputId);
      if (name && name.length > 0) {
        return `${name[0].FirstName}${" "} ${name[0].LastName}`;
      }
      return null;
    }
    return null;
  };

  const getActivityName = (inputId) => {
    const collection = optionList["activityNameType"];
    if (inputId && collection) {
      const name = collection.filter((el) => el.value === inputId.toString());
      if (name.length > 0) {
        return name[0].label;
      }
      return null;
    }
    return null;
  };

  const getActivityStatusName = (inputId) => {
    const collection = optionList["activityStatusType"];
    if (inputId && collection) {
      const name = collection.filter((el) => el.value === inputId.toString());
      if (name.length > 0) {
        return name[0].label;
      }
      return null;
    }
    return null;
  };

  const getStateName = (inputId) => {
    const collection = optionList["stateList"];
    if (inputId && collection) {
      const name = collection.filter((el) => el.value === inputId.toString());
      if (name.length > 0) {
        return name[0].label;
      }
      return null;
    }
    return null;
  };

  const getDistrictName = (inputId) => {
    const collection = optionList["districtList"];
    if (inputId && collection) {
      const name = collection.filter((el) => el.value === inputId.toString());
      if (name.length > 0) {
        return name[0].label;
      }
      return null;
    }
    return null;
  };
  const getTrainingForName = (inputId) => {
    const collection = optionList["trainingForList"];
    if (inputId && collection) {
      const name = collection.filter((el) => el.value === inputId.toString());
      if (name.length > 0) {
        return name[0].label;
      }
      return null;
    }
    return null;
  };

  const getOptionData = () => {
    Promise.all([
      getBranchList(),
      getActivityStatusNameList(),
      getActivityNameList(),
      getStateNameList(),
      getDistrictNameList(),
      getTrainingNameList(),
    ]).then((res) => {
      if (res && res.length > 0) {
        const optionState = {
          branchType: res[0],
          activityStatusType: res[1],
          activityNameType: res[2],
          stateList: res[3],
          districtList: res[4],
          trainingForList: res[5],
        };
        setOptionList(optionState);
        const activityId = props.match.params.id;
        getActivityDetailsOffline(activityId);
      }
    });
  };
  const getActivityDetailsOffline = (activityId) => {
    getActivityOffline(activityId).then((result) => {
      const activityModel = {
        ActivityDoneWith: null,
        ActivityID: result?.ActivityID,
        ActivityName: getActivityName(result?.ActivityID),
        ActivityStatus: getActivityStatusName(result?.ActivityStatusId),
        ActivityStatusId: result?.ActivityStatusId,
        ActivitySubTypeId: null,
        ActivitySubTypeName: null,
        ActualNoOfTrainees: null,
        Address: result?.Address,
        AdvisorID: null,
        AssignedTo: result?.AssignedToName
          ? result?.AssignedToName
          : getAssigneTohName(result?.AssignedTo),
        AverageTicketSize: result?.AverageTicketSize,
        BillAmount: null,
        BillDate: null,
        BillFileName: null,
        BillNumber: null,
        BranchId: result?.BranchId,
        BranchName: result.BranchId
          ? getBranchName(result.BranchId)
          : result?.UserName,
        CallingToVLERAPDM: null,
        ChannelID: null,
        ChannelName: null,
        CheckinCount: result?.CheckinCount,
        ChekinEnabled: true,
        CreatedBy: null,
        CreatedDate: result?.CreatedDate,
        CurrentState: null,
        DistrictID: null,
        DistrictName: getDistrictName(result.DistrictID),
        ExpectedConversion: result?.ExpectedConversion,
        ExpectedLead: result?.ExpectedLead,
        ExpectedPremium: null,
        ExpenseAmount: null,
        ExpenseClaim: null,
        ExpenseTypeId: null,
        ExpenseTypeName: null,
        IsActive: true,
        IsBacklog: result?.IsBacklog,
        IsCheckin: result?.IsCheckin,
        IsClaimRequired: null,
        IsDeleted: false,
        Location: result?.Location,
        MeetingEndDateTime: result?.MeetingEndDateTime,
        MeetingStartDateTime: result?.MeetingStartDateTime,
        MeetingType: null,
        MeetingWith: null,
        ModifiedBy: null,
        ModifiedDate: result?.ModifiedDate,
        NewFormType: null,
        NoOfCallsPlanned: null,
        NoOfExpectedLeadsConverted: null,
        NoOfExpectedLeadsGenerated: null,
        NoOfLeads: null,
        NoOfPoliciesPlanned: result?.NoOfPoliciesPlanned,
        NoOfRAPActivationPlanned: result?.NoOfRAPActivationPlanned,
        NumberOfCallsDone: null,
        NumberOfProposals: null,
        OutcomeOfCall: null,
        PlannedForNoOfPeople: result?.PlannedForNoOfPeople,
        PremiumAmount: null,
        RAPActivated: null,
        RAP_VLE: null,
        Reason: null,
        RecruitmentFor: null,
        Remarks: null,
        ReviewWith: result?.ReviewWith,
        StateID: null,
        StateName: getStateName(result.StateID),
        SubjectAgendaOfMeeting: null,
        TicketSize: result?.TicketSize,
        TrainingFor: result.TrainingFor,
        TrainingForName: getTrainingForName(result.TrainingFor),
        TrainingTopic: result?.TrainingTopic,
        TransportMode: null,
        TransportModeID: null,
        TravelExpence: result?.TravelExpence,
        TrnActivityID: result?.TrnActivityID,
        UploadBill: null,
        UploadPhoto: null,
        UploadTrainingAttendanceDocument1: null,
        UploadTrainingAttendanceDocument2: null,
        UploadTrainingAttendanceDocument3: null,
        UploadTrainingAttendancePhoto1: null,
        UploadTrainingAttendancePhoto2: null,
        UploadTrainingAttendancePhoto3: null,
        UserId: result?.UserId,
        UserName: result?.UserName ? result?.UserName : result?.AssignedToName,
        VLERAPDMMobile: null,
        VehicleTypeID: null,
        ZMAID: null,
        LmsActivityGuid: result?.LmsActivityGuid,
        LmsActivitylastSyncTime: result?.LmsActivitylastSyncTime,
        IsLmsSync: result?.IsLmsSync,
      };
      if (result) {
        setActivityData(activityModel);
        SetActivityTitle(result);
      }
    });
  };

  const loadData = async () => {
    dispatch(activateLoader());
    const activityId = props.match.params.id;
    if (!activityId) {
      props.history.push("/app/activity/all");
    }
    const breadcrumbs = [
      { name: "Sales Management", url: `/app/activity/smaActivities` },
      { name: "Activity Details", url: `/app/salesmanagement/update/${activityId}` },
    ];
    dispatch(setBreadcrumbs(breadcrumbs));
    if (navigator.onLine) {
      const result = await dispatch(
        getActivityDetails({ activityId: activityId })
      );
      if (result.status === 200) {
        dispatch(deActivateLoader());
        const data = result.data;
        setActivityData(data);
        SetActivityTitle(data);
        dispatch(deActivateLoader());
      }
    } else {
      getOptionData();
      dispatch(deActivateLoader());
    }
    if (navigator.onLine) {
      dispatch(activateLoader());
      const resultHistory = await dispatch(
        getActivityDetailsHistory({ activityId: activityId })
      );
      if (resultHistory.status === 200) {
        setActivityHistoryData(resultHistory.data);
        dispatch(deActivateLoader());
      }
    }
  };

  useEffect(() => {
    loadData();
  }, [props.match.params.id]);

  // const takeWordFirstLetter = (name) => {
  //   const str = [];
  //   if (name) {
  //     name.split(" ").map((el) => {
  //       str.push(el.charAt(0));
  //     });
  //   }
  //   return str.toString().replaceAll(",", "");
  // };

  const getUpdatedActivityHistory = async () => {
    if (navigator.onLine) {
      const activityId = props.match.params.id;
      dispatch(activateLoader());
      const resultHistory = await dispatch(
        getActivityDetailsHistory({ activityId: activityId })
      );
      if (resultHistory.status === 200) {
        setActivityHistoryData(resultHistory.data);
        dispatch(deActivateLoader());
      }
    }
  };


  return (
    <>
      <div className="mainContainer activityDetail">
        <Breadcrumb></Breadcrumb>
        <DetailLayout
          activityData={activityData}
          activityTitle={activityTitle}
          activityHistoryData={activityHistoryData}
          getActivityHistory={getUpdatedActivityHistory}
          loadData={loadData}
        ></DetailLayout>
      </div>
    </>
  );
};

export default SalesManagementActivityDetails;

