import React, {useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import {  useDispatch,useSelector } from 'react-redux';
import { getBDMReport } from '../../../redux/actions/dashboardAction';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    fontWeight:"bold",
    color: theme.palette.common.white,
    borderRight:"1px solid rgba(224, 224, 224, 1)",
    verticalAlign: 'bottom',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontWeight:"bold",
    borderBottom:'none',
    borderRight:"1px solid rgba(224, 224, 224, 1)",
    '&:first-child': {
        minWidth: 150,
      },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:nth-of-type(odd)': {
//     backgroundColor: theme.palette.action.hover,
//   },
  // hide last border
//   '&:last-child td, &:last-child th': {
//     border: 0,
//   },
}));



const BdmPerformance = () => {
    const dispatch = useDispatch()
    const commonState = useSelector(state => state)
    const userID = commonState.userReducer.profileInfo.userId;
    const roleId = commonState.userReducer.profileInfo.roleId;

    const [getBDMList, setGetBDMList] = useState([])
    console.log("getBDMList", getBDMList)
    
    const onUnload = e => {
        console.log('hello')
        e.preventDefault();
        e.stopPropation()
      };
    useEffect(()=>{
        window.addEventListener("beforeunload", onUnload);
        dispatch(getBDMReport({
            userId: userID,
            viewfor: "BDM",
            subordinateId: "all",
            RoleName: roleId
      
          }))
            .then((res) => {
              setGetBDMList(res.data)
            }).catch((err) => {
              console.log("err", err)
            })
      
    },[])


    const addSpaceBeforeCapital = (str) => {
        return str
          .split('')
          .map(char => (char === char.toUpperCase() && char !== ' ' ? ` ${char}` : char))
          .join('');
      }
    

  return (
    <div>
        <div style={{display:"flex",justifyContent:'center',alignItems:"center",color:'#365076',margin:'4px',fontWeight:'bold'}}>
           <Typography variant='h5'>BDM Performance</Typography>
        </div>
     <TableContainer component={Paper}>
           <Table sx={{ minWidth: 700 }} aria-label="customized table">
             <TableHead>
               <TableRow>
                 <StyledTableCell sx={{backgroundColor:'#053c6d'}}>BDM Name</StyledTableCell>
                 <StyledTableCell sx={{backgroundColor:'#365076'}} align="center">Total Cancelled Activities</StyledTableCell>
                 <StyledTableCell sx={{backgroundColor:'#365076'}} align="center">Total Completed Activities</StyledTableCell>
                 <StyledTableCell sx={{backgroundColor:'#365076'}} align="center">Total Converted Prospects</StyledTableCell>
                 <StyledTableCell sx={{backgroundColor:'#365076'}} align="center">Total Distance</StyledTableCell>
                 <StyledTableCell sx={{backgroundColor:'#365076'}} align="center">Total Expense</StyledTableCell>
                 <StyledTableCell sx={{backgroundColor:'#365076'}} align="center">Total Premium Collected</StyledTableCell>
                 <StyledTableCell sx={{backgroundColor:'#365076'}} align="center">Total Rescheduled Activities</StyledTableCell>
                 <StyledTableCell sx={{backgroundColor:'#365076'}} align="center">Weekly Activities</StyledTableCell>
                 <StyledTableCell sx={{backgroundColor:'#365076'}} align="center">Weekly Activities More than 20</StyledTableCell>
                 <StyledTableCell sx={{backgroundColor:'#365076'}} align="center">Weekly Activities target 10 to 20</StyledTableCell>
                 <StyledTableCell sx={{backgroundColor:'#365076'}} align="center">Weekly Activities target Less than 10</StyledTableCell>
               </TableRow>
             </TableHead>
                <TableBody>

               {getBDMList.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell sx={{backgroundColor:'#e3e4e3'}} component="th" scope="row">
                {addSpaceBeforeCapital(row.BDMName)}
              </StyledTableCell>
              <StyledTableCell sx={{color:row.charge >= 0 ? 'green' :'red'}} align="center">{row.TotalCancelledActivities}</StyledTableCell>
              <StyledTableCell align="center">{row.TotalCompletedActivities}</StyledTableCell>
              <StyledTableCell align="center">{row.TotalConvertedProspects}</StyledTableCell>
              <StyledTableCell align="center">{row.TotalDistance}</StyledTableCell>
              <StyledTableCell align="center">{row.TotalExpense}</StyledTableCell>
              <StyledTableCell align="center">{row.TotalPremiumCollected}</StyledTableCell>
              <StyledTableCell align="center">{row.TotalRescheduledActivities}</StyledTableCell>
              <StyledTableCell align="center">{row.WeeklyActivities}</StyledTableCell>
              <StyledTableCell align="center">{row.WeeklyActivitiesMorethan20}</StyledTableCell>
              <StyledTableCell align="center">{row.WeeklyActivitiestarget10to20}</StyledTableCell>
              <StyledTableCell align="center">{row.WeeklyActivitiestargetLessthan10}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  )
}

export default BdmPerformance




