import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import { customValidators } from "./leadBacklogFilterForm";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { leadBacklogFilterForm } from "./leadBacklogFilterForm";
import { useSelector, useDispatch } from "react-redux";
import DynamicForm from "f1-dynamic-form-react";
import { t } from "i18next";
import { activateErrorMessageBar, getSubordinateList } from "../../../redux/actions";
import { getDropdownDataBranch } from '../../../utils/utils';
import { LEAD_STATUS as LEAD_OPTION, ROLE_ABH, USER_TYPE, ROLE_BDM, ROLE_FPM } from "../../../utils/config";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  drawerMobile: {
    display: "none",
    [theme.breakpoints.down('xs')]: {
      display: "block"
    },
    [theme.breakpoints.down('sm')]: {
      display: "block"
    }
  },
  desktopFilter: {
    [theme.breakpoints.down('xs')]: {
      display: "none"
    },
    [theme.breakpoints.down('sm')]: {
      display: "none"
    }
  }
}));

let leadActivityData = []

const LeadBacklogFilter = ({
  filterState,
  setFilterState,
  setbackLeadReq,
  handleClose,
  getAllBacklogList,
  getFilteredBacklogList,
  anchor,
  setSelectedFilter,
  IsRecruitmentlead,
  setStateAnchor,
  stateAnchor
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation()
  const locationParams = location.pathname.split('/')[2]
  const userState = useSelector(mainState => mainState.userReducer);
  const userData = JSON.parse(localStorage.getItem("persist:root"));
  const UserId = userState?.profileInfo?.userId
  const FullName = `${userState?.profileInfo?.firstName} ${userState?.profileInfo?.lastName}`;
  const [UserFullName, setUserFullName] = useState(FullName);

  const userName = `${JSON.parse(userData.userReducer).profileInfo.firstName
    } ${" "}${JSON.parse(userData.userReducer).profileInfo.lastName}`;
  const roleId = userState?.profileInfo?.roleId
  const channelId = userState.profileInfo.channelid;

  const { ProductDetails, SPType, LeadSource, Branches } =
    useSelector((state) => state.commonReducer);
  const { leadStatus, Activities, getCampaignNames } = useSelector(
    (state) => state.leadFormReducer
  );
  const meetingDateFormate = "YYYY-MM-DD";
  const { GetBDMByABH } = useSelector(bdmState => bdmState.commonReducer)

  const [state, setState] = useState({
    current: { id: 1 },
    formData: leadBacklogFilterForm,
    autoCompleteItems: {
      product: [],
      activityID: [],
      branchNameCode: []
    }
  });

  const [newUserList, setNewUserList] = useState([{
    key: UserId,
    label: UserFullName,
    value: UserId
  }])


  useEffect(() => {
    let recruitmentleadActivities = Activities?.ActivityID.filter((activity) => activity.activitytypeid === 14)
    let bussinessLeadActivities = Activities?.ActivityID.filter((activity) => activity.activitytypeid === 13)

    bussinessLeadActivities.unshift(Activities?.ActivityID[0])
    recruitmentleadActivities.unshift(Activities?.ActivityID[0])

    if (locationParams === "businesslead") {
      leadActivityData = bussinessLeadActivities
    } else if (locationParams === "recruitmentlead") {
      leadActivityData = recruitmentleadActivities
    } else {
      leadActivityData = Activities?.ActivityID
    }
  }, [])



  const getDropdownData = (optionsArr, key, label, value) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr.map((option) => {
        return { key: option[key], label: option[label], value: option[value] };
      });
    }
    arr.unshift({ key: "select", label: "Select", value: "" });
    return arr;
  };
  let LEAD_STATUS = getDropdownData(
    leadStatus,
    "LeadStatus",
    "LeadStatus",
    "LeadStatusId"
  );

  const CAMPAIGN_NAMES = getDropdownData(
    getCampaignNames,
    "CampaignNameID",
    "CampaignName",
    "CampaignNameID"
  )

  if (channelId == 1 && roleId == "BDM") {
    LEAD_STATUS = LEAD_STATUS?.filter(ele => ele.value == 1 || ele.value == 2 || ele.value == 3 || ele.value == 4 || ele.value == 7)
  }

  if (channelId == 1 && roleId == "ABH") {
    LEAD_STATUS = LEAD_STATUS?.filter(ele => ele.value == 1 || ele.value == 2 || ele.value == 3 || ele.value == 4 || ele.value == 5 || ele.value == 6 || ele.value == 7 || ele.value == 8 || ele.value == 10)
  }
  if (channelId === 21) {
    LEAD_STATUS = LEAD_STATUS.filter(opt => opt.value === '' || opt.value === 1 || opt.value === 2 || opt.value === 3
      || opt.value === 4 || opt.value === 5 || opt.value === 6 || opt.value === 7 || opt.value === 10)
  }

  const initFormData = (formFields) => {
    const formData = [...formFields];
    setFilterState({ ...filterState, UserName: userName });
    const BranchesCopy = [...Branches];
    formData[0].options = USER_TYPE
    formData[14].options = ProductDetails;
    formData[16].options = CAMPAIGN_NAMES;

    if (roleId === "BDM" || roleId === "ABH") {
      let banca_sp_type = ['SRM'];
      let banca_sp_arr = [];
      if (SPType && SPType.length > 0) {
        banca_sp_arr = SPType.filter((option) => {
          if (!banca_sp_type.includes(option.key)) {
            return option;
          }
        });
      }
      formData[15].options = banca_sp_arr;
    } else if (roleId === "FPM") {
      const sp_type = ['STAFF'];
      let filter_arr = [];
      if (SPType && SPType.length > 0) {
        filter_arr = SPType.filter((option) => {
          if (!sp_type.includes(option.key)) {
            return option;
          }
        });
      }
      formData[15].options = filter_arr;
    } else {
      formData[15].options = SPType;
    }

    if (userState.profileInfo.channelid === 3) {
      formData[15].hide = true;
    }
    formData[14].options = LeadSource;
    formData[4].options = LEAD_OPTION;
    formData[8].options = LEAD_STATUS

    // formData[12].options = getDropdownData(
    //   leadActivityData,
    //   "id",
    //   "label",
    //   "id"
    // ).slice(1);
    formData[13].options = getDropdownDataBranch(BranchesCopy, 'key', 'label', 'value');
    if (userState.profileInfo.channelid === 3) {
      formData[13].hide = true;
    }

    return formData;
  };

  const dynamicRef = React.useRef();

  const getDropdownOFData = (optionsArr, id, label) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr.map((option) => {
        return { "id": `${option[id]}`, "label": `${option[label]}` }
      })
    }
    arr.shift({ "id": " ", "label": "select" })
    arr.unshift({ "id": " ", "label": "select" })
    return arr;
  }

  const getActivityTypeOptions = (allActivityOptions) => {
    const processedOption = [{ key: "select", label: "Select", value: "" }];
    if (history?.location?.pathname === "/app/businesslead/all" && channelId === 3) {
      allActivityOptions.map(option => {
        if (option.activitytypeid === 13) {
          processedOption.push({
            "key": option.id,
            "label": option.label,
            "value": option.id
          })
        }
      })
      return processedOption
    } else if (history?.location?.pathname === "/app/recruitmentlead/all" && channelId === 3) {
      allActivityOptions.map(option => {
        if (option.activitytypeid === 14) {
          processedOption.push({
            "key": option.id,
            "label": option.label,
            "value": option.id
          })
        }
      })
      return processedOption
    } else if (channelId === 21) {
      allActivityOptions.map(option => {
        if (option.activitytypeid === 53) {
          processedOption.push({
            "key": option.id,
            "label": option.label,
            "value": option.id
          })
        }
      })
      return processedOption
    } else {
      return allActivityOptions
    }
  }


  useEffect(() => {
    if (roleId !== ROLE_BDM && roleId !== ROLE_FPM) {
      getSub()
    }
    const autoCompleteItems = { ...state.autoCompleteItems };
    autoCompleteItems.product = getDropdownOFData(ProductDetails, "key", "label");
    const copyData = Activities?.ActivityID;
    autoCompleteItems.activityID = getActivityTypeOptions(copyData)
    const BranchesCopy = [...Branches];
    autoCompleteItems.branchNameCode = getDropdownDataBranch(BranchesCopy, 'key', 'label', 'value');
    const formData = initFormData(state.formData);
    setState({ formData: formData, autoCompleteItems: autoCompleteItems });

    if (history?.location?.pathname === "/app/businesslead/all") {

      const businessfilterHide = ["userType", "product", "typeOfSP", "teamMember"]
      const businessfilterShow = ["leadSource", "LeadTypeID"]

      leadBacklogFilterForm.map((item, index) => {
        if (businessfilterHide.includes(item.key)) {
          leadBacklogFilterForm[index].hide = true
        } else if (businessfilterShow.includes(item.key)) {
          leadBacklogFilterForm[index].hide = false
        }
      })

    }
    if (history?.location?.pathname === "/app/recruitmentlead/all") {
      const recruitmentfilterHide = ["leadSource", "userType", "LeadTypeID", "product", "typeOfSP", "teamMember"]
      leadBacklogFilterForm.map((item, index) => {
        if (recruitmentfilterHide.includes(item.key)) {
          leadBacklogFilterForm[index].hide = true
        }
      })
    }
    if (channelId === 21) {
      const brocaLeadfilterHide = ["MeetingDate", "branchNameCode", "userType", "LeadTypeID", "product", "typeOfSP", "teamMember"]
      leadBacklogFilterForm.map((item, index) => {
        if (brocaLeadfilterHide.includes(item.key)) {
          leadBacklogFilterForm[index].hide = true
        }
        return item
      })
    }
  }, []);

  useEffect(() => {
    if (roleId === ROLE_ABH) {
      state.formData[0].hide = false
    }
  }, [roleId])

  useEffect(() => {
    // console.log(UserFullName)

    setFilterState({
      ...filterState,
      UserName: UserFullName
    })
  }, [UserFullName]);

  const getSub = async () => {
    const formData = [...state.formData]
    dispatch(getSubordinateList({ userId: userState.profileInfo.userId })).then((response) => {
      const r = response.data.map(el => {
        return {
          key: el.userID,
          label: el.userName,
          value: el.userID
        }
      });
      setNewUserList([...newUserList, ...r])
      formData[1].options = [...newUserList, ...r];

      if (GetBDMByABH !== 'all') {
        const res = response.data.filter((el) => {
          if (GetBDMByABH == el.userID)
            return true;
        });
        setUserFullName(res[0].userName)
        formData[0].hide = true;
        formData[1].hide = true;
        formData[2].hide = false;
      }
      // else {
      //   formData[0].hide = false;
      //   formData[1].hide = true;
      //   formData[2].hide = false;
      // }
    })
  }

  const onSubmitHandler = (model) => {
    if (model.currentStatus === 0) {
      model.currentStatus = null
    }
    if (model.LeadTypeID === 0) {
      model.LeadTypeID = null
    }
    if (model.branchNameCode === 0) {
      model.branchNameCode = null
    }
    if (model.activityIDlabel !== 0) {
      let activitykey = Activities?.ActivityID.filter((activity) => activity.label === model.activityIDlabel)
      model.activityID = activitykey[0]?.id
    } else {
      model.activityID = null
    }
    if (model.leadSource === 0) {
      model.leadSource = null
    }
    if (model.product === 0) {
      model.product = null
    }
    if (model.typeOfSP === 0) {
      model.typeOfSP = null
    }
    if (model.ActivityID === 0) {
      model.ActivityID = null
    }
    if (model.MobileNo === "") {
      model.MobileNo = null
    }

    if (model.teamMember === "" || model.teamMember === undefined) {
      model.teamMember = null
    }
    if (model.LeadName === "" || model.LeadName === undefined) {
      model.LeadName = null
    }
    const status = model.LeadCurrentStatus?.map((val) => {
      return val.value;
    })
    model.currentStatus = status?.join(',');
    if (model.currentStatus === "") {
      model.currentStatus = null
    }
    const { LeadName, LeadTypeID, CreatedDateTime, currentStatus, MeetingDate, ActivityID,
      branchNameCode, leadSource, product, typeOfSP, fromdate, todate, CreatedDateStartTIme,
      CreatedDateEndTIme, teamMember, MobileNo } = model;
    const branchNameValue = [...Branches].some((el) => el.value === branchNameCode)

    let meetingstart = null;
    let meetingend = null;
    let createdstart = null;
    let createdend = null;
    if (MeetingDate !== null && MeetingDate !== 'duration' && MeetingDate !== undefined) {
      const lastdatearr = model.MeetingDate.split(',');
      meetingstart = lastdatearr[0] ? lastdatearr[0] : null;
      meetingend = lastdatearr[1] ? lastdatearr[1] : null;
    }
    if (CreatedDateTime !== null && CreatedDateTime !== 'duration' && CreatedDateTime !== undefined) {
      const lastdatearr = model.CreatedDateTime.split(',');
      createdstart = lastdatearr[0] ? lastdatearr[0] : null;
      createdend = lastdatearr[1] ? lastdatearr[1] : null;
    }

    if (MeetingDate === 'duration') {
      if (fromdate === null || todate === null) {
        dispatch(activateErrorMessageBar('Please Select Value For Both Meeting Start Date and End Date'))
        return false
      }
      meetingstart = model.fromdate;
      meetingend = model.todate
    }
    if (CreatedDateTime === 'duration') {
      if (CreatedDateStartTIme === null || CreatedDateEndTIme === null) {
        dispatch(activateErrorMessageBar('Please Select Value For Both Created Start Date and End Date'))
        return false
      }
      createdstart = model.CreatedDateStartTIme;
      createdend = model.CreatedDateEndTIme
    }
    model.fromdate = meetingstart !== "null" ? meetingstart : null;
    model.todate = meetingend !== "null" ? meetingend : null;

    model.CreatedDateStartTIme = createdstart !== "null" ? createdstart : null;
    model.CreatedDateEndTIme = createdend !== "null" ? createdend : null;
    if (CreatedDateTime !== null || MeetingDate !== null
      || currentStatus !== null || branchNameCode !== null || typeOfSP !== null || product !== null
      || leadSource !== null || LeadTypeID !== null || ActivityID !== null || LeadName !== null || teamMember !== null || MobileNo !== null) {
      const submitPayload = {
        CreatedDateTime: CreatedDateTime,
        MeetingDate: MeetingDate,
        currentStatus: currentStatus,
        branchNameCode: branchNameCode,
        typeOfSP: typeOfSP,
        product: product,
        leadSource: leadSource,
        LeadTypeID: LeadTypeID,
        ActivityID: ActivityID,
        Leadname: LeadName,
        fromdate: model.fromdate ? model.fromdate : null,
        todate: model.todate ? model.todate : null,
        MobileNo: MobileNo,
      };
      setFilterState(submitPayload)
      setbackLeadReq(submitPayload)
      handleClose();
      getFilteredBacklogList(model, teamMember, branchNameValue);
    } else {
      dispatch(activateErrorMessageBar('Please Apply Any One Filter'))
    }
  };

  const onAutocompleteSelectHandler = (key, value, item) => {
    if (key === "branchNameCode") {
      setFilterState({
        branchNameCode: item.value
      })
    }
  }

  const onChangeHandler = (e, key) => {

    if (key === 'LeadName') {
      setFilterState({
        ...filterState,
        LeadName: e.target.value
      })
    }
    if (key === 'LeadTypeID') {
      setFilterState({
        ...filterState,
        LeadTypeID: e.target.value
      })
    }
    if (key === 'MobileNo') {
      setFilterState({
        ...filterState,
        MobileNo: e.target.value
      })
    }
    if (key === 'CreatedDateTime') {
      setFilterState({
        ...filterState,
        CreatedDateTime: e.target.value
      })
      if (e.target.value === "duration") {
        leadBacklogFilterForm[6].hide = false;
        leadBacklogFilterForm[7].hide = false;
        setFilterState({
          ...filterState,
          CreatedDateTime: e.target.value,
          CreatedDateStartTIme: moment(new Date()).format(meetingDateFormate),
          CreatedDateEndTIme: moment(new Date()).format(meetingDateFormate),
        });
      } else {
        leadBacklogFilterForm[6].hide = true;
        leadBacklogFilterForm[7].hide = true;
        setFilterState({
          ...filterState,
          CreatedDateTime: e.target.value,
          CreatedDateStartTIme: "null",
          CreatedDateEndTIme: "null",
        });
      }
    }
    if (key === 'currentStatus') {
      setFilterState({
        ...filterState,
        currentStatus: e.target.value
      })
    }
    if (key === 'LastUpdateDateTime') {
      setFilterState({
        ...filterState,
        LastUpdateDateTime: e.target.value
      })
    }
    if (key === 'leadSource') {
      setFilterState({
        ...filterState,
        leadSource: e.target.value
      })
    }
    if (key === 'activityID') {
      const activityNameValue = Activities?.ActivityID.filter((el) => el.id === e.target.value)
      setFilterState({
        ...filterState,
        activityID: e.target.value,
        activityIDlabel: activityNameValue[0]?.label
      })
    }
    if (key === 'product') {
      const branchNameValue = ProductDetails.filter((el) => el.key === parseInt(e.target.value))

      setFilterState({
        ...filterState,
        product: e.target.value,
        productlabel: branchNameValue[0]?.label
      })
    }
    if (key === 'typeOfSP') {
      setFilterState({
        ...filterState,
        typeOfSP: e.target.value
      })
    }
    if (key === 'MeetingDate') {
      setFilterState({
        ...filterState,
        MeetingDate: e.target.value
      })
      if (e.target.value === "duration") {
        leadBacklogFilterForm[10].hide = false;
        leadBacklogFilterForm[11].hide = false;
        setFilterState({
          ...filterState,
          MeetingDate: e.target.value,
          fromdate: moment(new Date()).format(meetingDateFormate),
          todate: moment(new Date()).format(meetingDateFormate),
        });
      } else {
        leadBacklogFilterForm[10].hide = true;
        leadBacklogFilterForm[11].hide = true;
        setFilterState({
          ...filterState,
          MeetingDate: e.target.value,
          fromdate: "null",
          todate: "null",
        });
      }
    }
    if (key === 'CreatedDateStartTIme') {
      setFilterState({
        ...filterState,
        CreatedDateStartTIme: e.target.value
      })
      leadBacklogFilterForm[7].min = e.target.value

    }
    if (key === 'CreatedDateEndTIme') {
      setFilterState({
        ...filterState,
        CreatedDateEndTIme: e.target.value
      })
    }
    if (key === 'fromdate') {
      setFilterState({
        ...filterState,
        fromdate: e.target.value
      })
      leadBacklogFilterForm[11].min = e.target.value
    }
    if (key === 'todate') {
      setFilterState({
        ...filterState,
        todate: e.target.value
      })
    }
    if (key === "userType") {
      const getUserType = e.target.value
      if (getUserType === "team") {
        state.formData[1].hide = false
        state.formData[2].hide = true
        // dispatch({
        //   type: "viewFor",
        //   payload: ""
        // })
        setSelectedFilter({
          roleId: "ABH",
          viewFor: "BDM"
        })
      } else {
        state.formData[1].hide = true
        state.formData[2].hide = false
        setSelectedFilter({
          roleId: "ABH",
          viewFor: "SELF"
        })
      }
    }
  };

  const onResetHandler = (anchorVerible) => {
    if (anchorVerible) {
      setStateAnchor({ ...stateAnchor, [anchorVerible]: false });
    }
    let teamMember = '';
    leadBacklogFilterForm[6].hide = true;
    leadBacklogFilterForm[7].hide = true;
    leadBacklogFilterForm[10].hide = true;
    leadBacklogFilterForm[11].hide = true;
    const payload = {
      LeadName: "",
      LeadTypeID: 0,
      CreatedDateTime: "null",
      CreatedDateStartTIme: "null",
      MeetingDate: "null",
      CreatedDateEndTIme: "null",
      Createstartdate: null,
      Createenddate: "null",
      LeadCurrentStatus: [],
      MeetingStartdate: "null",
      MeetingEnddate: "null",
      LeadStatusId: null,
      Modifieddate: null,
      LeadSourceID: null,
      ProductIDlabel: null,
      product: 0,
      SPTypeID: "null",
      BranchID: 0,
      IsBacklog: true,
      campaign: "null",
      MobileNo: "",
      Noofdays: 0,
      branchNameCodelabel: "",
      branchNameCode: "",
      activityIDlabel: "",
      typeOfSP: "null",
      productlabel: "",
      IsRecruitmentlead: IsRecruitmentlead ? 1 : 0
    }
    setFilterState(payload);
    const reqPayload = {
      Noofdays: 0,
      LeadtypeID: null,
      Leadname: null,
      ActivityID: null,
      CreatedBy: UserId.toString(),
      Createddate: null,
      LeadStatusId: "1,2,3,4,5,6,7,8,9,10,14,15,16,17,18,19,20,21,22,23,24",
      MeetingDate: null,
      Modifieddate: null,
      LeadSourceID: null,
      ProductID: null,
      SPTypeID: null,
      BranchID: null,
      MeetingStartdate: null,
      MeetingEnddate: null,
      IsBacklog: true,
      Modifiedstartdate: null,
      Modifiedenddate: null,
      CreatedDateStartTIme: null,
      CreatedDateEndTIme: null,
      IsRecruitmentlead: IsRecruitmentlead ? 1 : 0
    }
    setbackLeadReq(reqPayload,)

    getAllBacklogList(payload, teamMember, reqPayload);
  };

  return (
    <>
      <div className={anchor ? classes.drawerMobile : classes.desktopFilter}>
        <div className="filterBox">
          <h4 className="filterSec">
            {" "}
            <FilterAltIcon className="icnFilter" /> Filter By{" "}
            <CloseIcon
              className={classes.drawerMobile}
              style={{ float: "right" }}
              onClick={handleClose}
            />
          </h4>
          <div className="fformBox">
            <DynamicForm
              key={1}
              ref={dynamicRef}
              className="dynamicForm leadFilters  leadFiltersMobile"
              title={" "}
              buttonSubmit={t("Apply Filter")}
              defaultValues={filterState}
              customValidators={customValidators}
              model={state.formData}
              onSubmit={(model) => onSubmitHandler(model)}
              disableSubmitOnInValid={false}
              design="bootrstap"
              materialOutline={false}
              onChange={(event, key) => onChangeHandler(event, key)}
              onAutocompleteSelect={onAutocompleteSelectHandler}
              autoCompleteItems={state.autoCompleteItems}
              showAstersikOnRequiredFields={false}
              extaraActionFormButton={
                <span className="reset" onClick={() => onResetHandler(anchor)}>
                  <RestartAltIcon /> Reset
                </span>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadBacklogFilter;
