import Grid from "@mui/material/Grid";
import React from "react";
import Card from './card';
import { useHistory } from 'react-router-dom';
import DynamicForm from "f1-dynamic-form-react";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from "react-redux";
import moment from "moment";

export default function BacklogActivity({
    activitiesByUserBacklogTable,
    NoOfBacklogRecordperPage,
    TotalnoofBacklogRows,
    loadMoreBacklog,
    BacklogfilterFormData,
    resetBacklogFilters,
    toggleDrawer,
    classes,
    backlogfilterState,
    state,
    onBacklogSubmitHandler,
    setbacklogFilterState,
    autoCompleteItems,
    isForCampaign
}) {
    const dispatch = useDispatch()
    const onBacklogFilterChange = (e, k) => {
        if (k === "userType") {
            const getUserType = e.target.value;
            if (getUserType === "team") {
                BacklogfilterFormData[1].hide = false;
                BacklogfilterFormData[2].hide = true;
                //   dispatch({
                //     type: "viewFor",
                //     payload: "",
                //   });
                setbacklogFilterState({
                    ...backlogfilterState,
                    userType: "team",
                });
            } else {
                BacklogfilterFormData[1].hide = true;
                BacklogfilterFormData[2].hide = false;
            }
        }

        const meetingDateFormate = "YYYY-MM-DD";
        if (k === 'lastdate') {
            if (e.target.value === 'duration') {
                BacklogfilterFormData.map((val, index) => {
                    if (val.key === "formdate" || val.key === "todate") {
                        BacklogfilterFormData[index].hide = false;
                        setbacklogFilterState({
                            ...backlogfilterState,
                            lastdate: e.target.value,
                            formdate: moment(new Date()).format(meetingDateFormate),
                            todate: moment(new Date()).format(meetingDateFormate),
                        });
                    }
                })

            } else {
                // BacklogfilterFormData[4].hide = true;
                // BacklogfilterFormData[5].hide = true;
                BacklogfilterFormData.map((val, index) => {
                    if (val.key === "formdate" || val.key === "todate") {
                        BacklogfilterFormData[index].hide = true;
                        setbacklogFilterState({
                            ...backlogfilterState,
                            lastdate: e.target.value,
                            formdate: "null",
                            todate: "null",
                        });
                    }
                })
            }
        }
        if (k === "userName") {
            setbacklogFilterState({
                username: e.target.value
            })
            dispatch({
                type: "viewFor",
                payload: "BDM"
            })

        }
        return '';

    }
    const history = useHistory()
    const onAutocompleteSelectHandler = (key, value, item) => {

        if (key === "branchNameAndCode") {
            setbacklogFilterState({
                branchNameAndCode: item.value
            })
        }
    }

    return <>
        <Grid item xs={12} md={9} className="leftCol">
            <div class="topMenus">
                <Grid container spacing={3} textAlign="left">
                    <Grid item xs={12} md={6}>
                        <button className="addItem" onClick={() => history.push('/app/activity/create')}>+</button>
                    </Grid>

                    <Grid item xs={12} md={6} >
                        <div className="rightPanel">
                            <span> {NoOfBacklogRecordperPage < TotalnoofBacklogRows ?
                                NoOfBacklogRecordperPage :
                                TotalnoofBacklogRows}
                            </span>/
                            <span>{TotalnoofBacklogRows} Results</span>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {activitiesByUserBacklogTable.map(item => {
                return <Card item={item} isForCampaign={isForCampaign} />
            })}
            {NoOfBacklogRecordperPage <= TotalnoofBacklogRows ?
                <button className="btn loadMore" onClick={loadMoreBacklog}>Load More</button> : <div classname="noMoreDiv">No more records to load</div>}

        </Grid>
        <Grid item xs={12} md={3} className="rightCol">
            <div className={classes.desktopFilter}>
                <div className="filterBox">
                    <h4 className="filterSec"> <FilterAltIcon className="icnFilter" /> Filter By</h4>
                    <DynamicForm
                        key={"filterId"}
                        title={' '}

                        buttonSubmit={'APPLY FILTER'}
                        defaultValues={backlogfilterState}
                        model={BacklogfilterFormData}
                        onSubmit={(model) => {
                            onBacklogSubmitHandler(model);
                        }}
                        disableSubmitOnInValid={false}
                        design="bootrstap" // material/bootrstap/advanceMaterial/advanceBootstrap
                        materialOutline={false}
                        onChange={(event, key) => onBacklogFilterChange(event, key)}
                        onAutocompleteSelect={onAutocompleteSelectHandler}
                        autoCompleteItems={autoCompleteItems}
                        showAstersikOnRequiredFields={true}
                        className="activityFilters"
                        extaraActionFormButton={
                            <span className="reset" onClick={resetBacklogFilters} ><RestartAltIcon /> Reset</span>}
                    />

                </div>
            </div>
            <span className={classes.drawerMobile}>

                {['top'].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Box className="editBtn" sx={{ '& > :not(style)': { m: 1 } }} onClick={toggleDrawer(anchor, true)} >
                            <span className="icnFilterBox"> <FilterAltIcon /> </span>
                        </Box>
                        <SwipeableDrawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            onOpen={toggleDrawer(anchor, true)}
                        >
                            <div className="filterBox">
                                <h4 className="filterSec">
                                    <FilterAltIcon className="icnFilter" /> Filter By  <span style={{ float: 'right' }} onClick={toggleDrawer(anchor, false)}><CloseIcon /></span>
                                </h4>
                                <DynamicForm
                                    key={"filterId"}
                                    title={' '}

                                    buttonSubmit={'APPLY FILTER'}
                                    defaultValues={backlogfilterState}
                                    model={BacklogfilterFormData}
                                    onSubmit={(model) => {
                                        onBacklogSubmitHandler(model, anchor);
                                    }}
                                    disableSubmitOnInValid={false}
                                    design="bootrstap" // material/bootrstap/advanceMaterial/advanceBootstrap
                                    materialOutline={false}
                                    onChange={(event, key) => onBacklogFilterChange(event, key)}
                                    onAutocompleteSelect={onAutocompleteSelectHandler}
                                    autoCompleteItems={autoCompleteItems}
                                    showAstersikOnRequiredFields={true}
                                    className="activityFilters activityFiltersMobile"
                                    extaraActionFormButton={
                                        <span className="reset" onClick={() => resetBacklogFilters(anchor)} style={{ marginRight: 15 }}><RestartAltIcon /> Reset</span>}
                                />

                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                ))}
            </span>

        </Grid>
    </>
}
