import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom"
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AccountOverViewCard from './accountOverViewCard';
import ActivityOverviewCard from './activityOverviewCard';
import CampaignActivityOverviewCard from './campaignActivityOverviewCard';

import PersistencyLeadOverview from './persistencyLeadOverview';
import CalenderDashboardCard from "./calenderDashboardCard";
import LeadOverviewCard from "./leadOverviewCard";
import BusinessLeadOverviewCard from "./businessLeadOverviewCard";
import RecruitmentLeadOverviewCard from "./recruitmentLeadOverviewCard";
import CampaignLeadOverviewCard from "./campaignLeadOverviewCard";
import SalesManagementOverviewCard from "../salesManagement/salesManagementOverviewCard";
import SalesManagementOverviewCardBroca from "../activity/brocaSMA/salesManagementOverviewCardBroca";
import { Card } from "react-bootstrap";
import { CardContent } from "@material-ui/core";
import { checkInConfig } from "../../../utils/config";
import DynamicForm  from "f1-dynamic-form-react"
import { activateErrorMessageBar, addBdmLiveTracking } from "../../../redux/actions";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const ADDRESS_NOT_FOUND = 'Couldn\'t find the address for the selected location!'
const DashboardLayout = ({ selectedBDM, roleId, selectedView }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const commonState = useSelector((state) => state);
    const userState = commonState.userReducer;
    const [  leadDetail,setLeadDetail]=useState({})
    const [  currentPosition,setCurrentPosition]=useState({})
    const [  fromPosition,setFromPosition]=useState({})
    const [  isBdmLive,setIsBdmLive]=useState(false)
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            textAlign: 'left',
            color: theme.palette.text.secondary,
            //height: '100%',
            padding: theme.spacing(2),

        },
        paper2: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary,
            // height: '300px',
        },
        paper3: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
            height: '250px',
        },
        activityLogo: {
            //  borderRadius: 10,
            textAlign: 'center',
            backgroundColor: 'rgb(0 66 126 / 27%)',
            height: '50px',
            padding: theme.spacing(2),
        },
        heading: {
            padding: "1px 2px 2px 1px",
        }
    }));

    const classes = useStyles();
    const formData=[
      {
          "fieldSetClassName": 'custom-fieldset',
          "fieldSetLabelType": "string",
          "fielsetId": "fieldset1",
          "columns": [
              {
  
                  "rows": [
  
                      [
                        {
                          "colmd": "12", "key": "Location", "locationIcon": "fa fa-map-marker", "label": "Designation", "hide": false, "type": "checkinField",
                          "name": "address", "locationLabel": "Current Address: ", "okayButtonText": "Submit",
                          "cancelButtonText": "Cancel", "id": "Location",
                          "validators": [{ "name": "required", "value": false }]
                      }
                    ]
                  ]
                }
              ]
            }
    ]

    const updateBdmLocation=async()=>{
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position)=>{
                 dispatch(addBdmLiveTracking(
                    { 
                        "userId": userState?.profileInfo?.userId,
                        "currentLatitude": position.coords.latitude,
                        "currentLongitude": position.coords.longitude
                      }
                ))
            }, handleError);
        } else {
            alert('It seems like Geolocation, which is required for this page, is not enabled in your browser. Please use a browser which supports it.');
        }
    }

    const onSubmitHandler = async (model) => {
        const res = await dispatch(addBdmLiveTracking(
            { 
                "userId": userState?.profileInfo?.userId,
                "fromLatitude":fromPosition?.lat,
                "fromLongitude": fromPosition?.long,
                "toLatitude":  model.position?.lat,
                "toLongitude": model.position?.lng,
                "currentLatitude": currentPosition?.lat,
                "currentLongitude": currentPosition?.long,
                "toLocationName": model.Location
              }
        ))
        if(res.status ==200){
            setIsBdmLive(true)
        }
    }

    const handleError = (error) => {
        dispatch(activateErrorMessageBar(ADDRESS_NOT_FOUND))
        console.error(error);
        console.log('fetching handleError');
    }

    function successFunction(position) {
        var lat = position.coords.latitude;
        var long = position.coords.longitude;
        setCurrentPosition({
            lat:lat,
            long:long
        })
        setFromPosition({
            lat:lat,
            long:long
        })
    }

    const getCurrentLocation=()=>{
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(successFunction, handleError);
        } else {
            alert('It seems like Geolocation, which is required for this page, is not enabled in your browser. Please use a browser which supports it.');
        }
    }

    const onCheckinHandler = (position, address) => {
        setLeadDetail({
            leadDetail,
            Location: address,
            position:position
        })
    }


    useEffect(()=>{
        getCurrentLocation()
    },[])

    useEffect(()=>{
        if(isBdmLive){
            setInterval(()=>{
                updateBdmLocation()
            },1000*10)
        }
    },[isBdmLive])

    return <>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4} >
                    <AccountOverViewCard
                        selectedBDM={selectedBDM}
                        selectedView={selectedView}
                        roleId={roleId} classes={classes}
                        Item={Item} history={history}
                        rbhView={true}></AccountOverViewCard>

                    {roleId === 'ABH' &&
                        userState?.profileInfo?.channelid !== 2 &&
                        userState?.profileInfo?.channelid !== 3 &&
                        userState?.profileInfo?.channelid !== 13 &&
                        userState?.profileInfo?.channelid !== 21 &&
                        <SalesManagementOverviewCard
                            selectedBDM={selectedBDM}
                            selectedView={selectedView}
                            roleId={roleId} classes={classes}
                            Item={Item} history={history}
                        />}
                    {roleId === 'ABH' && userState?.profileInfo?.channelid === 21 &&
                        <SalesManagementOverviewCardBroca
                            selectedBDM={selectedBDM}
                            selectedView={selectedView}
                            roleId={roleId} classes={classes}
                            Item={Item} history={history}
                        />}
                    {/* <p className="pehlaKadam" onClick={redirectTopehilaKadam}>pehlaKadam</p> */}

                    <ActivityOverviewCard classes={classes}
                        Item={Item}
                        selectedBDM={selectedBDM}
                        selectedView={selectedView}
                        roleId={roleId} history={history}>
                    </ActivityOverviewCard>
                    {userState.profileInfo.channelid === 2 && roleId === 'BDM' &&
                        <CampaignActivityOverviewCard
                            classes={classes}
                            Item={Item}
                            selectedBDM={selectedBDM}
                            selectedView={selectedView}
                            roleId={roleId} history={history}>
                        </CampaignActivityOverviewCard>
                    }
                    {userState.profileInfo.channelid !== 3 && <>
                        <LeadOverviewCard classes={classes} Item={Item} selectedBDM={selectedBDM} selectedView={selectedView} roleId={roleId} history={history}></LeadOverviewCard>
                    </>}
                    {userState.profileInfo.channelid === 3 && <>
                        <BusinessLeadOverviewCard classes={classes} Item={Item} selectedBDM={selectedBDM} selectedView={selectedView} roleId={roleId} history={history} />
                        <RecruitmentLeadOverviewCard classes={classes} Item={Item} selectedBDM={selectedBDM} selectedView={selectedView} roleId={roleId} history={history} />
                    </>}
                </Grid>
                <Grid item xs={12} md={4}>
                    <CalenderDashboardCard classes={classes} Item={Item} selectedBDM={selectedBDM} selectedView={selectedView}>
                    </CalenderDashboardCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    {roleId =="BDM" && 
                <Paper className={classes.paper3} sx={{ mb: 2 }}>
                    <div className="addtask-container">
                        <DynamicForm
                            key={1}
                            className="dynamicForm"
                            buttonSubmit={'Submit'}
                            defaultValues={leadDetail}
                            model={formData}
                            title={'Add Task'}
                            onSubmit={(model) => onSubmitHandler(model)}
                            design="advanceBootstrap"
                            materialOutline={false}
                            // onChange={(event, key, model) => onChangeHandler(event, key, model)}
                            // extErrors={this.state.extErrors}
                            checkInConfig={checkInConfig}
                            onCheckinHandler={onCheckinHandler}
                        />
                    </div>
                    </Paper>}
                    {userState.profileInfo.channelid !== 21 &&
                        <PersistencyLeadOverview classes={classes}
                            Item={Item}
                            selectedBDM={selectedBDM}
                            selectedView={selectedView}
                            roleId={roleId}
                            history={history}>
                        </PersistencyLeadOverview>
                    }
                    {(userState.profileInfo.channelid !== 21 && roleId !== 'FPM') ? (<CampaignLeadOverviewCard
                        classes={classes}
                        Item={Item}
                        selectedBDM={selectedBDM}
                        selectedView={selectedView}
                        roleId={roleId} history={history}
                    />)
                        : ''
                    }
                </Grid>

            </Grid>
        </Box>

    </>
}

export default DashboardLayout;
