import React, { useState, useEffect } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DynamicForm from "f1-dynamic-form-react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { t } from "i18next";
import {
  NHList,
  activateLoader,
  deActivateLoader,
} from "../../../redux/actions/common";
import { useSelector, useDispatch, connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core/styles";
import {
  activateErrorMessageBar,
  getSubordinateList,
  getMasterDataForAdmin,
} from "../../../redux/actions";
import {
  customValidators,
  persistancyLeadFilterForm,
} from "./persistencyLeadDataGridFilterForm";
import { ROLE_ABH, ADMIN_HIERARCHY_TYPE } from "../../../utils/config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  drawerMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  desktopFilter: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const PersistancyLeadFilter = (props) => {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("persist:root"));
  const userName = `${
    JSON.parse(userData.userReducer).profileInfo.firstName
  } ${" "}${JSON.parse(userData.userReducer).profileInfo.lastName}`;
  const { leadStatus } = useSelector((leadreducer) => leadreducer.leadFormReducer); // Activities,
  const userState = useSelector((usereducer) => usereducer.userReducer);
  const UserId = userState?.profileInfo?.userId;
  const UserFullName = `${userState?.profileInfo?.firstName} ${userState?.profileInfo?.lastName}`;
  const roleId = userState?.profileInfo?.roleId;
  const [productDetails, setProductDetails] = useState([]);
  const [UserID, setUserID] = useState();
  const [NhUsers, setNhUsers] = useState([]);
  const [newUserList, setNewUserList] = useState([
    {
      key: UserId,
      label: UserFullName,
      value: UserId,
    },
  ]);
  const [state, setState] = useState({
    current: { id: 1 },
    formData: persistancyLeadFilterForm,
    autoCompleteItems: {
      product: [],
    },
  });

  const dynamicRef = React.useRef();

  useEffect(() => {
    const getProductIdChannelId = async () => {
      const response = await props.getMasterDataForAdmin({
        tableName: "ProductDetails",
        config: {
          TABLE_NAME: "ProductDetails",
          KEY: "ProductId",
          LABEL: "ProductName",
          CODE: "ProductId",
          CHANNEL_ID: "",
          FIELD_NAME: "ProductId",
        },
      });
      const data = response.data.data.ProductDetails;
      setProductDetails(data);
      console.log("products  response ", response);
      console.log("current Status ", productDetails);
    };
    if (!productDetails.length) {
      getProductIdChannelId();
    } else if (productDetails.length) {
      const autoCompleteItems = { ...state.autoCompleteItems };
      autoCompleteItems.product = getDropdownOFData(
        productDetails,
        "key",
        "label"
      );
      const formData = initFormData(state.formData);
      setState({ formData: formData, autoCompleteItems: autoCompleteItems });
      console.log("current Status is ", productDetails);
    }
  }, [productDetails]);

  useEffect(() => {
    if (roleId === ROLE_ABH) {
      persistancyLeadFilterForm[0].hide = false;
    }
  }, [roleId]);

  const getDropdownOFData = (optionsArr, id, label) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr.map((option) => {
        return { id: `${option[id]}`, label: `${option[label]}` };
      });
    }
    arr.shift({ id: " ", label: "select" });
    arr.unshift({ id: " ", label: "select" });
    return arr;
  };

  useEffect(() => {
    NhUsers.push({ key: "Select", label: "Select", value: "Select" });
    getNhList();
    persistancyLeadFilterForm[2].hide = true;
    persistancyLeadFilterForm[3].hide = true;
    persistancyLeadFilterForm[4].hide = true;
    persistancyLeadFilterForm[5].hide = true;
    persistancyLeadFilterForm[6].hide = true;
    persistancyLeadFilterForm[7].hide = true;
    persistancyLeadFilterForm[10].hide = true;
    persistancyLeadFilterForm[11].hide = true;
    persistancyLeadFilterForm[14].hide = true;
    persistancyLeadFilterForm[15].hide = true;
  }, []);

  const getSub = async (key, getUser) => {
    dispatch(getSubordinateList({ userId: getUser })).then((response) => {
      const r = response.data.map((el) => {
        return {
          key: el.userID,
          label: el.userName,
          value: el.userID,
        };
      });
      setNewUserList([newUserList, ...r]);
      if (key === "User") {
        persistancyLeadFilterForm[4].options = [newUserList, ...r];
      } else if (key === "UserOne") {
        if (props.chanelId === 2) {
          persistancyLeadFilterForm[6].options = [newUserList, ...r];
        } else {
          persistancyLeadFilterForm[5].options = [newUserList, ...r];
        }
      } else if (key === "UserTwo") {
        persistancyLeadFilterForm[6].options = [newUserList, ...r];
      } else if (key === "UserThree") {
        persistancyLeadFilterForm[7].options = [newUserList, ...r];
      }
      props.deActivateLoader();
    });
  };
  const getNhList = async () => {
    const result = await props.NHList({ channelId: props.chanelId });
    console.log("nh users are", result.data);
    const r = result.data.map((el) => {
      return {
        key: el.UserID,
        label: el.FirstName + " " + el.LastName,
        value: el.UserID,
      };
    });
    setNhUsers([...NhUsers, ...r]);
    persistancyLeadFilterForm[3].options = [...NhUsers, ...r];
  };
  const onSubmitHandler = (model) => {
    if (
      model.LeadName === "" ||
      model.LeadName === "null" ||
      model.LeadName === 0
    ) {
      model.LeadName = null;
    }
    if (model.CreatedDateTime === 0) {
      model.CreatedDateTime = null;
    }
    if (model.MeetingDate === 0) {
      model.MeetingDate = null;
    }
    if (
      model.MobileNo === "" ||
      model.MobileNo === "null" ||
      model.MobileNo === 0
    ) {
      model.MobileNo = null;
    }
    if (
      model.activityID === "" ||
      model.activityID === "null" ||
      model.activityID === 0
    ) {
      model.activityID = null;
    }

    if (model.teamMember === 0 || model.teamMember === undefined) {
      model.teamMember = null;
    }
    if (model.CreatedDateStartTIme === "null") {
      model.CreatedDateStartTIme = null;
    }
    if (model.CreatedDateEndTIme === "null") {
      model.CreatedDateEndTIme = null;
    }
    if (model.fromdate === "null") {
      model.fromdate = null;
    }
    if (model.todate === "null") {
      model.todate = null;
    }
    if (
      model.EmployeeCode === "" ||
      model.EmployeeCode === "null" ||
      model.EmployeeCode === 0
    ) {
      model.EmployeeCode = null;
    }
    const status = model.persistancycurrentStatus?.map((val) => {
      return val.value;
    });
    model.currentStatus = status?.join(",");

    if (model.currentStatus === "") {
      model.currentStatus = null;
    }
    const {
      CreatedDateEndTIme,
      LeadName,
      CreatedDateTime,
      CreatedDateStartTIme,
      MeetingOn,
      currentStatus,
      product,
      activityID,
      MeetingDate,
      fromdate,
      todate,
      MobileNo,
      teamMember,
      EmployeeCode,
    } = model;
    let meetingstart = null;
    let meetingend = null;
    let createdstart = null;
    let createdend = null;
    if (
      MeetingDate !== null &&
      MeetingDate !== "duration" &&
      MeetingDate !== undefined
    ) {
      const lastdatearr = model.MeetingDate.split(",");
      meetingstart = lastdatearr[0] ? lastdatearr[0] : null;
      meetingend = lastdatearr[1] ? lastdatearr[1] : null;
    }
    if (
      CreatedDateTime !== null &&
      CreatedDateTime !== "duration" &&
      CreatedDateTime !== undefined
    ) {
      const lastdatearr = model.CreatedDateTime.split(",");
      createdstart = lastdatearr[0] ? lastdatearr[0] : null;
      createdend = lastdatearr[1] ? lastdatearr[1] : null;
    }
    if (MeetingDate === "duration") {
      if (fromdate === null || todate === null) {
        dispatch(
          activateErrorMessageBar(
            "Please Select Value For Both Meeting Start Date and End Date"
          )
        );
        return false;
      }
      meetingstart = model.fromdate;
      meetingend = model.todate;
    }
    if (CreatedDateTime === "duration") {
      if (CreatedDateStartTIme === null || CreatedDateEndTIme === null) {
        dispatch(
          activateErrorMessageBar(
            "Please Select Value For Both Created Start Date and End Date"
          )
        );
        return false;
      }
      createdstart = model.CreatedDateStartTIme;
      createdend = model.CreatedDateEndTIme;
    }
    model.fromdate = meetingstart;
    model.todate = meetingend;

    model.CreatedDateStartTIme = createdstart;
    model.CreatedDateEndTIme = createdend;
    if (
      CreatedDateTime !== null ||
      LeadName !== null ||
      product !== 0 ||
      MeetingOn !== null ||
      currentStatus !== null ||
      activityID !== null ||
      MeetingDate !== null ||
      MobileNo !== null ||
      fromdate !== null ||
      todate !== null ||
      teamMember !== null ||
      UserID !== null ||
      EmployeeCode !== null
    ) {
      props.setFilterState({
        ...props.filterState,

        LeadName: LeadName,
        CreatedDateTime: CreatedDateTime,
        CreatedDateStartTIme: CreatedDateStartTIme,
        CreatedDateEndTIme: CreatedDateEndTIme,
        MeetingOn: null,
        currentStatus: currentStatus,
        product: product,
        activityID: activityID,
        MeetingDate: MeetingDate,
        fromdate: fromdate,
        todate: todate,
        MobileNo: MobileNo,
        UserID: UserID,
        EmployeeCode: EmployeeCode,
      });
      props.getFilteredLeads(model);
      props.handleClose();
    } else {
      dispatch(activateErrorMessageBar("Please select anyone to apply filter"));
    }
  };

  const onResetHandler = () => {
    props.setFilterState({
      ...props.filterState,
      LeadName: "",
      CreatedDateTime: 0,
      CreatedDateStartTIme: "null",
      CreatedDateEndTIme: "null",
      MeetingOn: null,
      LastUpdateDateTime: null,
      currentStatus: "",
      persistancycurrentStatus: [],
      product: 0,
      activityID: "",
      MeetingDate: "null",
      fromdate: "null",
      todate: "null",
      MobileNo: "",
      userType: 0,
      teamMember: null,
      User: null,
      UserOne: null,
      UserTwo: null,
      UserThree: null,
      UserFour: null,
      EmployeeCode: null,
      UserID: null,
      NhUsers: [],
      newUserList: [],
    });
    persistancyLeadFilterForm[1].hide = true;
    persistancyLeadFilterForm[2].hide = true;
    persistancyLeadFilterForm[3].hide = true;
    persistancyLeadFilterForm[4].hide = true;
    persistancyLeadFilterForm[5].hide = true;
    persistancyLeadFilterForm[6].hide = true;
    persistancyLeadFilterForm[7].hide = true;
    persistancyLeadFilterForm[10].hide = true;
    persistancyLeadFilterForm[11].hide = true;
    persistancyLeadFilterForm[14].hide = true;
    persistancyLeadFilterForm[15].hide = true;
    props.getAllLeads();
    //props.setFilterDisabled();
    props.handleClose();
  };
  const onChangeHandler = async (e, key) => {
    if (key === "EmployeeCode") {
      props.setFilterState({
        ...props.filterState,
        EmployeeCode: e.target.value,
      });
    }
    if (key === "User") {
      props.setFilterState({
        ...props.filterState,
        UserID: e.target.value,
      });
    }
    if (key === "UserOne") {
      props.setFilterState({
        ...props.filterState,
        UserID: e.target.value,
      });
    }
    if (key === "UserTwo") {
      props.setFilterState({
        ...props.filterState,
        UserID: e.target.value,
      });
    }
    if (key === "UserThree") {
      props.setFilterState({
        ...props.filterState,
        UserID: e.target.value,
      });
    }
    if (key === "UserFour") {
      props.setFilterState({
        ...props.filterState,
        UserID: e.target.value,
      });
    }
    if (key === "LeadName") {
      props.setFilterState({
        ...props.filterState,
        LeadName: e.target.value,
      });
    }
    if (key === "activityID") {
      props.setFilterState({
        ...props.filterState,
        activityID: e.target.value,
      });
    }
    if (key === "MobileNumber") {
      props.setFilterState({
        ...props.filterState,
        MobileNo: e.target.value,
      });
    }
    if (key === "CreatedDateTime") {
      props.setFilterState({
        ...props.filterState,
        CreatedDateTime: e.target.value,
      });
      if (e.target.value === "duration") {
        persistancyLeadFilterForm[10].hide = false;
        persistancyLeadFilterForm[11].hide = false;
      } else {
        persistancyLeadFilterForm[10].hide = true;
        persistancyLeadFilterForm[11].hide = true;
      }
    }
    if (key === "CreatedDateStartTIme") {
      props.setFilterState({
        ...props.filterState,
        CreatedDateStartTIme: e.target.value,
      });
      persistancyLeadFilterForm[11].min = e.target.value;
    }
    if (key === "CreatedDateEndTIme") {
      props.setFilterState({
        ...props.filterState,
        CreatedDateEndTIme: e.target.value,
      });
    }
    if (key === "currentStatus") {
      props.setFilterState({
        ...props.filterState,
        currentStatus: e.target.value,
      });
    }
    if (key === "MeetingDate") {
      props.setFilterState({
        ...props.filterState,
        MeetingOn: e.target.value,
        MeetingDate: e.target.value,
      });
      if (e.target.value === "duration") {
        persistancyLeadFilterForm[14].hide = false;
        persistancyLeadFilterForm[15].hide = false;
      } else {
        persistancyLeadFilterForm[14].hide = true;
        persistancyLeadFilterForm[15].hide = true;
      }
    }
    if (key === "LastUpdateDateTime") {
      props.setFilterState({
        ...props.filterState,
        LastUpdateDateTime: e.target.value,
      });
    }
    if (key === "product") {
      props.setFilterState({
        ...props.filterState,
        product: e.target.value,
      });
    }
    if (key === "fromdate") {
      const fromDate = e.target.value;
      props.setFilterState({
        ...props.ADMIN_HIERARCHY_TYPEfilterState,
        fromdate: fromDate,
      });
      persistancyLeadFilterForm[15].min = e.target.value;
    }
    if (key === "todate") {
      const todate = e.target.value;
      props.setFilterState({
        ...props.filterState,
        todate: todate,
      });
    }
    if (userState.profileInfo.roleId === "ADM" && key === "userType") {
      const getUserType = e.target.value;
      if (getUserType === "1") {
        props.setFilterState({
          ...props.filterState,
          teamMember: null,
          User: null,
          UserOne: null,
          UserTwo: null,
          UserThree: null,
          UserFour: null,
        });
        persistancyLeadFilterForm[1].hide = false;
        persistancyLeadFilterForm[2].hide = true;
        persistancyLeadFilterForm[3].hide = true;
        persistancyLeadFilterForm[4].hide = true;
        persistancyLeadFilterForm[5].hide = true;
        persistancyLeadFilterForm[6].hide = true;
        persistancyLeadFilterForm[7].hide = true;
      } else if (getUserType === "2") {
        props.setFilterState({
          ...props.filterState,
          EmployeeCode: null,
        });
        persistancyLeadFilterForm[2].hide = false;
        persistancyLeadFilterForm[3].hide = true;
        persistancyLeadFilterForm[1].hide = true;
        persistancyLeadFilterForm[6].hide = true;
        persistancyLeadFilterForm[4].hide = true;
        persistancyLeadFilterForm[5].hide = true;
        persistancyLeadFilterForm[7].hide = true;
      }
    }
    if (key === "teamMember") {
      const getUser = e.target.value;
      if (getUser === "Team") {
        persistancyLeadFilterForm[3].hide = false;
      } else {
        persistancyLeadFilterForm[3].hide = true;
        persistancyLeadFilterForm[4].hide = true;
        persistancyLeadFilterForm[5].hide = true;
        persistancyLeadFilterForm[6].hide = true;
        persistancyLeadFilterForm[7].hide = true;
      }
    }
    if (key === "User") {
      props.activateLoader();
      const getUser = e.target.value;
      setUserID(getUser);
      console.log("last user's user id is:", UserID);
      props.setFilterState({
        ...props.filterState,
      });
      if (getUser) {
        persistancyLeadFilterForm[4].hide = false;
        persistancyLeadFilterForm[5].hide = true;
        persistancyLeadFilterForm[6].hide = true;
        persistancyLeadFilterForm[7].hide = true;
      }
      await getSub(key, getUser);
    }
    if (key === "UserOne") {
      props.activateLoader();
      const getUser = e.target.value;
      setUserID(getUser);
      console.log("last user's user id is:", UserID);
      await getSub(key, getUser);
      if (getUser) {
        if (props.chanelId === 2) {
          persistancyLeadFilterForm[6].hide = false;
          persistancyLeadFilterForm[5].hide = true;
        } else {
          persistancyLeadFilterForm[5].hide = false;
          persistancyLeadFilterForm[6].hide = true;
          persistancyLeadFilterForm[7].hide = true;
        }
      }
    }
    if (key === "UserTwo") {
      props.activateLoader();
      const getUser = e.target.value;
      setUserID(getUser);
      console.log("last user's user id is:", UserID);
      await getSub(key, getUser);
      if (getUser) {
        persistancyLeadFilterForm[6].hide = false;
      }
    }
    if (key === "UserThree") {
      props.activateLoader();
      const getUser = e.target.value;
      setUserID(getUser);
      console.log("last user's user id is:", UserID);
      await getSub(key, getUser);
      if (getUser) {
        persistancyLeadFilterForm[7].hide = false;
      }
    }
    if (key === "UserFour") {
      const getUser = e.target.value;
      setUserID(getUser);
      console.log("last user's user id is:", UserID);
    }
    if (userState.profileInfo.roleId !== "ADM" && key === "userType") {
      manageUserHeirarcheyForNonAdminUsers(
        e.target.value,
        key
      );
    }
  };
  const manageUserHeirarcheyForNonAdminUsers = async (
    userId,
    key
  ) => {
    props.activateLoader();
    console.log("persistancyLeadFilterForm ", persistancyLeadFilterForm);
    // if user is non admin, set filter level accordingly
    if (userState.profileInfo.roleId !== "ADM") {
      const userRole = userState.profileInfo.roleId;
      switch (userRole) {
        case "BDM":
          console.log("inside of ", userRole);
          break;

        case "ABH":
          console.log("inside of ", userRole);
          setUserID(userState.profileInfo.userId);
          await getSub("UserThree", userState.profileInfo.userId);
          if (userState.profileInfo.userId) {
            persistancyLeadFilterForm[7].hide = false;
            persistancyLeadFilterForm[6].hide = true;
            persistancyLeadFilterForm[5].hide = true;
            persistancyLeadFilterForm[4].hide = true;
            persistancyLeadFilterForm[2].hide = true;
          }
          break;
        case "RBH":
          console.log("inside of ", userRole, userId);
          setUserID(userState.profileInfo.userId);
          await getSub("UserTwo", userState.profileInfo.userId);
          persistancyLeadFilterForm[7].hide = false;
          persistancyLeadFilterForm[6].hide = false;
          persistancyLeadFilterForm[5].hide = true;
          persistancyLeadFilterForm[4].hide = true;
          persistancyLeadFilterForm[2].hide = true;
          break;

        case "ZBH":
          console.log("inside of ", userRole, userId);
          setUserID(userState.profileInfo.userId);
          await getSub("UserOne", userState.profileInfo.userId);
          persistancyLeadFilterForm[7].hide = false;
          persistancyLeadFilterForm[6].hide = false;
          if (props.chanelId === 2) {
            persistancyLeadFilterForm[5].hide = true;
          } else {
            persistancyLeadFilterForm[5].hide = false;
          }
          persistancyLeadFilterForm[4].hide = true;
          persistancyLeadFilterForm[2].hide = true;
          break;

        case "NH":
          console.log("inside of ", userRole, userId);
          setUserID(userState.profileInfo.userId);
          await getSub("User", userState.profileInfo.userId);
          persistancyLeadFilterForm[7].hide = false;
          persistancyLeadFilterForm[6].hide = false;
          if (props.chanelId === 2) {
            persistancyLeadFilterForm[5].hide = true;
          } else {
            persistancyLeadFilterForm[5].hide = false;
          }
          persistancyLeadFilterForm[4].hide = false;
          persistancyLeadFilterForm[2].hide = true;
          break;

        default:
          break;
      }
    }
  };

  const getDropdownData = (optionsArr, key, label, value) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr.map((option) => {
        return { key: option[key], label: option[label], value: option[value] };
      });
    }
    arr.unshift({ key: "select", label: "Select", value: "" });
    return arr;
  };
  let LEAD_STATUS = getDropdownData(
    leadStatus,
    "LeadStatus",
    "LeadStatus",
    "LeadStatusId"
  );

  LEAD_STATUS = LEAD_STATUS?.filter(
    (ele) =>
      ele.value === 1 ||
      ele.value === 2 ||
      ele.value === 3 ||
      ele.value === 4 ||
      ele.value === 5 ||
      ele.value === 6 ||
      ele.value === 7 ||
      ele.value === 8 ||
      ele.value === 10
  );
  const onAutocompleteSelectHandler = (key, value, item) => {
    // if (key === "activityID") {
    //   setFilterState({
    //     activityID:item.value
    //   })
    // }
  };

  const initFormData = (formFields) => {
    const formData = [...formFields];
    const currenstatusoption = LEAD_STATUS;
    formData[0].options = ADMIN_HIERARCHY_TYPE;
    props.setFilterState({ ...props.filterState, UserName: userName });
    formData[17].options = productDetails;
    formData[12].options = currenstatusoption;
    return formData;
  };
  const classes = useStyles();
  return (
    <div className={props.anchor ? classes.drawerMobile : classes.desktopFilter}>
      <div className="filterBox">
        <h4 className="filterSec">
          {" "}
          <FilterAltIcon className="icnFilter" /> Filter By{" "}
          <CloseIcon
            className={classes.drawerMobile}
            style={{ float: "right", cursor: "pointer" }}
            onClick={props.handleClose}
          />
        </h4>
        <div className="fformBox">
          <DynamicForm
            key={1}
            ref={dynamicRef}
            className="dynamicForm leadFilters  leadFiltersMobile"
            title={" "}
            buttonSubmit={t("Apply Filter")}
            defaultValues={props.filterState}
            customValidators={customValidators}
            model={state.formData}
            onSubmit={(model) => onSubmitHandler(model)}
            disableSubmitOnInValid={false}
            design="bootrstap"
            materialOutline={false}
            onChange={(event, key) => onChangeHandler(event, key)}
            onAutocompleteSelect={onAutocompleteSelectHandler}
            autoCompleteItems={state.autoCompleteItems}
            showAstersikOnRequiredFields={false}
            extaraActionFormButton={
              <span className="reset" onClick={onResetHandler}>
                <RestartAltIcon /> Reset
              </span>
            }
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  leadFormData: state.leadFormReducer,
  masterDataState: state.commonReducer,
});
export default connect(mapStateToProps, {
  getMasterDataForAdmin,
  NHList,
  activateLoader,
  deActivateLoader,
})(PersistancyLeadFilter);
