import {
  DROPPED,
  MEETINGSCHEDULE,
  REASSIGN,
  FOLLOWUP,
  RESCHEDULE,
  INTRESTED,
  CALLBACK,
  restrictPastDateTime,
  ROLE_BDM,
  PREMIUMCOLLECTED,
  CONFIGS,
  TRAVEL_EXPENSE_IS_REQUIRED,
  MODE_OF_TRANSPORT_IS_REQUIRED,
  DO_YOU_WANT_TO_CLAIM_TRAVEL_EXPENSE,
  THIS_IS_REQUIRED,
  MODE_OF_TRANSPORT,
  VEHICLE_TYPE,
  TRAVEL_EXPENSE,
  PLEASE_UPLOAD_BILL_COPY,
  MAXIMUM_FOUR_DIGITS_ALLOWED,
  IMAGE_JPEG,
  APPLICATION_PDF,
  UPLOAD_BILL_PHOTO,
  DATETIME_LOCAL,
  ACTIVE_BOX_SHADOW,
  BORDER_ONE_PIXEL_SOLID_GREY,
  FA_FA_MAP_MARKER,
  CONVERTED,
} from "../../../../utils/config";
import { isNumberOnly, isPositiveNumber } from "../../../../utils/validators";

import moment from "moment";
const endMaxDate = moment(new Date()).endOf('day').format("YYYY-MM-DD kk:mm");
export const rescheduledForm = [
  {
    key: "MeetingStartDateTime",
    hide: false,
    type: DATETIME_LOCAL,
    disabled: false,
    id: "MeetingStartDateTime",
    label: "Reschedule Meeting Start Date & Time",
    min: restrictPastDateTime,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage:
          "Please plan the reschedule start date post the current time.",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    hide: false,
    type: DATETIME_LOCAL,
    disabled: false,
    id: "MeetingEndDateTime",
    label: "Reschedule Meeting End Date & Time",
    min: restrictPastDateTime,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage:
          "Please plan the reschedule end date post the current time.",
      },
    ],
  },
  {
    key: "Location",
    locationIcon: FA_FA_MAP_MARKER,
    label: "Reschedule Meeting Location",
    hide: false,
    type: "checkinField",
    name: "Location",
    validators: [],
  },
  {
    key: "ReasonID",
    hide: false,
    type: "select",
    disabled: false,
    id: "ReasonID",
    label: "Reason",
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
    options: [],
  },

  {
    key: "Remarks",
    hide: false,
    type: "text",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: [],
  },
  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    id: "IsClaimRequired",
    label: DO_YOU_WANT_TO_CLAIM_TRAVEL_EXPENSE,
    handleDiameter: 45,
    offColor: "#00427E",
    onColor: "#FFFFFF",
    offHandleColor: "#FFFFFF",
    onHandleColor: "#00427E",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: BORDER_ONE_PIXEL_SOLID_GREY,
    activeBoxShadow: ACTIVE_BOX_SHADOW,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
  {
    key: "TransportModeID",
    hide: true,
    type: "radio",
    disabled: false,
    label: MODE_OF_TRANSPORT,
    validators: [],
    options: [],
  },
  {
    key: "VehicleTypeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "VehicleTypeID",
    label: VEHICLE_TYPE,
    validators: [],
    options: [],
  },
  {
    key: "TravelExpense",
    hide: true,
    type: "number",
    props: { min: "1" },
    disabled: false,
    id: "RescheduleTravelExpense",
    label: TRAVEL_EXPENSE,
    validators: [],
  },
  {
    key: "UploadBill",
    hide: true,
    type: "file",
    dropTitle: UPLOAD_BILL_PHOTO,
    acceptFileTypes: [
      IMAGE_JPEG,
      "image/jpg",
      "image/png",
      APPLICATION_PDF,
    ],
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: PLEASE_UPLOAD_BILL_COPY,
    validators: [],
  },
];

export const meetingScheduledForm = [
  {
    key: "MeetingStartDateTime",
    hide: false,
    type: DATETIME_LOCAL,
    min: restrictPastDateTime,
    disabled: false,
    id: "MeetingStartDateTime",
    label: "Meeting Start Date & Time",
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
  },
  {
    key: "MeetingEndDateTime",
    hide: false,
    type: DATETIME_LOCAL,
    min: restrictPastDateTime,
    max: endMaxDate,
    disabled: false,
    id: "MeetingEndDateTime",
    label: "Meeting End Date & Time",
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
  },
  {
    key: "Location",
    hide: false,
    type: "checkinField",
    locationIcon: FA_FA_MAP_MARKER,
    disabled: false,
    id: "Location",
    label: "Meeting Location",
    validators: [],
  },
  {
    key: "Remarks",
    hide: false,
    type: "textarea",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: [],
  },
];

export const callBackForm = [
  {
    key: "MeetingStartDateTime",
    hide: false,
    type: DATETIME_LOCAL,
    disabled: false,
    id: "MeetingStartDateTime",
    label: "Callback Start Date & Time",
    min: restrictPastDateTime,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage:
          "Please plan the callback date post the current time.",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    hide: false,
    type: DATETIME_LOCAL,
    min: restrictPastDateTime,
    max: endMaxDate,
    disabled: false,
    id: "MeetingEndDateTime",
    label: "Callback End Date & Time",
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
  },
  {
    key: "ReasonID",
    hide: false,
    type: "select",
    disabled: false,
    id: "ReasonID",
    label: "Reason",
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
    options: [],
  },
  {
    key: "OtherReason",
    hide: true,
    type: "text",
    disabled: false,
    id: "OtherReason",
    label: "Other Reason",
  },
  {
    key: "Remarks",
    hide: false,
    type: "textarea",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
  },
];

export const followupForm = [
  {
    key: "MeetingStartDateTime",
    hide: false,
    type: DATETIME_LOCAL,
    disabled: false,
    id: "MeetingStartDateTime",
    label: "Follow-up Meeting Start Date & Time.",
    min: restrictPastDateTime,
    noDate: null,
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
  },
  {
    key: "MeetingEndDateTime",
    hide: false,
    type: DATETIME_LOCAL,
    disabled: false,
    id: "MeetingEndDateTime",
    label: "Follow-up Meeting End Date & Time.",
    min: restrictPastDateTime,
    max: endMaxDate,
    noDate: null,
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
  },
  {
    key: "Location",
    locationIcon: FA_FA_MAP_MARKER,
    label: "Follow-up Meeting Location",
    hide: false,
    type: "checkinField",
    name: "Location",
    validators: [],
  },
  {
    key: "Remarks",
    hide: false,
    type: "text",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: [],
  },
  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    id: "IsClaimRequired",
    label: "Do You Want to Claim Travel Expense",
    handleDiameter: 45,
    offColor: "#00427E",
    onColor: "#FFFFFF",
    offHandleColor: "#FFFFFF",
    onHandleColor: "#00427E",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: BORDER_ONE_PIXEL_SOLID_GREY,
    activeBoxShadow: ACTIVE_BOX_SHADOW,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
  {
    key: "TransportModeID",
    hide: true,
    type: "radio",
    disabled: false,
    label: MODE_OF_TRANSPORT,
    validators: [],
    options: [],
  },
  {
    key: "VehicleTypeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "VehicleTypeID",
    label: VEHICLE_TYPE,
    validators: [],
    options: [],
  },
  {
    key: "TravelExpense",
    hide: true,
    type: "number",
    props: { min: "1" },
    disabled: false,
    id: "RescheduleTravelExpense",
    label: TRAVEL_EXPENSE,
    validators: [],
  },
  {
    key: "UploadBill",
    hide: true,
    type: "file",
    dropTitle: UPLOAD_BILL_PHOTO,
    acceptFileTypes: [
      IMAGE_JPEG,
      "image/jpg",
      "image/png",
      APPLICATION_PDF,
    ],
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: PLEASE_UPLOAD_BILL_COPY,
    validators: [],
  },
];

export const droppedForm = [
  {
    key: "ReasonID",
    hide: false,
    type: "select",
    disabled: false,
    id: "ReasonID",
    label: "Reason",
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
    options: [],
  },
  {
    key: "Remarks",
    hide: false,
    type: "text",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: [],
  },
  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    id: "IsClaimRequired",
    label: DO_YOU_WANT_TO_CLAIM_TRAVEL_EXPENSE,
    handleDiameter: 45,
    offColor: "#00427E",
    onColor: "#FFFFFF",
    offHandleColor: "#FFFFFF",
    onHandleColor: "#00427E",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: BORDER_ONE_PIXEL_SOLID_GREY,
    activeBoxShadow: ACTIVE_BOX_SHADOW,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
  {
    key: "TransportModeID",
    hide: true,
    type: "radio",
    disabled: false,
    label: MODE_OF_TRANSPORT,
    validators: [],
    options: [],
  },
  {
    key: "VehicleTypeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "VehicleTypeID",
    label: VEHICLE_TYPE,
    validators: [],
    options: [],
  },
  {
    key: "TravelExpense",
    props: { min: "1" },
    hide: true,
    type: "number",
    disabled: false,
    id: "TravelExpense",
    label: TRAVEL_EXPENSE,
    validators: [],
  },
  {
    key: "UploadBill",
    hide: true,
    type: "file",
    dropTitle: UPLOAD_BILL_PHOTO,
    acceptFileTypes: [
      IMAGE_JPEG,
      "image/jpg",
      "image/png",
      APPLICATION_PDF,
    ],
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: PLEASE_UPLOAD_BILL_COPY,
    validators: [],
  },
];

export const interestedForm = [
  {
    key: "Remarks",
    hide: false,
    type: "text",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: [],
  },
  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    id: "IsClaimRequired",
    label: DO_YOU_WANT_TO_CLAIM_TRAVEL_EXPENSE,
    handleDiameter: 45,
    offColor: "#00427E",
    onColor: "#FFFFFF",
    offHandleColor: "#FFFFFF",
    onHandleColor: "#00427E",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: BORDER_ONE_PIXEL_SOLID_GREY,
    activeBoxShadow: ACTIVE_BOX_SHADOW,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
  {
    key: "TransportModeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "TransportModeID",
    label: MODE_OF_TRANSPORT,
    validators: [],
    options: [],
  },
  {
    key: "VehicleTypeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "VehicleTypeID",
    label: VEHICLE_TYPE,
    validators: [],
    options: [],
  },
  {
    key: "TravelExpense",
    props: { min: "1" },
    hide: true,
    type: "number",
    disabled: false,
    id: "TravelExpense",
    label: TRAVEL_EXPENSE,
    validators: [],
  },
  {
    key: "UploadBill",
    hide: true,
    type: "file",
    dropTitle: UPLOAD_BILL_PHOTO,
    acceptFileTypes: [
      IMAGE_JPEG,
      "image/jpg",
      "image/png",
      APPLICATION_PDF,
    ],
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: PLEASE_UPLOAD_BILL_COPY,
    validators: [],
  },
];

export const premiunCollectedForm = [
  {
    key: "PaymentTypeID",
    hide: false,
    type: "select",
    disabled: false,
    id: "PaymentTypeID",
    label: "Payment Method",
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
    options: [],
  },
];

export const premiunCollectedFormECS = [
  {
    key: "PaymentSubType",
    hide: false,
    type: "select",
    disabled: false,
    id: "PaymentSubType",
    label: "Select Fund/Cheque",
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
    options: [],
  },
  {
    key: "FundAccountDate",
    hide: true,
    type: DATETIME_LOCAL,
    disabled: false,
    id: "FundAccountDate",
    label: "Fund Account Date",
    min: restrictPastDateTime,
    noDate: null,
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
  },
  {
    key: "ChequeDetails",
    hide: true,
    type: "text",
    disabled: false,
    id: "ChequeDetails",
    label: "Cheque Details",
    validators: [],
  },
  {
    key: "UploadDGHPhoto",
    hide: false,
    type: "file",
    dropTitle: "Upload DGH photo Copy",
    acceptFileTypes: [
      IMAGE_JPEG,
      "image/jpg",
      "image/png",
      APPLICATION_PDF,
    ],
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadDGHPhoto",
    label: "Upload DGH Photo Copy",
    validators: [],
  },
  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    id: "IsClaimRequired",
    label: DO_YOU_WANT_TO_CLAIM_TRAVEL_EXPENSE,
    handleDiameter: 45,
    offColor: "#00427E",
    onColor: "#FFFFFF",
    offHandleColor: "#FFFFFF",
    onHandleColor: "#00427E",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: BORDER_ONE_PIXEL_SOLID_GREY,
    activeBoxShadow: ACTIVE_BOX_SHADOW,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
  {
    key: "TransportModeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "TransportModeID",
    label: MODE_OF_TRANSPORT,
    validators: [],
    options: [],
  },
  {
    key: "VehicleTypeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "VehicleTypeID",
    label: VEHICLE_TYPE,
    validators: [],
    options: [],
  },
  {
    key: "TravelExpense",
    props: { min: "1" },
    hide: true,
    type: "number",
    disabled: false,
    id: "TravelExpense",
    label: TRAVEL_EXPENSE,
    validators: [],
  },
  {
    key: "UploadBill",
    hide: true,
    type: "file",
    dropTitle: UPLOAD_BILL_PHOTO,
    acceptFileTypes: [
      IMAGE_JPEG,
      "image/jpg",
      "image/png",
      APPLICATION_PDF,
    ],
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: PLEASE_UPLOAD_BILL_COPY,
    validators: [
      // {
      //   name: "required",
      //   value: true,
      //   validationMessage: "Travel expense is required",
      // },
    ],
  },
];
export const customValidators = [
  {
    key: 'ChequeNumber',
    validator: isNumberOnly,
    message: 'Please enter numbers only.'
  },
  {
    key: "TravelExpense",
    validator: isPositiveNumber, // function to be called
    message: "Travel Expense should be greater than 0", // message to show
  },
]
export const premiunCollectedFormNonEcs = [
  {
    key: "ChequePaymentDate",
    hide: false,
    type: DATETIME_LOCAL,
    disabled: false,
    id: "ChequePaymentDate",
    label: "Cheque Payment Date",
    min: restrictPastDateTime,
    noDate: null,
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
  },
  {
    key: "ChequeNumber",
    hide: false,
    type: "text",
    disabled: false,
    id: "ChequeNumber",
    label: "Cheque No",
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
      { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
    ],
  },
  {
    key: "UploadDGHPhoto",
    hide: false,
    type: "file",
    dropTitle: "Upload DGH photo Copy",
    acceptFileTypes: [
      IMAGE_JPEG,
      "image/jpg",
      "image/png",
      APPLICATION_PDF,
    ],
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadDGHPhoto",
    label: "Upload DGH Photo Copy",
    validators: [],
  },
  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    id: "IsClaimRequired",
    label: DO_YOU_WANT_TO_CLAIM_TRAVEL_EXPENSE,
    handleDiameter: 45,
    offColor: "#00427E",
    onColor: "#FFFFFF",
    offHandleColor: "#FFFFFF",
    onHandleColor: "#00427E",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: BORDER_ONE_PIXEL_SOLID_GREY,
    activeBoxShadow: ACTIVE_BOX_SHADOW,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
  {
    key: "TransportModeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "TransportModeID",
    label: MODE_OF_TRANSPORT,
    validators: [],
    options: [],
  },
  {
    key: "VehicleTypeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "VehicleTypeID",
    label: VEHICLE_TYPE,
    validators: [],
    options: [],
  },
  {
    key: "TravelExpense",
    props: { min: "1" },
    hide: true,
    type: "number",
    disabled: false,
    id: "TravelExpense",
    label: TRAVEL_EXPENSE,
    validators: [],
  },
  {
    key: "UploadBill",
    hide: true,
    type: "file",
    dropTitle: UPLOAD_BILL_PHOTO,
    acceptFileTypes: [
      IMAGE_JPEG,
      "image/jpg",
      "image/png",
      APPLICATION_PDF,
    ],
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: PLEASE_UPLOAD_BILL_COPY,
    validators: [],
  },

];

export const premiunCollectedFormAlreadyPaid = [
  {
    key: "Remarks",
    hide: false,
    type: "text",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: [],
  },
  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    id: "IsClaimRequired",
    label: DO_YOU_WANT_TO_CLAIM_TRAVEL_EXPENSE,
    handleDiameter: 45,
    offColor: "#00427E",
    onColor: "#FFFFFF",
    offHandleColor: "#FFFFFF",
    onHandleColor: "#00427E",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: BORDER_ONE_PIXEL_SOLID_GREY,
    activeBoxShadow: ACTIVE_BOX_SHADOW,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
  {
    key: "TransportModeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "TransportModeID",
    label: MODE_OF_TRANSPORT,
    validators: [],
    options: [],
  },
  {
    key: "VehicleTypeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "VehicleTypeID",
    label: VEHICLE_TYPE,
    validators: [],
    options: [],
  },
  {
    key: "TravelExpense",
    props: { min: "1" },
    hide: true,
    type: "number",
    disabled: false,
    id: "TravelExpense",
    label: TRAVEL_EXPENSE,
    validators: [],
  },
  {
    key: "UploadBill",
    hide: true,
    type: "file",
    dropTitle: UPLOAD_BILL_PHOTO,
    acceptFileTypes: [
      IMAGE_JPEG,
      "image/jpg",
      "image/png",
      APPLICATION_PDF,
    ],
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: PLEASE_UPLOAD_BILL_COPY,
    validators: [],
  },
];
export const reassign = [
  {
    hide: false,
    key: "AssignedTo",
    type: "autocomplete",
    id: "AssignedTo",
    label: "Select Assignee",
    validators: [{ name: "required", value: false }],
    options: [],
  },
];

export const convertedForm = [
  {
    key: "DateofJoining",
    hide: false,
    type: "date",
    disabled: false,
    id: "DateofJoining",
    label: "Date Of Joining",
    // min: restrictPastDateTime,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage:
          "This is required",
      },
    ],
  },
  {
    key: "Remarks",
    hide: false,
    type: "text",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: [],
  },
  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    id: "IsClaimRequired",
    label: DO_YOU_WANT_TO_CLAIM_TRAVEL_EXPENSE,
    handleDiameter: 45,
    offColor: "#00427E",
    onColor: "#FFFFFF",
    offHandleColor: "#FFFFFF",
    onHandleColor: "#00427E",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: BORDER_ONE_PIXEL_SOLID_GREY,
    activeBoxShadow: ACTIVE_BOX_SHADOW,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
  {
    key: "TransportModeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "TransportModeID",
    label: MODE_OF_TRANSPORT,
    validators: [],
    options: [],
  },
  {
    key: "VehicleTypeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "VehicleTypeID",
    label: VEHICLE_TYPE,
    validators: [],
    options: [],
  },
  {
    key: "TravelExpense",
    props: { min: "1" },
    hide: true,
    type: "number",
    disabled: false,
    id: "TravelExpense",
    label: TRAVEL_EXPENSE,
    validators: [],
  },
  {
    key: "UploadBill",
    hide: true,
    type: "file",
    dropTitle: UPLOAD_BILL_PHOTO,
    acceptFileTypes: [
      IMAGE_JPEG,
      "image/jpg",
      "image/png",
      APPLICATION_PDF,
    ],
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: PLEASE_UPLOAD_BILL_COPY,
    validators: [],
  },
]


export const getFormTitle = (actionType) => {
  let formTitle = "";
  switch (actionType) {
    case CALLBACK:
      formTitle = "Call Back";
      break;
    case DROPPED:
      formTitle = "Dropped";
      break;
    case RESCHEDULE:
      formTitle = "Reschedule Meeting";
      break;
    case FOLLOWUP:
      formTitle = "Follow-up Meeting";
      break;
    case REASSIGN:
      formTitle = "Reassign";
      break;
    case MEETINGSCHEDULE:
      formTitle = "Meeting Scheduled";
      break;
    case INTRESTED:
      formTitle = "Interested";
      break;
    case PREMIUMCOLLECTED:
      formTitle = "Premium Collected";
      break;
    case CONVERTED:
      formTitle = "Converted"
      break
    default:
      formTitle = "Call Back";
      break;
  }
  return formTitle;
};

export const getForm = (actionType) => {
  let form = [];
  switch (actionType) {
    case CALLBACK:
      form = callBackForm;
      break;
    case DROPPED:
      form = droppedForm;
      break;
    case RESCHEDULE:
      form = rescheduledForm;
      break;
    case FOLLOWUP:
      form = followupForm;
      break;
    case REASSIGN:
      form = reassign;
      break;
    case MEETINGSCHEDULE:
      form = meetingScheduledForm;
      break;
    case INTRESTED:
      form = interestedForm;
      break;
    case PREMIUMCOLLECTED:
      form = premiunCollectedForm;
      break;
    case CONVERTED:
      form = convertedForm
      break;
    default:
      form = callBackForm;
      break;
  }
  return form;
};

const formDataModifications = (formFields, fieldIndexes, property, value) => {
  console.log(formFields)
  // const formData = [...formFields];
  for (const index of fieldIndexes) {
    formFields[index][property] = value;
  }
};

export const getOptionById = (id, optionValues) => {
  return optionValues.find(item => item.ID === parseInt(id))
}

export const getDropdownData = (optionsArr, key, label, value) => {
  let arr = [];
  if (optionsArr && optionsArr.length > 0) {
    arr = optionsArr?.map((option) => {
      return { key: option[key], label: option[label], value: option[value] }
    })
  }
  arr.unshift({ "key": "select", "label": "select", "value": "" })
  return arr;
}

export const removeExtraConcat = (data) => {
  return data.splice(0, 1);
}
export const handleDropdownSelection = (
  event,
  key,
  formFields,
  actionType,
  masterDataState,
  updateLeadData,
  role
) => {
  let formData = [...formFields];
  switch (actionType) {
    case RESCHEDULE:
      switch (key) {
        case "TransportModeID":
          if (event.target.value === "1") {
            formData[7].hide = true;
            formDataModifications(formFields, [6, 8, 9], "hide", false);
            formDataModifications(formFields, [6, 7], "validators", "");
            formData[8].validators = [
              {
                name: "required",
                value: true,
                validationMessage: TRAVEL_EXPENSE_IS_REQUIRED,
              },
              {
                name: "maxLength",
                value: 4,
                validationMessage: MAXIMUM_FOUR_DIGITS_ALLOWED,
              },
            ];
          } else {
            if (event.target.value === "2") {
              // if (role === ROLE_BDM) {
                formData[6].hide = false;
                formDataModifications(formFields, [ 8, 9], "hide", true);
                formDataModifications(formFields, [ 8, 9], "validators", "");
                formData[7].hide=false
              // } else {
              //   // formDataModifications(formFields, [  9], "hide", false);
              //   formData[7].hide=false
              //   formData[8].hide=true
              //   formData[9].hide = true;
              //   formData[9].validators = "";
              // }
            } else {
              formDataModifications(formFields, [7, 8, 9], "hide", true);
              formData[6].validators = [
                {
                  name: "required",
                  value: true,
                  validationMessage: MODE_OF_TRANSPORT_IS_REQUIRED,
                },
              ];
              formData[7].validators = [
                {
                  name: "required",
                  value: true,
                  validationMessage: THIS_IS_REQUIRED,
                },
              ];
              formData[8].validators = [
                {
                  name: "required",
                  value: true,
                  validationMessage: TRAVEL_EXPENSE_IS_REQUIRED,
                },
                {
                  name: "maxLength",
                  value: 4,
                  validationMessage: MAXIMUM_FOUR_DIGITS_ALLOWED,
                },
              ];
              formDataModifications(formFields, [6, 7, 8, 9], "validators", "");
            }
          }
          break;
        case "travelexpence":
          break;

        default:
          break;
      }
      break;
    case DROPPED:
      switch (key) {
        case "TransportModeID":
          if (event.target.value === "1") {
            formDataModifications(formFields, [5, 6], "hide", false);
            formDataModifications(formFields, [4, 5, 6], "validators", "");
            formData[5].validators = [
              {
                name: "required",
                value: true,
                validationMessage: TRAVEL_EXPENSE_IS_REQUIRED,
              }, {
                name: "maxLength",
                value: 4,
                validationMessage: MAXIMUM_FOUR_DIGITS_ALLOWED,
              },
            ];
          } else {
            if (event.target.value === "2") {
              // if (role === ROLE_BDM) {
              //   formDataModifications(formFields, [4, 5, 6], "hide", true);
              // } else {
                formDataModifications(formFields, [4], "hide", false);
                formData[6].hide = true;
                formData[5].hide = true
                
              // }
            } else {
              formDataModifications(formFields, [5, 6], "hide", true);
              formData[3].validators = [
                {
                  name: "required",
                  value: true,
                  validationMessage: MODE_OF_TRANSPORT_IS_REQUIRED,
                },
              ];
              formData[4].validators = [
                {
                  name: "required",
                  value: true,
                  validationMessage: MODE_OF_TRANSPORT_IS_REQUIRED,
                },
              ];
              formData[5].validators = [
                {
                  name: "required",
                  value: true,
                  validationMessage: TRAVEL_EXPENSE_IS_REQUIRED,
                }, {
                  name: "maxLength",
                  value: 4,
                  validationMessage: MAXIMUM_FOUR_DIGITS_ALLOWED,
                },
              ];
              formData[6].validators = [
                {
                  name: "required",
                  value: true,
                  validationMessage: TRAVEL_EXPENSE_IS_REQUIRED,
                },
                {
                  name: "maxLength",
                  value: 4,
                  validationMessage: MAXIMUM_FOUR_DIGITS_ALLOWED,
                },
              ];
              formDataModifications(formFields, [4, 5, 6], "validators", "");
            }
          }
          break;
        case "travelexpence":
          break;
        default:
          break;
      }
      break;
    case FOLLOWUP:
      switch (key) {
        case "TransportModeID":
          if (event.target.value === "1") {
            formDataModifications(formFields, [5, 7, 8], "hide", false);
            formDataModifications(formFields, [5, 6, 7], "validators", "");
            formData[6].hide = true;
            formData[7].validators = [
              {
                name: "required",
                value: true,
                validationMessage: TRAVEL_EXPENSE_IS_REQUIRED,
              },
              {
                name: "maxLength",
                value: 4,
                validationMessage: MAXIMUM_FOUR_DIGITS_ALLOWED,
              },
            ];
          } else {
            if (event.target.value === "2") {
              // if (role === ROLE_BDM) {
                formDataModifications(formFields, [6, 7, 8], "hide", true);
                formDataModifications(formFields, [6, 7, 8], "validators", "");
                formData[5].hide = false;
              // } else {
                formDataModifications(formFields, [5, 6], "hide", false);
                formData[8].hide = true;
                formData[8].validators = "";
              // }
            } else {
              formDataModifications(formFields, [6, 7, 8], "hide", true);
              formDataModifications(formFields, [5, 7, 7, 8], "validators", "");
              formData[5].validators = [
                {
                  name: "required",
                  value: true,
                  validationMessage: MODE_OF_TRANSPORT_IS_REQUIRED,
                },
              ];
              formData[6].validators = [
                {
                  name: "required",
                  value: true,
                  validationMessage: THIS_IS_REQUIRED,
                },
              ];
              formData[7].validators = [
                {
                  name: "required",
                  value: true,
                  validationMessage: TRAVEL_EXPENSE_IS_REQUIRED,
                },
                {
                  name: "maxLength",
                  value: 4,
                  validationMessage: MAXIMUM_FOUR_DIGITS_ALLOWED,
                },
              ];
            }
          }
          break;
        case "travelexpence":
          break;
        default:
          break;
      }
      break;
      case CONVERTED:
        switch (key) {
          case "TransportModeID":
            if (event.target.value === "1") {
              formDataModifications(formFields, [5, 6], "hide", false);
              formDataModifications(formFields, [4, 5, 6], "validators", "");
              formData[4].hide = true;
              formData[5].validators = [
                {
                  name: "required",
                  value: true,
                  validationMessage: TRAVEL_EXPENSE_IS_REQUIRED,
                }, {
                  name: "maxLength",
                  value: 4,
                  validationMessage: MAXIMUM_FOUR_DIGITS_ALLOWED,
                },
              ];
            } else {
              if (event.target.value === "2") {
                // if (role === ROLE_BDM) {
                  formDataModifications(formFields, [4, 5, 6], "hide", true);
                // } else {
                  formDataModifications(formFields, [4], "hide", false);
                  formData[6].hide = true;
                  // formData[5].validators = [
                  //   {
                  //     name: "required",
                  //     value: true,
                  //     validationMessage: TRAVEL_EXPENSE_IS_REQUIRED,
                  //   }, {
                  //     name: "maxLength",
                  //     value: 4,
                  //     validationMessage: MAXIMUM_FOUR_DIGITS_ALLOWED,
                  //   },
                  // ];
                  // formData[6].validators = [
                  //   {
                  //     name: "required",
                  //     value: true,
                  //     validationMessage: TRAVEL_EXPENSE_IS_REQUIRED,
                  //   },
                  //   {
                  //     name: "maxLength",
                  //     value: 4,
                  //     validationMessage: MAXIMUM_FOUR_DIGITS_ALLOWED,
                  //   },
                  // ];
                // }
              } else {
                formDataModifications(formFields, [5, 6], "hide", true);
                formData[3].validators = [
                  {
                    name: "required",
                    value: true,
                    validationMessage: MODE_OF_TRANSPORT_IS_REQUIRED,
                  },
                ];
                formData[4].validators = [
                  {
                    name: "required",
                    value: true,
                    validationMessage: MODE_OF_TRANSPORT_IS_REQUIRED,
                  },
                ];
                formData[5].validators = [
                  {
                    name: "required",
                    value: true,
                    validationMessage: TRAVEL_EXPENSE_IS_REQUIRED,
                  }, {
                    name: "maxLength",
                    value: 4,
                    validationMessage: MAXIMUM_FOUR_DIGITS_ALLOWED,
                  },
                ];
                formData[6].validators = [
                  {
                    name: "required",
                    value: true,
                    validationMessage: TRAVEL_EXPENSE_IS_REQUIRED,
                  },
                  {
                    name: "maxLength",
                    value: 4,
                    validationMessage: MAXIMUM_FOUR_DIGITS_ALLOWED,
                  },
                ];
                formDataModifications(formFields, [4, 5, 6], "validators", "");
              }
            }
            break;
          case "travelexpence":
            break;
          default:
            break;
        }
        break;
    case INTRESTED:
      switch (key) {
        case "TransportModeID":
          if (event.target.value === "1") {
            formDataModifications(formFields, [2, 4, 5], "hide", false);
            formDataModifications(formFields, [2, 3, 4, 5], "validators", "");
            formData[3].hide = true;
            formData[4].validators = [
              {
                name: "required",
                value: true,
                validationMessage: TRAVEL_EXPENSE_IS_REQUIRED,
              }, {
                name: "maxLength",
                value: 4,
                validationMessage: MAXIMUM_FOUR_DIGITS_ALLOWED,
              },
            ];
          } else {
            if (event.target.value === "2") {
              // if (role === ROLE_BDM) {
                formDataModifications(formFields, [ 4, 5], "hide", true);
                formDataModifications(formFields, [ 4, 5], "validators", "");
                formData[2].hide = false;
                formData[3].hide = false;
              // } else {
                // formDataModifications(formFields, [2, 3, 4], "hide", false);
                // formData[5].hide = true;
                // formData[5].validators = "";
              // }
            } else {
              formDataModifications(formFields, [3, 4, 5], "hide", true);
              formDataModifications(formFields, [2, 3, 4, 5], "validators", "");
              formData[2].validators = [
                {
                  name: "required",
                  value: true,
                  validationMessage: MODE_OF_TRANSPORT_IS_REQUIRED,
                },
              ];
              formData[3].validators = [
                {
                  name: "required",
                  value: true,
                  validationMessage: THIS_IS_REQUIRED,
                },
              ];
              formData[4].validators = [
                {
                  name: "required",
                  value: true,
                  validationMessage: TRAVEL_EXPENSE_IS_REQUIRED,
                },
                {
                  name: "maxLength",
                  value: 4,
                  validationMessage: MAXIMUM_FOUR_DIGITS_ALLOWED,
                },
              ];
              formData[5].validators = [
                {
                  name: "required",
                  value: true,
                  validationMessage: TRAVEL_EXPENSE_IS_REQUIRED,
                },
              ];

            }
          }
          break;
        case "travelexpence":
          break;
        default:
          break;
      }
      break;

    case CALLBACK:
      switch (key) {
        case "CallbackReasonID":
          if (event.target.value === "6") {
            formData[2].hide = false;
          } else {
            formData[2].hide = true;
            formData[6].validators = "";
          }
          break;
        case "travelexpence":
          break;
        default:
          break;
      }
      break;
    case PREMIUMCOLLECTED:
      switch (key) {
        case "PaymentTypeID":
          const PaymentMode = getOptionById(event.target.value, updateLeadData['Payment_Type']);
          switch (PaymentMode.ShortCode) {
            case "ECS":
              formData = removeExtraConcat(formData);
              formData = formData.concat(premiunCollectedFormECS)
              formData[6].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME]
              formData[1].options = getDropdownData(updateLeadData['Payment_Sub_Type'], 'ShortCode', 'Name', 'ID')
              if (role === ROLE_BDM) {
                formData[7].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME]
              }
              return formData;
            case "NON_ECS":
              formData = removeExtraConcat(formData);
              formData = formData.concat(premiunCollectedFormNonEcs)
              formData[5].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME]
              formData[3].hide = false
              return formData;
            case "ALREADY_PAID":
              formData = removeExtraConcat(formData);
              formData = formData.concat(premiunCollectedFormAlreadyPaid)
              formData[3].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME]
              return formData;
          }
          break;
        case "TransportModeID":
          if (event.target.value === "1") {
            formData.find((val, index) => {
              if (val.key === "VehicleTypeID") {
                formData[index].hide = true;
                formData[index].validators = "";
              } else if (val.key === "TransportModeID" || val.key === "TravelExpense" || val.key === "UploadBill") {
                formData[index].hide = false;
                if (val.key === "TravelExpense") {
                  formData[index].validators = [
                    {
                      name: "required",
                      value: true,
                      validationMessage: TRAVEL_EXPENSE_IS_REQUIRED,
                    },
                    {
                      name: "maxLength",
                      value: 4,
                      validationMessage: MAXIMUM_FOUR_DIGITS_ALLOWED,
                    },
                  ];
                }
              }
            })
          } else {
            if (event.target.value === "2") {
              formData.find((val, index) => {
                if (val.key === "TransportModeID") {
                  if (role === ROLE_BDM) {
                    formData[index].hide = false;
                  }

                } else if (val.key === "VehicleTypeID" || val.key === "TravelExpense" || val.key === "UploadBill") {
                  if (role === ROLE_BDM) {
                    formData[index].hide = true;
                    formData[index].validators = "";
                  } else {
                    formData[index].hide = false;
                    if (val.key === "UploadBill") {
                      formData[index].hide = true;
                      formData[index].validators = "";
                    }
                  }
                }
              })
            }
          }
          break;
      }
      break;
    default:
      break;
  }
  return formData;
};
