import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import "./livetracking.scss";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import { Button, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import {
  activateSuccessMessageBar,
  activateErrorMessageBar,
  activateLoader,
  deActivateLoader,
  getsubordinatedetailsbylevel,
  getMappedRole,
  getBdmLiveTracking,
} from "../../../redux/actions";

import GoogleMap from "./googleMap";

const Livetracking = (props) => {
  const isOnline = useOnlineStatus();
  const dispatch = useDispatch();
  const userId = props.userState?.profileInfo?.userId;
  const roleId = props.userState?.profileInfo?.roleId;
  const [defualtSelectedRole, setDefualtSelectedRole] = useState([0]);
  const [userData, setUserData] = useState({});
  const [Roles, setRoles] = useState([]);
  const [mappedRole, setMappedRole] = useState([]);
  const [selectedUser, setSelectedUser] = useState(userId);
  const [selectedRole, setSelectedRole] = useState("");
  const [trackBDMLocation,setTrackBDMLocation]=useState(false)
  const [bdmTrackData,setBdmTrackData] = useState({
    "FromLatitude": null,
    "FromLongitude": null,
    "ToLatitude": null,
    "ToLongitude": null,
    "CurrentLatitude": null,
    "CurrentLongitude": null,
    "FromLocationName": null,
    "ToLocationName": null,
  })
  const getLevel = (level) => {
    for (let i = 0; i < mappedRole.length; i++) {
      if (mappedRole[i] === level) {
        return mappedRole[i + 1];
      }
    }
    return false;
  };
  const changeMappedRole = (roles) => {
    const changeRoles =
      roles &&
      roles.map((item, index) => {
        if (index == 0) {
          return {
            name: item,
            isActive: true,
          };
        } else {
          return {
            name: item,
            isActive: false,
          };
        }
      });
    return changeRoles;
  };

  const teamsMapping = async (userId, level) => {
    const res = await props.getsubordinatedetailsbylevel({
      userId: userId,
      level: level,
    });
    return res;
  };

  const handleChange = async (e, item, index) => {
    if (e.target.value == 0) {
      const test = [...defualtSelectedRole];
      test[index] = e.target.value;
      setDefualtSelectedRole(test);
      if (index === 0) {
        setSelectedUser(userId);
        setSelectedRole(roleId);
      }
      const mapped = Roles.map((item, ind) => {
        if (ind == index && index !== 0) {
          setSelectedUser(test[index - 1]);
          setSelectedRole(Roles[index - 1].name);
        }
        if (ind > index) {
          return {
            ...item,
            isActive: false,
          };
        } else {
          return item;
        }
      });
      setRoles(mapped);
    } else {
      const level = getLevel(item.name);
      const res = await teamsMapping(e.target.value, level);
      setSelectedUser(e.target.value);
      const copyDefualtUser = [...defualtSelectedRole];
      copyDefualtUser[index] = e.target.value;
      copyDefualtUser[index + 1] = 0;
      setDefualtSelectedRole(copyDefualtUser);
      const mapped = Roles.map((item, ind) => {
        if (ind == index + 1) {
          if (res.data !== "User not found") {
            setUserData({
              ...userData,
              [item.name]: res.data,
            });
          }
          return {
            ...item,
            isActive: true,
          };
        } else {
          return item;
        }
      });
      setSelectedRole(item.name);
      setRoles(mapped);
    }
  };

  const handleTrackBdm = async ()=>{
    const res = await dispatch(getBdmLiveTracking(selectedUser))
    if(res.status==200){
      setBdmTrackData(res.data)
      dispatch({
        type:"bdmTrackData",
        payload:res.data
      })
      setTrackBDMLocation(true)
    }

  }

  useEffect(()=>{
    props.getMappedRole(userId).then((res)=>{
        setMappedRole(res.data)
    })
  },[])

  useEffect(() => {
    if (mappedRole.length > 0) {
      setRoles(changeMappedRole(mappedRole));
    }
  }, [mappedRole]);
  useEffect(() => {
    props
      .getsubordinatedetailsbylevel({ userId: userId, level: mappedRole[0] })
      .then((res) => {
        setUserData({
          ...userData,
          [mappedRole[0]]: res.data,
        });
      });
  }, [mappedRole]);
  return (
    <div>
    <div className="mainContainer dashboardDetail">
      <div className="dashboardHead">
        {!isOnline && (
          <Typography
            variant="h6"
            style={{ color: "red", fontSize: "13px", display: "block" }}>
            You are offline! to see actual data, enable internet connection
          </Typography>
        )}
        <Typography variant="h4" style={{ display:"block" }}>Live Tracking BDM</Typography>
      </div>
      <div className="dropdown-container">
        <Grid container >
          {Roles.map((item, index) => {
            if (item.isActive == true) {
              return (
                <Grid item xs={6} sm={3} textAlign="right">
                  {/* <p className="viewAll">View All</p> */}
                  <FormControl className="selectBx">
                    <Select
                      value={defualtSelectedRole[index]}
                      onChange={(e) => handleChange(e, item, index)}>
                      <MenuItem value={0}>Select</MenuItem>
                      {Object.keys(userData).includes(item.name) &&
                        userData[item.name].map((elem, index) => {
                          return (
                            <MenuItem value={elem.userID} key={index}>
                              {elem.userName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              );
            }
          })}
            { defualtSelectedRole["BDM"] !== 0 && selectedRole ==="BDM"&&
            //  <div className="btn-inner-container">
               <Grid xs={6} sm={3}>
                <Button
                  className={`myView active`}
                  style={{
                    "width": "130px",
                    "padding": "0.4rem 0",
                    "fontWeight": "700",
                    "fontSize": "14px",
                    "borderRadius": "5px",
                    "float": "left",
                    "margin":"0px 20px"
                  }}
                  onClick={()=>handleTrackBdm ()}>
                 Track BDM Location
                </Button>
              </Grid>
            //  </div>
              }
          </Grid>
          </div>
          {/* { defualtSelectedRole["BDM"] !== 0 && selectedRole ==="BDM"&&
                <Button
                  className={`myView active`}
                  style={{
                    "width": "130px",
                    "padding": "0.4rem 0",
                    "fontWeight": "700",
                    "fontSize": "14px",
                    "borderRadius": "5px",
                    "float": "left",
                    "margin":"0px 20px"
                  }}
                  onClick={()=>handleTrackBdm ()}>
                 Refresh Location
                </Button>
                } */}
    </div>

    {trackBDMLocation &&
             <GoogleMap/>
            } 
    </div>
  );
};

const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
  userState: state.userReducer,
});
const mapDispatchToProps = (dispatch) => ({
    getMappedRole: (payload) =>
    dispatch(getMappedRole(payload)),
  getsubordinatedetailsbylevel: (payload) =>
    dispatch(getsubordinatedetailsbylevel(payload)),
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  activateSuccessMessageBar: (payload) =>
    dispatch(activateSuccessMessageBar(payload)),
  activateErrorMessageBar: (payload) =>
    dispatch(activateErrorMessageBar(payload)),
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps)(Livetracking),
);
