import { post ,get,deleteReq} from '../../api/api'
import { UPDATE_ACTIVITY,GET_ACTIVITY_BY_ID, GET_ACTIVITIES_BY_USER_CALENDAR,
    GET_ACTIVITIES_BY_USER,GET_MASTER_DATA,GET_ACTIVITIES_BY_USER_DRAFT,DELETE_ACTIVITIES_BY_USER_DRAFT,GET_UPDATED_STATUS_CALL_COUNT,GET_CALEDNDER_LEAD_ACTIVTIES_BY_USER ,CAMPAIGN_ACTIVITY_LISTING ,GET_CAMPIAGN_ACTIVITIES_BY_USER } from '../../api/baseURL';
import { deActivateLoader } from './common';

export const getStatusCallCount =(data)=>({
    type:"GET_UPDATED_STATUS_CALL_COUNT",payload:data
})
export const setActivityData=(data)=>({
    type:'EDIT_ACTIVITY',payload:data
})

export const setFilterBy=(data)=>({
    type:'FILTER_BY',payload:data
})

export const getActivityForm=(data)=>({
    type:'GET_FORM',payload:data
})

export const setCompleteForm=()=>({
    type:'EDIT_ACTIVITY_COMPLETE_FORM',payload : true
})

export const setFpmJointCallCompleteForm = () => ({
    type: 'EDIT_FPM_JOINT_CALL_COMPLETE_FORM' ,payload:true
})

export const setCancelForm=()=>({
    type:'EDIT_ACTIVITY_CANCEL_FORM', payload: true
})

export const setJointCallCancelForm = () =>({
    type:'JOINT_CALL_CANCELFORM',payload:true
})

export const setJointCallRescheduleForm = () =>({
    type:'JOINT_CALL_RESCHEDULEFORM',payload :true
})

export const setCallingVLEForm=()=>({
    type:'EDIT_ACTIVITY_CALLING_VLE_FORM', payload: true
})

export const setUpdateCallsForm=()=>({
    type:'EDIT_ACTIVITY_UPDATE_CALLS', payload: true
})

export const setRescheduleForm=()=>({
    type:'EDIT_ACTIVITY_RESCHEDULE_FORM',payload : true
})

export const setActivityListLanding=(data)=>({
    type:'ACTIVITY_LISTING_LANDING',payload:data
})

export const getActivityById = (id)=>(dispatch,getState)=>{
    return new Promise(async (resolve, reject)=>{
        try{
            const res = await get(GET_ACTIVITY_BY_ID+id)
            dispatch(deActivateLoader());
            resolve(res)
        } catch (err){
            dispatch(deActivateLoader());
            reject(err)
        }
    })
}

export const getActivityStatusMasterData = ()=>(dispatch, getState)=>{
    return new Promise(async (resolve,reject)=>{
        try{
            const res =  await get(GET_MASTER_DATA+"ActivityStatus")
            dispatch(deActivateLoader());
            resolve(res)
        } catch (err){
            dispatch(deActivateLoader());
            reject(err)
        }
    })
}

export const getJointCallActivityCancelReason = ()=>(dispatch, getState)=>{
    return new Promise(async (resolve,reject)=>{
        try{
            const res =  await get(GET_MASTER_DATA+"JointCallActivityCancelReason")
            dispatch(deActivateLoader());
            resolve(res)
        } catch (err){
            dispatch(deActivateLoader());
            reject(err)
        }
    })
}

export const getJointCallActivityRescheduleReason = ()=>(dispatch, getState)=>{
    return new Promise(async (resolve,reject)=>{
        try{
            const res =  await get(GET_MASTER_DATA+"JointCallActivityRescheduleReason")
            dispatch(deActivateLoader());
            resolve(res)
        } catch (err){
            dispatch(deActivateLoader());
            reject(err)
        }
    })
}

export const getUpdatedStatusCallCountforCSC = (id)=>(dispatch, getState)=>{
    return new Promise(async (resolve,reject)=>{
        try{
            const res =  await get(GET_UPDATED_STATUS_CALL_COUNT+id)
            dispatch(deActivateLoader());
            resolve(res)
        } catch (err){
            dispatch(deActivateLoader());
            reject(err)
        }
    })
}
export const getUpdatedStatusCallCountforCampaignCSC = (id)=>(dispatch, getState)=>{
    return new Promise(async (resolve,reject)=>{
        try{
            const res =  await get(GET_UPDATED_STATUS_CALL_COUNT+id+"/?CountFor=campaignactivity")
            dispatch(deActivateLoader());
            resolve(res)
        } catch (err){
            dispatch(deActivateLoader());
            reject(err)
        }
    })
}
// export const getUpdatedStatusCallCountforGCL = (id)=>(dispatch, getState)=>{
//     return new Promise(async (resolve,reject)=>{
//         try{
//             const res =  await get(GET_UPDATED_STATUS_CALL_COUNT+id)
//             console.log(res)
//             resolve(res)
//         } catch (err){
//             reject(err)
//         }
//     })
// }

export const getActivityListByIdForTable = (payload, userID, roleID, userType, teamMember)=>(dispatch,getState)=>{
   return new Promise(async (resolve,reject)=>{
       try{
           const res = await post(`${GET_ACTIVITIES_BY_USER}?rolename=${roleID}&viewFor=${userType}&subordinateUserIds=${teamMember
               ? teamMember : "all"}` ,payload)
            dispatch(deActivateLoader());
           resolve(res)
       } catch (err){
           dispatch(deActivateLoader());
           reject(err)
       }
   })
}

export const getCampaignActivityListByIdForTable = (payload, userID, roleID, userType, teamMember)=>(dispatch,getState)=>{
    return new Promise(async (resolve,reject)=>{
        try{
            const res = await post(`${GET_CAMPIAGN_ACTIVITIES_BY_USER}?rolename=${roleID}&viewFor=${userType}&subordinateUserIds=${teamMember
                ? teamMember : "all"}` ,payload)
             dispatch(deActivateLoader());
            resolve(res)
        } catch (err){
            dispatch(deActivateLoader());
            reject(err)
        }
    })
 }
 
export const getCampaignActivityListing = (payload,ChannelId,PageNo,NoOfRecordperPage)=>(dispatch,getState)=>{
    return new Promise(async (resolve,reject)=>{
        try{
            const res = await post(`${CAMPAIGN_ACTIVITY_LISTING}${ChannelId}/${PageNo}/${NoOfRecordperPage}` ,payload)
             dispatch(deActivateLoader());
            resolve(res)
        } catch (err){
            dispatch(deActivateLoader());
            reject(err)
        }
    })
 }


export const updateActivityAction = (data) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await post(UPDATE_ACTIVITY, data)
            dispatch(deActivateLoader());
            resolve(res)
        } catch (err) {
            dispatch(deActivateLoader());
            reject(err)
        }
    })
}

export const getActivitiesListByCalendar = (id,startDate,endDate)=>(dispatch,getState)=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const res = await get(`${GET_ACTIVITIES_BY_USER_CALENDAR}${id}/${startDate}/${endDate}`)
            dispatch(deActivateLoader());
            resolve(res)
        } catch (err){
            dispatch(deActivateLoader());
            reject(err)
        }
    })
}

export const getActivityListByIdForDraftTable = (payload,userID,roleID,viewFor,selectedUser)=>(dispatch,getState)=>{
    return new Promise(async (resolve,reject)=>{
        try{
            const res = await post(`${GET_ACTIVITIES_BY_USER_DRAFT}?rolename=${roleID}&viewFor=${viewFor !== "" ? viewFor : "SELF"}&subordinateUserIds=${selectedUser}` ,payload)
            dispatch(deActivateLoader());
            resolve(res)
        } catch (err){
            dispatch(deActivateLoader());
            reject(err)
        }
    })
 }
export const getActivityListByIdForBacklogTable = (payload, userID, roleID, viewFor, selectedUser)=>(dispatch,getState)=>{
    return new Promise(async (resolve,reject)=>{
        try{
            const res= await post(`${GET_ACTIVITIES_BY_USER_DRAFT}?rolename=${roleID}&viewFor=${viewFor}&subordinateUserIds=${selectedUser !== "" ? selectedUser : "all"}` ,payload)
            dispatch(deActivateLoader());
            resolve(res)
        } catch (err){
            dispatch(deActivateLoader());
            reject(err)
        }
    })
 }

 export const deleteActivityListByIdForDraftTable = (draftfor,ids)=>(dispatch,getState)=>{
    return new Promise(async (resolve,reject)=>{
        try{
            const res= await deleteReq(`${DELETE_ACTIVITIES_BY_USER_DRAFT}/${draftfor}/${ids}`)
            dispatch(deActivateLoader());
            resolve(res)
        } catch (err){
            dispatch(deActivateLoader());
            reject(err)
        }
    })
 }



 export const getFilteredCalendarData = (userID,meetingDate,roleID,viewFor,subUserId) =>{
    return new Promise(async(resolve,reject)=>{
        try{
            const res = await get(`${GET_CALEDNDER_LEAD_ACTIVTIES_BY_USER}${userID}&MeetingDate=${meetingDate}&rolename=${roleID}&viewFor=${viewFor}&subordinateUserIds=${subUserId}`)
            // dispatch(deActivateLoader());
            resolve(res.data)
        }catch(e){
            // dispatch(deActivateLoader());
            reject(e)
        }
    })
}


