import React, { useState, useEffect, useRef, Fragment } from "react";
import Grid from "@mui/material/Grid";
import "./leadList.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getFilterLeadList,
  getLeadListByIdForTable,
  getLeadDraftList,
  getLeadBacklogList,
  getLeadStatusList,
} from "../../../redux-reducer/reducer";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import LeadFilter from "./leadFilter";
import DraftListing from "../../../components/draftListing/draftListing";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import {
  getAllLeadOffline,
  getFilterLeads,
} from "../../../offlineManager/leadOfflineManager";
import { getLeadList, setLeadsOverview, setRecruitmentLeadsOverview } from "../../../redux-reducer/reducer/leadListReducer";
import Listing from "../../../components/listing/listing";
import LeadBacklogFilter from "./LeadBacklogsFilter";
import { useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


function LeadListing() {
  const dispatch = useDispatch();
  const isOnline = useOnlineStatus();
  const formRef = useRef();
  const location = useLocation();
  const commonState = useSelector((reducerState) => reducerState);
  const userViewFor = commonState.commonReducer.viewForStatus;
  const { getLeadData, getLeadsOverView, getRecruitmentLeadsOverView } = commonState.leadListReducer
  const userData = JSON.parse(localStorage.getItem("persist:root"));
  const userId = JSON.parse(userData.userReducer).profileInfo.userId;
  const roleId = JSON.parse(userData.userReducer).profileInfo.roleId;
  const [selectedFilterLead, setSelectedFilterLead] = useState({
    roleId: roleId,
    viewFor: ""
  })

  const [selectedFilterBacklog, setSelectedFilterBacklog] = useState({
    roleId: roleId,
    viewFor: ""
  })
  const [state, setState] = React.useState({
    bottom: false,
    right: false
  });

  const [value, setValue] = React.useState(0);
  const [totalDraftRecord, setTotalDraftRecord] = useState(10);
  const [totalBacklogRecord, setTotalBacklogRecord] = useState(5);
  const { getLeadDraftData } = useSelector((state) => state.leadListReducer);
  const [selectedTeamMemberLead, setSelectedTeamMemberLead] = useState("")
  const [selectedTeamMemberBacklog, setSelectedTeamMemberBacklog] = useState("")
  const { GetactivitybyactivitybyUserID, TotalnoofRows: totalDraftRows } =
    getLeadDraftData;

  const { Listofleads, TotalnoofRows } = getLeadData;
  const IsRecruitmentlead = location.pathname.includes("recruitmentlead")
  // if(location.pathname.includes("recruitmentlead")){
  //   IsRecruitmentlead = 1
  // } else {
  //   IsRecruitmentlead = 0
  // }
  const [NoOfRecordperPage, setNoOfRecordperPage] = useState(5);
  const [allOfflineLeads, setAllOfflineLeads] = useState([]);
  const { getBacklogData } = useSelector((nextState) => nextState.leadListReducer);
  const { viewForStatus, GetBDMByABH } = useSelector(reducer => reducer.commonReducer)
  const {
    GetactivitybyactivitybyUserID: backlogList,
    TotalnoofRows: totalBacklogRows,
  } = getBacklogData;

  const [leadFilterState, setLeadFilterState] = useState({
    UserName: "",
    CreatedDateTime: "null",
    CreatedDateStartTIme: "null",
    CreatedDateEndTIme: "null",
    MeetingOn: "null",
    LastUpdateDateTime: "null",
    currentStatus: 0,
    currentstatus: [],
    branchNameCode: 0,
    typeOfSP: 0,
    product: 0,
    leadSource: 0,
    LeadName: null,
    LeadTypeID: 0,
    activityID: 0,
    MeetingDate: "null",
    fromdate: "null",
    todate: "null",
    MobileNo: null,
    Modifiedstartdate: "null",
    Modifiedenddate: "null",
    MobileNumber: null
  });
  const [backlogFilterState, setBacklogFilterState] = useState({
    Noofdays: 10,
    Leadname: null,
    LeadtypeID: null,
    CreatedDateTime: null,
    CreatedDateStartTIme: null,
    CreatedDateEndTIme: null,
    currentStatus: 0,
    LeadCurrentStatus: [],
    MeetingOn: null,
    ActivityID: null,
    branchNameCode: null,
    leadSource: null,
    product: null,
    typeOfSP: null,
    MeetingDate: null,
    fromdate: null,
    todate: null,
    date: null,
    IsBacklog: true,
    CreatedBy: userId.toString(),
    MobileNo: null,
    IsRecruitmentlead: IsRecruitmentlead ? 1 : 0
  });
  // const BacklogReq = {
  //   Noofdays: 10,
  //   LeadtypeID: null,
  //   Leadname: null,
  //   ActivityID: null,
  //   CreatedBy: userId.toString(),
  //   Createddate: null,
  //   // LeadStatusId: "12",
  //   MeetingDate: null,
  //   Modifieddate: null,
  //   LeadSourceID: null,
  //   ProductID: null,
  //   SPTypeID: null,
  //   BranchID: null,
  //   MeetingStartdate: null,
  //   MeetingEnddate: null,
  //   IsBacklog: true,
  // };

  const draftReq = {
    Noofdays: null,
    LeadtypeID: null,
    Leadname: null,
    ActivityID: null,
    CreatedBy: userId.toString(),
    Createddate: null,
    LeadStatusId: "11",
    MeetingDate: null,
    Modifieddate: null,
    LeadSourceID: null,
    ProductID: null,
    SPTypeID: null,
    BranchID: null,
    IsBacklog: false,
    IsRecruitmentlead: IsRecruitmentlead ? 1 : 0
  };

  const [leadReq, setLeadReq] = useState({
    Noofdays: 0,
    LeadtypeID: null,
    Leadname: null,
    ActivityID: null,
    CreatedBy: userId.toString(),
    Createddate: null,
    LeadStatusId: "1,2,3,4,5,6,7,8,9,10,14,15,16,17,18,19,20,21,22,23,24",
    MeetingDate: null,
    Modifieddate: null,
    LeadSourceID: null,
    ProductID: null,
    SPTypeID: null,
    BranchID: null,
    MeetingStartdate: null,
    MeetingEnddate: null,
    IsBacklog: false,
    Modifiedstartdate: null,
    Modifiedenddate: null,
    CreatedDateStartTIme: null,
    CreatedDateEndTIme: null
  });

  const [backleadReq, setbackLeadReq] = useState({
    Noofdays: 0,
    LeadtypeID: null,
    Leadname: null,
    ActivityID: null,
    CreatedBy: userId.toString(),
    Createddate: null,
    LeadStatusId: "1,2,3,4,5,6,7,8,9,10,14,15,16,17,18,19,20,21,22,23,24",
    MeetingDate: null,
    Modifieddate: null,
    LeadSourceID: null,
    ProductID: null,
    SPTypeID: null,
    BranchID: null,
    MeetingStartdate: null,
    MeetingEnddate: null,
    IsBacklog: true,
    Modifiedstartdate: null,
    Modifiedenddate: null,
    CreatedDateStartTIme: null,
    CreatedDateEndTIme: null,
    IsRecruitmentlead: IsRecruitmentlead ? 1 : 0
  });

  const getAllBacklogListData = (reqFilterPayload, TeamMember, reqPayload) => {
    setSelectedTeamMemberBacklog(TeamMember ? TeamMember : GetBDMByABH)
    const Backlogpayload = { ...reqFilterPayload, LeadStatusId: getLeadStatusId(IsRecruitmentlead ? getRecruitmentLeadsOverView : getLeadsOverView) }
    const BacklogReq = { ...reqPayload, LeadStatusId: getLeadStatusId(IsRecruitmentlead ? getRecruitmentLeadsOverView : getLeadsOverView) }
    setbackLeadReq({ ...BacklogReq })
    if (isOnline) {
      dispatch(getLeadBacklogList(userId, 1, totalBacklogRecord, BacklogReq, selectedFilterBacklog, viewForStatus, GetBDMByABH));
    }
    setBacklogFilterState({ ...Backlogpayload })
  };

  const getFilterBacklogList = (backlogRequest, teamMember, branchNameValue) => {
    scrollToTop();
    const req = {
      Noofdays: 10,
      LeadtypeID:
        backlogRequest.LeadTypeID === 0
          ? null
          : parseInt(backlogRequest.LeadTypeID),
      Leadname: backlogRequest?.LeadName,
      ActivityID:
        backlogRequest.activityID === 0
          ? null
          : parseInt(backlogRequest.activityID),
      CreatedBy: userId.toString(),
      Createstartdate: backlogRequest.CreatedDateStartTIme !== null || backlogRequest.CreatedDateStartTIme !== "null" ? backlogRequest.CreatedDateStartTIme : null,
      Createenddate: backlogRequest.CreatedDateEndTIme !== null ? backlogRequest.CreatedDateEndTIme : null,
      LeadStatusId:
        backlogRequest.currentStatus === 0
          ? null
          : backlogRequest.currentStatus,
      MeetingStartdate:
        backlogRequest.fromdate === null || backlogRequest.fromdate === "null"
          ? null
          : backlogRequest.fromdate,
      MeetingEnddate:
        backlogRequest.todate === null
          ? null
          : backlogRequest.todate,
      Modifieddate:
        backlogRequest.LastUpdateDateTime === "null"
          ? null
          : backlogRequest.LastUpdateDateTime,
      LeadSourceID:
        backlogRequest.leadSource === 0
          ? null
          : parseInt(backlogRequest.leadSource),
      ProductID:
        backlogRequest.product === 0 ? null : parseInt(backlogRequest.product),
      SPTypeID:
        backlogRequest.typeOfSP === 0
          ? null
          : parseInt(backlogRequest.typeOfSP),
      BranchID:
        !branchNameValue
          ? null
          : parseInt(backlogRequest.branchNameCode),
      // LeadStatusId:"12",
      IsBacklog: true,
      MobileNo: backlogRequest.MobileNo,
      IsRecruitmentlead: IsRecruitmentlead ? 1 : 0
    };
    setBacklogFilterState({ ...req })

    setbackLeadReq({
      ...backleadReq,
      Noofdays: 0,
      LeadtypeID: req.LeadtypeID,
      Leadname: req.Leadname,
      ActivityID: req.ActivityID,
      CreatedBy: userId.toString(),
      LeadStatusId: req.LeadStatusId,
      Modifieddate: req.Modifieddate,
      LeadSourceID: req.LeadSourceID,
      ProductID: req.ProductID,
      SPTypeID: req.SPTypeID,
      BranchID: req.BranchID,
      MeetingStartdate: req.MeetingStartdate,
      MeetingEnddate: req.MeetingEnddate,
      IsBacklog: true,
      CreatedDateStartTIme: req.Createstartdate,
      CreatedDateEndTIme: req.Createenddate,
      IsRecruitmentlead: req.IsRecruitmentlead
    })

    if (isOnline) {
      dispatch(getLeadBacklogList(userId, 1, totalBacklogRecord, req, selectedFilterBacklog, teamMember ? "BDM" : viewForStatus, teamMember ? teamMember : GetBDMByABH));
      setSelectedTeamMemberBacklog(teamMember ? teamMember : GetBDMByABH)
    }
  };

  const getLeadByOverviewSelection = (type) => {
    switch (type) {
      case "open":
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq, LeadStatusId: "2,3,4,7,15,17", IsRecruitmentlead: IsRecruitmentlead ? 1 : 0 }
        };
      case "un-actioned":
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq, LeadStatusId: "1,18", IsRecruitmentlead: IsRecruitmentlead ? 1 : 0 }
        };
      case "converted":
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq, LeadStatusId: "6,19,20,24,22,21", IsRecruitmentlead: IsRecruitmentlead ? 1 : 0 },
        };
      case 'dropped':
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq, LeadStatusId: "5,16", IsRecruitmentlead: IsRecruitmentlead ? 1 : 0 },
        };
      case 'all':
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq, IsRecruitmentlead: IsRecruitmentlead ? 1 : 0 }
        };
    }
  };

  useEffect(() => {
    if (isOnline) {
      const payload = getLeadByOverviewSelection(IsRecruitmentlead ? getRecruitmentLeadsOverView : getLeadsOverView)
      setLeadReq({ ...leadReq, LeadStatusId: payload.filterReq.LeadStatusId })
      if (value == 0) {
        dispatch(getLeadListByIdForTable(payload, userId, roleId, viewForStatus, GetBDMByABH));
      } else if (value == 1) {
        //Draft
        dispatch(getLeadDraftList(userId, 1, totalDraftRecord, draftReq, roleId, viewForStatus, GetBDMByABH));
      } else if (value == 2) {
        //Backlogs
        getAllBacklogListData(backlogFilterState, selectedTeamMemberLead, backleadReq);
      } else {
        dispatch(getLeadListByIdForTable(payload, userId, roleId, viewForStatus, GetBDMByABH));
      }
    } else {
      getAllLeadOffline().then((leads) => {
        leads = leads.sort(
          (a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)
        );
        const initialLeadList = leads;
        const leadResponse = {
          Noofpages: Math.round(leads.length / NoOfRecordperPage),
          TotalnoofRows: leads.length,
          Listofleads: initialLeadList.slice(0, NoOfRecordperPage),
        };
        setAllOfflineLeads(leads);
        dispatch(getLeadList(leadResponse));
      });
    }

    return () => {
      // Anything in here is fired on component unmount.
      dispatch(setLeadsOverview("all"));
      dispatch(setRecruitmentLeadsOverview('all'));
    }
  }, [value]);

  useEffect(() => {
    if (isOnline) {
      dispatch(getLeadStatusList());
    }
    setSelectedFilterLead({
      roleId: roleId,
      viewFor: viewForStatus
    })
    setSelectedFilterBacklog({
      roleId: roleId,
      viewFor: viewForStatus
    })

  }, []);


  const loadMoreRecord = () => {
    const payload = {
      UserId: userId,
      PageNo: 1,
      NoOfRecordperPage: NoOfRecordperPage + 5,
      filterReq: {
        ...leadReq,
      },
    };
    if (isOnline) {
      const { viewFor } = selectedFilterLead
      dispatch(getLeadListByIdForTable(payload, userId, roleId, viewFor, selectedTeamMemberLead ? selectedTeamMemberLead : GetBDMByABH));
      setNoOfRecordperPage(NoOfRecordperPage + 5);
    } else {
      let batchLeads = [...allOfflineLeads];
      batchLeads = batchLeads.splice(NoOfRecordperPage, NoOfRecordperPage + 5);
      const leadResponse = {
        Noofpages: batchLeads.length / NoOfRecordperPage,
        TotalnoofRows: allOfflineLeads.length,
        Listofleads: batchLeads,
      };
      setNoOfRecordperPage(NoOfRecordperPage + 5);
      dispatch(getLeadList(leadResponse));
    }
  };

  useEffect(() => {
  }, [leadReq])

  const loadMoreBacklogs = () => {
    if (isOnline) {
      dispatch(
        getLeadBacklogList(userId, 1, totalBacklogRecord + 5, backleadReq, selectedFilterBacklog, selectedTeamMemberBacklog !== 'all' ? "BDM" : viewForStatus, selectedTeamMemberBacklog ? selectedTeamMemberBacklog : GetBDMByABH)
      );
      setTotalBacklogRecord(totalBacklogRecord + 5);
    }
  };

  const loadMoreDraftRecord = () => {
    if (isOnline) {
      dispatch(getLeadDraftList(userId, 1, totalDraftRecord + 5, draftReq, roleId, viewForStatus, GetBDMByABH));
      setTotalDraftRecord(totalDraftRecord + 5);
    }
  };

  const getFilteredLeads = (filterRequest, teamMemberID, branchNameValue) => {
    scrollToTop();
    const req = {
      Noofdays: 0,
      LeadtypeID:
        filterRequest.LeadTypeID === 0
          ? null
          : parseInt(filterRequest.LeadTypeID),
      Leadname: filterRequest?.LeadName === 0 ? null : filterRequest.LeadName,
      ActivityID:
        filterRequest.activityID === 0
          ? null
          : parseInt(filterRequest.activityID),
      CreatedBy: userId.toString(),
      LeadStatusId:
        filterRequest.currentStatus === 0 ? getLeadStatusId(IsRecruitmentlead ? getRecruitmentLeadsOverView : getLeadsOverView) : filterRequest.currentStatus,

      // Modifieddate:
      //   filterRequest.LastUpdateDateTime === "null"
      //     ? null
      //     : filterRequest.LastUpdateDateTime,
      LeadSourceID:
        filterRequest.leadSource === 0
          ? null
          : parseInt(filterRequest.leadSource),
      ProductID:
        filterRequest.product === 0 ? null : parseInt(filterRequest.product),
      SPTypeID:
        filterRequest.typeOfSP === 0 ? null : parseInt(filterRequest.typeOfSP),
      BranchID:
        !branchNameValue
          ? null
          : parseInt(filterRequest.branchNameCode),
      IsBacklog: false,
      MeetingStartdate:
        filterRequest.fromdate === null
          ? null
          : filterRequest.fromdate,
      MeetingEnddate:
        filterRequest.todate === null
          ? null
          : filterRequest.todate,
      MobileNo: filterRequest.MobileNo === 0 ? null : filterRequest.MobileNo,
      Createstartdate: filterRequest.CreatedDateStartTIme === null ? null : filterRequest.CreatedDateStartTIme,
      Createenddate: filterRequest.CreatedDateEndTIme === null ? null : filterRequest.CreatedDateEndTIme,
      Modifiedstartdate: filterRequest.Modifiedstartdate === null ? null : filterRequest.Modifiedstartdate,
      Modifiedenddate: filterRequest.Modifiedenddate === null ? null : filterRequest.Modifiedenddate,
      IsRecruitmentlead: IsRecruitmentlead ? 1 : 0
    };
    setLeadReq({
      ...leadReq,
      Noofdays: 0,
      LeadtypeID: req.LeadtypeID,
      Leadname: req.Leadname,
      ActivityID: req.ActivityID,
      CreatedBy: userId.toString(),
      LeadStatusId: req.LeadStatusId,
      Modifieddate: req.Modifieddate,
      LeadSourceID: req.LeadSourceID,
      ProductID: req.ProductID,
      SPTypeID: req.SPTypeID,
      BranchID: req.BranchID,
      MeetingStartdate: req.MeetingStartdate,
      MeetingEnddate: req.MeetingEnddate,
      IsBacklog: false,
      Modifiedenddate: req.Modifiedenddate,
      Modifiedstartdate: req.Modifiedstartdate,
      CreatedDateStartTIme: req.Createstartdate,
      CreatedDateEndTIme: req.Createenddate,
      IsRecruitmentlead: req.IsRecruitmentlead
    })
    if (isOnline) {
      dispatch(getFilterLeadList(userId, 1, NoOfRecordperPage, req, selectedFilterLead, teamMemberID ? 'BDM' : viewForStatus, teamMemberID ? teamMemberID : GetBDMByABH));
      setSelectedTeamMemberLead(teamMemberID ? teamMemberID : GetBDMByABH)// viewFor === 'BDM' ? teamMemberID : ""
    } else {
      // filter leads offline
      let filterLeads = getFilterLeads(allOfflineLeads, req);
      filterLeads = filterLeads.sort(
        (a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)
      );
      const initialLeadList = filterLeads;
      const leadResponse = {
        Noofpages: Math.round(filterLeads.length / NoOfRecordperPage),
        TotalnoofRows: filterLeads.length,
        Listofleads: initialLeadList.slice(0, NoOfRecordperPage),
      };
      setAllOfflineLeads(filterLeads);
      dispatch(getLeadList(leadResponse));
    }
  };
  const getLeadStatusId = (type) => {
    switch (type) {
      case "open":
        return "2,3,4,7"
      case "un-actioned":
        return "1"
      case "converted":
        return "6"
      case 'dropped':
        return "5"
      case 'all':
        return "1,2,3,4,5,6,7,8,9,10,14,15,16,17,18,19,20,21,22,23,24"
    }
  };
  const getAllLeadsOfUser = () => {
    scrollToTop();
    const payload = {
      UserId: userId,
      PageNo: 1,
      NoOfRecordperPage: 5,
      filterReq: {
        Noofdays: 0,
        LeadtypeID: null,
        Leadname: null,
        ActivityID: null,
        CreatedBy: userId.toString(),
        Createddate: null,
        LeadStatusId: getLeadStatusId(IsRecruitmentlead ? getRecruitmentLeadsOverView : getLeadsOverView),
        MeetingDate: null,
        Modifieddate: null,
        LeadSourceID: null,
        ProductID: null,
        SPTypeID: null,
        BranchID: null,
        MeetingStartdate: null,
        MeetingEnddate: null,
        IsBacklog: false,
        CreatedDateStartTIme: null,
        CreatedDateEndTIme: null,
        IsRecruitmentlead: IsRecruitmentlead ? 1 : 0
      },
    };
    setLeadReq({ ...payload.filterReq })
    setNoOfRecordperPage(5)
    if (isOnline) {
      dispatch(getLeadListByIdForTable(payload, userId, roleId, viewForStatus, GetBDMByABH));
    }
  };

  const scrollToTop = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleLeadFilterState = (item) => {
    setLeadFilterState({
      ...item,
    });
  };
  const handleBacklogFilterState = (item) => {
    setBacklogFilterState({
      ...item,
    });
  };



  const toggleReAssignLeadDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  }

  return (
    <>
      <div className="tabBox">
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="tabs example"
        >
          <Tab label="Leads" {...a11yProps(0)} />
          <Tab label="Drafts" {...a11yProps(1)} />
          <Tab label="Backlog" {...a11yProps(2)} />
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <div ref={formRef}>
          <Grid container columns={{ xs: 4, md: 12 }}>
            <Listing
              data={Listofleads}
              toggleDrawer={toggleDrawer}
              loadMoreRecord={loadMoreRecord}
              NoOfRecordperPage={NoOfRecordperPage}
              TotalnoofRows={TotalnoofRows}
              roleId={roleId}
              toggleReAssignLeadDrawer={toggleReAssignLeadDrawer}
            />

            <Grid
              item
              xs={12}
              md={3}
              className="rightCol"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <LeadFilter
                filterState={leadFilterState}
                setFilterState={handleLeadFilterState}
                getFilteredLeads={getFilteredLeads}
                getAllLeads={getAllLeadsOfUser}
                parent={"sidebar"}
                setSelectedFilter={setSelectedFilterLead}
                handleClose={toggleDrawer(state.bottom, false)}
                IsRecruitmentlead={IsRecruitmentlead ? 1 : 0}
                setState={setState}
                state={state}
              />
            </Grid>
          </Grid>
          {/* <Grid container xs={4} md={4}>
                            {['right'].map((anchor) => (
                                <Fragment key={anchor}>
                                    <span  onClick={()=>toggleReAssignLeadDrawer(anchor, true)}><ArrowRightAltIcon /></span>
                                    <Drawer
                                        anchor={anchor}
                                        open={state[anchor]}
                                        onClose={()=>toggleReAssignLeadDrawer(anchor, false)}
                                    >
                                      <ReAssignLead handleClose={()=>toggleReAssignLeadDrawer(anchor, false)}/>
                                    </Drawer>
                                </Fragment>
                            ))}
                </Grid> */}
          {/* <Loader></Loader> */}
        </div>
        <span>
          {["bottom"].map((anchor) => (
            <React.Fragment key={anchor}>
              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                <LeadFilter
                  anchor={anchor}
                  filterState={leadFilterState}
                  setFilterState={handleLeadFilterState}
                  getFilteredLeads={getFilteredLeads}
                  getAllLeads={getAllLeadsOfUser}
                  parent={"drawer"}
                  setSelectedFilter={setSelectedFilterLead}
                  handleClose={toggleDrawer(anchor, false)}
                  IsRecruitmentlead={IsRecruitmentlead ? 1 : 0}
                  setStateAnchor={setState}
                  stateAnchor={state}
                />
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </span>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DraftListing
          ListOfDrafts={GetactivitybyactivitybyUserID}
          TotalNoOfRows={totalDraftRows}
          loadMoreRecord={loadMoreDraftRecord}
          NoOfRecordperPage={totalDraftRecord}
          getAllDraftsData={() =>
            dispatch(getLeadDraftList(userId, 1, totalDraftRecord, draftReq, roleId, viewForStatus, GetBDMByABH))
          }
          UserId={userId}
          userViewFor={userViewFor}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div ref={formRef}>
          <Grid container columns={{ xs: 4, md: 12 }}>
            <Listing
              data={backlogList}
              toggleDrawer={toggleDrawer}
              loadMoreRecord={loadMoreBacklogs}
              NoOfRecordperPage={totalBacklogRecord}
              TotalnoofRows={totalBacklogRows}
            />

            <Grid
              item
              xs={12}
              md={3}
              className="rightCol"
              sx={{ display: { xs: "none", md: "flex" } }}
            >

              {
                <LeadBacklogFilter
                  filterState={backlogFilterState}
                  setFilterState={handleBacklogFilterState}
                  setbackLeadReq={setbackLeadReq}
                  getFilteredBacklogList={getFilterBacklogList}
                  getAllBacklogList={getAllBacklogListData}
                  handleClose={toggleDrawer(state.bottom, false)}
                  setSelectedFilter={setSelectedFilterBacklog}
                  IsRecruitmentlead={IsRecruitmentlead ? 1 : 0}
                  setStateAnchor={setState}
                  stateAnchor={state}
                />
              }
            </Grid>
          </Grid>
        </div>
        <span>
          {["bottom"].map((anchor) => (
            <React.Fragment key={anchor}>
              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                {
                  <LeadBacklogFilter
                    anchor={anchor}
                    filterState={backlogFilterState}
                    setFilterState={handleBacklogFilterState}
                    setbackLeadReq={setbackLeadReq}
                    getFilteredBacklogList={getFilterBacklogList}
                    getAllBacklogList={getAllBacklogListData}
                    handleClose={toggleDrawer(anchor, false)}
                    setSelectedFilter={setSelectedFilterBacklog}
                    IsRecruitmentlead={IsRecruitmentlead ? 1 : 0}
                    setStateAnchor={setState}
                    stateAnchor={state}
                  />
                }
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </span>
      </TabPanel>
    </>
  );
}

const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, {
  })(LeadListing)
);
