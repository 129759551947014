import { post,get, put } from '../../api/api'
import { ACCOUNT_OVERVIEW,GET_ACTIVITY_OVERVIEW_COUNT,GET_ACTIVITY_OVERVIEW_COUNT_GRAPH_DATA, PERFORMANCE_STATS_OVERVIEW,USER_INFORMATION,UPDATE_USER_HOME_LOCATION, BASE_URL_JWT,
    GET_CAMPAIGN_ACTIVITY_OVERVIEW } from '../../api/baseURL'

import { activateLoader, deActivateLoader,activateSuccessMessageBar } from "./common";
import { GET_BDM_REPORT } from '../../api/baseURL.demo'

export const getAccountOverviewById = (request) => {
    return new Promise(async (resolve, reject) => {
        try{
            const res = await post(`${ACCOUNT_OVERVIEW}`, request)
            resolve(res)
        } catch (err){
            reject(err)
        }
    })
}
export const getPerformanceStatDataById = (request) => {
    return new Promise(async (resolve, reject) => {
        try{
            const res = await post(`${PERFORMANCE_STATS_OVERVIEW}`, request)
            resolve(res)
        } catch (err){
            reject(err)
        }
    })
}

export const getActivitiesOverviewCount = (userId,noOfDays)=>(dispatch,getState)=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const res = await get(`${GET_ACTIVITY_OVERVIEW_COUNT}${userId}/${noOfDays}`)
            dispatch(deActivateLoader());
            resolve(res)
        } catch (err){
            dispatch(deActivateLoader());
            reject(err)
        }
    })
}

export const getActivitiesOverviewCountGraphData = (payload)=>(dispatch,getState)=>{
    return new Promise(async (resolve,reject)=>{
        dispatch(activateLoader());
        try{
            const res= await post(GET_ACTIVITY_OVERVIEW_COUNT_GRAPH_DATA ,payload)
            resolve(res)
            dispatch(deActivateLoader());
        } catch (err){
            reject(err)
            dispatch(deActivateLoader());
        }
    })
 }
 export const getCampaignActivitiesOverviewCountGraphData = (payload)=>(dispatch,getState)=>{
    return new Promise(async (resolve,reject)=>{
        dispatch(activateLoader());
        try{
            const res= await post(GET_CAMPAIGN_ACTIVITY_OVERVIEW ,payload)
            resolve(res)
            dispatch(deActivateLoader());
        } catch (err){
            reject(err)
            dispatch(deActivateLoader());
        }
    })
 }

export const getUserInformationData = (userId)=>(dispatch,getState)=>{
    return new Promise(async(resolve,reject)=>{
        try{
            dispatch(activateLoader());
            const res = await get(`${USER_INFORMATION}${userId}`)
            dispatch(deActivateLoader());
            resolve(res)
        } catch (err){
            dispatch(deActivateLoader());
            reject(err)
        }
    })
}

export const putUserAddress = (payload)=>(dispatch,getState)=>{
    return new Promise(async(resolve,reject)=>{
        dispatch(activateLoader());
        try{
            const res = await put(UPDATE_USER_HOME_LOCATION , payload);
            resolve(res)
            dispatch(deActivateLoader());
            dispatch(
              activateSuccessMessageBar("Address updated Successfully")
            );
        } catch(err){
            reject(err)
            dispatch(deActivateLoader());
            dispatch(
                activateSuccessMessageBar("Address not updated")
              );
        }
    })
}
export const ssoAuthValidateUserData = (payload)=>{
    return new Promise(async (resolve,reject)=>{
        try{
            const res= await post(`${BASE_URL_JWT}${'/api/validatetoken?'}${'authToken='}${payload.authToken}${'&email='}${payload.email}`)
            resolve(res)
        } catch (err){
            reject(err)
        }
    })
}


export const getBDMReport = (payload)=>(dispatch,getState)=>{
    const {userId,viewfor,subordinateId,RoleName} = payload
    return new Promise(async(resolve,reject)=>{
        dispatch(activateLoader());
        try{
            const res = await get(`${GET_BDM_REPORT}/${userId}/${viewfor}/${RoleName}/${subordinateId}`)
            dispatch(deActivateLoader());
            resolve(res)
        } catch (err){
            dispatch(deActivateLoader());
            reject(err)
        }
    })
}

