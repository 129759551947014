import React, { useState } from 'react';
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineConnector from "@mui/lab/TimelineConnector";
import moment from "moment";
import ActivityTravelHistory from "./activityTravelHistory";
import {
  BDF,
  BRANCH_TRAINING,
  NEW_BUSINESS,
  BRANCH_VISIT,
  HNI_MEET,
  ICE_E_SAMPARK,
  MONTHLY_REVIEW,
  TRAINING,
  MEETING_VISIT,
  CALLING_TO_VLE_RAP_DM,
  JOINT_SALES_CALL_WITH_SRM,
  SCHEDULE_STRUCTURED_REVIEW_BDM,
  BRANCH_VISIT_PLAN_BDM,
  REGIONAL_OFFICE_MEETING_ABH,
  SALES_TRAINING_PLAN_BDM,
  JOINT_CALLS_BDM,
  CIRCULAR_FOR_SALES_ENABLEMENT_ABH,
  NEW_RECRUITMENT_ACTIVITY,
  REVIEW_MEETING,

  BUSINESS_DEVELOPMENT_ACTIVITY, PARTNER_VISIT, PRODUCT_TRAINING, BIRTHDAY_CELEBRATION, PRODUCT_LAUNCH,
  BRANCH_HEAD_MEET, BRANCH_FOUNDATION_DAY, JOINT_CALL_WITH_PARTNER, LIVE_LEAD_GENERATION,
  ICE_FOR_LOAN_ACTIVITY,
  HNI_LEAD_GENERATION,
  SME_LF,
  SME_CELL,
  BRANCH,
  COLLATE_THE_UNQUALIFIED_LEADS_DATA,
  REGIONAL_OFFICE_CONNECT,
  TEAM_REVIEW,
  PARTNER_BRANCH_VISIT_PLAN
} from "../../../../utils/config";
import { getUploadedImage } from "../../../../redux-reducer/reducer";

const RescheduleHistory = (props) => {
  const [fileData, setfileData] = useState(null)
  const [loader, setLoader] = useState(true)
  const { history, activityOption } = props;
  const remarks = history.Remarks ? history.Remarks : "N/A";
  const historyDate = history.MeetingStartDateTime
    ? moment(history.MeetingStartDateTime).format("DD MMM YYYY")
    : "";
  const historyTime = history.MeetingStartDateTime
    ? moment(history.MeetingStartDateTime).format("h:mma")
    : "";
  const userData = JSON.parse(localStorage.getItem("persist:root"));
  const roleId = JSON.parse(userData.userReducer).profileInfo.roleId;
  const channelid = JSON.parse(userData.userReducer).profileInfo.channelid;
  const getBase64Data = async () => {
    if (history.UploadBillFileName) {
      setLoader(false)
      const res = await getUploadedImage(history.UploadBillFileName);
      if (res.status === 200 && res.data) {
        setfileData(`${"data:image/png;base64,"}${res.data}`);
        var image = new Image();
        image.src = "data:image/jpg;base64," + res.data;
        var w = window.open("");
        w.document.write(image.outerHTML);
      }
      setLoader(true)
    }
  };
  return (
    <>
      {history.ActivityStatus === "Activity Rescheduled" ? (
        <>
          {activityOption &&
            (activityOption.key == TEAM_REVIEW ||
              activityOption.key == PARTNER_BRANCH_VISIT_PLAN) && (
              <>
                <TimelineItem>
                  <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <label>Reason</label>
                    <label>{history.Reason}</label>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Reschedule Date</label>
                  <label>
                    {historyDate} {historyTime}
                  </label>
                </TimelineContent>
              </TimelineItem>
              </>
            )}
          {activityOption &&
            (activityOption.key === BRANCH_VISIT ||
              activityOption.key === BRANCH_TRAINING ||
              activityOption.key === HNI_MEET ||
              activityOption.key === ICE_E_SAMPARK ||
              activityOption.key === BDF ||
              activityOption.key === LIVE_LEAD_GENERATION ||
              activityOption.key === JOINT_CALL_WITH_PARTNER ||
              activityOption.key === BRANCH_FOUNDATION_DAY ||
              activityOption.key === BRANCH_HEAD_MEET ||
              activityOption.key === PRODUCT_LAUNCH ||
              activityOption.key === BIRTHDAY_CELEBRATION ||
              activityOption.key === PRODUCT_TRAINING ||
              activityOption.key === PARTNER_VISIT ||
              activityOption.key === BUSINESS_DEVELOPMENT_ACTIVITY||
              activityOption.key === ICE_FOR_LOAN_ACTIVITY ||
              activityOption.key === HNI_LEAD_GENERATION ||
              activityOption.key === SME_LF||
              activityOption.key === SME_CELL||
              activityOption.key === BRANCH||
              activityOption.key === COLLATE_THE_UNQUALIFIED_LEADS_DATA||
              activityOption.key === REGIONAL_OFFICE_CONNECT
            ) ? (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Reason</label>
                  <label>{history.Reason}</label>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Reschedule Date</label>
                  <label>
                    {historyDate} {historyTime}
                  </label>
                </TimelineContent>
              </TimelineItem>
  {
    (channelid !== 79 && channelid !== 21 && channelid !== 13 && roleId !== "RBH" && roleId !== "ZBH" && roleId !== "NH") ?
    <TimelineItem>
      <TimelineSeparator>
        <span className="separator">-</span>
      </TimelineSeparator>
      <TimelineContent>
        <label>Remarks</label>
        <label>{remarks}</label>
      </TimelineContent>
    </TimelineItem>
    : ""
  }
            </>
          ) : (
  ""
)}

{
  activityOption && activityOption.key === NEW_RECRUITMENT_ACTIVITY ? (
    <>
      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Rescheduled Meeting Date Time</label>
          {/* <label> {history.MeetingStartDateTime}</label>
                  <label>{history.MeetingEndDateTime}</label> */}
          <label>{historyDate} {historyTime}</label>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Reason</label>
          <label>{history.Reason}</label>
        </TimelineContent>
      </TimelineItem>
    </>
  ) : (
  ""
)
}

{
  activityOption && activityOption.key === TRAINING ? (
    <>
      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Rescheduled Meeting Date Time</label>
          {/* <label> {history.MeetingStartDateTime}</label>
                  <label>{history.MeetingEndDateTime}</label> */}
          <label>{historyDate} {historyTime}</label>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Reason</label>
          <label>{history.Reason}</label>
        </TimelineContent>
      </TimelineItem>
    </>
  ) : (
  ""
)
}

{
  activityOption && activityOption.key === REVIEW_MEETING ? (
    <>
      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Rescheduled Meeting Date Time</label>
          {/* <label> {history.MeetingStartDateTime}</label>
                  <label>{history.MeetingEndDateTime}</label> */}
          <label>{historyDate} {historyTime}</label>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Reason</label>
          <label>{history.Reason}</label>
        </TimelineContent>
      </TimelineItem>
    </>
  ) : (
  ""
)
}

{
  activityOption && activityOption.key === CALLING_TO_VLE_RAP_DM ? (
    <>
      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Rescheduled Meeting Date Time</label>
          {/* <label> {history.MeetingStartDateTime}</label>
                  <label>{history.MeetingEndDateTime}</label> */}
          <label>{historyDate} {historyTime}</label>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Reason</label>
          <label>{history.Reason}</label>
        </TimelineContent>
      </TimelineItem>
    </>
  ) : (
  ""
)
}
{
  activityOption && activityOption.key === MONTHLY_REVIEW ? (
    <>
      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Rescheduled Meeting Date Time</label>
          {/* <label> {history.MeetingStartDateTime}</label>
                  <label>{history.MeetingEndDateTime}</label> */}
          <label>{historyDate} {historyTime}</label>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Reason</label>
          <label> {history.Reason}</label>
        </TimelineContent>
      </TimelineItem>
    </>
  ) : (
  ""
)
}

{
  activityOption && activityOption.key === NEW_BUSINESS ? (
    <>

      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Reason</label>
          <label> {history.Reason}</label>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Rescheduled Meeting Date Time</label>
          {/* <label> {history.MeetingStartDateTime}</label>
                  <label>{history.MeetingEndDateTime}</label> */}
          <label>{historyDate} {historyTime}</label>
        </TimelineContent>
      </TimelineItem>
    </>
  ) : (
  ""
)
}

{
  activityOption && activityOption.key === JOINT_SALES_CALL_WITH_SRM ? (
    <>
      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Rescheduled Meeting Date Time</label>
          {/* <label> {history.MeetingStartDateTime}</label>
                  <label>{history.MeetingEndDateTime}</label> */}
          <label>{historyDate} {historyTime}</label>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Reason</label>
          <label>{history.FpmActivityReschedulereason}</label>
        </TimelineContent>
      </TimelineItem>
    </>
  ) : (
  ""
)
}


{
  activityOption && activityOption.key === JOINT_CALLS_BDM ||
  activityOption.key === SCHEDULE_STRUCTURED_REVIEW_BDM ||
  activityOption.key === BRANCH_VISIT_PLAN_BDM ||
  activityOption.key === REGIONAL_OFFICE_MEETING_ABH ||
  activityOption.key === SALES_TRAINING_PLAN_BDM ||
  activityOption.key === CIRCULAR_FOR_SALES_ENABLEMENT_ABH ||
  activityOption.value === "38" ||
  activityOption.value === "39" ||
  activityOption.value === "40" ||
  activityOption.value === "41" ||
  activityOption.value === "42" ||
  activityOption.value === "43" ||
  activityOption.value === "44"
  ? (
    <>
      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Reason</label>
          <label>{history.SmaReason}</label>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Reschedule Date</label>
          <label>
            {historyDate} {historyTime}
          </label>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
        </TimelineSeparator>
        <TimelineContent>
          <label>Remarks</label>
          <label>{remarks}</label>
        </TimelineContent>
      </TimelineItem>
    </>
  ) : (
    ""
  )
}
{
  activityOption && activityOption.key === MEETING_VISIT ? (
    <>
      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Rescheduled Meeting Date Time</label>
          {/* <label> {history.MeetingStartDateTime}</label>
                  <label>{history.MeetingEndDateTime}</label> */}
          <label>{historyDate} {historyTime}</label>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <span className="separator">-</span>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <label>Reason</label>
          <label> {history.Reason}</label>
        </TimelineContent>
      </TimelineItem>
      {/* <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator> 
                <TimelineContent>
                  <label>Remarks</label>
                  <label> {history.Remarks}</label>
                </TimelineContent> 
              </TimelineItem> */}

    </>
  ) : (
  ""
)
}
{
  history.IsClaimRequired && (
    <ActivityTravelHistory
      history={history}
      styletimeline={props.styletimeline}
    />
  )
}
{
  history.UploadBill &&
  <TimelineItem>
    <TimelineSeparator>
      <span className="separator">-</span>
      <TimelineConnector />
    </TimelineSeparator>
    <TimelineContent>
      {loader == true ? <label className="link-label" onClick={() => getBase64Data()}>View uploaded Bill</label> : <>Loading</>}
      {
        fileData &&
        <img className='bill-copy' alt="uploaded bill" src={fileData} />
      }
    </TimelineContent>
  </TimelineItem>
}
        </>
      ) : (
  ""
)}
    </>
  );
};

export default RescheduleHistory
