import { CLAIM_TRAVEL_EXPENCE_OPTIONS, ACTIVITY_RESCHEDULED, ACTIVITY_CANCELLED, ACTIVITY_COMPLETED, CONFIGS, ROLE_BDM, ALLOWED_FUTURE_DAYS } from "../../../../utils/config";

import { format } from 'date-fns';

import { getDateAfterDays } from "../../../../utils/utils";

import { isPositiveNumber, numberStartWith6789Validator } from "../../../../utils/validators";

const dateFormat = 'yyyy-MM-dd';
const max100Charmessage = "Maximum 100 charactors allowed.";
const claimtravelExpenseLabel = "Do You Want to Claim Travel Expense?";
const optionSelectValidationMessage = "Please select one option.";
const cssBorder = "1px solid #ddd";
const cssactiveBoxShadow = "0px 0px 1px 2px #fffc35";
const modeOfTransportLabel = "Mode of Transport";
const travelExpenseLabel = "Travel Expense";
const travelExpenseValidationmessage = "Travel expense is required.";
const fourDigitAllowed = "Maximum 4 digits allowed";
const twoDigitAllowed = "Maximum 2 digits allowed";
const billPhoto="Upload bill photo";
const uploadBill = "Please Upload Bill Copy";
const acceptFileType = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
const vehicleType="Vehicle Type";
const modeOftransportRequired="mode of Trasport is required.";
export const ActivityRescheduledForm = [
  {
    "key": "MeetingStartDateTime",
    "hide": false, "type": "datetime-local",
    "disabled": false,
    "id": "MeetingStartDateTime",
    "min": `${format(new Date(), dateFormat)}T${format(new Date(), 'kk:mm')}`,
    "max": `${format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat)}T23:59`,
    "label": "Reschedule Meeting Start Date & Time", "validators": [{ "name": "required", "value": true, "validationMessage": "Meeting start time is required." }]
  },
  {
    "key": "MeetingEndDateTime",
    "hide": false,
    "type": "datetime-local", "disabled": false, "id": "MeetingEndDateTime",
    "min": `${format(new Date(), dateFormat)}T${format(new Date(), 'kk:mm')}`,
    "max": `${format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat)}T23:59`,
    "label": "Reschedule Meeting End Date & Time", "validators": [{ "name": "required", "value": true, "validationMessage": "Meeting end time is required." }]
  },
  {
    "key": "Reason",
    "hide": false,
    "type": "text",
    "disabled": false,
    "id": "Reason",
    "label": "Reason",
    "validators": [{ "name": "required", "value": true, "validationMessage": "Reason is required." },
    { "name": "maxLength", "value": 100, "validationMessage": max100Charmessage }]
  },
  {
    "key": "Remarks",
    "hide": false,
    "type": "text",
    "disabled": false,
    "id": "Remarks",
    "label": "Remarks",
    "validators": [{ "name": "maxLength", "value": 100, "validationMessage": max100Charmessage }]
  },
  {
    "key": "IsClaimRequired",
    "name": "IsClaimRequired",
    "type": "CustomSwitch",
    "disabled": false,
    "id": "IsClaimRequired",
    "label": claimtravelExpenseLabel,
    "validators": [{ "name": "required", "value": false, "validationMessage": optionSelectValidationMessage }], handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: cssBorder,
    activeBoxShadow: cssactiveBoxShadow,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>
  }
]
export const ActivityRescheduledFormPart1 = [
  {
    "key": "TransportModeID",
    "type": "radio",
    "label": modeOfTransportLabel,
    "validators": [{ "name": "required", "value": true, "validationMessage": "Mode of transport is required." }], "options": []
  },

]

export const ActivityRescheduledFormPart2 = [
  {
    "key": "TravelExpence",
    "hide": false,
    "props": { "min": "0" },
    "type": "number",
    "disabled": false,
    "id": "TravelExpence",
    "label": travelExpenseLabel,
    "validators": [{ "name": "required", "value": true, "validationMessage": travelExpenseValidationmessage }, {
      "name": "maxLength",
      "value": 4,
      "validationMessage": fourDigitAllowed
    }]
  },
  {
    "key": "UploadBill",
    "hide": false,
    "type": "file",
    "dropTitle": billPhoto,
    "acceptFileTypes":acceptFileType,
    "componentType": "DRAG_DROP_BROWSE",
    "layout": "1column",
    "restrictMultipleFiles": true,
    "disabled": false,
    "id": "UploadBill",
    "label": uploadBill,
    "validators": []
  }
]
export const ActivityRescheduledFormPart3 = [
  {
    "key": "VehicleTypeID",
    "hide": false,
    "type": "radio",
    "disabled": false,
    "id": "VehicleType",
    "label": vehicleType,
    "validators": [{ "name": "required", "value": true, "validationMessage": modeOftransportRequired }], "options": []
  },
]
//Activity Cancel JSON start
export const ActivityCancelledForm = [
  {
    "key": "Reason",
    "hide": false,
    "type": "text",
    "disabled": false,
    "id": "Reason",
    "label": "Reason for Cancellation",
    "validators": [
      { "name": "required", "value": true, "validationMessage": "Reason for cancellation is required." },
       { "name": "maxLength", "value": 100, "validationMessage": max100Charmessage }]
  },
  {
    "key": "Remarks",
    "hide": false,
    "type": "text",
    "disabled": false,
    "id": "Remarks",
    "label": "Remarks",
    "validators": [{ "name": "maxLength", "value": 100, "validationMessage": max100Charmessage }]
  },
  //  { "key": "IsClaimRequired", "hide": false, "type": "select", "disabled":false, "id":"IsClaimRequired", "label": "Do You Want to Claim Travel Expense",
  //   "validators":[{"name":"required", "value":true, "validationMessage": optionSelectValidationMessage}], "options": []},

  {
    "key": "IsClaimRequired",
    "name": "IsClaimRequired",
     "type": "CustomSwitch",
     "disabled": false,
     "id": "IsClaimRequired",
     "label": claimtravelExpenseLabel,
     "validators": [{ "name": "required", "value": false, "validationMessage": optionSelectValidationMessage }], handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: cssBorder,
    activeBoxShadow: cssactiveBoxShadow,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>
  }
]
export const ActivityCancelledFormPart1 = [
  { "key": "TransportModeID",
   "type": "radio",
   "label": modeOfTransportLabel,
   "validators": [
     { "name": "required", "value": true, "validationMessage": "Mode of transport is required." }]
     , "options": [] }
]

export const ActivityCancelledFormPart2 = [
  { "key": "TravelExpence",
  "hide": false,
   "type": "number",
   "props": { "min": "0" },
   "disabled": false,
    "id": "TravelExpence",
     "label": travelExpenseLabel,
      "validators": [
        { "name": "required", "value": true, "validationMessage": travelExpenseValidationmessage },
         { "name": "maxLength", "value": 4, "validationMessage": fourDigitAllowed }] },
  { "key": "UploadBill",
  "hide": false,
   "type": "file",
   "dropTitle": billPhoto,
   "acceptFileTypes":acceptFileType,
    "componentType": "DRAG_DROP_BROWSE",
    "layout": "1column",
     "restrictMultipleFiles": true,
      "disabled": false,
       "id": "UploadBill",
       "label": uploadBill,
       "validators": [] }
]
export const ActivityCancelledFormPart3 = [{ "key": "VehicleTypeID",
 "hide": false,
 "type": "radio",
 "disabled": false,
 "id": "VehicleType",
  "label": vehicleType,
   "validators": [{ "name": "required",
    "value": true,
    "validationMessage": modeOftransportRequired
  }], "options": [] },
]
//Activity Cancel JSON End

//Activity Complet JSON start
export const ActivityCompletedForm = [
  {
    key: "BDMMetCreditHeadOfficer",
    type: "select",
    id: "BDMMetCreditHeadOfficer",
    label: "BDM met the Credit Head Officer Loan Officer ",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "This field is required",
      },
    ],
    options: [
      { key: "select", label: "select", value: "select" },
      {
        key: "Yes",
        label: "Yes",
        value: 1,
      },
      { key: "No", label: "No", value: 0 },
    ],
  },
  { "key": "CreditHeadOfficerName",
  "hide": false,
   "type": "text",
    "disabled": false,
    "id": "CreditHeadOfficerName",
    "label": "Credit Head Officer Loan Officer Name",
     "validators": [
       { "name": "required", "value": true, "validationMessage": "This field is required" },
        { "name": "maxLength", "value": 100, "validationMessage": max100Charmessage }] },
  {
        "key": "CreditHeadOfficerMobileNo", "type": "number", "id": "CreditHeadOfficerMobileNo", "label": "Mobile No.",
        "validators": [
            { "name": "required", "value": true, "validationMessage": "This field is required" },
            { "name": "minLength", "value": 10, "validationMessage": "Min length for Mobile No is 10 digits" },
            { "name": "maxLength", "value": 10, "validationMessage": "Mobile number field require 10 digits." }]
    },
  { "key": "Remarks",
   "hide": false,
    "type": "text",
     "disabled": false,
     "id": "Remarks",
     "label": "Remarks",
      "validators": [
        { "name": "required", "value": true, "validationMessage": "Remarks is required." },
         { "name": "maxLength", "value": 100, "validationMessage": max100Charmessage }] },
  {
    "key": "IsClaimRequired",
    "name": "IsClaimRequired",
    "type": "CustomSwitch",
    "disabled": false,
    "id": "IsClaimRequired",
    "label": claimtravelExpenseLabel,
    "validators": [{ "name": "required", "value": false, "validationMessage": optionSelectValidationMessage }], handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: cssBorder,
    activeBoxShadow: cssactiveBoxShadow,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>
  }
]

export const ActivityBranchTrainingCompletedForm = [  
  { "key": "TrainingTopic",
   "hide": false,
    "type": "text",
     "disabled": false,
     "id": "TrainingTopic",
     "label": "Topic of training",
      "validators": [
         { "name": "maxLength", "value": 30, "validationMessage": "Maximum 30 charactors allowed" }] },
  {
    "key": "DateOfTraining",
    "hide": false, "type": "datetime-local",
    "disabled": false,
    "id": "DateOfTraining",
    "min": `${format(new Date(), dateFormat)}T${format(new Date(), 'kk:mm')}`,
    "label": "Date Of Training", 
    "validators": [{ "name": "required", "value": true, "validationMessage": "Meeting start time is required." }]
  },
  { "key": "UploadTrainingAttendanceDocument1",
  "hide": false,
  "type": "file",
  "dropTitle": "Training Attendance Document",
  "acceptFileTypes":acceptFileType,
  "componentType": "DRAG_DROP_BROWSE", 
  "layout": "1column", 
  "restrictMultipleFiles": true, 
  "disabled": false, 
  "id": "UploadTrainingAttendanceDocument1", 
  "label": "Training Attendance Document", 
    "AllowedFileTypesErrorMessage": "File type not allowed. Allowed file types are image/jpeg, image/jpg, image/png, application/pdf",
  "name":"UploadTrainingAttendanceDocument1", 
    "validators": [{
      "name": "required", "value": true, "validationMessage": "Training Attendance Document is required." }] 
},
  { "key": "UploadTrainingAttendancePhoto1",
  "hide": false,
  "type": "file",
  "dropTitle": "Group Photo Of Training",
  "acceptFileTypes":acceptFileType,
  "componentType": "DRAG_DROP_BROWSE", 
  "layout": "1column", 
  "restrictMultipleFiles": true, 
  "disabled": false, 
  "id": "UploadTrainingAttendancePhoto1", 
  "label": "Group Photo Of Training", 
    "AllowedFileTypesErrorMessage": "File type not allowed. Allowed file types are image/jpeg, image/jpg, image/png, application/pdf",
  "name":"UploadTrainingAttendancePhoto1", 
    "validators": [{
      "name": "required", "value": true, "validationMessage": "Group Photo Of Training is required." }] 
},
  {
    "key": "ActualNoOfTrainees", 
    "props": { "min": "0" }, 
    "hide": false, 
    "type": "number", "disabled": false, 
    "id": "ActualNoOfTrainees", 
    "label": "No of participants", "validators": [
    { "name": "required",
      "value": true,
      "validationMessage": "No Of Participants are required." },
      { "name": "maxLength", "value": 2, "validationMessage": "Maximum 2 digits allowed" }]
  },
  { "key": "Remarks",
   "hide": false,
    "type": "text",
     "disabled": false,
     "id": "Remarks",
     "label": "Remarks",
      "validators": [
        { "name": "required", "value": true, "validationMessage": "Remarks is required." },
         { "name": "maxLength", "value": 100, "validationMessage": "Maximum 30 charactors allowed" }] },
 
  {
    "key": "IsClaimRequired",
    "name": "IsClaimRequired",
    "type": "CustomSwitch",
    "disabled": false,
    "id": "IsClaimRequired",
    "label": claimtravelExpenseLabel,
    "validators": [{ "name": "required", "value": false, "validationMessage": optionSelectValidationMessage }], handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: cssBorder,
    activeBoxShadow: cssactiveBoxShadow,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>
  }
]


export const ActivityCompletedFormPart1 = [
  { "key": "TransportModeID",
  "hide": false,
   "type": "radio",
   "disabled": false,
   "id": "ModeOfTransport",
   "label": modeOfTransportLabel,
   "validators": [{ "name": "required", "value": true, "validationMessage": modeOftransportRequired }], "options": [] },
]

export const ActivityCompletedFormPart2 = [
  { "key": "TravelExpence",
  "hide": false,
  "type": "number",
  "props": { "min": "0" },
  "disabled": false,
  "id": "TravelExpence",
  "label": travelExpenseLabel,
  "validators": [{ "name": "required", "value": true, "validationMessage": travelExpenseValidationmessage },
  { "name": "maxLength", "value": 4, "validationMessage": fourDigitAllowed }] },

  { "key": "UploadBill",
   "hide": false,
   "type": "file",
   "dropTitle": billPhoto,
   "acceptFileTypes":acceptFileType,
   "componentType": "DRAG_DROP_BROWSE", "layout": "1column", "restrictMultipleFiles": true, "disabled": false, "id": "UploadBill", "label": uploadBill, "validators": [] }
]

export const ActivityCompletedFormPart3 = [{ "key": "VehicleTypeID",
 "hide": false,
 "type": "radio",
 "disabled": false,
 "id": "VehicleType",
 "label": vehicleType,
 "validators": [{ "name": "required", "value": true, "validationMessage": modeOftransportRequired }], "options": [] },
]


//Activity Complet JSON End
export class ActivityFormBuilder {
  constructor(actionType,activityTypeForupdate) {
    this.actionType = actionType;
    this.activityTypeForupdate=activityTypeForupdate;
  }

  getFormTitle = () => {
    let formTitle = ''
    switch (this.actionType) {
      case ACTIVITY_RESCHEDULED:
        formTitle = 'Activity Rescheduled';
        break;
      case ACTIVITY_CANCELLED:
        formTitle = 'Activity Cancelled';
        break;
      case ACTIVITY_COMPLETED:
        formTitle = 'Activity Completed';
        break;
      default:
        formTitle = 'Activity Completed';
        break;
    }
    return formTitle;
  }

  getForm = () => {
    let form = []
    switch (this.actionType) {
      case ACTIVITY_RESCHEDULED:
        form = ActivityRescheduledForm;
        break;
      case ACTIVITY_CANCELLED:
        form = ActivityCancelledForm;
        break;
      case ACTIVITY_COMPLETED:
        if(this.activityTypeForupdate.includes("Branch Training")){
            form = ActivityBranchTrainingCompletedForm;
        }else{
          form=ActivityCompletedForm;
        }
        break;
      default:
        form = ActivityCompletedForm;
        break;
    }
    return form;
  }
}
export const initFormData = (formFields, masterDataState, actionType, activityTypeForupdate) => {
  const formData = [...formFields]
  switch (actionType) {
    case ACTIVITY_RESCHEDULED:
      if (activityTypeForupdate === "ICE for Loan Activity" || "Branch Training"){
        formData[3].hide = true
      }
      formData[4].options = CLAIM_TRAVEL_EXPENCE_OPTIONS
      break;
    case ACTIVITY_CANCELLED:
      if (activityTypeForupdate === "ICE for Loan Activity" || "Branch Training") {
        formData[1].hide = true
      }
      formData[2].options = CLAIM_TRAVEL_EXPENCE_OPTIONS
      break;
    case ACTIVITY_COMPLETED:
      formData[3].options = CLAIM_TRAVEL_EXPENCE_OPTIONS
      break;
    default:
      formData[3].options = CLAIM_TRAVEL_EXPENCE_OPTIONS;
      break;
  }
  return formData
}
export const removeSubArrayFromArray = (subArray, keyForSearch, arrOfObjects) => {
  let newArray = [...arrOfObjects]
  for (const object of subArray) {
    newArray = newArray.filter((item) => item[keyForSearch] !== object[keyForSearch])
  }
  return newArray
}
export const handleDropdownSelection = (event, key, formFields, masterDataState, actionType, role = ROLE_BDM) => {
  let formData = [...formFields]
  switch (actionType) {
    case ACTIVITY_RESCHEDULED:
      switch (key) {
        case "IsClaimRequired":
          if (event) {
            formData = formData.concat(ActivityRescheduledFormPart1)
            formData[5].options = masterDataState['ModeofTransport']
          } else {
            const arrayToRemove1 = ActivityRescheduledFormPart3
            formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);
            const arrayToRemove2 = ActivityRescheduledFormPart2
            formData = removeSubArrayFromArray(arrayToRemove2, "key", formData);
            const arrayToRemove = ActivityRescheduledFormPart1
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData)
          }
          break;
        case "TransportModeID":
          if (event.target.value === '1') {
            formData = formData.concat(ActivityRescheduledFormPart2);
            //  formData[6].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME]

            const arrayToRemove = ActivityRescheduledFormPart3
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          } else if (event.target.value === '2') {
            const arrayToRemove = ActivityRescheduledFormPart2
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
            if (role !== ROLE_BDM) {
              formData = formData.concat(ActivityRescheduledFormPart3);
              formData[6].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME]
            }
          }
          else {
            const arrayToRemove = ActivityRescheduledFormPart3
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          }
      }
      break;
    case ACTIVITY_CANCELLED:
      switch (key) {
        case "IsClaimRequired":
          if (event) {
            formData = formData.concat(ActivityCancelledFormPart1);
            formData[3].options = masterDataState['ModeofTransport']
          } else {

            const arrayToRemove = ActivityCancelledFormPart1
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);

            const arrayToRemove1 = ActivityCancelledFormPart2
            formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);

            const arrayToRemove2 = ActivityCancelledFormPart3
            formData = removeSubArrayFromArray(arrayToRemove2, "key", formData)
          }
          break;
        case "TransportModeID":

          if (event.target.value === '1') {
            formData = formData.concat(ActivityCancelledFormPart2);

            const arrayToRemove1 = ActivityCancelledFormPart3
            formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);
          } else if (event.target.value === '2') {
            const arrayToRemove = ActivityCancelledFormPart2
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
            if (role !== ROLE_BDM) {
              formData = formData.concat(ActivityCancelledFormPart3);
              formData[4].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME]
            }
          }
          else {
            const arrayToRemove = ActivityCancelledFormPart3
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          }
      }
      break;
    case ACTIVITY_COMPLETED:
      switch (key) {
        case "IsClaimRequired":
          if (event) {
            formData = formData.concat(ActivityCompletedFormPart1);
            formData[4].options = masterDataState['ModeofTransport']
          } else {
            const arrayToRemove1 = ActivityCompletedFormPart3
            formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);
            const arrayToRemove2 = ActivityCompletedFormPart2
            formData = removeSubArrayFromArray(arrayToRemove2, "key", formData);
            const arrayToRemove = ActivityCompletedFormPart1
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          }
          break;
        case "TransportModeID":
          if (event.target.value === '1') {
            formData = formData.concat(ActivityCompletedFormPart2);
           const arrayToRemove = ActivityCompletedFormPart3
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          } else if (event.target.value === '2') {
            const arrayToRemove = ActivityCompletedFormPart2
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
            if (role !== ROLE_BDM) {
              formData = formData.concat(ActivityCompletedFormPart3);
              formData[5].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME]
            }
          }
          else {
            const arrayToRemove = ActivityCompletedFormPart3
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          }
      }
      break;
  }
  return formData
}

export const customValidators =[
  {
    key: "ExpectedPremium",
    validator: isPositiveNumber, // function to be called
    message: "Expected Premium should be greater than 0", // message to show
  },
  {
        key: 'CreditHeadOfficerMobileNo',
        validator: numberStartWith6789Validator,
        message: '10 digit phone number starting with 6,7,8,9 only.'
  }
]


