import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import loginLogo from "../../assets/img/fulcrum-logo.png";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import { Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch, connect } from "react-redux";
import moment from "moment";
import { PERMISSIONS, isAuthorized } from "../../auth";
import {
  getSearchLeadData,
  logoutUserReducer,
} from "../../redux-reducer/reducer";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { resetMasterDataSyncTime } from "../../offlineManager/leadOfflineManager";
import Notification from "../notification/notification";
import { setFilterBy, readAllNotification } from "../../redux/actions";
import { withTranslation } from "react-i18next";
import {
  getallnotificationsbyuser,
  getLatestVersionInfo,
  getMasterDataForNotification,
  readsinglenotificationsbyuser,
} from "../../redux/actions/notificationAction";
import "./menuDrawer.scss";
import useOnlineStatus from "../../hooks/useOnlineStatus";
import Fab from "@mui/material/Fab";
import ListItemIcon from "@mui/material/ListItemIcon";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PersonIcon from "@mui/icons-material/Person";
import packageJson from "../../../package.json";
import IdleTimer from "react-idle-timer";
import ModalPopup from "f1-modal-react";
import { Button } from "@material-ui/core";
import SubMenu from "./submenu";
import { post } from "../../api/api";
import {
  REFRESH_TOKEN,
  SIMPLIFY_ANDROID_APP_URL,
  SIMPLIFY_LOGIN_URL,
  SIMPLIFY_SSO_TOKEN,
  SIMPLIFY_WEB_APP_URL,
} from "../../api/baseURL";
import {
  getSession,
  setSession,
  getMobileOS,
  getEnvName,
  checkIsMobile
} from "../../utils/utils";
import {
  addUpdateAppVersionInfo,
  getCurrentInsatlledAppVersion,
} from "../../offlineManager/userAuthOfflineManager";

const drawerWidth = 220;
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50ch",
    },
  },
}));

const MenuDrawer = (props) => {
  const listOfRoutesForAddButton = [
    "/app/home",
    "/app/activity/all",
    "/app/activity/smaActivities",
    "/app/lead/all",
    "/app/businesslead/all",
    "/app/recruitmentlead/all",
    "/app/persistencylead/all",
    "/app/campaignlead/all",
    "/app/calendar",
    "/app/Persistencylead/all",
    "/app/home/myTeam/all",
    "/app/home/myTeam/active",
    "/app/home/myTeam/non-active",
    "/app/persistencylead/home",
    "/app/campaignlead/home",
    "/app/campaignactivity/home",
  ];
  const isOnline = useOnlineStatus();
  // const isMobileView = getMobileOS();
  const checkIsMobileView = checkIsMobile();
  const commonState = useSelector((select) => select);
  const userState = commonState.userReducer;

  const [stateAnchor, setStateAnchor] = useState({ bottom: false });
  const [mobileOpen, setMobileOpen] = useState(false);
  const [drawerState, setDrawerState] = useState({ right: false });
  const [drawerList, setDrawerList] = useState({
    right: false,
  });
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    location: { pathname },
  } = history;
  const [anchorEl, setAnchorEl] = React.useState();
  const [showAddButton, setShowAddButton] = useState(false);
  const [timeout] = useState(1000 * 60 * 60);
  const [showModal, setShowModal] = useState(false);
  const [showAppVersionModal, setShowAppVersionModal] = useState(false);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [tempAppVersion, setTempAppVersion] = useState();
  let idleTimer = useRef();
  const { getLoginCount } = commonState.leadListReducer;

  useEffect(() => {
    if (listOfRoutesForAddButton.includes(pathname)) {
      setShowAddButton(true);
    } else {
      setShowAddButton(false);
    }
  }, [pathname]);
  const UserId = userState?.profileInfo?.userId;
  const RoleId = userState?.profileInfo?.roleId;
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const loggedTime = moment(userState.profileInfo.lastLoginDatetime).format(
    "h:mma"
  );
  const logoutHandler = () => {
    setAnchorEl(null);
    // this will reset the master data sync time, so on next login it will push all master data again
    resetMasterDataSyncTime().then((res) => {
      dispatch(logoutUserReducer());
    });

    clearInterval(interval());
    localStorage.removeItem("roleId");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("permissions");
    localStorage.removeItem("expiration");
    localStorage.removeItem("token");
    sessionStorage.clear();
    history.push("/");
  };
  const menuHandler = (item) => {
    setAnchorEl(null);
    if (item === "CHANGE_PASSWORD") {
      history.push("/app/changePassword");
    }
  };

  const onStay = () => {
    setShowModal(false);
    post(REFRESH_TOKEN, {
      token: getSession("refreshToken"),
      userEmail: commonState.userReducer.profileInfo.email,
    })
      .then((res) => {
        if (res.status === 200) {
          setSession("token", res.data.accessToken);
          setSession("refreshToken", res.data.refreshToken);
          setShowModal(false);
        }
      })
      .catch((error) => {
        if (error && error.status == 400) {
          setShowModal(false);
        }
      });
  };

  const handleOnAction = (e) => {
    setIsTimedOut(false);
  };

  const handleOnActive = (e) => {
    setIsTimedOut(false);
  };

  const handleOnIdle = (e) => {
    if (isTimedOut) {
      this.logoutHandler();
    } else {
      setShowModal(true);
    }
  };

  const getSearchValue = (e) => {
    const inputValue = e.target.value;
    setSearchValue(inputValue);
  };

  const handleSearch = (event, isValid) => {
    if (searchValue === "") {
      return false;
    }
    if ((event.keyCode === 13 && !event.shiftKey) || isValid) {
      event.preventDefault();
      dispatch(getSearchLeadData(UserId, searchValue)).then((response) => {
        if (response.status === 200) {
          history.push("/app/searchListing");
          setSearchValue("");
        }
      });
    }
  };

  const menuId = "primary-search-account-menu";
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleBack = () => {
    history.goBack();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectSame = (el) => {
    switch (el.LeadtypeID) {
      case 1:
        history.push(`/app/lead/detail/${el.LeadId}`);
        break;
      case 2:
        history.push(`/app/persistencyLead/detail/${el.LeadId}`);
        break;
      case 3:
        history.push(`/app/campaignLead/detail/${el.LeadId}`);
        break;
      default:
        break;
    }
  };
  const redirectToDetails = async (el) => {
    switch (el.CategoryID) {
      case 1:
        if (el.TrnActivityID) {
          history.push(`/app/activity/detail/${el.TrnActivityID}`);
        }
        break;
      case 2:
        history.push(`/app/activity/detail/${el.TrnActivityID}`);
        break;
      case 3:
        history.push("/app/calendar");
        break;
      case 4:
        switch (el.LeadtypeID) {
          case 1:
            history.push(`/app/lead/detail/${el.LeadId}`);
            break;
          case 2:
            history.push(`/app/persistencyLead/detail/${el.LeadId}`);
            break;
          case 3:
            history.push(`/app/campaignLead/detail/${el.LeadId}`);
            break;
          default:
            break;
        }
        break;

      case 5:
        history.push(`/app/activity/detail/${el.TrnActivityID}`);
        break;
      case 6:
        redirectSame(el);
        break;

      case 7:
        redirectSame(el);
        break;
      case 8:
        history.push(`/app/activity/detail/${el.TrnActivityID}`);
        break;
      case 9:
        history.push(`/app/activity/detail/${el.TrnActivityID}`);
        break;
      case 10:
        if (el.TrnActivityID) {
          history.push(`/app/activity/detail/${el.TrnActivityID}`);
        } else {
          switch (el.LeadtypeID) {
            case 1:
              history.push(`/app/lead/detail/${el.LeadId}`);
              break;
            case 2:
              history.push(`/app/persistencyLead/detail/${el.LeadId}`);
              break;
            case 3:
              history.push(`/app/campaignLead/detail/${el.LeadId}`);
              break;
            default:
              break;
          }
        }
        break;
      case 11:
      case 12:
        if (el.TrnActivityID) {
          history.push(`/app/salesmanagement/detail/${el.TrnActivityID}`);
        }
        break;
        case 30:
          history.push(`/app/activity/campaigndetails/${el.TrnActivityID}`);
          break;
        case 31:
          history.push(`/app/activity/campaigndetails/${el.TrnActivityID}`);
          break;
      default:
        break;
    }
    const res = await props.readsinglenotificationsbyuser(el.NotificationID);
    if (res?.data.Responsestatus) {
      setDrawerList({ right: false });
      props.readAllNotification(true);
    }
  };

  const toggleNotificationDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerList({ ...drawerList, [anchor]: open });
  };
  const activityClick = () => history.push("/app/activity/create");
  const leadClick = () => history.push("/app/lead/create");
  const agencyCreateLeadHandler = (type) => {
    if (type === "business") {
      history.push("/app/businesslead/create");
    } else {
      history.push("/app/recruitmentlead/create");
    }
  };
  // const list = (anchor) => (
  //   <Box
  //   >
  //     <List className="updatePopupDiv myDashboardDiv">
  //       <ListItem
  //         color="black"
  //         sx={{ fontWeight: "bold", fontSize: 16, color: "#00427E" }}
  //       >
  //         My Dashboard Settings
  //         <CloseIcon
  //           className="closeBtn"
  //           onClick={toggleDrawer(anchor, false)}
  //         />
  //       </ListItem>
  //       {dashboardSettingList?.map((item) => {
  //         return (
  //           <ListItem button key={item.id}>
  //             <Checkbox
  //               checked={item.isChecked}
  //               inputProps={{ "aria-label": "controlled" }}
  //             />
  //             {item.title}
  //             <ListItemText />
  //           </ListItem>
  //         );
  //       })}
  //       <button
  //         data-test="button"
  //         type="submit"
  //         class="btn-indigo btn Ripple-parent btn btn-primary"
  //         id="submitButton"
  //       >
  //         Apply<div data-test="waves" class="Ripple is-reppling"></div>
  //       </button>
  //       <span className="resetClick" onClick={toggleDrawer(anchor, false)}>
  //         <RestartAltIcon className="resetIcon" /> Reset
  //       </span>
  //     </List>
  //   </Box>
  // );
  const drawerMenuListDesktop = (
    <Box
      sx={{
        overflow: "auto",
        backgroundColor: "#00427E",
        color: "#ffffff",
        height: "100vh",
      }}
    >
      <List className="deskMenu">
        {props.roleId === "ADM" &&
          props.menuList.map((item, index) => {
            return <SubMenu item={item} key={index} />;
          })}
        {props.roleId !== "ADM" &&
          props.menuList
            ?.filter((f) => {
              if (userState.profileInfo.channelid === 2 && (RoleId == "NH" || RoleId == "RBH" ||RoleId == "ZBH" ||RoleId == "ABH"))  {
                return f.id !== 3 && f.id !== 9 && f.id !== 6 && f.id !== 15;
              } else if (userState.profileInfo.channelid === 2 && RoleId == "BDM")  {
                return f.id !== 3 && f.id !== 9 && f.id !== 6 ;
              }
              else if (userState.profileInfo.channelid === 13) {
                return f.id !== 4 && f.id !== 9 && f.id !== 5 && f.id !== 15
              } else if (userState.profileInfo.channelid === 3) {
                return f.id !== 9 && f.id !== 15;
              } else if (userState.profileInfo.channelid === 1 && RoleId == "RBH") {
                return  f.id !==15
              }  else if (RoleId == "NH" || RoleId == "ZBH" || RoleId == "RBH" && (userState.profileInfo.channelid !== 21 && userState.profileInfo.channelid !== 1)) {
                return f.id !== 3 && f.id !== 9 && f.id !== 6 && f.id !== 4 && f.id !== 15
              }else if (RoleId == "NH" || RoleId == "ZBH" || RoleId == "RBH" && (userState.profileInfo.channelid === 21 || userState.profileInfo.channelid === 1)) {
                return f.id !== 3  && f.id !== 6 && f.id !== 4 && f.id !== 15
              } else if (userState.profileInfo.channelid === 21) {
                return f.id !== 4 && f.id !== 6 && f.id !== 15;
              } else if (userState.profileInfo.channelid === 79) {
                  return f.id !== 15 && f.id !== 9 && f.id !== 13 && f.id !== 14 && f.id !== 4 && f.id !== 5 && f.id !== 6;
              } else if (userState.profileInfo.channelid === 1 && RoleId == "BDM")  {
                return  f.id !==30
              }
              else {
                return  f.id !==15;
              }
            }).filter((f)=>{
              if ((RoleId === "ABH" || RoleId == "NH" || RoleId == "ZBH" || RoleId == "RBH")  && userState.profileInfo.channelid === 1 ) {
                return f.id !== 18 && f.id !==19 && f.id!==20 && f.id !==21 && f.id !==22;
              } else if ((RoleId === "ABH" || RoleId == "NH" || RoleId == "ZBH" || RoleId == "RBH") && userState.profileInfo.channelid === 2 ) {
                return f.id !== 17 && f.id !==18 && f.id!==20 && f.id !==21 && f.id !==22;
              } else if ((RoleId === "ABH" || RoleId == "NH" || RoleId == "ZBH" || RoleId == "RBH") && userState.profileInfo.channelid === 3 ) {
                return f.id !== 17 && f.id !==19 && f.id!==20 && f.id !==21 && f.id !==22;
              } else if ((RoleId === "ABH" || RoleId == "NH" || RoleId == "ZBH" || RoleId == "RBH") && userState.profileInfo.channelid === 21 ) {
                return f.id !== 17 && f.id !== 18 && f.id !==19 && f.id !==21 && f.id !==22
              } else if ((RoleId === "ABH" || RoleId == "NH" || RoleId == "ZBH" || RoleId == "RBH") && userState.profileInfo.channelid === 13 ) {
                return f.id !== 17 && f.id !== 18 && f.id !==19&& f.id!==20 && f.id !==22
              }else if ((RoleId === "ABH" || RoleId == "NH" || RoleId == "ZBH" || RoleId == "RBH") && userState.profileInfo.channelid === 79 ) {
                return f.id !== 17 && f.id !== 18 && f.id !==19 && f.id!==20 && f.id !==21
              }
               else {
                return f.id !== 17 && f.id !== 18 && f.id !==19 && f.id!==20 && f.id !==21 && f.id !==22
              }
            })
            .map((item) => (
              // <ListItem
              //   disabled={getMenuDisabled}
              //   button
              //   key={item.id}
              //   className="menu-list-item"
              //   onClick={() => handleMenuClick(item)}
              //   selected={item.route === pathname}
              // >
              //   <ListItemText primary={item.icon} secondary={item.title} />
              // </ListItem>
              <SubMenu item={item} />
            ))
            }
      </List>
      <Divider />
    </Box>
  );
  const drawerMenuListMobile = (
    <Box
      className="mobMenu"
      sx={{
        overflow: "auto",
        backgroundColor: "#ffffff",
        color: "#333333",
        height: "100vh",
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "space-between",
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography className="mobLogo" variant="h3">
          <div className="profileMobile">
            <IconButton onClick={handleDrawerToggle} className="closeBtn">
              <CloseIcon />
            </IconButton>
            <div className="leftBox">
              <span className="onlineGreen"></span>
              {userState.profileInfo.firstName?.charAt(0).toUpperCase()}{" "}
              {userState.profileInfo.lastName?.charAt(0).toUpperCase()}
            </div>
            <div className="profText">
              {" "}
              {userState.profileInfo.firstName} {userState.profileInfo.lastName}
              <span>Last Logged in at {loggedTime}</span>
              <span>
                Build version: {getEnvName()} {packageJson.version}
              </span>
            </div>
          </div>
        </Typography>
      </Box>
      <Box sx={{ flex: 5 }}>
        <List className="mobListMenu">
          {props.roleId !== "ADM" &&
            props.menuList
              ?.filter((f) => {
                if (userState.profileInfo.channelid === 2 && (RoleId == "NH" || RoleId == "RBH" ||RoleId == "ZBH" ||RoleId == "ABH"))  {
                return f.id !== 3 && f.id !== 9 && f.id !== 6 && f.id !== 15 && f.id !== 17 && f.id !== 18 && f.id !==19 && f.id !==20 && f.id !==21 && f.id !==22;
              } else if (userState.profileInfo.channelid === 2 && RoleId == "BDM")  {
                return f.id !== 3 && f.id !== 9 && f.id !== 6 && f.id !== 17 && f.id !== 18 && f.id !==19 && f.id !==20 && f.id !==21 && f.id !==22 ;
              } else if (userState.profileInfo.channelid === 13) {
                  return f.id !== 4 && f.id !== 9 && f.id !== 5 && f.id !== 15 && f.id !== 17 && f.id !== 18 && f.id !==19 && f.id !==20 && f.id !==21 && f.id !==22
                } else if (userState.profileInfo.channelid === 3) {
                  return f.id !== 9 && f.id !== 15 && f.id !== 17 && f.id !== 18 && f.id !==19 && f.id !==20 && f.id !==21 && f.id !==22;
                } else if (userState.profileInfo.channelid === 21) {
                  return f.id !== 4 && f.id !== 6 && f.id !== 15 && f.id !== 17 && f.id !== 18 && f.id !==19 && f.id !==20 && f.id !==21 && f.id !==22;
                } else if (userState.profileInfo.channelid === 79) {
                  return f.id !== 15 && f.id !== 9 && f.id !== 13 && f.id !== 14 && f.id !== 4 && f.id !== 5 && f.id !== 6 && f.id !== 17 && f.id !== 18 && f.id !==19 && f.id !==20 && f.id !==21 && f.id !==22;
                }
                else {
                  return f.id !== 15 && f.id !== 17 && f.id !== 18 && f.id !==19 && f.id !==20 && f.id !==21 && f.id !==22;
                }
              }).map((item) =>  (
                <ListItem
                  disabled={props.getMenuDisabled}
                  button
                  key={item.id}
                  className="menu-list-item"
                  onClick={() => handleMenuClick(item, true)}
                >
                  <ListItemText primary={item.icon} secondary={item.title} />
                  <ArrowRightAltRoundedIcon />
                </ListItem>
                ))}
        </List>
      </Box>
      <Divider />
      <Box sx={{ flex: 1 }}>
        <List className="logoutButton">
          <ListItem
            button
            key="logout"
            className="menu-list-item logoutButton"
            onClick={() => logoutHandler()}
          >
            <ExitToAppRoundedIcon />
            <ListItemText primary="Log Out" />
          </ListItem>
        </List>
      </Box>
      <Divider />
    </Box>
  );

  const container =
    props.menuWindow !== undefined ? () => props.menuWindow().document.body : undefined;
  const clearSetFilterBy = () => {
    dispatch(setFilterBy(""));
  };
  const openInNewTab = (href) => {
    const getDeviceType = getMobileOS();
    const userData = userState.profileInfo;
    let redirectUrl = "";
    const matchQuery = `${"Agent_Code="}${userData.agentCode ? userData.agentCode : userData.employeeCode
      }${"&AgentType="}${userData.userType}${"&Source=Actify"}${"&Email="}${userData.email
      }${"&Token="}${SIMPLIFY_SSO_TOKEN}`;
    if (getDeviceType === "Android") {
      redirectUrl = `${SIMPLIFY_ANDROID_APP_URL}${matchQuery}`;
    } else {
      redirectUrl = `${SIMPLIFY_WEB_APP_URL}${matchQuery}`;
    }
    Object.assign(document.createElement("a"), {
      target: "_blank",
      rel: "noopener noreferrer",
      href: redirectUrl,
    }).click();
  };

  const handleMenuClick = (menuItem, isMobile) => {
    if (isMobile) {
      setMobileOpen(false);
    }
    if (menuItem.title === "Activities") {
      clearSetFilterBy();
    }

    // Simplify login
    if (menuItem.id === 5) {
      openInNewTab(SIMPLIFY_LOGIN_URL);
      return;
    }

    if (menuItem.id !== 10) {
      history.push(`${menuItem.route}`);
    } else {
      setDrawerState({ ...drawerState, right: true });
    }
    resetViewFor();
  };

  const resetViewFor = () => {
    dispatch({
      type: "viewFor",
      payload: "SELF",
    });
    dispatch({
      type: "SetBDMByABH",
      payload: "all",
    });
  };

  const [notificationData, setNotificationData] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const getNotificationTypes = async () => {
    const paylod = {
      tableName: "Notificationcategory",
    };

    const resposne = await props.getMasterDataForNotification(paylod);
    setNotificationTypes(resposne.data);
  };

  const getAllNotificationsByUser = async () => {
    const res = await props.getallnotificationsbyuser(UserId);
    let da = res.data?.PushNotifications;
    setNotificationData(da);
    props.readAllNotification(false);
  };
  const checkIsNewVersion = async () => {
    const result = await props.getLatestVersionInfo();
    const { Appversion } = result;
    if (Appversion) {
      setTempAppVersion(Appversion);
      getCurrentInsatlledAppVersion().then((res) => {
        if (res) {
          const { appVersionInfo } = res;
          if (parseInt(Appversion) > parseInt(appVersionInfo)) {
            setShowAppVersionModal(true);
          } else {
            setShowAppVersionModal(false);
          }
        } else {
          addUpdateAppVersionInfo(Appversion);
        }
      });
    }
  };
  const [toggleMobileSearchBox, setToggleMobileSearchBox] = useState(false);
  const onClick = () => {
    setDrawerList({ right: false });
  };

  const interval = () =>
    setInterval(() => {
      // disabeling notifications for GCL channel
      if (userState.profileInfo.channelid !== 13) {
        getAllNotificationsByUser();
      }
      checkIsNewVersion();
    }, 60 * 1000 * 5);

  const readNot = commonState.commonReducer.readAllNotifications;
  useEffect(() => {
    // disabeling notifications for GCL channel
    if (userState.profileInfo.channelid !== 13) {
      getNotificationTypes();
      getAllNotificationsByUser();
    }
    checkIsNewVersion();
    interval();
    localStorage.setItem("roleAdmin", props.roleId);
    // roleId = localStorage.getItem("roleAdmin");
  }, [readNot]);
  const toggleAddDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStateAnchor({ ...stateAnchor, [anchor]: open });
  };
  const addList = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleAddDrawer(anchor, false)}
      onKeyDown={toggleAddDrawer(anchor, false)}
    >
      <List className="updatePopupDiv">
        <ListItem color="black" sx={{ fontWeight: "bold", fontSize: 18 }}>
          Create
          <CloseIcon />
        </ListItem>
        {(commonState.userReducer.profileInfo.channelid !== 21 ||
          (commonState.userReducer.profileInfo.channelid === 21 &&
            commonState.userReducer.profileInfo.roleId !== "RBH")) &&
          <ListItem button onClick={() => activityClick()}>
            <ListItemIcon>
              <Fab size="small" color="primary" aria-label="add" sx={{ mr: 2 }}>
                <PendingActionsIcon />
              </Fab>{" "}
              Create Activity
            </ListItemIcon>
            <ListItemText />
          </ListItem>
        }
        {userState.profileInfo.channelid !== 2 &&
          userState.profileInfo.channelid !== 3 &&
          userState.profileInfo.roleId !== "NH" &&
          userState.profileInfo.roleId !== "RBH" &&
          userState.profileInfo.roleId !== "ZBH" && (
            <ListItem button onClick={() => leadClick()}>
              <ListItemIcon>
                <Fab
                  size="small"
                  color="primary"
                  aria-label="add"
                  sx={{ mr: 2 }}
                >
                  <PersonIcon />
                </Fab>{" "}
                Create Lead
              </ListItemIcon>
              <ListItemText />
            </ListItem>
          )}
        {(userState.profileInfo.roleId === "ABH" ||
          userState.profileInfo.roleId === "BDM") &&
          userState.profileInfo.channelid === 3 && (
            <>
              <ListItem
                button
                onClick={() => agencyCreateLeadHandler("business")}
              >
                <ListItemIcon>
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    sx={{ mr: 2 }}
                  >
                    <PersonIcon />
                  </Fab>{" "}
                  Create Business Lead
                </ListItemIcon>
                <ListItemText />
              </ListItem>
              <ListItem
                button
                onClick={() => agencyCreateLeadHandler("recruitment")}
              >
                <ListItemIcon>
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    sx={{ mr: 2 }}
                  >
                    <PersonIcon />
                  </Fab>{" "}
                  Create Recruitment Lead
                </ListItemIcon>
                <ListItemText />
              </ListItem>
            </>
          )}
        {(commonState.userReducer.profileInfo.roleId === "ABH" || commonState.userReducer.profileInfo.roleId === "RBH") &&
          (commonState.userReducer.profileInfo.channelid === 1 || commonState.userReducer.profileInfo.channelid === 21) && (
            <ListItem
              button
              onClick={() => history.push("/app/salesmanagement/create")}
            >
              <ListItemIcon>
                <Fab
                  size="small"
                  color="primary"
                  aria-label="add"
                  sx={{ mr: 2 }}
                >
                  <PendingActionsIcon />
                </Fab>{" "}
                Create Sales Management Activity
              </ListItemIcon>
              <ListItemText />
            </ListItem>
          )}
      </List>
      <Divider />
    </Box>
  );
  const clearCache = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
      addUpdateAppVersionInfo(tempAppVersion).then((success) => {
        if (success) {
          setShowAppVersionModal(false);
          // delete browser cache and hard reload
          logoutHandler();
          window.location.reload(true);
        }
      });
    } else {
      console.log("cache undefined");
    }
  };
  return (
    <Box sx={{ display: "flex" }}>
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref;
        }}
        element={document}
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        onAction={handleOnAction}
        debounce={250}
        timeout={timeout}
      />
      <ModalPopup
        show={showModal}
        onHide={() => setShowModal(false)}
        size={"sm"}
        centered={true}
        modelHeader={"You have been idle!"}
        modelContent={
          <div className="footerButtons">
            <p>You will be get logged out. Want to stay on the page ?</p>
            <Button
              className="cancelBtn btn Ripple-parent btn btn-primary"
              onClick={() => {
                setShowModal(false);
                idleTimer.reset();
                setIsTimedOut(true);
                logoutHandler();
              }}
            >
              Logout
            </Button>
            &nbsp;
            <Button
              className="btn-black btn Ripple-parent btn btn-primary"
              onClick={onStay}
            >
              Stay
            </Button>
          </div>
        }
        backdrop={"static"}
      />
      <ModalPopup
        show={showAppVersionModal}
        onHide={() => setShowAppVersionModal(false)}
        size={"sm"}
        centered={true}
        modelHeader={""}
        modelContent={
          <div className="footerButtons">
            <p>
              There is new version of the app available; please click on the
              button below to get the latest features of the app.
            </p>
            <p>You will have to Login again to use new app features</p>
            <Button
              className="btn-black btn Ripple-parent btn btn-primary"
              onClick={() => {
                clearCache();
              }}
            >
              Update
            </Button>
          </div>
        }
        backdrop={"static"}
      />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <CssBaseline />
        <AppBar
          className="hdTop"
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            backgroundColor: "#f3f5f7",
            border: "0",
            top: "-6px",
          }}
        >
          <Toolbar className="hdTopDiv">
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              {pathname !== "/app/home" ? (
                <IconButton
                  disabled={getLoginCount.isFirstLogin}
                  sx={{
                    display: { xs: "flex", md: "none" },
                    backgroundColor: "none",
                  }}
                  onClick={handleBack}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              ) : (
                ""
              )}
              <IconButton
                disabled={getLoginCount.isFirstLogin}
                size="small"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ mr: 1 }}
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                disabled={getLoginCount.isFirstLogin}
                sx={{
                  display: { xs: "flex", md: "none" },
                  backgroundColor: "none",
                }}
                onClick={() => {
                  history.push("/app/home");
                }}
              >
                <HomeIcon />
              </IconButton>
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            <Search
              disabled={getLoginCount.isFirstLogin}
              className="srchBox"
              sx={{ display: { xs: "none", md: "flex" } }}
              onKeyUp={(e) => handleSearch(e)}
            >
              <StyledInputBase
                disabled={getLoginCount.isFirstLogin}
                placeholder="Search by name, phone, email address"
                inputProps={{ "aria-label": "search" }}
                color="primary"
                value={searchValue}
                onChange={getSearchValue}
              />
              {props.roleId !== "ADM" && (
                <SearchIconWrapper
                  className="srchBtn"
                  onClick={(e) => handleSearch(e, "isValid")}
                >
                  <SearchIcon style={{ color: "rgba(93, 93, 93, 1)" }} />
                </SearchIconWrapper>
              )}
            </Search>
            <IconButton
              disabled={getLoginCount.isFirstLogin}
              sx={{
                display: { xs: "flex", md: "none" },
                backgroundColor: "none",
              }}
            >
              <SearchIcon
                onClick={() => setToggleMobileSearchBox(!toggleMobileSearchBox)}
              />
            </IconButton>

            {props.roleId !== "ADM" && (
              <IconButton
                disabled={getLoginCount.isFirstLogin}
                sx={{
                  mr: 1,
                  ml: 1,
                  display: { xs: "flex", md: "none" },
                  backgroundColor: "none",
                }}
                onClick={() => {
                  resetViewFor();
                  history.push("/app/calendar");
                }}
              >
                <DateRangeIcon />
              </IconButton>
            )}
            {props.roleId !== "ADM" && userState.profileInfo.channelid !== 13 && userState.profileInfo.channelid !== 79 && (

              <IconButton
                disabled={getLoginCount.isFirstLogin}
                className="notifiDiv"
                sx={{
                  display: { xs: "flex", md: "flex" },
                  backgroundColor: "none",
                }}
                size="small"
                aria-label="show 17 new notifications"
                onClick={toggleNotificationDrawer("right", true)}
              >
                <Badge
                  badgeContent={
                    notificationData.filter(
                      (f) => f.Isnotificationreadbyuser === false
                    ).length
                  }
                  color="error"
                >
                  <NotificationsIcon style={{ color: "#fff" }} />
                </Badge>
              </IconButton>
            )}
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <SwipeableDrawer
                  anchor={anchor}
                  open={drawerList[anchor]}
                  onClose={toggleNotificationDrawer(anchor, false)}
                  onOpen={toggleNotificationDrawer(anchor, true)}
                >
                  <Notification
                    notificationData={notificationData.slice(0, 5)}
                    notificationTypes={notificationTypes}
                    toggleNotifi={toggleNotificationDrawer}
                    redirectToDetails={redirectToDetails}
                    onClose={onClick}
                    history={history}
                  />
                </SwipeableDrawer>
              </React.Fragment>
            ))}
            {!isOnline ? (
              <Typography className="networkStatusDiv" variant="h6">
                <span className="offlineCircle"></span>
              </Typography>
            ) : (
              <Typography className="networkStatusDiv" variant="h6">
                <span className="onlineCircle"></span>
              </Typography>
            )}
            {!checkIsMobileView && (
              <>
                <IconButton
                  className="accoDiv"
                  sx={{ display: { xs: "none", md: "flex" } }}
                >
                  <AccountBoxIcon />
                </IconButton>
                <IconButton
                  className="drpMenuIcon"
                  sx={{ display: { xs: "none", md: "flex" } }}
                  size="small"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                >
                  <KeyboardArrowDownRoundedIcon />
                </IconButton>


                <Menu
                  className="menuDrp"
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {isAuthorized(PERMISSIONS.CHANGE_PASSWORD) ? (
                    <MenuItem onClick={() => menuHandler("CHANGE_PASSWORD")}>
                      Change Password
                    </MenuItem>
                  ) : (
                    ""
                  )}
                  <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                </Menu>
              </>
            )}
          </Toolbar>
          {toggleMobileSearchBox && (
            <Toolbar className="hdTopDiv hdSrchBx">
              {props.roleId !== "ADM" && (
                <Search
                  className="srchBox"
                  sx={{ display: { xs: "flex", md: "none" } }}
                  onKeyUp={(e) => handleSearch(e)}
                >
                  <StyledInputBase
                    placeholder="Search by name, phone, email address"
                    inputProps={{ "aria-label": "search" }}
                    color="primary"
                    value={searchValue}
                    onChange={getSearchValue}
                  />
                  {props.roleId !== "ADM" && (
                    <SearchIconWrapper
                      className="srchBtn"
                      onClick={(e) => handleSearch(e, "isValid")}
                    >
                      <SearchIcon style={{ color: "rgba(93, 93, 93, 1)" }} />
                    </SearchIconWrapper>
                  )}
                </Search>
              )}
            </Toolbar>
          )}
        </AppBar>
        {/* Drawer for mobile devices */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawerMenuListMobile}
        </Drawer>
        {/* Drawer for desktop */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <Box sx={{ p: "20px 0 25px 0", textAlign: "center" }}>
            <img
              onClick={() => history.push("/app/home")}
              src={loginLogo}
              alt="loginLogo"
              height={"80vh"}
            />
          </Box>
          {drawerMenuListDesktop}
        </Drawer>
        {/* Drawer for Dashboard settings */}
        {/* <span>
          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              <SwipeableDrawer
                anchor={anchor}
                open={drawerState[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                {list(anchor)}
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </span> */}
      </Box>
      {showAddButton && (
        <span>
          {["bottom"].map((anchor) => (
            <React.Fragment key={anchor}>
              <button
                className="addItem"
                onClick={toggleAddDrawer(anchor, true)}
              >
                +
              </button>
              <SwipeableDrawer
                anchor={anchor}
                open={stateAnchor[anchor]}
                onClose={toggleAddDrawer(anchor, false)}
                onOpen={toggleAddDrawer(anchor, true)}
              >
                {addList(anchor)}
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </span>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getMasterDataForNotification: (payload) =>
    dispatch(getMasterDataForNotification(payload)),
  getallnotificationsbyuser: (payload) =>
    dispatch(getallnotificationsbyuser(payload)),
  readsinglenotificationsbyuser: (payload) =>
    dispatch(readsinglenotificationsbyuser(payload)),

  readAllNotification: (payload) => dispatch(readAllNotification(payload)),
  getLatestVersionInfo: () => dispatch(getLatestVersionInfo()),
});

export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps)(MenuDrawer)
);
