import React, { useState } from "react";
import { useHistory } from "react-router-dom"
import { useSelector } from 'react-redux';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AccountOverViewCard from './accountOverViewCard';
import ActivityOverviewCard from './activityOverviewCard';
import TopPerformingBDMCard from './topPerformingBDMCard';
import CalenderDashboardCard from "./calenderDashboardCard";
import LeadOverviewCard from "./leadOverviewCard";
import BusinessLeadOverviewCard from "./businessLeadOverviewCard";
import RecruitmentLeadOverviewCard from "./recruitmentLeadOverviewCard";
import PersistencyLeadOverview from './persistencyLeadOverview';
import CampaignLeadOverviewCard from "./campaignLeadOverviewCard";
import CampaignActivityOverviewCard from './campaignActivityOverviewCard';
import PersonIcon from '@mui/icons-material/Person';
import MyTeamOverviewCard from "./myTeamOverviewCard";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const DashboardMyTeamLayout = ({ selectedBDM, roleId, selectedView }) => {
    const history = useHistory();
    const commonState = useSelector(state => state)
    const userID = commonState.userReducer.profileInfo.userId;
    const viewForStatus = commonState.commonReducer.viewForStatus
    const [stateToggleDrive, setStateToggleDrive] = useState({ 'bottom': false });
    const activityClick = () => history.push("/app/activity/create");
    const leadClick = () => history.push("/app/lead/create");
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setStateToggleDrive({ ...stateToggleDrive, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className="updatePopupDiv">
                <ListItem color="black" sx={{ fontWeight: 'bold', fontSize: 18 }} >
                    Create
                    <CloseIcon />
                </ListItem>
                <ListItem
                    button
                    onClick={() => activityClick()}>
                    <ListItemIcon>
                        <Fab size="small" color="primary" aria-label="add" sx={{ mr: 2 }}>
                            <PendingActionsIcon />
                        </Fab> + Create Activity
                    </ListItemIcon>
                    <ListItemText />
                </ListItem>
                {commonState.userReducer.profileInfo.channelid !== 2 &&
                    <ListItem
                        button
                        onClick={() => leadClick()}
                    >
                        <ListItemIcon >
                            <Fab size="small" color="primary" aria-label="add" sx={{ mr: 2 }}>
                                <PersonIcon />
                            </Fab> + Create Lead
                        </ListItemIcon>
                        <ListItemText />
                    </ListItem>
                }
            </List>
            <Divider />
        </Box>
    );

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            textAlign: 'left',
            color: theme.palette.text.secondary,
            //height: '100%',
            padding: theme.spacing(2),

        },
        paper2: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary,
            // height: '300px',
        },
        activityLogo: {
            //  borderRadius: 10,
            textAlign: 'center',
            backgroundColor: 'rgb(0 66 126 / 27%)',
            height: '50px',
            padding: theme.spacing(2),
        },
        heading: {
            padding: "1px 2px 2px 1px",
        }
    }));
    const classes = useStyles();
    return <>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4} >
                    <AccountOverViewCard
                        selectedBDM={selectedBDM}
                        selectedView={selectedView}
                        roleId={roleId} classes={classes}
                        Item={Item} history={history}
                        rbhView={true}></AccountOverViewCard>
                    {
                        viewForStatus === 'BDM' && selectedBDM === 0 &&
                        <Grid item xs={12} md={12} >
                            <MyTeamOverviewCard classes={classes} Item={Item} history={history}></MyTeamOverviewCard>
                        </Grid>
                    }
                    <ActivityOverviewCard
                        classes={classes}
                        Item={Item}
                        selectedBDM={selectedBDM}
                        selectedView={selectedView}
                        roleId={roleId}
                        history={history}
                    >
                    </ActivityOverviewCard>
                    {commonState.userReducer.profileInfo.channelid === 2 &&
                        <CampaignActivityOverviewCard
                            classes={classes}
                            Item={Item}
                            selectedBDM={selectedBDM}
                            selectedView={selectedView}
                            roleId={roleId} history={history}>
                        </CampaignActivityOverviewCard>
                    }
                    {commonState.userReducer.profileInfo.channelid !== 3 && <>
                        <LeadOverviewCard classes={classes} Item={Item}
                            selectedBDM={selectedBDM}
                            selectedView={selectedView}
                            roleId={roleId}
                            history={history}></LeadOverviewCard>
                    </>}
                    {commonState.userReducer.profileInfo.channelid === 3 && <>
                        <BusinessLeadOverviewCard classes={classes} Item={Item} selectedBDM={selectedBDM} selectedView={selectedView} roleId={roleId} history={history} />
                        <RecruitmentLeadOverviewCard classes={classes} Item={Item} selectedBDM={selectedBDM} selectedView={selectedView} roleId={roleId} history={history} />
                    </>}

                </Grid>

                {selectedBDM !== 0 && selectedBDM !== undefined &&
                    <Grid item xs={12} md={4}>
                        <CalenderDashboardCard
                            selectedBDM={selectedBDM}
                            selectedView={selectedView}
                            roleId={roleId} classes={classes}
                            Item={Item} history={history}>
                        </CalenderDashboardCard>

                    </Grid>}
                {commonState.userReducer.profileInfo.channelid !== 21 && <>
                    <Grid item xs={12} md={4}>
                        <PersistencyLeadOverview classes={classes}
                            Item={Item}
                            selectedBDM={selectedBDM}
                            selectedView={selectedView}
                            roleId={roleId}
                            history={history}>
                        </PersistencyLeadOverview>
                        <CampaignLeadOverviewCard
                            classes={classes}
                            Item={Item}
                            selectedBDM={selectedBDM}
                            selectedView={selectedView}
                            roleId={roleId} history={history}
                        />
                    </Grid>
                </>}

                <Grid item xs={12} md={4} >
                   
                    <TopPerformingBDMCard
                        classes={classes}
                        userID = {userID}
                        Item={Item}
                        selectedBDM={selectedBDM}
                        selectedView={selectedView}
                        roleId={roleId}
                        history={history}
                    >
                    </TopPerformingBDMCard>
                  

                </Grid>


            </Grid>
        </Box>
        <span>
            {['bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                    {/* <button className="addItem" onClick={() =>{toggleDrawer(anchor, true)}}>+</button> */}
                    {/* </Box> */}
                    <SwipeableDrawer
                        anchor={anchor}
                        open={stateToggleDrive[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </span>
    </>
}

export default DashboardMyTeamLayout;
