import React, { useState } from "react";
import LeadListing from "./leadDataGridListing";
import { connect ,useSelector } from "react-redux";
import {
  activateLoader,
} from "../../../redux/actions/common";
import { useLocation } from "react-router-dom";
export const LeadAgencyListing = () => {
  const ChannelId = 3;
  const BUSINESS_LEAD_AGENCY = "Business Agency Lead Listing";
  const [recruitment] = useState(false);
  const fileName = "BusinessLeadsAgency_";
  return (
    <>
      <LeadListing
        ChannelId={ChannelId}
        Listingtitle={BUSINESS_LEAD_AGENCY}
        Isrecruitmentlead={recruitment}
        fileName={fileName}
      />
    </>
  );
};

export const LeadBancaListing = () => {
  const ChannelId = 1;
  const BUSINESS_LEAD_BANCA = "Business Banca Lead Listing";
  const [recruitment] = useState(false);
  const fileName = "BusinessLeadsBanca_";
  return (
    <>
      <LeadListing
        ChannelId={ChannelId}
        Listingtitle={BUSINESS_LEAD_BANCA}
        Isrecruitmentlead={recruitment}
        fileName={fileName}
      />
    </>
  );
};
export const LeadBrocaListing = () => {
  const ChannelId = 21;
  const BUSINESS_LEAD_BANCA = "Broca Business Lead Listing";
  const [recruitment] = useState(false);
  return (
    <>
      <LeadListing
        ChannelId={ChannelId}
        Listingtitle={BUSINESS_LEAD_BANCA}
        Isrecruitmentlead={recruitment}
      />
    </>
  );
};

export const RecruitmentLeadAgencyListing = () => {
  const ChannelId = 3;
  const RECRUITMENT_LEAD_AGENCY = "Recruitment Agency Lead Listing";
  const [recruitment] = useState(true);
  const fileName = "RecruitmentLeadsAgency_";
  return (
    <>
      <LeadListing
        ChannelId={ChannelId}
        Listingtitle={RECRUITMENT_LEAD_AGENCY}
        Isrecruitmentlead={recruitment}
        fileName={fileName}
      />
    </>
  );
};

  const LeadListingAll  = (props) => {
    const commonState = useSelector((state) => state);
    const location = useLocation();
    const userState = commonState.userReducer;
    const [channelId] = useState(userState.profileInfo.channelid)
    const LEAD_TITLE = "Lead Listing";
    const IsRecruitmentlead = location.pathname.includes("recruitmentlead")
    const fileName = "Leads_";
  return (
    <>
        <LeadListing
        ChannelId={channelId}
        Listingtitle={LEAD_TITLE}
        Isrecruitmentlead={IsRecruitmentlead}
        fileName={fileName}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
});
export default (
  connect(mapStateToProps, {
    activateLoader,
  })(LeadListingAll)
);