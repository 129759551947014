import React, { useState, useEffect, useRef } from "react";
import DynamicForm from "f1-dynamic-form-react";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import {
  SalesActivityFormBuilder,
  OtherReason,
} from "./updateSmaActivityBrocaForm";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import "./../createActivity.scss";
import {
  FOUR_WHEELER,
  PUBLIC_TRANSPORT,
  SELF_OWNED_TRANSPORT,
  TWO_WHEELER,
  ABH_SALES_ACTIVITY_TYPES_ID,
  ACTIVITY_CANCELLED,
  ACTIVITY_COMPLETED,
  ACTIVITY_RESCHEDULED,
  CONFIGS,
} from "../../../../utils/config";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import {
  setBreadcrumbs,
  activateErrorMessageBar,
  activateLoader,
  deActivateLoader,
} from "../../../../redux/actions/common";
import { minusMinuteFromDate } from "../../../../utils/utils";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { validateActivity } from "../../../../redux/actions/createActivityActions";

import {
  updateDynamicData,
  getActivityById,
  uploadFileToBlob,
  activateSuccessMessageBar,
  getActivityStatusMasterData,
  getReasons,
  getAcitvityDuration,
  getPartnerDetails,
} from "../../../../redux/actions";

import {
  validateIsCheckin,
} from "../../../../redux-reducer/reducer";
import { isPositiveNumber } from "../../../../utils/validators";

const UpdatesalesManagementDetailBroca = (props) => {
  const [currentData, setCurrentData] = useState([]);
  const select = useSelector((state) => state);
  const dispatch = useDispatch();
  const activityType = select.commonReducer.Activities;
  const UserId = select.userReducer.profileInfo.userId;
  const activityTypeList = activityType.filter((item) =>
    ABH_SALES_ACTIVITY_TYPES_ID.includes(Number(item.value))
  );

  const travelExpenseValidationmessage = "Travel expense is required.";
  const fourDigitAllowed = "Maximum 4 digits allowed";
  const modeOftransportRequired = "mode of Trasport is required.";
  const customValidators = [
    {
      key: "TravelExpence",
      validator: isPositiveNumber, // function to be called
      message: "Expense amount should be greater than 0", // message to show
    },
    {
      key: "TravelExpense",
      validator: isPositiveNumber, // function to be called
      message: "Expense amount should be greater than 0", // message to show
    },
  ];

  const _formatDate = "YYYY-MM-DDTHH:mm";
  const _format = "YYYY-MM-DD HH:mm:ss";
  const DateFormatString = "YYYY-MM-DDTHH:mm";

  const history = useHistory();

  const TrnActivityID = props.match.params.id;
  let SmaReason = [];
  let activityName = select.updateSalesManagementActivityReducer.activityName;
  const activityData =
    select.updateSalesManagementActivityReducer.activityData.ActivityID;

  const [formBuilder, setFormBuilder] = useState([]);

  const selectOption = [{ key: "Select", label: "Select", value: "" }];
  const [current, setCurrent] = useState({
    MeetingStartDateTime: moment(new Date()).format(_formatDate),
    MeetingEndDateTime: moment(new Date()).add(30, "m").format(_formatDate),
    CBIsClaimRequired: false,
    CROIsClaimRequired: false,
    CJIsClaimRequired: false,
    IsClaimRequired: false,
    IsPartnerMet: select.updateSalesManagementActivityReducer.activityData.ActivityID == 55 ? "1" : null
  });
  const [extErrors] = useState({});
  const [uploadedFile, setuploadedFile] = useState({
    uploadedFile: "",
    UploadPdfFile: "",
  });
  const [OldData, setOldData] = useState();
  const [responseMessage, setresponseMessage] = useState("");
  const [activityStatus, setActivityStatus] = useState({});
  const [branchId, setBranchId] = useState();
  const [partnerDetails, setPartnerDetails] = useState([]);
  let dyFormRef = useRef();

  useEffect(() => {
    if (navigator.onLine) {
      fetchData();
      setCurrentData(currentData)
    }
  }, []);

  const fetchData = async () => {
    props.activateLoader();
    const getCurrentActivity = await dispatch(getActivityById(TrnActivityID));
    if (getCurrentActivity.status === 200) {
      setOldData(getCurrentActivity.data);
    }
    props.deActivateLoader();
  };

  useEffect(() => {
    statusFunction();
    const fetch = async () => {
      const data = await props.getReasons();
      if (data.status === 200) {
        SmaReason = data.data;
      }
      const formBuilderdata = formBuilderData();
      setFormBuilder(formBuilderdata);
      const formData = formBuilderdata.getForm()
      formData.find((val, index) => {
        if (val.key === "TransportModeID" || val.key === "VehicleTypeID"  || val.key === "TravelExpence" || val.key === "TravelExpense" || val.key === "UploadBill") {
          formData[index].hide = true;
        }
        if (val.key==="TransportModeID"){
          formData[index].options =props.masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME];
        }
        if (val.key==="VehicleTypeID"){
          formData[index].options = props.masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME];
        }
      })
      LoadBreadCrumbs();
      const getCurrentActivity = await dispatch(getActivityById(TrnActivityID));
      if (getCurrentActivity.status === 200) {
        setBranchId(getCurrentActivity.data.BranchId);
      }
        const id = select.commonReducer.Branches.filter(
          (item) => item.value == getCurrentActivity.data.BranchId
        );
        console.log("id", id);
        if (select.updateSalesManagementActivityReducer.activityName == "ACTIVITY_COMPLETED" && select.updateSalesManagementActivityReducer.activityData.ActivityID == 55) {
          formData[1].hide = false;
          formData[2].hide = false;
          const res = await props.getPartnerDetails(id[0].key);
          setPartnerDetails(res.data);
          formData[1].options = getDropdownData(
            res.data,
            "BranchCode",
            "PartnerName",
            "PartnerName"
          );
          formData[2].validators = [];
        }
        setCurrentData(formData);
        setCurrent({...current,IsPartnerMet: "1"})
    };
    fetch();
  }, []);

  const getOptionActivityType = (optionList) => {
    const options = optionList.map((item) => {
      return {
        key: item["key"],
        label: item["label"],
        value: item["key"],
      };
    });
    return [...selectOption, ...options];
  };

  const getOptionActivityDuration = (optionList) => {
    const options = optionList.map((item) => {
      return {
        key: item["key"],
        label: `${item["label"]} mins`,
        value: item["value"],
      };
    });
    return [...selectOption, ...options];
  };

  const getOptionSmaReason = (optionList) => {
    const options = optionList.map((item) => {
      if (item.IsActive) {
        return {
          key: item["ReasonCode"],
          label: item["ReasonName"],
          value: item["ReasonID"],
        };
      }
    });
    return [...selectOption, ...options];
  };

  const getOptionReason = (optionList) => {
    const options = optionList.map((item) => {
      if (item.IsActive) {
        return {
          key: item["ReasonCode"],
          label: item["ReasonName"],
          value: item["ReasonName"],
        };
      }
    });
    return [...selectOption, ...options];
  };

  const formBuilderData = () => {
    return new SalesActivityFormBuilder(
      select.updateSalesManagementActivityReducer.activityComplete
        ? ACTIVITY_COMPLETED
        : select.updateSalesManagementActivityReducer.activityReshedule
        ? ACTIVITY_RESCHEDULED
        : ACTIVITY_CANCELLED,
      select.updateSalesManagementActivityReducer.activityName,
      getOptionActivityType(activityTypeList),
      activityData,
      getOptionActivityDuration(props.masterDataState.SmaActivityDuration.slice(4,7)),
      getOptionSmaReason(SmaReason),
      getOptionReason(SmaReason)
    );
  };


  const renderRadioIcons = (radioOption) => {
    if (radioOption.key === PUBLIC_TRANSPORT) {
      return <DirectionsBusIcon></DirectionsBusIcon>;
    } else if (radioOption.key === SELF_OWNED_TRANSPORT) {
      return <TwoWheelerIcon></TwoWheelerIcon>;
    } else if (radioOption.key === TWO_WHEELER) {
      return <TwoWheelerIcon></TwoWheelerIcon>;
    } else if (radioOption.key === FOUR_WHEELER) {
      return <DirectionsCarIcon></DirectionsCarIcon>;
    } else {
      return "";
    }
  };

  const LoadBreadCrumbs = () => {
    let breadcrumbs = [];
    switch (activityName) {
      case "ACTIVITY_COMPLETED":
        breadcrumbs = [
          {
            name: "Sales Management",
            url: `/app/activity/smaActivities`,
          },
          {
            name: "Complete activity",
            url: `/app/broca/salesmanagement/update/${TrnActivityID}`,
          },
        ];
        dispatch(setBreadcrumbs(breadcrumbs));
        break;
      case "ACTIVITY_CANCELLED":
        breadcrumbs = [
          {
            name: "Sales Management",
            url: `/app/activity/smaActivities`,
          },
          {
            name: "Cancel activity",
            url: `/app/broca/salesmanagement/update/${TrnActivityID}`,
          },
        ];
        dispatch(setBreadcrumbs(breadcrumbs));
        break;
      case "ACTIVITY_RESCHEDULED":
        breadcrumbs = [
          {
            name: "Sales Management",
            url: `/app/activity/smaActivities`,
          },
          {
            name: "Rescheduled activity",
            url: `/app/broca/salesmanagement/update/${TrnActivityID}`,
          },
        ];
        dispatch(setBreadcrumbs(breadcrumbs));
        break;
        default :
        break;
    }
  };

  const getDropdownData = (optionsArr, key, label, value) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr?.map((option) => {
        return { key: option[key], label: option[label], value: option[value] };
      });
    }
    arr.unshift({ key: "select", label: "select", value: "" });
    return arr;
  };

  const onChangeHandler = async (event, key, model) => {
    const form = [...currentData];

    if (key === "IsPartnerMet") {
      const id = select.commonReducer.Branches.filter(
        (item) => item.value == branchId
      );
      console.log("id", id);
      if (event.target.value === "1") {
        form[1].hide = false;
        form[2].hide = false;
        const res = await props.getPartnerDetails(id[0].key);
        setPartnerDetails(res.data);
        form[1].options = getDropdownData(
          res.data,
          "BranchCode",
          "PartnerName",
          "PartnerName"
        );
        form[1].validators = [
          {
            name: "required",
            value: true,
            validationMessage: "Please select Partner Name option ",
          },
        ];
        form[2].validators = [];
        setCurrentData(form);
      } else if (event.target.value === "0") {
        form[1].hide = true;
        form[2].hide = true;
        form[1].validators = [];
        form[2].validators = [];
      }
    }
    const isExpense = currentData && currentData.length > 0 && currentData.find((data) => data.key === "TransportModeID");
    const isVehicleType = currentData && currentData.length > 0 && currentData.find((data) => data.key === "VehicleTypeID");
    const isTravelExpense = currentData && currentData.length > 0 && currentData.find((data) => data.key === "TravelExpence" || data.key === "TravelExpense");
    const isUploadBill = currentData && currentData.length > 0 && currentData.find((data) => data.key === "UploadBill");
    if (key === "PartnerName") {
      const getPartnerMobileNO = partnerDetails.filter(
        (item) => item.PartnerName == event.target.value
      );
      setCurrent({ ...current, MobileNo: getPartnerMobileNO[0]?.Mobilenumber });
    }
    if (key === "Reason") {
      if (event.target.value === "Other Reason") {
        setCurrentData([
          ...currentData.slice(0, 1),
          ...OtherReason,
          ...currentData.slice(1),
        ]);
      } else {
        const newData = currentData.filter((val) => {
          return val.key !== "Other_Reason";
        });

        setCurrentData(newData);
      }
    }
    if (
      key === "UploadBill" ||
      key === "UploadPdfFile" ||
      key === "UploadTrainingAttendancePhoto1"
    ) {
      uploadFile(event, key);
    }

    if (key === "MeetingStartDateTime") {
      const newDate = moment(event.target.value)
        .add(30, "m")
        .format(DateFormatString);
      var StartDate = moment(event.target.value).format(_formatDate);
      currentData.map((option) => {
        if (option.key === "MeetingEndDateTime") {
          option.max = moment(StartDate)
            .endOf("day")
            .format("YYYY-MM-DD kk:mm");
          option.min = StartDate;
        }
      });
      setCurrent({
        ...current,
        MeetingStartDateTime: event.target.value,
        MeetingEndDateTime: newDate,
      });
      if (key === "MeetingEndDateTime") {
        var newEndDate = moment(event.target.value).format(DateFormatString);
        setCurrent({ ...current, MeetingEndDateTime: newEndDate });
      }
    }
    // if (key === "MeetingEndDateTime") {
    //   var endDate = moment(event.target.value).format(DateFormatString);
    //   setCurrent({ ...current, MeetingEndDateTime: endDate });
    // }
    if(key === "IsClaimRequired"){
      if (event === false){
        currentData.find((val, index)=>{
         if (val.key==="VehicleTypeID"){
          currentData[index].validators =  []
         }
         if (val.key==="TravelExpence" || val.key==="TravelExpense"){
          currentData[index].validators =  []
         }
         if (val.key==="TransportModeID"){
          currentData[index].validators =  []
         }
       })
     }
     if (event === true) {
      setCurrent({
           IsClaimRequired: true
       });
       isExpense.hide = false;
       let checkIn;
           checkIn = await props.validateIsCheckin({ checkinFor: "activity", Id: TrnActivityID })
       const { data } = checkIn
         if (data === true){
          currentData.find((val, index) => {
         if (val.key === "TransportModeID"){
          currentData[index].validators = [
           {
             name: "required",
             value: true,
             validationMessage: "This field is required",
           }]
           }
         })
       }
       if (data) {
        setCurrent({
             IsClaimRequired: data,
             TransportModeID: null,
             TravelExpence: null,
           UploadBill: null,
           VehicleTypeID: null
           });
         isExpense.hide = false;
         isExpense.validators = [
          {
            name: "required",
            value: true,
            validationMessage: "Mode of transport is required.",
          },
        ]
         const errorElement1 =
           document.getElementsByClassName(`activeRadio`);
         if (errorElement1.length > 0) {
           errorElement1[0].classList.remove("activeRadio");
         }
       } else {
        setCurrent({
             IsClaimRequired: false,
           });
         isExpense.validators = [];
         isExpense.hide = true;
         isVehicleType.hide = true;
         isTravelExpense.hide = true;
         isTravelExpense.validators = [];
         isUploadBill.hide = true;
         props.activateErrorMessageBar(
           "Please Check-In first to claim the expenses"
         );
         delete model.errors["UploadBill"];
       }
     } else {
       setCurrent({
           IsClaimRequired: false,
       });
       isExpense.hide = true;
       isVehicleType.hide = true;
       isTravelExpense.hide = true;
       isUploadBill.hide = true;
     }
    }
    if(key === "TransportModeID"){
        const TransportModeID = event.target.value;
        if (TransportModeID === "1") {
          isVehicleType.hide = true;
          isVehicleType.validators = [];
          isTravelExpense.hide = false;
          isUploadBill.hide = false;
          isTravelExpense.validators = [
            {
              name: "required",
              value: true,
              validationMessage: travelExpenseValidationmessage,
            },
            { name: "maxLength", value: 4, validationMessage: fourDigitAllowed },
          ];
          setCurrent({
              VehicleTypeID: null,
            });
        } else { 
          isVehicleType.hide = false;
          isVehicleType.validators = [
            {
              name: "required",
              value: true,
              validationMessage: modeOftransportRequired,
            },
          ];
          isTravelExpense.hide = true;
          isTravelExpense.validators = [];
          isUploadBill.hide = true;
          
          setCurrent({
            TravelExpence: null,
              UploadBill: null,
            }
          );
        }
      }
  };

  const uploadFile = async (files, key) => {
    if (navigator.onLine) {
      const fileRes = await props.uploadFileToBlob({ file: files[0] });
      const { downloadUrl } = fileRes.data;
      if (fileRes.status === 200) {
        if (key === "UploadBill") {
          setuploadedFile({
            ...uploadedFile,
            uploadedFile: downloadUrl,
            originalFileName: files[0].name,
          });
        } else {
          setuploadedFile({ ...uploadedFile, UploadPdfFile: files[0].name });
        }
      }
    } else {
      props.activateErrorMessageBar("Cannot upload files in offline mode!");
    }
  };

  const isValidBusinessActivity = async (formData) => {
    if (
      formData.hasOwnProperty("MeetingStartDateTime") &&
      formData.hasOwnProperty("MeetingEndDateTime")
    ) {
      const checkActivity = await props.validateActivity({
        user_id: UserId,
        formData: formData,
      });
      if (checkActivity.data.Responsestatus === false) {
        setresponseMessage(checkActivity.data.Responsemessage);
      }
      return (
        checkActivity.status === 200 &&
        checkActivity.data.Responsestatus === true
      );
    }
    return true;
  };
  const statusFunction = async () => {
    const result = await props.getActivityStatusMasterData();
    if (result.status === 200) {
      let statusId = [];
      if (select.updateSalesManagementActivityReducer.activityComplete) {
        statusId = result.data.filter(
          (f) => f.ActivityStatus === "Activity Completed"
        );
      } else if (
        select.updateSalesManagementActivityReducer.activityReshedule
      ) {
        statusId = result.data.filter(
          (f) => f.ActivityStatus === "Activity Rescheduled"
        );
      } else {
        statusId = result.data.filter(
          (f) => f.ActivityStatus === "Activity Cancelled"
        );
      }
      setActivityStatus(statusId[0]);
    }
  };
  const onSubmit = async (model) => {
    if (
      !model.isValidForm ||
      Object.keys(model.errors).length > 0 ||
      Object.keys(extErrors).length > 0
    ) {
      props.activateErrorMessageBar("Please fill all required fields");
      return false;
    }

    props.activateLoader();

    if (model.MeetingStartDateTime && model.MeetingEndDateTime) {
      model.MeetingStartDateTime = model.MeetingStartDateTime.replace(
        "T",
        " "
      ).replace("Z", "");
      model.MeetingEndDateTime = minusMinuteFromDate(model.MeetingEndDateTime)
        .replace("T", " ")
        .replace("Z", "");
    }
    const oldData = OldData;
    oldData.ActivityStatusId = select.updateSalesManagementActivityReducer.activityComplete? 34: select.updateSalesManagementActivityReducer.activityReshedule? 35: 36;
    oldData.ActivityStatus = activityStatus?.ActivityStatus;
    oldData.ModifiedDate = moment(new Date())
      .format(DateFormatString)
      .replace("T", " ")
      .replace("Z", "");
    let formData = { ...oldData, ...model };
    formData["Remarks"] = null;
    for (let key in model) {
      formData[key] = model[key];
    }
    if (
      model.CBIsClaimRequired ||
      model.CJIsClaimRequired ||
      model.CROIsClaimRequired ||
      model.IsClaimRequired
    ) {
      formData["IsClaimRequired"] = true;
      formData["TransportMode"] =
        model.TransportModeID === "1"
          ? "Public Transport"
          : "Self Owned Transport";
    } else {
      formData["IsClaimRequired"] = false;
      formData["TransportMode"] = null;
      formData["TransportModeID"] = null;
      formData["TravelExpence"] = null;
      formData["TravelExpense"] = null;
      formData["VehicleTypeID"] = null;
    }

    formData["ModifiedDate"] = moment().format(_format);
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    delete formData["CBIsClaimRequired"];
    delete formData["CJIsClaimRequired"];
    delete formData["CROIsClaimRequired"];
    const activityTypeListData = activityType.filter((item) => {
      if (parseInt(item.value) === parseInt(activityData)) {
        return item;
      }
    });
    formData["ActivityID"] = activityTypeListData[0].value;
    formData["ActivityName"] = activityTypeListData[0].label;
    formData["ActivityDurationID"] = formData["ActivityDurationID"]
      ? parseInt(formData["ActivityDurationID"])
      : null;
    formData["IsCheckin"] = false;
    formData["ModifiedBy"] = UserId;
    formData["CreatedDate"] = moment(formData["CreatedDate"]).format(_format);

    formData.IsBacklog = 0;
    if (formData.hasOwnProperty("UploadBill")) {
      formData["UploadBill"] = uploadedFile.uploadedFile;
      formData["BillFileName"] = uploadedFile.originalFileName;
    }
    if (formData.hasOwnProperty("UploadPdfFile")) {
      formData["UploadPdfFile"] = uploadedFile.UploadPdfFile;
    }
    formData["OtherReason"] = formData["Other_Reason"];
    const validateMeetingStatusForm = [
      "ACTIVITY_COMPLETED",
      "ACTIVITY_CANCELLED",
    ];
    if (navigator.onLine) {
      if (validateMeetingStatusForm.includes(activityName)) {
        const result = await props.updateDynamicData({
          data: formData,
          formName: CONFIGS.UPDATE_ACTIVITY_FORM,
          formBuilder: formBuilder?.actionType,
          userID: UserId,
        });
        if (result.status === 200) {
          props.activateSuccessMessageBar("Activity is updated successfully.");
          props.deActivateLoader();
          setTimeout(() => {
            const str = `/app/salesmanagement/detail/${TrnActivityID}`;
            history.push(str);
          }, 1000);
        } else {
          props.activateErrorMessageBar("Please fill all required fields");
          props.deActivateLoader();
        }
      } else {
        const isValidActivity = await isValidBusinessActivity(formData);
        if (isValidActivity) {
          const result = await props.updateDynamicData({
            data: formData,
            formName: CONFIGS.UPDATE_ACTIVITY_FORM,
            formBuilder: formBuilder.actionType,
            userID: UserId,
          });
          if (result.status === 200) {
            props.activateSuccessMessageBar(
              "Activity is updated successfully."
            );
            props.deActivateLoader();
            setTimeout(() => {
              const str = `/app/salesmanagement/detail/${TrnActivityID}`;
              history.push(str);
            }, 1000);
          } else {
            props.activateErrorMessageBar("Please fill all required fields");
            props.deActivateLoader();
          }
        } else {
          props.activateErrorMessageBar(responseMessage);
          props.deActivateLoader();
        }
      }
    }
  };
  const removeFile = (e, file, index, key) => {
    dyFormRef.removeFile(e, file, index, key);
    setuploadedFile("");
  };

  const renderUploadedFilesItem = (file, index, key) => {
    return (
      <li style={{ borderColor: "#ccc" }} key={index}>
        <span className="file-name">{file.name}</span>
        <span
          className="action"
          onClick={(e) => removeFile(e, file, index, key)}
        >
          <i className="fa fa-trash"></i>
        </span>
      </li>
    );
  };

  const onCancelHandler = () => {
    history.push(`/app/salesmanagement/detail/${TrnActivityID}`);
  };
  return (
    <>
      <div className="mainContainer createActivity updateActivity">
        <Breadcrumb></Breadcrumb>
        <div className="formBox">
          <DynamicForm
            key={1}
            onRef={(ref) => {
              dyFormRef.current = ref;
            }}
            title={OldData ? OldData.ActivityName : ''}
            defaultValues={current}
            model={currentData}
            onChange={(event, key, model) => onChangeHandler(event, key, model)}
            className="activityTypeDiv"
            renderRadioIcons={(option) => renderRadioIcons(option)}
            showAstersikOnRequiredFields={true}
            onSubmit={(model) => {
              onSubmit(model);
            }}
            customValidators={customValidators}
            extErrors={extErrors}
            extaraActionFormButton={
              <>
                <button
                  className="btn cancelBtn"
                  key="cancel"
                  id="cancel"
                  type="button"
                  onClick={onCancelHandler}
                >
                  {"Cancel"}
                </button>
              </>
            }
            fileUploaderFields={["UploadBill"]}
            renderUploadedFilesItem={renderUploadedFilesItem}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
});
const mapDispatchToProps = (dispatch) => ({
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  updateDynamicData: (payload) => dispatch(updateDynamicData(payload)),
  activateSuccessMessageBar: (payload) =>
    dispatch(activateSuccessMessageBar(payload)),
  activateErrorMessageBar: (payload) =>
    dispatch(activateErrorMessageBar(payload)),
  validateActivity: (payload) => dispatch(validateActivity(payload)),
  uploadFileToBlob: (payload) => dispatch(uploadFileToBlob(payload)),
  validateIsCheckin: (payload) => dispatch(validateIsCheckin(payload)),
  getReasons: (payload) => dispatch(getReasons(payload)),
  getAcitvityDuration: (payload) => dispatch(getAcitvityDuration(payload)),
  getActivityStatusMasterData: (payload) =>
    dispatch(getActivityStatusMasterData(payload)),
  getPartnerDetails: (payload) => dispatch(getPartnerDetails(payload)),
});

export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps)(UpdatesalesManagementDetailBroca)
);
